<button
  type="button"
  [class.loading]="isSubmitted"
  [ngClass]="buttonClasses"
  [title]="'TR_SAVE' | translate"
  [disabled]="(isSubmitted && isCurrent) || isDisabled"
  (click)="submit.emit()"
>
  <i class="pe-7s-diskette"></i>
  <div *ngIf="isSubmitted && isCurrent" class="spinner-border save-btn-loader" role="status">
    <span class="sr-only">Loading...</span> 
  </div>
</button>
