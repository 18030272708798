import { Directive, ElementRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appHelpAlert]'
})
export class HelpAlertDirective {
  constructor(
    public viewContainerRef: ViewContainerRef,
    public elementRef: ElementRef
  ) { }

}
