import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class HelpService {

  setPageName(url: string) {
    if (!url) { return; }
    switch (true) {
      // b2b admin
      case url.includes("/usertype"):
        return "Usertypes";

      case url.includes("/user") && !url.includes("/usertype"):
        return "users";

      case url.includes("/group"):
        return "faculties";

      case url.includes("/student"):
        return "students";

      case url.includes("/organizationSettings"):
        return "organization_settings";
      //

      // Met user
      case url.includes("/document"):
        return "educational_materials";
      //


      default:
        break;
    }
  }

}