import { Component, HostListener, OnInit } from '@angular/core';
import { ThemeOptions } from '../../../theme-options';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MENU_KEY } from 'src/app/Constants/main-keys';
import { TranslationService } from 'src/app/Services/translation.service';
import { IUserData } from 'src/app/Models/IUserData';
import { DataStorageService } from 'src/app/Services/data-storage.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  isMenuLoaded: boolean;
  menu: Array<any> = [];
  currentUser: IUserData;
  public extraParameter: any;

  constructor(
    public globals: ThemeOptions,
    private activatedRoute: ActivatedRoute,
    private translationService: TranslationService,
    private dataStorageService: DataStorageService,
  ) { }

  @select('config') public config$: Observable<any>;

  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboardsMenu';

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  ngOnInit() {
    this.currentUser = this.dataStorageService.getUser();
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    this.extraParameter = this.activatedRoute.snapshot.firstChild.data.extraParameter;

    // es if-y piti pakvi erb vor 'admin' user type-i lriv page-ery tarvi react
    if (this.currentUser && this.currentUser.userTypeId === 2) {
      let _menu = [];
      let menu = JSON.parse(localStorage.getItem(MENU_KEY));
      if (menu && menu.length) {
        menu.forEach(element => {
          if (element.name === "TR_CONTAINER_TWO_PAGE" || element.name === "TR_CONTAINER_FIVE_PAGE") {
            _menu.push(element)
          }
        });
      }
      this.menu = _menu;
    }else{
      this.menu = JSON.parse(localStorage.getItem(MENU_KEY));
    }


    this.menu.forEach(data => {
      if (data.name) {
        this.translationService.translatePages(data.name).subscribe(translatedName => {
          if (translatedName) {
            data.name = translatedName;
          }
          this.isMenuLoaded = data.name === this.menu[this.menu.length - 1].name;
          if (data.children.length) {
            data.children.forEach(children => {
              if (children.name) {
                this.translationService.translatePages(children.name).subscribe(translatedChildName => {
                  if (translatedChildName) {
                    children.name = translatedChildName;
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }
  }
}
