import { Component, OnInit } from '@angular/core';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { IRoleData } from 'src/app/Models/IUserData';
import { ROLE_CREATE, ROLE_DELETE, ROLE_UPDATE, ROLE_VIEW } from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-roles-table',
  templateUrl: './roles-table.component.html',
  styleUrls: ['./roles-table.component.scss']
})
export class RolesTableComponent implements OnInit {
  role_view: boolean;
  role_create: boolean;
  role_update: boolean;
  role_delete: boolean;

  roles: Array<IRoleData> = [];
  userRoles: Array<string> = [];

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    public mainService: MainService,
  ) { }

  ngOnInit(): void {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();

    this.getRoles();
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case ROLE_VIEW:
          this.role_view = true;
          break;
        case ROLE_CREATE:
          this.role_create = true;
          break;
        case ROLE_UPDATE:
          this.role_update = true;
          break;
        case ROLE_DELETE:
          this.role_delete = true;
          break;
      }
    });
  }

  getRoles() {
    if(this.role_view) {
      this.dataHandlerService.getDataWithSpinner('role').then(response => {
        this.roles = response;
      });
    }
  }

}
