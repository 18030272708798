import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IGroupData } from 'src/app/Models/IGroupData';
import { IStudentData } from 'src/app/Models/IStudentData';
import { ITemplateData, ITemplateInfoData } from 'src/app/Models/ITemplateData';
import { IUserData } from 'src/app/Models/IUserData';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { DataStorageService } from 'src/app/Services/data-storage.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { DOCUMENTTEMPLATE_I_VIEW } from 'src/app/Constants/main-keys';
import {
  DOCUMENT_TEMPLATE_INFO_DATA_PATH_KEY,
  DOCUMENT_TEMPLATE_INFO_PATH_KEY,
  GROUPS_PATH_KEY,
  GROUP_STUDENTS_BY_GROUP_ID_PATH_KEY,
  LIBRARY_DATA_PATH_KEY,
  LIB_ID_PATH_PARAM_KEY,
  TEMPLATE_ID_PATH_PARAM_KEY,
  TEMPLATE_INFO_ID_PATH_PARAM_KEY
} from 'src/app/Constants/request-path-keys';

@Component({
  selector: 'app-template-document-form',
  templateUrl: './template-document-form.component.html',
  styleUrls: ['./template-document-form.component.scss']
})
export class TemplateDocumentFormComponent implements OnInit {
  @Input() templateData: ITemplateData;
  @Input() documentBodyData: string;
  @Output() templateDataEmiter = new EventEmitter();
  @Output() closeEmit = new EventEmitter();

  alert: boolean = false;
  alertTitle: string;
  alertContent: string;
  alertType: string;
  currentUser: IUserData;
  templateInfo: Array<ITemplateInfoData>;
  templateModel = {
    a1: '', a2: '', a3: '', a4: '', a5: '', a6: '',
    a7: '', a8: '', a9: '', a10: '', a11: '', a12: '',
    a13: '', a14: '', a15: '', a16: '', a17: '', a18: '',
    a19: '', a20: '', a21: '', a22: '', a23: '', a24: '', a25: '',
    a26: '', a27: '', a28: '', a29: '', a30: '', a31: '', a32: '', a33: '',
    tableData: []
  };
  groupData: Array<IGroupData> = [];
  students: Array<IStudentData> = [];
  studentsList: Array<string> = [];
  studentsInTable: Array<IStudentData> = [];
  listData: Array<any>;
  inputsListData: Array<string> = [];
  tableTds: Array<string> = [];
  tableRowCount: number = 1;
  UpdateTableRowCount: number = 1;
  tableData: Array<any> = [];
  tableLibDatas: Array<any> = [];
  tableLibDatasObs: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  tableColumnCount: number = 0;
  tableListDates: Array<any> = [];
  tableListDatasObs: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  documentId: number;
  userRoles: Array<string>;
  tableHasFirstRow: boolean;
  openView: boolean;

  documenttemplate_i_view: boolean;

  constructor(
    private dataHandlerService: DataHandlerService,
    private dataStorageService: DataStorageService,
    private translationService: TranslationService,
    private mainService: MainService,
    private rolesService: RolesService,
  ) { }

  ngOnInit() {
    this.currentUser = this.dataStorageService.getUser();
    this.userRoles = this.rolesService.userRoles;
    if (this.templateData && this.templateData.id) {
      this.getTemplatesInfoByTemplateId(this.templateData.id);
    }

    this.mainService.templateData.subscribe(data => {
      if (data) {
        if (this.tableData.length) { this.templateModel.tableData = this.tableData; }
        this.templateDataEmiter.emit(this.templateModel);
      }
    });
  }

  ngOnChanges() {
    this.currentUser = this.dataStorageService.getUser();
    this.userRoles = this.rolesService.userRoles;
    if (!this.templateInfo) { return; }
    if (this.templateData.id) {
      this.tableRowCount = 1;
      this.tableData = [];
      this.tableLibDatas = [];
      this.tableListDates = [];
      this.studentsList = [];
      this.students = [];
      this.templateInfo = [];
      this.tableTds = [];
      this.groupData = [];
      this.inputsListData = [];
      this.listData = [];
      this.templateModel = {
        a1: '', a2: '', a3: '', a4: '', a5: '', a6: '',
        a7: '', a8: '', a9: '', a10: '', a11: '', a12: '',
        a13: '', a14: '', a15: '', a16: '', a17: '', a18: '',
        a19: '', a20: '', a21: '', a22: '', a23: '', a24: '', a25: '',
        a26: '', a27: '', a28: '', a29: '', a30: '', a31: '', a32: '', a33: '',
        tableData: []
      };
      this.getTemplatesInfoByTemplateId(this.templateData.id);
    }
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case DOCUMENTTEMPLATE_I_VIEW:
          this.documenttemplate_i_view = true;
          break;
      }
    });
  }

  getTemplatesInfoByTemplateId(id: number): void {
    // if (!this.documenttemplate_i_view) { return; }
    this.dataHandlerService.getData(`${DOCUMENT_TEMPLATE_INFO_PATH_KEY}?${TEMPLATE_ID_PATH_PARAM_KEY}${id}`).then(response => {
      this.templateInfo = response;
      this.setTemplateInfoData();
    });
  }

  setTemplateInfoData(): void {
    if (this.documentBodyData) {
      const documentData = JSON.parse(this.documentBodyData);
      this.templateModel = documentData;
      if (this.templateModel.tableData && this.templateModel.tableData.length) {
        this.tableData = this.templateModel.tableData;
      }
    }

    if (this.templateModel.tableData && this.templateModel.tableData.length) {
      const valueForCheck = this.templateModel.tableData.find(item => item.number == 1);
      if (!valueForCheck) {
        this.tableHasFirstRow = false;
        this.templateData.body = this.templateData.body.replace(`<tr><td>${this.tableRowCount}</td></tr>`, ``);
      } else {
        this.tableHasFirstRow = true;
      }
    }

    this.templateInfo.forEach((data, index) => {
      const position = data.position;

      if (data.type === 'table-input-string'
        || data.type === 'table-input-date'
        || data.type === 'table-input-number'
        || data.type === 'table-library'
        || data.type === 'table-list'
        || data.type === 'table-sign-part'
        || data.type === 'table-number-and-date'
        || data.type === 'table-input-start-end-time'
        || data.type === 'remove-row-button') {
        this.tableTds.push(`<td>{a${data.position}}</td>`);
        if (this.templateModel.tableData.length) {
          this.templateData.body = this.templateData.body.replace(`<td>{a${data.position}}</td>`, `<td class="col${data.position}-${this.templateModel.tableData[0].number}">{a${data.position}}</td>`);
          this.templateData.body = this.templateData.body.replace(`<td>№ {a${data.position}}</td>`, `<td class="col${data.position}-${this.templateModel.tableData[0].number}">№ {a${data.position}}</td>`)
        }
      }

      if (data.type === 'organization-name') {
        this.templateModel[`a${position}`] = this.currentUser.organizationName;
        this.templateData.body = this.templateData.body.replace(`<p>{a${position}}</p>`, `<input id="a${position}" name="a${position}" autocomplete="off" class="custom-input organization-title center" value="${this.currentUser.organizationName}">`);
      }
      else if (data.type === 'input-create-date') {
        this.templateData.body = this.templateData.body.replace(`<p>{a${position}}</p>`, `<input id="a${position}" name="a${position}" type="date" autocomplete="off" class="custom-input date-input">`);
      }
      else if (data.type === 'input-date') {
        this.templateData.body = this.templateData.body.replace(`{a${position}}`, `<input id="a${position}" name="a${position}" type="date" autocomplete="off" class="custom-input date-input bottom-short-mrg">`);
      }
      else if (data.type === 'input-number') {
        this.templateData.body = this.templateData.body.replace(`<p>№ {a${position}}</p>`, `<div class="direction-right"><span class="number-label">№</span><input id="a${position}" name="a${position}" type="number" autocomplete="off" class="custom-input doc-num"  oninput="this.value = Math.round(this.value)"></div>`);
        this.templateData.body = this.templateData.body.replace(`{a${position}}`, `<input id="a${position}" name="a${position}" type="number" autocomplete="off" class="custom-input doc-num">`);
      }
      else if (data.type === 'input-date-to-right') {
        this.templateData.body = this.templateData.body.replace(`<p>{a${position}}</p>`, `<div class="direction-right"><input id="a${position}" name="a${position}" type="date" autocomplete="off" class="custom-input date-input bottom-short-mrg"></div>`);
      }
      else if (data.type === 'input-string') {
        if (!data.placeholder) { data.placeholder = '' }
        this.templateData.body = this.templateData.body.replace(`<h2>{a${position}}</h2>`, `<h2 class="center selected-font"><input id="a${position}" name="a${position}" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg"></h2>`);
        this.templateData.body = this.templateData.body.replace(`{a${position}}`, `<input id="a${position}" name="a${position}" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg fit-height">`);
      }
      else if (data.type === 'table-input-string') {
        if (!this.templateModel.tableData.length) {
          if (!data.placeholder) { data.placeholder = '' }
          this.templateData.body = this.templateData.body.replace(`{a${position}}`, `<input id="col${position}-1" name="a${position}" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-table-input">`);
        }
      }
      else if (data.type === 'table-input-number') {
        if (!this.templateModel.tableData.length) {
          this.templateData.body = this.templateData.body.replace(`{a${position}}`, `<input id="col${position}-1" name="a${position}" type="number" autocomplete="off" class="custom-input template-table-input">`);
        }
      }
      else if (data.type === 'table-input-date') {
        if (!this.templateModel.tableData.length) {
          this.templateData.body = this.templateData.body.replace(`{a${position}}`, `<input id="col${position}-1" name="a${position}" type="date" autocomplete="off" class="custom-input date-input">`);
        }
      }
      else if (data.type === 'table-number-and-date') {
        if (!this.templateModel.tableData.length) {
          this.templateData.body = this.templateData.body.replace(`{a${position}}`, `№<input id="0-col${position}-1" name="a${position}" type="number" autocomplete="off" class="custom-input template-table-input">от<input id="1-col${position}-1" name="a${position}" type="date" autocomplete="off" class="custom-input date-input">`);
        }
      }
      else if (data.type === 'table-input-start-end-time') {
        if (!this.templateModel.tableData.length) {
          this.templateData.body = this.templateData.body.replace(`{a${position}}`, `<div class="flexable"><input id="startcol${position}-1" name="a${position}" type="time" autocomplete="off" class="custom-input compact-width"> - <input id="endcol${position}-1" name="a${position}" type="time" autocomplete="off" class="custom-input compact-width"></div>`);
        }
      }
      else if (data.type === 'input-short-string') {
        if (!data.placeholder) { data.placeholder = '' }
        this.templateData.body = this.templateData.body.replace(`<p>{a${position}}</p>`, `<input id="a${position}" name="a${position}" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input date-input bottom-short-mrg">`);
      }
      else if (data.type === 'input-long-string') {
        if (!data.placeholder) { data.placeholder = '' }
        this.templateData.body = this.templateData.body.replace(`{a${position}}`, `<input id="a${position}" name="a${position}" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input fit-height">`);
      }
      else if (data.type === 'inputs-list') {
        if (this.templateModel && this.documentBodyData) {
          const inputsData = this.templateModel[`a${position}`];
          this.templateData.body = this.templateData.body.replace(`{a${position}}`, `<div id="a${position}" class="users-input-list"><input id="inputs-list-0" value="${inputsData[0]}" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg fit-height"></div>`);
          for (let i = 0; i < inputsData.length; i++) {
            if (!i) { continue; }
            this.templateData.body = this.templateData.body.replace(`<input id="inputs-list-${i - 1}" value="${inputsData[i - 1]}" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg fit-height"></div>`,
              `<input id="inputs-list-${i - 1}" value="${inputsData[i - 1]}" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg fit-height"><input id="inputs-list-${i}" value="${inputsData[i]}" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg fit-height"></div>`);
          }
          this.templateData.body = this.templateData.body.replace(`<input id="inputs-list-${inputsData.length - 1}" value="${inputsData[inputsData.length - 1]}" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg fit-height"></div>`,
            `<input id="inputs-list-${inputsData.length - 1}" value="${inputsData[inputsData.length - 1]}" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg fit-height"><input id="inputs-list-${inputsData.length}" value="" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg fit-height"></div>`);
          this.inputsListData = inputsData;
        } else {
          if (!data.placeholder) { data.placeholder = '' }
          this.templateData.body = this.templateData.body.replace(`{a${position}}`, `<div id="a${position}" class="users-input-list"><input id="inputs-list-0" value="" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg fit-height"></div>`);
        }
      }
      else if (data.type === 'sign-row') {
        this.templateData.body = this.templateData.body.replace(`{a${position}}`, `<input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height">`);
      }
      else if (data.type === 'drop-down-group' || data.type === 'drop-down-group-not-draw') {
        // this.dataHandlerService.getData(GROUPS_PATH_KEY).then(response => {
        this.dataHandlerService.getData(`Division/GetAllStudentDivisions`).then(response => {
          this.groupData = response;
          let str = this.setDropDownValue(this.groupData, position, null);
          this.templateData.body = this.templateData.body.replace(`{a${position}}`, str);
          for (let i in this.templateModel) {
            if (this.templateModel[i] && this.groupData.find(data => (data.groupName || data.name) === this.templateModel[i])) {
              this.templateData.body = this.templateData.body.replace(`>${this.templateModel[i]}`, `selected >${this.templateModel[i]}`);
            }
          }
          const selectedTemplateInfo = this.templateInfo.find(data => data.position === position + 1);
          if (this.documentBodyData && this.templateModel && selectedTemplateInfo.type === 'students-list') {
            const studentsData = this.templateModel[`a${position + 1}`];
            const selectedGroup: IGroupData = this.groupData.find(group => (group.groupName || data.name) === this.templateModel[`a${position}`]);
            if (selectedGroup) {
              this.getGroupStudents(selectedGroup.id, `a${position}`, studentsData);
            }
          }
        });
      }
      else if (data.type === 'library') {
        this.dataHandlerService.getData(`${LIBRARY_DATA_PATH_KEY}?${LIB_ID_PATH_PARAM_KEY}${data.entityId}`).then(libData => {
          const libraryData = libData;
          let str = this.setDropDownValue(libraryData, position, null, this.templateModel[`a${position}`]);
          this.templateData.body = this.templateData.body.replace(`{a${position}}`, str);
        });
      }
      else if (data.type === 'table-library') {
        if (!this.templateModel.tableData.length) {
          this.dataHandlerService.getData(`${LIBRARY_DATA_PATH_KEY}?${LIB_ID_PATH_PARAM_KEY}${data.entityId}`).then(libData => {
            const libraryData = libData;
            this.tableLibDatas[position] = libraryData;
            let str = this.setDropDownValue(libraryData, position, 1);
            str = str.replace(`id="${position}"`, `id="col${position}-1"`);
            this.templateData.body = this.templateData.body.replace(`{a${position}}`, str);
          });
        }
      }
      else if (data.type === 'table-list') {
        if (!this.templateModel.tableData.length) {
          this.dataHandlerService.getData(`${DOCUMENT_TEMPLATE_INFO_DATA_PATH_KEY}?${TEMPLATE_INFO_ID_PATH_PARAM_KEY}${data.id}`).then(response => {
            const tableListData = response;
            this.tableListDates[position] = tableListData;
            let str = this.setDropDownValue(this.tableListDates[position], position, 1);
            str = str.replace(`id="${position}"`, `id="col${position}-1"`)
            this.templateData.body = this.templateData.body.replace(`{a${position}}`, str);
          });
        }
      }
      else if (data.type === 'list') {
        this.dataHandlerService.getData(`${DOCUMENT_TEMPLATE_INFO_DATA_PATH_KEY}?${TEMPLATE_INFO_ID_PATH_PARAM_KEY}${data.id}`).then(response => {
          this.listData = response;
          let str = this.setDropDownValue(this.listData, position, null);
          this.templateData.body = this.templateData.body.replace(`{a${position}}`, str);
          for (let i in this.templateModel) {
            if (this.templateModel[i] && this.listData.find(data => data.data === this.templateModel[i])) {
              this.templateData.body = this.templateData.body.replace(`>${this.templateModel[i]}`, ` selected >${this.templateModel[i]}`);
            }
          }
        });
      }
      else if (data.type === 'text-area') {
        this.templateData.body = this.templateData.body.replace(`{a${position}}`, `<textarea id="a${position}" class="text-area" placeholder="Ввести текст"></textarea>`);
      }
      else if (data.type === 'add-row-button') {
        this.templateData.body = this.templateData.body.replace(`{a${position}}`, `<button id="add-${position}" type="button" class="table-row-add">Add</button>`);
      }
      else if (data.type === 'remove-row-button') {
        if (!this.templateModel.tableData.length) {
          this.templateData.body = this.templateData.body.replace(`{a${position}}`, `<button id="remove-${position}-1" type="button" class="table-row-add">Remove</button>`);
        }
      }
      else if (data.type === 'sign-row-arr') {
        if (this.documentBodyData && this.templateModel) {
          const inputsData = this.templateModel[`a${position}`];
          this.templateData.body = this.templateData.body.replace(`{a${position}}`, `<div id="a${position}" class="input-list"><div class="flexable"><input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-0" value="${inputsData[0]}" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg fit-height"></div></div>`);
          for (let i = 0; i < inputsData.length; i++) {
            if (!i) { continue; }
            this.templateData.body = this.templateData.body.replace(`<input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-${i - 1}" value="${inputsData[i - 1]}" type="text" autocomplete="off" placeholder="Фамилия И.О." class="custom-input template-input bottom-short-mrg fit-height"></div>`,
              `<input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-${i - 1}" value="${inputsData[i - 1]}" type="text" autocomplete="off" placeholder="Фамилия И.О." class="custom-input template-input bottom-short-mrg fit-height"></div><div class="flexable"><input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-${i}" value="${inputsData[i]}" type="text" autocomplete="off" placeholder="Фамилия И.О." class="custom-input template-input bottom-short-mrg fit-height"></div>`);
          }
          this.templateData.body = this.templateData.body.replace(`<input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-${inputsData.length - 1}" value="${inputsData[inputsData.length - 1]}" type="text" autocomplete="off" placeholder="Фамилия И.О." class="custom-input template-input bottom-short-mrg fit-height"></div>`,
            `<input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-${inputsData.length - 1}" value="${inputsData[inputsData.length - 1]}" type="text" autocomplete="off" placeholder="Фамилия И.О." class="custom-input template-input bottom-short-mrg fit-height"></div><div class="flexable"><input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-${inputsData.length}" value="" type="text" autocomplete="off" placeholder="Фамилия И.О." class="custom-input template-input bottom-short-mrg fit-height"></div>`);
        } else {
          this.templateData.body = this.templateData.body.replace(`{a${position}}`, `<div id="a${position}" class="input-list"><div class="flexable"><input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-0" value="" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg fit-height"></div></div>`);
        }
      }
      else if (data.type === 'table-sign-part') {
        this.templateData.body = this.templateData.body.replace(`{a${data.position}}`, `<div id="col${position}-1"></div>`);
      }

      if (data.hidden) {
        if (this.documentBodyData && this.templateModel && data.type === 'students-list') {
          const studentsData = this.templateModel[`a${position}`];
        }
        if (this.templateInfo[index - 1].entityId === 5) {
          this.templateData.body = this.templateData.body.replace(`id="a${position}"`, `id="entity-a${position}" class="hidden"`);
        } else {
          this.templateData.body = this.templateData.body.replace(`id="a${position}"`, `id="a${position}" class="hidden"`);
          this.templateData.body = this.templateData.body.replace(`<p>{a${position}}</p>`, `<p class="hidden">{a${position}}</p>`);
        }
      }
    });

    if (this.templateModel.tableData && this.templateModel.tableData.length) {
      let tableData: Array<any> = this.templateModel.tableData;
      let templateBody = this.templateData.body;
      if (tableData && tableData.length) {
        templateBody = templateBody.replace('<tr>', `<tr class="row-th">`);
        templateBody = templateBody.replace('<tr>', `<tr class="row-${tableData[0].number}">`);

        tableData.forEach(data => {
          if (!templateBody.includes(`<tr class="row-${data.number}">`)) {
            let row = `<tr class="row-${data.number}"><td>${++this.tableRowCount}</td></tr>`;
            this.tableTds.forEach(tdItem => {
              const startIndexOfColumn = tdItem.indexOf('{a') + 2;
              const endIndexOfColumn = tdItem.indexOf('}');
              const column = tdItem.substring(startIndexOfColumn, endIndexOfColumn);
              tdItem = tdItem.replace('<td>', `<td class="col${column}-${data.number}">`);
              row = row.replace('</tr>', `${tdItem}</tr>`);
            });
            const lastIndexTR = templateBody.lastIndexOf('</tr>');
            const lastIndexTBody = templateBody.lastIndexOf('</tbody>') + 8;
            const subStr = templateBody.substring(lastIndexTR, lastIndexTBody);
            templateBody = templateBody.replace(subStr, `</tr>${row}</tbody>`);
          }
          const infoData = this.templateInfo.find(info => info.position.toString() === data.columnNumber);
          if (typeof (data.value) === 'object' && infoData.type === 'table-number-and-date') {
            if (data.value[0]) {
              templateBody = templateBody.replace(`<td class="col${infoData.position}-${data.number}">{a${infoData.position}}</td>`, `<td class="col${infoData.position}-${data.number}">№<input id="0-col${infoData.position}-${data.number}" value="${data.value[0]}" name="a${infoData.position}" type="number" autocomplete="off" class="custom-input template-table-input">от<input id="1-col${infoData.position}-${data.number}" name="a${infoData.position}" type="date" autocomplete="off" class="custom-input date-input"></td>`);
              if (data.value[1]) {
                templateBody = templateBody.replace(`<td class="col${infoData.position}-${data.number}">№<input id="0-col${infoData.position}-${data.number}" value="${data.value[0]}" name="a${infoData.position}" type="number" autocomplete="off" class="custom-input template-table-input">от<input id="1-col${infoData.position}-${data.number}" name="a${infoData.position}" type="date" autocomplete="off" class="custom-input date-input"></td>`,
                  `<td class="col${infoData.position}-${data.number}">№<input id="0-col${infoData.position}-${data.number}" value="${data.value[0]}" name="a${infoData.position}" type="number" autocomplete="off" class="custom-input template-table-input">от<input id="1-col${infoData.position}-${data.number}" value="${data.value[1]}" name="a${infoData.position}" type="date" autocomplete="off" class="custom-input date-input"></td>`);
              }
            } else if (data.value[1]) {
              templateBody = templateBody.replace(`<td class="col${infoData.position}-${data.number}">{a${infoData.position}}</td>`,
                `<td class="col${infoData.position}-${data.number}">№<input id="0-col${infoData.position}-${data.number}" name="a${infoData.position}" type="number" autocomplete="off" class="custom-input template-table-input">от<input id="1-col${infoData.position}-${data.number}" value="${data.value[1]}" name="a${infoData.position}" type="date" autocomplete="off" class="custom-input date-input"></td>`);
            }
          }
          else if (infoData && infoData.type === 'table-input-number') {
            templateBody = templateBody.replace(`<td class="col${infoData.position}-${data.number}">{a${infoData.position}}</td>`, `<td class="col${infoData.position}-${data.number}"><input id="col${infoData.position}-${data.number}" value="${data.value}" name="a${infoData.position}" type="number" autocomplete="off" class="custom-input template-table-input"></td>`);
            templateBody = templateBody.replace(`<td class="col${infoData.position}-${data.number}">№ {a${infoData.position}}</td>`, `<td class="col${infoData.position}-${data.number}"><input id="col${infoData.position}-${data.number}" value="${data.value}" name="a${infoData.position}" type="number" autocomplete="off" class="custom-input template-table-input"></td>`);
          }
          else if (infoData && infoData.type === 'table-input-date') {
            templateBody = templateBody.replace(`<td class="col${infoData.position}-${data.number}">{a${infoData.position}}</td>`, `<td class="col${infoData.position}-${data.number}"><input id="col${infoData.position}-${data.number}" value="${data.value}" name="a${infoData.position}" type="date" autocomplete="off" class="custom-input date-input"></td>`);
          }
          else if (data.timeType && data.timeType === 'start') {
            const endValue = tableData.find(item => item.columnNumber == infoData.position && item.number == data.number && item.timeType == 'end');
            if (data.value) {
              templateBody = templateBody.replace(`<td class="col${infoData.position}-${data.number}">{a${infoData.position}}</td>`, `<td class="col${infoData.position}-${data.number}"><div class="flexable"><input id="startcol${infoData.position}-${data.number}" value="${data.value}" name="a${infoData.position}" type="time" autocomplete="off" class="custom-input compact-width"> - <input id="endcol${infoData.position}-${data.number}" name="a${infoData.position}" type="time" autocomplete="off" class="custom-input compact-width"></div></td>`);
              if (endValue && endValue.value) {
                templateBody = templateBody.replace(`<td class="col${infoData.position}-${data.number}"><div class="flexable"><input id="startcol${infoData.position}-${data.number}" value="${data.value}" name="a${infoData.position}" type="time" autocomplete="off" class="custom-input compact-width"> - <input id="endcol${infoData.position}-${data.number}" name="a${infoData.position}" type="time" autocomplete="off" class="custom-input compact-width"></div></td>`,
                  `<td class="col${infoData.position}-${data.number}"><div class="flexable"><input id="startcol${infoData.position}-${data.number}" value="${data.value}" name="a${infoData.position}" type="time" autocomplete="off" class="custom-input compact-width"> - <input id="endcol${infoData.position}-${data.number}" value="${endValue.value}" name="a${infoData.position}" type="time" autocomplete="off" class="custom-input compact-width"></div></td>`);
              }
            } else if (endValue && endValue.value) {
              templateBody = templateBody.replace(`<td class="col${infoData.position}-${data.number}">{a${infoData.position}}</td>`, `<td class="col${infoData.position}-${data.number}"><div class="flexable"><input id="startcol${infoData.position}-${data.number}" name="a${infoData.position}" type="time" autocomplete="off" class="custom-input compact-width"> - <input id="endcol${infoData.position}-${data.number}" value="${endValue.value}" name="a${infoData.position}" type="time" autocomplete="off" class="custom-input compact-width"></div></td>`);
            }
          }
          else if (infoData && infoData.type === 'table-input-string') {
            if (!infoData.placeholder) { infoData.placeholder = ''; }
            templateBody = templateBody.replace(`<td class="col${infoData.position}-${data.number}">{a${infoData.position}}</td>`, `<td class="col${infoData.position}-${data.number}"><input id="col${infoData.position}-${data.number}" value="${data.value}" name="a${infoData.position}" type="text" autocomplete="off" placeholder="${infoData.placeholder}" class="custom-input template-table-input"></td>`);
          }
        });

        this.templateInfo.forEach(info => {
          if (info.type === 'table-library') {
            this.dataHandlerService.getData(`${LIBRARY_DATA_PATH_KEY}?${LIB_ID_PATH_PARAM_KEY}${info.entityId}`).then(libData => {
              const libraryData = libData;
              this.tableLibDatas[info.position] = libraryData;
              this.tableLibDatasObs.next(this.tableLibDatas);
            });
          }
          else if (info.type === 'table-list') {
            this.dataHandlerService.getData(`${DOCUMENT_TEMPLATE_INFO_DATA_PATH_KEY}?${TEMPLATE_INFO_ID_PATH_PARAM_KEY}${info.id}`).then(response => {
              const tableListData = response;
              this.tableListDates[info.position] = tableListData;
              this.tableListDatasObs.next(this.tableListDates);
            });
          }

          if (info.type === 'table-library'
            || info.type === 'table-string-and-date'
            || info.type === 'table-number-and-date'
            || info.type === 'table-input-number'
            || info.type === 'table-input-date'
            || info.type === 'table-input-start-end-time'
            || info.type === 'table-input-string'
          ) {
            this.tableColumnCount++;
          }
        });

        this.templateInfo.forEach(info => {
          const count = this.tableColumnCount * this.tableRowCount;
          for (let i = 0; i < count; i++) {
            if (info.type === 'table-number-and-date') {
              templateBody = templateBody.replace(`<td class="col${info.position}-${i}">{a${info.position}}</td>`,
                `<td class="col${info.position}-${i}">№<input id="0-col${info.position}-${i}" name="a${info.position}" type="number" autocomplete="off" class="custom-input template-table-input">от<input id="1-col${info.position}-${i}" name="a${info.position}" type="date" autocomplete="off" class="custom-input date-input"></td>`);
            }
            else if (info.type === 'table-input-number') {
              templateBody = templateBody.replace(`<td class="col${info.position}-${i}">{a${info.position}}</td>`, `<td class="col${info.position}-${i}"><input id="col${info.position}-${i}" name="a${info.position}" type="number" autocomplete="off" class="custom-input template-table-input"></td>`);
              templateBody = templateBody.replace(`<td class="col${info.position}-${i}">№ {a${info.position}}</td>`, `<td class="col${info.position}-${i}"><input id="col${info.position}-${i}" name="a${info.position}" type="number" autocomplete="off" class="custom-input template-table-input"></td>`);
            }
            else if (info.type === 'table-input-date') {
              templateBody = templateBody.replace(`<td class="col${info.position}-${i}">{a${info.position}}</td>`, `<td class="col${info.position}-${i}"><input id="col${info.position}-${i}" name="a${info.position}" type="date" autocomplete="off" class="custom-input date-input"></td>`);
            }
            else if (info.type === 'table-input-start-end-time') {
              templateBody = templateBody.replace(`<td class="col${info.position}-${i}">{a${info.position}}</td>`, `<td class="col${info.position}-${i}"><div class="flexable"><input id="startcol${info.position}-${i}" name="a${info.position}" type="time" autocomplete="off" class="custom-input compact-width"> - <input id="endcol${info.position}-${i}" name="a${info.position}" type="time" autocomplete="off" class="custom-input compact-width"></div></td>`);
            }
            else if (info.type === 'table-input-string') {
              if (!info.placeholder) { info.placeholder = ''; }
              templateBody = templateBody.replace(`<td class="col${info.position}-${i}">{a${info.position}}</td>`, `<td class="col${info.position}-${i}"><input id="col${info.position}-${i}" name="a${info.position}" type="text" autocomplete="off" placeholder="${info.placeholder}" class="custom-input template-table-input"></td>`);
            }
            else if (info.type === 'table-sign-part') {
              templateBody = templateBody.replace(`<td class="col${info.position}-${i}">{a${info.position}}</td>`, `<td class="col${info.position}-${i}"></td>`);
            }
            else if (info.type === 'remove-row-button') {
              templateBody = templateBody.replace(`<td class="col${info.position}-${i}">{a${info.position}}</td>`, `<td class="col${info.position}-${i}"><button id="remove-${info.position}-${i}" type="button" class="table-row-add">Remove</button></td>`);
            }
          }
        });

        this.templateData.body = templateBody;

      }

      this.tableLibDatasObs.subscribe(value => {
        if (value && value.length) {
          this.templateInfo.forEach(info => {
            if (!value[info.position] || !value[info.position].length) { return; }
            if (info.type === 'table-library' && value[info.position]) {
              const count = this.tableRowCount * this.tableColumnCount;
              for (let i = 0; i < count; i++) {
                const selectedTableItem = tableData.find(data => data.number == i && data.columnNumber == info.position);
                if (!selectedTableItem && this.templateData.body.includes(`<td class="col${info.position}-${i}">{a${info.position}}</td>`)) {
                  let str = this.setDropDownValue(value[info.position], info.position, i);
                  this.templateData.body = this.templateData.body.replace(`<td class="col${info.position}-${i}">{a${info.position}}</td>`, `<td class="col${info.position}-${i}">${str}</td>`);
                } else if (selectedTableItem) {
                  let str = this.setDropDownValue(value[info.position], info.position, +selectedTableItem.number, selectedTableItem.value);
                  this.templateData.body = this.templateData.body.replace(`<td class="col${info.position}-${selectedTableItem.number}">{a${info.position}}</td>`, `<td class="col${info.position}-${selectedTableItem.number}">${str}</td>`);
                }
              }
            }
          });
        }
      });

      this.tableListDatasObs.subscribe(value => {
        if (value && value.length) {
          this.templateInfo.forEach(info => {
            if (!value[info.position] || !value[info.position].length) { return; }
            if (info.type === 'table-list' && value[info.position]) {
              const count = this.tableRowCount * this.tableColumnCount;
              for (let i = 0; i < count; i++) {
                const selectedTableItem = tableData.find(data => data.number == i && data.columnNumber == info.position);
                if (!selectedTableItem && this.templateData.body.includes(`<td class="col${info.position}-${i}">{a${info.position}}</td>`)) {
                  let str = this.setDropDownValue(value[info.position], info.position, i);
                  this.templateData.body = this.templateData.body.replace(`<td class="col${info.position}-${i}">{a${info.position}}</td>`, `<td class="col${info.position}-${i}">${str}</td>`);
                } else if (selectedTableItem) {
                  let str = this.setDropDownValue(value[info.position], info.position, +selectedTableItem.number, selectedTableItem.value);
                  this.templateData.body = this.templateData.body.replace(`<td class="col${info.position}-${selectedTableItem.number}">{a${info.position}}</td>`, `<td class="col${info.position}-${selectedTableItem.number}">${str}</td>`);
                }
              }
            }
          });
        }
      });

    }

    if (this.templateModel) {
      for (let i in this.templateModel) {
        if (this.templateModel[i]) {
          this.templateData.body = this.templateData.body.replace(`<input id="entity-${i}" class="hidden"`, `<input id="entity-${i}" value="${this.templateModel[i]}"`);
          this.templateData.body = this.templateData.body.replace(`<input id="${i}"`, `<input id="${i}" value="${this.templateModel[i]}"`);
          this.templateData.body = this.templateData.body.replace(`<textarea id="${i}" class="text-area" placeholder="Ввести текст"></textarea>`, `<textarea id="${i}" class="text-area" placeholder="Ввести текст">${this.templateModel[i]}</textarea>`);
        }
      }
    }
    this.templateData.body = this.templateData.body.replace('<table', '<div class="template-table-container"><table class="template-table"');
    this.templateData.body = this.templateData.body.replace('</table>', '</table></div>');
    this.templateData.body = this.templateData.body.replace('<h1>', '<h1 class="center template-title">');
    this.templateData.body = this.templateData.body.replace('<h3>', '<h3 class="center template-title margin-h3">');
    this.templateData.body = this.templateData.body.replace('<h3>', '<h3 class="doc-form-sub-title">');
    this.templateData.body = this.templateData.body.replace('<h3 class="center template-title margin-h3"><strong>', '<h3 class="temp-sub-title"><strong>');
    this.templateData.body = this.templateData.body.replace('<h2>', '<h2 class="temp-sub-title center selected-font">');

    this.templateData.body = this.templateData.body.split('<p>').join('<p class="flex-wrap">');
    this.openView = true;
  }

  buttonClick(event: any): void {
    if (event.target.type !== 'button') { return; }

    if (event.target.id.includes('add')) {
      this.addTableRow();
    }
    else if (event.target.id.includes('remove')) {
      const row = event.target.id.substring(event.target.id.lastIndexOf('-') + 1);
      this.removeTableRow(row);
    }
  }

  addTableRow() {
    this.templateData.body = this.templateData.body.replace('</tbody>', `<tr><td>${++this.tableRowCount}</td></tr></tbody>`);

    for (let i = 0; i < this.tableRowCount; i++) {
      if (this.templateData.body.includes(`class="row-${this.tableRowCount}"`)) {
        ++this.tableRowCount;
      }
    }
    this.tableTds.forEach(tdItem => {
      this.templateData.body = this.templateData.body.replace('</td></tr></tbody>', `</td>${tdItem}</tr></tbody>`);
      const firstIndex = tdItem.indexOf('{') + 2;
      const lastIndex = tdItem.indexOf('}');
      const positionNumber = tdItem.substring(firstIndex, lastIndex);
      const templateInfo = this.templateInfo.find(data => { const position = data.position.toString(); return position === positionNumber; });
      if (templateInfo.type === 'table-input-string') {
        if (!templateInfo.placeholder) { templateInfo.placeholder = '' }
        this.templateData.body = this.templateData.body.replace(`{a${templateInfo.position}}`, `<input id="col${templateInfo.position}-${this.tableRowCount}" name="a${templateInfo.position}" type="text" autocomplete="off" placeholder="${templateInfo.placeholder}" class="custom-input template-table-input">`);
      }
      else if (templateInfo.type === 'table-input-date') {
        this.templateData.body = this.templateData.body.replace(`{a${templateInfo.position}}`, `<input id="col${templateInfo.position}-${this.tableRowCount}" name="a${templateInfo.position}" type="date" autocomplete="off" class="custom-input date-input">`);
      }
      else if (templateInfo.type === 'table-input-number') {
        this.templateData.body = this.templateData.body.replace(`{a${templateInfo.position}}`, `<input id="col${templateInfo.position}-${this.tableRowCount}" name="a${templateInfo.position}" type="number" autocomplete="off" class="custom-input template-table-input">`);
      }
      else if (templateInfo.type === 'table-number-and-date') {
        this.templateData.body = this.templateData.body.replace(`{a${templateInfo.position}}`, `№<input id="0-col${templateInfo.position}-${this.tableRowCount}" name="a${templateInfo.position}" type="number" autocomplete="off" class="custom-input template-table-input">от<input id="1-col${templateInfo.position}-${this.tableRowCount}" name="a${templateInfo.position}" type="date" autocomplete="off" class="custom-input date-input">`);
      }
      else if (templateInfo.type === 'table-input-start-end-time') {
        this.templateData.body = this.templateData.body.replace(`{a${templateInfo.position}}`, `<div class="flexable"><input id="startcol${templateInfo.position}-${this.tableRowCount}" name="a${templateInfo.position}" type="time" autocomplete="off" class="custom-input compact-width"> - <input id="endcol${templateInfo.position}-${this.tableRowCount}" name="a${templateInfo.position}" type="time" autocomplete="off" class="custom-input compact-width"></div>`);
      }
      else if (templateInfo.type === 'table-library') {
        const libraryData = this.tableLibDatas[templateInfo.position];
        let str = this.setDropDownValue(libraryData, templateInfo.position, this.tableRowCount);
        str = str.replace(`id="${templateInfo.position}"`, `id="col${templateInfo.position}-${this.tableRowCount}"`)
        this.templateData.body = this.templateData.body.replace(`{a${templateInfo.position}}`, str);
      }
      else if (templateInfo.type === 'table-list') {
        const listData = this.tableListDates[templateInfo.position];
        let str = this.setDropDownValue(listData, templateInfo.position, this.tableRowCount);
        str = str.replace(`id="${templateInfo.position}"`, `id="col${templateInfo.position}-${this.tableRowCount}"`)
        this.templateData.body = this.templateData.body.replace(`{a${templateInfo.position}}`, str);
      }
      else if (templateInfo.type === 'table-sign-part') {
        this.templateData.body = this.templateData.body.replace(`{a${templateInfo.position}}`, `<div id="col${templateInfo.position}-${this.tableRowCount}"></div>`);
      }
      else if (templateInfo.type === 'remove-row-button') {
        this.templateData.body = this.templateData.body.replace(`{a${templateInfo.position}}`, `<button id="remove-${templateInfo.position}-${this.tableRowCount}" type="button" class="table-row-add">Remove</button>`);
      }
    });
  }

  removeTableRow(row) {
    this.tableTds.forEach(tdItem => {
      const firstIndex = tdItem.indexOf('{') + 2;
      const lastIndex = tdItem.indexOf('}');
      const positionNumber = tdItem.substring(firstIndex, lastIndex);
      const templateInfo = this.templateInfo.find(data => data.position == positionNumber);
      if (row === '1') {
        if (templateInfo.type === 'table-input-string') {
          if (!templateInfo.placeholder) { templateInfo.placeholder = '' }
          const valueData = this.tableData.find(data => data.number === row && data.columnNumber === templateInfo.position.toString());
          if (valueData) {
            this.templateData.body = this.templateData.body.replace(`<input id="col${templateInfo.position}-${row}" value="${valueData.value}" name="a${templateInfo.position}" type="text" autocomplete="off" placeholder="${templateInfo.placeholder}" class="custom-input template-table-input">`, `<input id="col${templateInfo.position}-${row}" name="a${templateInfo.position}" type="text" autocomplete="off" placeholder="${templateInfo.placeholder}" class="custom-input template-table-input">`);
          }
        }
        else if (templateInfo.type === 'table-input-date') {
          const valueData = this.tableData.find(data => data.number === row && data.columnNumber === templateInfo.position.toString());
          if (valueData) {
            this.templateData.body = this.templateData.body.replace(`<input id="col${templateInfo.position}-${row}" value="${valueData.value}" name="a${templateInfo.position}" type="date" autocomplete="off" class="custom-input date-input">`, `<input id="col${templateInfo.position}-${row}" name="a${templateInfo.position}" type="date" autocomplete="off" class="custom-input date-input">`);
          }
        }
        else if (templateInfo.type === 'table-input-number') {
          const valueData = this.tableData.find(data => data.number === row && data.columnNumber === templateInfo.position.toString());
          if (valueData) {
            this.templateData.body = this.templateData.body.replace(`<input id="col${templateInfo.position}-${row}" value="${valueData.value}" name="a${templateInfo.position}" type="number" autocomplete="off" class="custom-input template-table-input">`, `<input id="col${templateInfo.position}-${row}" name="a${templateInfo.position}" type="number" autocomplete="off" class="custom-input template-table-input">`);
          }
        }
        else if (templateInfo.type === 'table-number-and-date') {
          const valueData = this.tableData.find(data => data.number === row && data.columnNumber === templateInfo.position.toString());
          if (valueData && valueData.value.length) {
            if (valueData.value[0]) {
              this.templateData.body = this.templateData.body.replace(`<input id="0-col${templateInfo.position}-${row}" value="${valueData.value[0]}" name="a${templateInfo.position}" type="number"`, `<input id="0-col${templateInfo.position}-${row}" name="a${templateInfo.position}" type="number"`);
            }
            if (valueData.value[1]) {
              this.templateData.body = this.templateData.body.replace(`<input id="1-col${templateInfo.position}-${row}" value="${valueData.value[1]}" name="a${templateInfo.position}" type="date"`, `<input id="1-col${templateInfo.position}-${row}" name="a${templateInfo.position}" type="date"`);
            }
          }
        }
        else if (templateInfo.type === 'table-input-start-end-time') {
          const startValueData = this.tableData.find(data => data.number === row && data.columnNumber === templateInfo.position.toString() && data.timeType === 'start');
          const endValueData = this.tableData.find(data => data.number === row && data.columnNumber === templateInfo.position.toString() && data.timeType === 'end');
          if (startValueData) {
            this.templateData.body = this.templateData.body.replace(`id="startcol${templateInfo.position}-1" value="${startValueData.value}"`, `id="startcol${templateInfo.position}-1"`);
          }
          if (endValueData) {
            this.templateData.body = this.templateData.body.replace(`id="endcol${templateInfo.position}-1" value="${endValueData.value}"`, `id="endcol${templateInfo.position}-1"`);
          }
        }
        else if (templateInfo.type === 'table-library' || templateInfo.type === 'table-list') {
          this.templateData.body = this.templateData.body.replace(`<option selected id="col${templateInfo.position}-${row}`, `<option id="col${templateInfo.position}-${row}`);
        }
      }
      else {
        if (templateInfo.type === 'table-input-string') {
          if (!templateInfo.placeholder) { templateInfo.placeholder = '' }
          const valueData = this.tableData.find(data => data.number === row && data.columnNumber === templateInfo.position.toString());
          if (valueData) {
            this.templateData.body = this.templateData.body.replace(`<td><input id="col${templateInfo.position}-${row}" value="${valueData.value}" name="a${templateInfo.position}" type="text" autocomplete="off" placeholder="${templateInfo.placeholder}" class="custom-input template-table-input"></td>`, ``);
            this.templateData.body = this.templateData.body.replace(`<td class="col${templateInfo.position}-${row}"><input id="col${templateInfo.position}-${row}" value="${valueData.value}" name="a${templateInfo.position}" type="text" autocomplete="off" placeholder="${templateInfo.placeholder}" class="custom-input template-table-input"></td>`, ``);
          } else {
            this.templateData.body = this.templateData.body.replace(`<td><input id="col${templateInfo.position}-${row}" name="a${templateInfo.position}" type="text" autocomplete="off" placeholder="${templateInfo.placeholder}" class="custom-input template-table-input"></td>`, ``);
            this.templateData.body = this.templateData.body.replace(`<td class="col${templateInfo.position}-${row}"><input id="col${templateInfo.position}-${row}" name="a${templateInfo.position}" type="text" autocomplete="off" placeholder="${templateInfo.placeholder}" class="custom-input template-table-input"></td>`, ``);
            this.templateData.body = this.templateData.body.replace(`<td><input id="col${templateInfo.position}-${row}" value="" name="a${templateInfo.position}" type="text" autocomplete="off" placeholder="${templateInfo.placeholder}" class="custom-input template-table-input"></td>`, ``);
            this.templateData.body = this.templateData.body.replace(`<td class="col${templateInfo.position}-${row}"><input id="col${templateInfo.position}-${row}" value="" name="a${templateInfo.position}" type="text" autocomplete="off" placeholder="${templateInfo.placeholder}" class="custom-input template-table-input"></td>`, ``);
          }
        }
        else if (templateInfo.type === 'table-input-date') {
          const valueData = this.tableData.find(data => data.number === row && data.columnNumber === templateInfo.position.toString());
          if (valueData) {
            this.templateData.body = this.templateData.body.replace(`<td><input id="col${templateInfo.position}-${row}" value="${valueData.value}" name="a${templateInfo.position}" type="date" autocomplete="off" class="custom-input date-input"></td>`, ``);
            this.templateData.body = this.templateData.body.replace(`<td class="col${templateInfo.position}-${row}"><input id="col${templateInfo.position}-${row}" value="${valueData.value}" name="a${templateInfo.position}" type="date" autocomplete="off" class="custom-input date-input"></td>`, ``);
          } else {
            this.templateData.body = this.templateData.body.replace(`<td><input id="col${templateInfo.position}-${row}" name="a${templateInfo.position}" type="date" autocomplete="off" class="custom-input date-input"></td>`, ``);
            this.templateData.body = this.templateData.body.replace(`<td class="col${templateInfo.position}-${row}"><input id="col${templateInfo.position}-${row}" name="a${templateInfo.position}" type="date" autocomplete="off" class="custom-input date-input"></td>`, ``);
            this.templateData.body = this.templateData.body.replace(`<td><input id="col${templateInfo.position}-${row}" value="" name="a${templateInfo.position}" type="date" autocomplete="off" class="custom-input date-input"></td>`, ``);
            this.templateData.body = this.templateData.body.replace(`<td class="col${templateInfo.position}-${row}"><input id="col${templateInfo.position}-${row}" value="" name="a${templateInfo.position}" type="date" autocomplete="off" class="custom-input date-input"></td>`, ``);
          }
        }
        else if (templateInfo.type === 'table-input-number') {
          const valueData = this.tableData.find(data => data.number === row && data.columnNumber === templateInfo.position.toString());
          if (valueData) {
            this.templateData.body = this.templateData.body.replace(`<td><input id="col${templateInfo.position}-${row}" value="${valueData.value}" name="a${templateInfo.position}" type="number" autocomplete="off" class="custom-input template-table-input"></td>`, ``);
            this.templateData.body = this.templateData.body.replace(`<td class="col${templateInfo.position}-${row}"><input id="col${templateInfo.position}-${row}" value="${valueData.value}" name="a${templateInfo.position}" type="number" autocomplete="off" class="custom-input template-table-input"></td>`, ``);
            this.templateData.body = this.templateData.body.replace(`<td>№ <input id="col${templateInfo.position}-${row}" value="${valueData.value}" name="a${templateInfo.position}" type="number" autocomplete="off" class="custom-input template-table-input"></td>`, ``);
            this.templateData.body = this.templateData.body.replace(`<td class="col${templateInfo.position}-${row}">№ <input id="col${templateInfo.position}-${row}" value="${valueData.value}" name="a${templateInfo.position}" type="number" autocomplete="off" class="custom-input template-table-input"></td>`, ``);
          } else {
            this.templateData.body = this.templateData.body.replace(`<td><input id="col${templateInfo.position}-${row}" name="a${templateInfo.position}" type="number" autocomplete="off" class="custom-input template-table-input"></td>`, ``);
            this.templateData.body = this.templateData.body.replace(`<td class="col${templateInfo.position}-${row}"><input id="col${templateInfo.position}-${row}" name="a${templateInfo.position}" type="number" autocomplete="off" class="custom-input template-table-input"></td>`, ``);
            this.templateData.body = this.templateData.body.replace(`<td>№ <input id="col${templateInfo.position}-${row}" name="a${templateInfo.position}" type="number" autocomplete="off" class="custom-input template-table-input"></td>`, ``);
            this.templateData.body = this.templateData.body.replace(`<td class="col${templateInfo.position}-${row}">№ <input id="col${templateInfo.position}-${row}" name="a${templateInfo.position}" type="number" autocomplete="off" class="custom-input template-table-input"></td>`, ``);
          }
        }
        else if (templateInfo.type === 'table-number-and-date') {
          const valueData = this.tableData.find(data => data.number === row && data.columnNumber === templateInfo.position.toString());
          if (valueData && valueData.value.length) {
            if (valueData.value[0]) {
              this.templateData.body = this.templateData.body.replace(`<input id="0-col${templateInfo.position}-${row}" value="${valueData.value[0]}" name="a${templateInfo.position}" type="number" autocomplete="off" class="custom-input template-table-input">`, ``);
            } else {
              this.templateData.body = this.templateData.body.replace(`<input id="0-col${templateInfo.position}-${row}" name="a${templateInfo.position}" type="number" autocomplete="off" class="custom-input template-table-input">`, ``);
            }

            if (valueData.value[1]) {
              this.templateData.body = this.templateData.body.replace(`<input id="1-col${templateInfo.position}-${row}" value="${valueData.value[1]}" name="a${templateInfo.position}" type="date" autocomplete="off" class="custom-input date-input">`, ``);
            } else {
              this.templateData.body = this.templateData.body.replace(`<input id="1-col${templateInfo.position}-${row}" name="a${templateInfo.position}" type="date" autocomplete="off" class="custom-input date-input">`, ``);
            }
            this.templateData.body = this.templateData.body.replace(`<td>№от</td>`, ``);
            this.templateData.body = this.templateData.body.replace(`<td class="col${templateInfo.position}-${row}">№от</td>`, ``);
          } else {
            this.templateData.body = this.templateData.body.replace(`<td>№<input id="0-col${templateInfo.position}-${row}" name="a${templateInfo.position}" type="number" autocomplete="off" class="custom-input template-table-input">от<input id="1-col${templateInfo.position}-${row}" name="a${templateInfo.position}" type="date" autocomplete="off" class="custom-input date-input"></td>`, ``);
            this.templateData.body = this.templateData.body.replace(`<td class="col${templateInfo.position}-${row}">№<input id="0-col${templateInfo.position}-${row}" name="a${templateInfo.position}" type="number" autocomplete="off" class="custom-input template-table-input">от<input id="1-col${templateInfo.position}-${row}" name="a${templateInfo.position}" type="date" autocomplete="off" class="custom-input date-input"></td>`, ``);
          }
        }
        else if (templateInfo.type === 'table-input-start-end-time') {
          const startValueData = this.tableData.find(data => data.number == row && data.columnNumber == templateInfo.position && data.timeType == 'start');
          const endValueData = this.tableData.find(data => data.number == row && data.columnNumber == templateInfo.position && data.timeType == 'end');
          if (startValueData) {
            this.templateData.body = this.templateData.body.replace(`<input id="startcol${templateInfo.position}-${row}" value="${startValueData.value}" name="a${templateInfo.position}" type="time" autocomplete="off" class="custom-input compact-width">`, ``);
          } else {
            this.templateData.body = this.templateData.body.replace(`<input id="startcol${templateInfo.position}-${row}" name="a${templateInfo.position}" type="time" autocomplete="off" class="custom-input compact-width">`, ``);
          }
          if (endValueData) {
            this.templateData.body = this.templateData.body.replace(`<input id="endcol${templateInfo.position}-${row}" value="${endValueData.value}" name="a${templateInfo.position}" type="time" autocomplete="off" class="custom-input compact-width">`, ``);
          } else {
            this.templateData.body = this.templateData.body.replace(`<input id="endcol${templateInfo.position}-${row}" name="a${templateInfo.position}" type="time" autocomplete="off" class="custom-input compact-width">`, ``);
          }
          this.templateData.body = this.templateData.body.replace(`<td><div class="flexable"> - </div></td>`, ``);
        }
        else if (templateInfo.type === 'table-library') {
          const libraryData = this.tableLibDatas[templateInfo.position];
          libraryData.forEach((library, index) => {
            this.templateData.body = this.templateData.body.replace(`<option selected id="col${templateInfo.position}-${row}-${index}">${library.name}</option>`, '');
            this.templateData.body = this.templateData.body.replace(`<option id="col${templateInfo.position}-${row}-${index}">${library.name}</option>`, '');
          });
          this.templateData.body = this.templateData.body.replace(`<td><select id="col${templateInfo.position}-${row}" class="select-list"><option selected disabled hidden></option></select></td>`, '');
          this.templateData.body = this.templateData.body.replace(`<td class="col${templateInfo.position}-${row}"><select id="col${templateInfo.position}-${row}" class="select-list"><option selected disabled hidden></option></select></td>`, '');
        }
        else if (templateInfo.type === 'table-list') {
          const listData = this.tableListDates[templateInfo.position];
          listData.forEach((library, index) => {
            this.templateData.body = this.templateData.body.replace(`<option selected id="col${templateInfo.position}-${row}-${index}">${library.data}</option>`, '');
            this.templateData.body = this.templateData.body.replace(`<option id="col${templateInfo.position}-${row}-${index}">${library.data}</option>`, '');
          });
          this.templateData.body = this.templateData.body.replace(`<td><select id="col${templateInfo.position}-${row}" class="select-list"><option selected disabled hidden></option></select></td>`, '');
          this.templateData.body = this.templateData.body.replace(`<td class="col${templateInfo.position}-${row}"><select id="col${templateInfo.position}-${row}" class="select-list"><option selected disabled hidden></option></select></td>`, '');
        }
        else if (templateInfo.type === 'table-sign-part') {
          this.templateData.body = this.templateData.body.replace(`<td><div id="col${templateInfo.position}-${row}"></div></td>`, ``);
          this.templateData.body = this.templateData.body.replace(`<td class="col${templateInfo.position}-${row}"><div id="col${templateInfo.position}-${row}"></div></td>`, ``);
          this.templateData.body = this.templateData.body.replace(`<td></td>`, ``);
          this.templateData.body = this.templateData.body.replace(`<td class="col${templateInfo.position}-${row}"></td>`, ``);
        }
        else if (templateInfo.type === 'remove-row-button') {
          this.templateData.body = this.templateData.body.replace(`<td><button id="remove-${templateInfo.position}-${row}" type="button" class="table-row-add">Remove</button></td>`, '');
          this.templateData.body = this.templateData.body.replace(`<td class="col${templateInfo.position}-${row}"><button id="remove-${templateInfo.position}-${row}" type="button" class="table-row-add">Remove</button></td>`, '');
        }
        this.templateData.body = this.templateData.body.replace(`<tr><td>${row}</td></tr>`, '');
        this.templateData.body = this.templateData.body.replace(`<tr class="row-${row}"><td>${row}</td></tr>`, '');
      }

      this.tableData.forEach((item, index) => {
        if (item.columnNumber === templateInfo.position.toString() && item.number === row.toString()) {
          this.tableData.splice(index, 1);
          if (item.timeType) {
            this.tableData.splice(index, 1);
          }
        }
      });

    });
  }

  setFormValues(event: any): void {
    let groupData: IGroupData;

    if (this.groupData.length) {
      groupData = this.groupData.find(data => (data.groupName || data.name) === event.target.value);
    }

    const lastIndex = event.target.id.lastIndexOf('-') + 2;
    const nextIndex = +event.target.id.substr(1) + 1;
    if (event.target.value === 'Другое (вписать вручную)') {
      this.templateData.body = this.templateData.body.replace(`id="entity-a${nextIndex}" class="hidden"`, `id="entity-a${nextIndex}"`);
    }
    else if (!this.templateData.body.includes(`id="entity-a${nextIndex}" class="hidden"`) && this.templateData.body.includes(`id="entity-a${nextIndex}"`)) {
      this.templateData.body = this.templateData.body.replace(`id="entity-a${nextIndex}"`, `id="entity-a${nextIndex}" class="hidden" value=""`);
      this.templateModel[`a${nextIndex}`] = '';
    }

    if (groupData) {
      if (event.target.type === 'select-one' && event.target.type !== 'checkbox') {
        this.setOptionSelectedAttr(event.target);
      }
      this.templateModel[`${event.target.id}`] = event.target.value;
      if (this.templateInfo.find(data => data.type === 'students-list')) {
        this.getGroupStudents(groupData.id, event.target.id, null);
      }
      if (this.templateInfo.find(data => data.type === 'students-in-table')) {
        this.setGroupsStudentsInTable(groupData.id);
      }
    }
    else if (event.target.type === 'checkbox') {
      this.hasStudentChecked(event.target.id, event.target.checked);
    }
    else if (event.target.id.includes('inputs-list')) {
      const templateInfo = this.templateInfo.find(data => data.type === 'inputs-list');
      const secondtemplateInfo = this.templateInfo.find(data => data.type === 'sign-row-arr');
      this.templateModel[`a${templateInfo.position}`] = this.inputsListData;
      if (secondtemplateInfo) {
        this.templateModel[`a${secondtemplateInfo.position}`] = this.inputsListData;
      }
    }
    else if (event.target.id.includes('entity')) {
      const itemId = event.target.id.substr(lastIndex);
      this.templateModel[`a${itemId}`] = event.target.value;
    }
    else if (event.target.id) {
      if (event.target.type === 'select-one' && event.target.type !== 'checkbox' && event.target.type !== 'button') {
        this.setOptionSelectedAttr(event.target);
        if (event.target.id.includes('col')) {
          this.setTableValue(event.target.id, event.target.value, null);
        }
      }
      if (!event.target.id.includes('col')) {
        this.templateModel[`${event.target.id}`] = event.target.value;
      }
    }
  }

  focusOut(event: any) {
    let tableScrollLeft = 0
    let tableBlock = document.querySelector(".template-table-container");
    if (document.querySelector(".template-table-container")) tableScrollLeft = tableBlock.scrollLeft;

    if (event.target.id.includes('inputs-list')) {
      const templateInfo = this.templateInfo.find(data => data.type === 'inputs-list');
      this.setInputsListValues(event.target.id, event.target.value, templateInfo.placeholder);
    }
    else if (event.target.type === 'textarea') {
      if (this.templateData.body.includes(`<textarea id="${event.target.id}" class="text-area" placeholder="Ввести текст"></textarea>`)) {
        this.templateData.body = this.templateData.body.replace(`<textarea id="${event.target.id}" class="text-area" placeholder="Ввести текст"></textarea>`, `<textarea id="${event.target.id}" class="text-area" placeholder="Ввести текст">${event.target.value}</textarea>`);
      }
      else {
        const valueIndex = this.templateData.body.indexOf(`</textarea>`);
        let str = this.templateData.body.substr(0, valueIndex);
        const valueLastIndex = str.lastIndexOf('>') + 1;
        str = str.substr(valueLastIndex);
        this.templateData.body = this.templateData.body.replace(`<textarea id="${event.target.id}" class="text-area" placeholder="Ввести текст">${str}</textarea>`, `<textarea id="${event.target.id}" class="text-area" placeholder="Ввести текст">${event.target.value}</textarea>`);
      }
    }
    else if (event.target.value && event.target.type !== 'select-one' && event.target.type !== 'checkbox') {
      if (event.target.id.includes('col') && event.target.type === 'text') {
        this.templateData.body = this.templateData.body.replace(`id="${event.target.id}"`, `id="${event.target.id}" value="${event.target.value}"`);
        this.setTableValue(event.target.id, event.target.value, null);
      }
      else if (event.target.id.includes('col') && event.target.type === 'date') {
        this.templateData.body = this.templateData.body.replace(`id="${event.target.id}"`, `id="${event.target.id}" value="${event.target.value}"`);
        this.setTableValue(event.target.id, event.target.value, null);
      }
      else if (event.target.id.includes('col') && event.target.type === 'number') {
        this.templateData.body = this.templateData.body.replace(`id="${event.target.id}"`, `id="${event.target.id}" value="${event.target.value}"`);
        this.setTableValue(event.target.id, event.target.value, null);
      }
      else if (event.target.id.includes('startcol') && event.target.type === 'time') {
        this.templateData.body = this.templateData.body.replace(`id="${event.target.id}"`, `id="${event.target.id}" value="${event.target.value}"`);
        this.setTableValue(event.target.id, event.target.value, 'start');
      }
      else if (event.target.id.includes('endcol') && event.target.type === 'time') {
        this.templateData.body = this.templateData.body.replace(`id="${event.target.id}"`, `id="${event.target.id}" value="${event.target.value}"`);
        this.setTableValue(event.target.id, event.target.value, 'end');
      }
      else {
        this.templateData.body = this.templateData.body.replace(`id="${event.target.id}"`, `id="${event.target.id}" value="${event.target.value}" autofocus`);
      }
    }
    else if (event.target.value === '' && event.target.type !== 'select-one' && event.target.type !== 'checkbox') {
      if (event.target.id.includes('col')) {
        const columnIndex = event.target.id.indexOf('l') + 1;
        const rowIndex = event.target.id.lastIndexOf('-');
        const column = event.target.id.substring(columnIndex, rowIndex);
        const row = event.target.id.substr(rowIndex + 1);
        const selectedData = this.tableData.find(data => data.number == row && data.columnNumber == column);
        if (selectedData && selectedData.value) {
          if (event.target.id[0] == 'c') {
            this.templateData.body = this.templateData.body.replace(`id="${event.target.id}" value="${selectedData.value}"`, `id="${event.target.id}"`);
          } else {
            if (selectedData.value.length == 1) {
              this.templateData.body = this.templateData.body.replace(`id="${event.target.id}" value="${selectedData.value[0]}"`, `id="${event.target.id}"`);
            } else {
              this.templateData.body = this.templateData.body.replace(`id="${event.target.id}" value="${selectedData.value[event.target.id[0]]}"`, `id="${event.target.id}"`);
            }
          }
          this.setTableValue(event.target.id, '', null);
        }
      }
    }

    if (document.querySelector(".template-table-container") && tableScrollLeft) {
      setTimeout(() => {
        document.querySelector(".template-table-container").scrollLeft = tableScrollLeft;
      });
    }

  }

  setTableValue(strId: string, value: string, timeType: string): void {
    const dashIndex = strId.lastIndexOf('-') + 1;
    const dataIndex = strId.substr(dashIndex);
    const columnNumber = strId.substring(strId.indexOf('l') + 1, dashIndex - 1);
    const tableItemData = this.tableData.find(data => data.number == dataIndex && data.columnNumber == columnNumber && timeType == data.timeType);
    const tableItemDataIndex = this.tableData.indexOf(data => data.number == dataIndex && data.columnNumber == columnNumber && timeType == data.timeType);
    if (tableItemData) {
      const info: ITemplateInfoData = this.templateInfo.find(info => info.position.toString() === tableItemData.columnNumber);
      if (info && info.type === 'table-string-and-date' || info && info.type === 'table-number-and-date') {
        if (value) {
          tableItemData.value[strId[0]] = value;
        } else {
          if (tableItemData.value.length > 1) {
            tableItemData.value.splice(strId[0], 1);
          } else {
            tableItemData.value = [];
          }
          if (!tableItemData.value.length) {
            this.tableData.splice(tableItemDataIndex, 1);
          }
        }
      } else {
        if (value) {
          tableItemData.value = value;
        } else {
          this.tableData.splice(tableItemDataIndex, 1);
        }
      }
    } else if (value) {
      const info: ITemplateInfoData = this.templateInfo.find(info => info.position.toString() === columnNumber);
      if (info && info.type === 'table-string-and-date' || info && info.type === 'table-number-and-date') {
        const arr = [];
        arr[strId[0]] = value;
        this.tableData.push({ number: dataIndex, columnNumber: columnNumber, value: arr, timeType: timeType });
      } else {
        this.tableData.push({ number: dataIndex, columnNumber: columnNumber, value: value, timeType: timeType });
      }
    }
  }

  setGroupsStudentsInTable(groupId: number): void {
    const columnStr = this.tableTds[0];
    const firstIndex = columnStr.indexOf('{') + 2;
    const lastIndex = columnStr.indexOf('}');
    const position = columnStr.substring(firstIndex, lastIndex);
    if (groupId) {
      // this.dataHandlerService.getData(`${GROUP_STUDENTS_BY_GROUP_ID_PATH_KEY}/${groupId}/${false}`).then(response => {
      this.dataHandlerService.getData(`DivisionStudent/GetStudentsByDivision/${groupId}/false`).then(response => {
        this.studentsInTable = response;
        while (this.tableRowCount > 1) {
          this.removeTableRow(this.tableRowCount.toString());
          this.tableRowCount--;
        }
        this.removeTableRow(this.tableRowCount.toString());
        this.templateData.body = this.templateData.body.replace(`id="col${position}-${this.tableRowCount}"`, `id="col${position}-${this.tableRowCount}" value="${this.studentsInTable[0].studentName}"`);
        this.setTableValue(`col${position}-${this.tableRowCount}`, this.studentsInTable[0].studentName, '');
        this.studentsInTable.forEach((student, index) => {
          if (index) {
            this.addTableRow();
            this.templateData.body = this.templateData.body.replace(`id="col${position}-${this.tableRowCount}"`, `id="col${position}-${this.tableRowCount}" value="${student.studentName}"`);
            this.setTableValue(`col${position}-${this.tableRowCount}`, student.studentName, '');
          }
        });
      });
    }
  }

  setInputsListValues(id: any, value: string, placeholder): void {
    const index = +id.substr(id.lastIndexOf('-') + 1);

    if (value) {
      if (this.inputsListData[index]) {
        this.templateData.body = this.templateData.body.replace(`<input id="${id}" value="${this.inputsListData[index]}"`, `<input id="${id}" value="${value}"`);
        if (this.templateData.body.includes('sign-list')) {
          this.templateData.body = this.templateData.body.replace(`<input disabled id="sign-list-${index}" value="${this.inputsListData[index]}"`, `<input disabled id="sign-list-${index}" value="${value}"`);
        }
        this.inputsListData[index] = value;
      }
      else {
        this.inputsListData.push(value);
        this.templateData.body = this.templateData.body.replace(`<input id="inputs-list-${this.inputsListData.length - 1}" value="" type="text" autocomplete="off" placeholder="${placeholder}" class="custom-input template-input bottom-short-mrg fit-height"></div>`,
          `<input id="inputs-list-${this.inputsListData.length - 1}" value="${value}" type="text" autocomplete="off" placeholder="${placeholder}" class="custom-input template-input bottom-short-mrg fit-height"><input id="inputs-list-${this.inputsListData.length}" value="" type="text" autocomplete="off" placeholder="${placeholder}" class="custom-input template-input bottom-short-mrg fit-height"></div>`);
        if (this.templateData.body.includes('sign-list')) {
          this.templateData.body = this.templateData.body.replace(`<input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-${this.inputsListData.length - 1}" value="" type="text" autocomplete="off" placeholder="Фамилия И.О." class="custom-input template-input bottom-short-mrg fit-height"></div>`,
            `<input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-${this.inputsListData.length - 1}" value="${value}" type="text" autocomplete="off" placeholder="Фамилия И.О." class="custom-input template-input bottom-short-mrg fit-height"></div><div class="flexable"><input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-${this.inputsListData.length}" value="" type="text" autocomplete="off" placeholder="Фамилия И.О." class="custom-input template-input bottom-short-mrg fit-height"></div>`);
        }
      }
    } else if (this.inputsListData[index]) {
      this.templateData.body = this.templateData.body.replace(`<input id="${id}" value="${this.inputsListData[index]}"`, `<input id="${id}" value=""`);
      if (this.templateData.body.includes('sign-list')) {
        this.templateData.body = this.templateData.body.replace(`<input disabled id="sign-list-${index}" value="${this.inputsListData[index]}"`, `<input id="sign-list-${index}" value=""`);
      }
      this.inputsListData[index] = '';
    }
  }

  hasStudentChecked(id: any, checked: boolean): void {
    if (this.templateData.body.includes(`<input checked type="checkbox" id="${id}">`)) {
      this.templateData.body = this.templateData.body.replace(`<input checked type="checkbox" id="${id}">`, `<input type="checkbox" id="${id}">`);
    } else {
      this.templateData.body = this.templateData.body.replace(`<input type="checkbox" id="${id}">`, `<input checked type="checkbox" id="${id}">`);
    }
    id = +id.substr(id.indexOf('-') + 1);
    let index = id - 1;
    const studentName = `${this.students[index].lastname} ${this.students[index].firstname} ${this.students[index].middlename ? this.students[index].middlename : ""}`;
    if (checked) {
      if (studentName !== this.studentsList[index]) {
        this.studentsList.splice(index, 0, studentName);
      }
    } else {
      const index = this.studentsList.indexOf(studentName);
      if (index > -1) {
        this.studentsList.splice(index, 1);
      }
    }
  }

  getGroupStudents(id: number, position: string, studentsData: Array<any>): void {
    if (id) {
      this.dataHandlerService.getData(`${GROUP_STUDENTS_BY_GROUP_ID_PATH_KEY}/${id}/${false}`).then(response => {
        this.students = response;
        const idNumberPart = position.substr(1);
        const index = +idNumberPart + 1;
        this.studentsList = [];
        if (studentsData) {
          this.studentsList = studentsData;
        }
        this.templateModel[`a${index}`] = this.studentsList;
        this.templateData.body = this.templateData.body.replace(`<div id="students-`, `<div class="hidden" id="students-`);

        if (this.students.length) {
          if (!studentsData) {
            this.students.forEach(student => this.studentsList.push(`${student.lastname} ${student.firstname} ${student.middlename ? student.middlename : ""}`));
          }
          const studentsInfo = this.templateInfo.find(data => data.type === 'students-list');
          this.templateData.body = this.templateData.body.replace(`<div id="students-`, `<div class="hidden" id="students-`);
          if (this.templateData.body.includes(`<div class="hidden" id="students-${id}"`)) {
            this.templateData.body = this.templateData.body.replace(`<div class="hidden" id="students-${id}"`, `<div id="students-${id}"`);
            this.templateModel[`a${index}`].forEach((name: string, ind: number) => {
              if (ind < -1) { return; }
              const chechBoxIndex = ind + 1;
              if (this.templateData.body.includes(`<input type="checkbox" id="${id}-${chechBoxIndex}">`)) {
                this.templateModel[`a${index}`][ind] = '';
              }
            });
            this.templateModel[`a${index}`].forEach((name: string, ind: number) => {
              if (ind < -1) { return; }
              if (!name) {
                this.templateModel[`a${index}`].splice(ind, 1);
              }
            });
          }
          else {
            if (studentsData && studentsData.length) {
              let str;
              for (let i = 0; i < this.students.length; i++) {
                for (let j = 0; j < studentsData.length; j++) {
                  if (!j && studentsData[j] === `${this.students[i].lastname} ${this.students[i].firstname} ${this.students[i].middlename ? this.students[i].middlename : ""}`) {
                    str = `<div id="students-${id}" class="students-container"><input checked type="checkbox" id="${id}-${i + 1}"><label for="${id}-${i + 1}">${this.students[i].lastname} ${this.students[i].firstname} ${this.students[i].middlename ? this.students[i].middlename : ""}</label><br></div>`;
                  }
                  else if (studentsData[j] === `${this.students[i].lastname} ${this.students[i].firstname} ${this.students[i].middlename ? this.students[i].middlename : ""}` && str) {
                    str = str.replace('</label><br></div>', `</label><br><input checked type="checkbox" id="${id}-${i + 1}"><label for="${id}-${i + 1}">${this.students[i].lastname} ${this.students[i].firstname} ${this.students[i].middlename ? this.students[i].middlename : ""}</label><br></div>`);
                  }
                }
              }
              for (let i = 0; i < this.students.length; i++) {
                if (str && !str.includes(`<input checked type="checkbox" id="${id}-${i + 1}"><label for="${id}-${i + 1}">${this.students[i].lastname} ${this.students[i].firstname} ${this.students[i].middlename ? this.students[i].middlename : ""}</label>`)) {
                  str = str.replace('</label><br></div>', `</label><br><input type="checkbox" id="${id}-${i + 1}"><label for="${id}-${i}">${this.students[i].lastname} ${this.students[i].firstname} ${this.students[i].middlename ? this.students[i].middlename : ""}</label><br></div>`);
                }
              }
              if (studentsInfo) {
                this.templateData.body = this.templateData.body.replace(`<p class="hidden">{a${studentsInfo.position}}</p>`, str);
              }
              this.templateData.body = this.templateData.body.replace(`<div class="hidden" id="students-`, `${str}<div class="hidden" id="students-`);
            } else {
              let str = `<div id="students-${id}" class="students-container"><input checked type="checkbox" id="${id}-1"><label for="${id}-1">${this.students[0].lastname} ${this.students[0].firstname} ${this.students[0].middlename ? this.students[0].middlename : ""}</label><br></div>`;
              for (let i = 1; i < this.students.length; i++) {
                str = str.replace('</label><br></div>', `</label><br><input checked type="checkbox" id="${id}-${i + 1}"><label for="${id}-${i + 1}">${this.students[i].lastname} ${this.students[i].firstname} ${this.students[i].middlename ? this.students[i].middlename : ""}</label><br></div>`);
              }
              this.templateData.body = this.templateData.body.replace(`<p class="hidden">{a${studentsInfo.position}}</p>`, str);
              this.templateData.body = this.templateData.body.replace(`<div class="hidden" id="students-`, `${str}<div class="hidden" id="students-`);
            }
          }
        }
      });
    }
  }

  setOptionSelectedAttr(target: any): void {
    const optionIdPart = target.id.substr(1);
    if (this.templateData.body.includes(`<option selected id="${optionIdPart}`)) {
      this.templateData.body = this.templateData.body.replace(`<option selected id="${optionIdPart}`, `<option id="${optionIdPart}`);
    }
    const options: NodeListOf<HTMLOptionElement> = target.querySelectorAll('option');
    let optionId: string;
    options.forEach(option => {
      if (option.selected) { optionId = option.id }
    });

    this.templateData.body = this.templateData.body.replace(`<option id="${optionId}">${target.value}</option>`, `<option selected id="${optionId}">${target.value}</option>`);
  }

  setDropDownValue(arr: Array<any>, ind: any, row: number, value?): string {
    if (!arr) { return; }
    if (!arr.length) {
      return `<select disabled id="${ind}" class="select-list"></select>`;
    }
    let str: string = '';
    if (row) {

      if (value && value === (arr[0].name || arr[0].groupName || arr[0].data)) {
        str = `<select id="col${ind}-${row}" class="select-list"><option selected disabled hidden></option><option selected id="col${ind}-${row}-0">${arr[0].name || arr[0].groupName || arr[0].data}</option></select>`;
      } else {
        str = `<select id="col${ind}-${row}" class="select-list"><option selected disabled hidden></option><option id="col${ind}-${row}-0">${arr[0].name || arr[0].groupName || arr[0].data}</option></select>`;
      }
      for (let i = 1; i < arr.length; i++) {
        if (value && value === (arr[i].name || arr[i].groupName || arr[i].data)) {
          str = str.replace('</option></select>', `</option><option selected id="col${ind}-${row}-${i}">${arr[i].name || arr[i].groupName || arr[i].data}</option></select>`);
        } else {
          str = str.replace('</option></select>', `</option><option id="col${ind}-${row}-${i}">${arr[i].name || arr[i].groupName || arr[i].data}</option></select>`);
        }
      }
    } else {
      if (value && value === (arr[0].name || arr[0].groupName || arr[0].data)) {
        str = `<select id="a${ind}" class="select-list"><option selected disabled hidden></option><option selected id="${ind}-0">${arr[0].name || arr[0].groupName || arr[0].data}</option></select>`;
      } else {
        str = `<select id="a${ind}" class="select-list"><option selected disabled hidden></option><option id="${ind}-0">${arr[0].name || arr[0].groupName || arr[0].data}</option></select>`;
      }
      for (let i = 1; i < arr.length; i++) {
        if (value && value === (arr[i].name || arr[i].groupName || arr[i].data)) {
          str = str.replace('</option></select>', `</option><option selected id="${ind}-${i}">${arr[i].name || arr[i].groupName || arr[i].data}</option></select>`);
        } else {
          str = str.replace('</option></select>', `</option><option id="${ind}-${i}">${arr[i].name || arr[i].groupName || arr[i].data}</option></select>`);
        }
      }
    }
    return str;
  }

  translate(name: string): string {
    let retName: string;
    this.translationService.translate(name).pipe(
      map((data: string) => retName = data)
    ).subscribe();
    return retName;
  }

}
