import { Component, OnInit } from '@angular/core';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { ITemplateData, ITemplateInfoData, ITemplateInfoDataModel } from 'src/app/Models/ITemplateData';
import {
  DOCUMENTTEMPLATE_I_D_CREATE,
  DOCUMENTTEMPLATE_I_D_UPDATE,
  DOCUMENTTEMPLATE_I_D_VIEW,
  DOCUMENTTEMPLATE_I_VIEW,
  DOCUMENTTEMPLATE_VIEW,
  TR_TEMPLATE_INFO_DATA_KEY
} from 'src/app/Constants/main-keys';
import {
  DOCUMENT_TEMPLATE_INFO_DATA_PATH_KEY,
  DOCUMENT_TEMPLATE_INFO_PATH_KEY,
  DOCUMENT_TEMPLATE_PATH_KEY,
  TEMPLATE_ID_PATH_PARAM_KEY,
  TEMPLATE_INFO_ID_PATH_PARAM_KEY
} from 'src/app/Constants/request-path-keys';

@Component({
  selector: 'app-template-info-data-table',
  templateUrl: './template-info-data-table.component.html',
  styleUrls: ['./template-info-data-table.component.scss']
})
export class TemplateInfoDataTableComponent implements OnInit {
  templatesInfoData: Array<ITemplateInfoDataModel> = [];
  templatesInfo: Array<ITemplateInfoData> = [];
  templates: Array<ITemplateData> = [];
  userRoles: Array<string> = [];
  selectedTemplateName: string;
  selectedTemplateInfoName: string;
  template_view: boolean;
  templateinfo_view: boolean;
  templateinfodata_view: boolean;
  templateinfodata_create: boolean;
  templateinfodata_update: boolean;
  isPageLoaded: boolean;

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private translationService: TranslationService,
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_TEMPLATE_INFO_DATA_KEY);

    this.getTemplates();
    this.getTemplatesInfo();
    this.getTemplatesInfoData();
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch (role) {
        case DOCUMENTTEMPLATE_VIEW:
          this.template_view = true;
          break;
        case DOCUMENTTEMPLATE_I_VIEW:
          this.templateinfo_view = true;
          break;
        case DOCUMENTTEMPLATE_I_D_VIEW:
          this.templateinfodata_view = true;
          break;
        case DOCUMENTTEMPLATE_I_D_CREATE:
          this.templateinfodata_create = true;
          break;
        case DOCUMENTTEMPLATE_I_D_UPDATE:
          this.templateinfodata_update = true;
          break;
      }
    });
  }

  getTemplatesInfoData() {
    this.dataHandlerService.getDataWithSpinner(DOCUMENT_TEMPLATE_INFO_DATA_PATH_KEY)
      .then(response => this.templatesInfoData = response);
  }

  async getTemplatesInfo() {
    this.templatesInfo = await this.dataHandlerService.getDataWithSpinner(DOCUMENT_TEMPLATE_INFO_PATH_KEY);
  }

  async getTemplates() {
    this.templates = await this.dataHandlerService.getDataWithSpinner(DOCUMENT_TEMPLATE_PATH_KEY);
  }

  setSelectedTemplateInfo(id: number, name: string): void {
    this.selectedTemplateInfoName = name;
    this.getTemplatesInfoDataByTemplateInfoId(id);
  }

  setSelectedTemplate(id: number, name: string): void {
    this.selectedTemplateName = name;
    this.getTemplatesInfoByTemplateId(id);
  }

  async getTemplatesInfoByTemplateId(id: number) {
    this.templatesInfo = await this.dataHandlerService.getData(`${DOCUMENT_TEMPLATE_INFO_DATA_PATH_KEY}?${TEMPLATE_ID_PATH_PARAM_KEY}${id}`);
  }

  async getTemplatesInfoDataByTemplateInfoId(id: number) {
    this.templatesInfoData = await this.dataHandlerService.getData(`${DOCUMENT_TEMPLATE_INFO_DATA_PATH_KEY}?${TEMPLATE_INFO_ID_PATH_PARAM_KEY}${id}`);
  }

}
