<ng-container *ngIf="pagerole_view">
  <div class="modal-header modal-list-header">
    <h5 class="modal-title">{{ 'TR_ROLES' | translate }}</h5>
    <button type="button" class="close pb-0" aria-label="Close" (click)="closeEmit.emit(null)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="flex overflow-auto">
    <app-modal-loader [modalSpinners]="[pageRolesSpinnerId, userTypeRolesSpinnerId]"></app-modal-loader>
    <div *ngIf="userTypeRolesData.roles && userTypeRolesData.roles.length" class="modal-list modal-container">
      <strong>
        <label>
          {{ 'TR_USER_TYPE_ROLES' | translate }}
          <app-help-alert alertKey="allow_deny_access_to_functions"></app-help-alert>
        </label>
      </strong>
      <div class="list-row" [class.max-label-width]="pageRolesData.roles && pageRolesData.roles.length" *ngFor="let item of userTypeRolesData.roles">
        <label [htmlFor]="item.name" class="mr-2 pointer">{{ item.name }}</label>
        <input
          *ngIf="usertype_role_create"
          [id]="item.name"
          [name]="item.name"
          type="checkbox"
          class="mt-2 pointer float-right"
          [disabled]="(isDefaultUserTypeSelected && userTypeRolesData.disable) || (item.isBelongToCurrentUserType && item.name.includes('view') && !item.name.includes('password'))"
          [(ngModel)]="item.isBelongToCurrentUserType"
          (ngModelChange)="changeUserTypeRoleValue(item.isBelongToCurrentUserType, item.id)"
        />
      </div>
    </div>
    <div *ngIf="pageRolesData.roles && pageRolesData.roles.length" class="modal-list modal-container">
      <strong><label>{{ 'TR_PAGE_ROLES' | translate }}</label></strong>
      <div class="list-row max-label-width" *ngFor="let item of pageRolesData.roles; index as i">
        <label [htmlFor]="item.name + i" class="mr-2 pointer">{{ item.name }}</label>
        <input
          *ngIf="page_role_create"
          [id]="item.name + i"
          [name]="item.name"
          type="checkbox"
          class="mt-2 pointer float-right"
          [disabled]="pageRolesData.disable"
          [(ngModel)]="item.isBelongToCurrentPage"
          (ngModelChange)="changePageRoleValue(item.isBelongToCurrentPage, item.id)"
        />
      </div>
    </div>
  </div>
</ng-container>