<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon k-cursor-pointer goback" (click)="mainService.goBack('group')"
        [title]="'TR_GOBACK' | translate"><i class="icon pe-7s-left-arrow icon-gradient bg-plum-plate"></i></div>
      <div>{{ isCreateForm ? ('TR_CREATE_GROUP' | translate) : ('TR_UPDATE_GROUP' | translate) }}</div>
    </div>
  </div>
</div>
<div class="main-card mb-3 card">
  <div class="card-body">
    <!-- <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('group')"></i>
      <h5 class="card-title">{{ isCreateForm ? ('TR_CREATE_GROUP' | translate) : ('TR_UPDATE_GROUP' | translate) }}</h5>
    </div> -->
    <div class="wrap-flex form-outline">
      <div *ngIf="user_view" ngbDropdown>
        <div class="flex flex-column">
          <!-- <label>{{ ('TR_CURATORS' | translate) + mainService.asterisk }}</label> -->
          <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary"
            [class.error-border]="isInvalidSubmit && !selectedCuratorId">
            {{ selectedCuratorName ? selectedCuratorName : ('TR_CURATORS' | translate) + mainService.asterisk }}
          </button>
        </div>
        <div ngbDropdownMenu>
          <button type="button" class="dropdown-item" *ngFor="let item of curators"
            (click)="setSelectedCurator(item.id, item.fullName)">
            {{ item.fullName }}
          </button>
        </div>
      </div>
      <ng-container *ngFor="let item of settingsItems;index as ind">
        <div ngbDropdown>
          <div class="flex flex-column">
            <!-- <label>{{ (item.name | translate) + mainService.asterisk }}</label> -->
            <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary" [disabled]="item.disabled"
              [class.error-border]="isInvalidSubmit && !item.selectedName">
              {{ item.selectedName && (item.selectedName | translate) ? (item.selectedName | translate) :
              item.selectedName && !(item.selectedName | translate) ? item.selectedName : (item.name | translate) +
              mainService.asterisk }}
            </button>
          </div>
          <div ngbDropdownMenu>
            <button type="button" class="dropdown-item" *ngFor="let content of item.content"
              (click)="openNextDropDown(item, content, ind)">
              {{ (content.name | translate) ? (content.name | translate) : content.name }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <hr>
    <form (submit)="submitData()" [formGroup]="form">
      <div class="form-row mt-2">
        <div class="col-lg-3 col-md-4">
          <div class="position-relative form-group">
            <label for="groupName">{{ ('TR_GROUP_NAME' | translate) + mainService.asterisk }}</label>
            <!-- [placeholder]="'TR_GROUP_NAME' | translate" -->
            <input type="text" name="groupName" class="form-control"
              [class.error-border]="(isInvalidSubmit && (!form.value.groupName || (form.value.groupName && !form.value.groupName.trim()))) || (form.value.groupName && form.value.groupName.trim().length && form.value.groupName.trim().length > groupNameMaxLength)"
              autocomplete="off" formControlName="groupName" />
          </div>
        </div>
      </div>
      <app-submit-button [spinnerId]="submitSpinnerId"
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit" [isFormValid]="form.valid"></app-submit-button>
    </form>
  </div>
</div>