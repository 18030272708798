import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { guid } from '@progress/kendo-angular-common';
import { ActivatedRoute } from '@angular/router';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { AlertService } from 'src/app/Services/alert.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { ICourseSectionData } from 'src/app/Models/ICourseData';
import { COURSE_PATH_KEY, COURSE_VIEW_URL_KEY, SECTION_PATH_KEY } from 'src/app/Constants/request-path-keys';
import { SUCCESS_KEY, TR_CREATE_MESSAGE_KEY, TR_CREATE_SECTION_KEY, TR_UPDATE_MESSAGE_KEY, TR_UPDATE_SECTION_KEY } from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-section-form',
  templateUrl: './section-form.component.html',
  styleUrls: ['./section-form.component.scss']
})
export class SectionFormComponent implements OnInit {
  form: FormGroup;
  courseId: number;
  sectionId: number;
  isInvalidSubmit: boolean;
  isCreateForm: boolean = true;
  submitSpinnerId: string = guid();
  userRoles: Array<string> = [];

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private translationService: TranslationService,
    private route: ActivatedRoute,
    public mainService: MainService,
    private rolesService: RolesService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.translationService.setPageTitle(TR_CREATE_SECTION_KEY);

    this.form = this.formBuilder.group({
      courseId: [null],
      name: [''],
    });

    this.route.paramMap.subscribe(params => {
      this.courseId = +params.get('item.id');
      this.sectionId = +params.get('section.id');
      if (this.courseId) {
        this.form.patchValue({ courseId: this.courseId });
      }
      if(this.sectionId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle(TR_UPDATE_SECTION_KEY);
        this.getSectionDataById(this.sectionId);
      }
    });
  }

  getSectionDataById(id: number): void {
    this.dataHandlerService.getDataWithSpinner(`${COURSE_PATH_KEY}/${SECTION_PATH_KEY}/${id}`).then(response => {
      const courseData: ICourseSectionData = response;
      this.form.patchValue({ name: courseData.name });
    });
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      if (this.sectionId) {
        form.id = this.sectionId;
        this.dataHandlerService.updateFormData(form, `${COURSE_PATH_KEY}/${SECTION_PATH_KEY}`, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.goBack();
        });
      } else {
        this.dataHandlerService.createFormData(form, `${COURSE_PATH_KEY}/${SECTION_PATH_KEY}`, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.goBack();
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }

  goBack(): void {
    this.mainService.goBackWithParams([COURSE_PATH_KEY, COURSE_VIEW_URL_KEY, this.courseId]);
  }
}
