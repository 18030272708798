<div class="main-card mb-3 card">
  <div class="card-body">
    <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('student')"></i>
      <h5 class="card-title">{{ 'TR_CHANGE_STUDENT_DOCUMENT_NUMBER' | translate }}</h5>
    </div>
    <form (submit)="submitData()">
      <div class="form-row">
        <div class="col-lg-3 col-md-4">
          <div class="position-relative form-group">
            <label for="personalDocumentNumber">{{ ('TR_DOCUMENT_NUMBER' | translate) + mainService.asterisk }}</label>
            <input
              name="personalDocumentNumber"
              [placeholder]="('TR_DOCUMENT_NUMBER' | translate) + mainService.asterisk"
              type="text"
              class="form-control"
              [class.error-border]="isInvalidSubmit && !personalDocumentNumber"
              autocomplete="off"
              maxlength="64"
              [(ngModel)]="personalDocumentNumber"
            />
          </div>
        </div>
      </div>
      <app-submit-button
        [spinnerId]="updateSpinnerId"
        [buttonName]="'TR_UPDATE' | translate"
        [isInvalidSubmit]="isInvalidSubmit"
        [isFormValid]="true"
      ></app-submit-button>
    </form>
  </div>
</div>