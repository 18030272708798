<ng-container *ngIf="isPageLoaded">
  <div class="title-block mb-3">
    <h4 class="card-title">{{ 'TR_QUESTIONS' | translate }}</h4>
  </div>

  <div class="row">
    <div class="col-md-6 col-sm-12 col-12">
      <div class="btn-block">
        <button type="button" class="mb-2 mr-2 btn btn-alternate create-btn" [routerLink]="['questiongroup-form']">
          <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE_QUESTION_GROUP' | translate }}</span>
        </button>

        <button type="button" class="mb-2 mr-2 btn btn-success create-btn" [routerLink]="['question-form']">
          <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE_QUESTION' | translate }}</span>
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-accordion>
        <mat-expansion-panel *ngFor="let questionsGroup of questionsGroups; index as questionsGroupIndex" (click)="getQuestionsByQuestionsGroupId(questionsGroup.id)">
          <mat-expansion-panel-header>
            <div class="btn-container">
              <button type="button" class="btn create-topic-btn" [title]="'TR_CREATE_QUESTION' | translate" [routerLink]="['question-form/createbygroup', questionsGroup.id]">
                <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE_QUESTION' | translate }}</span>
              </button>
            </div>
            <mat-panel-title>
              <span class="section-name mr-1">{{ 'TR_QUESTIONS_GROUP' | translate }} {{ questionsGroupIndex + 1 }}: </span> {{ questionsGroup.name }}
              <div *ngIf="selectedQuestionsGroupId === questionsGroup.id && !isQuestionGroupContentLoaded" class="spinner-border text-light ml-2" role="status">
                <span class="sr-only">Loading...</span> 
              </div>
            </mat-panel-title>
            <mat-panel-description>
              <div role="group" class="actions-group btn-group-sm btn-group float-right">
                <button class="btn-transition btn btn-outline-info" [title]="'TR_UPDATE_QUESTION_GROUP' | translate" [routerLink]="['questiongroup-form', questionsGroup.id]">
                  <i class="pe-7s-note"> </i>
                </button>
                <button class="ml-1 btn-transition btn btn-outline-primary" [title]="'TR_DELETE' | translate" (click)="confirmQuestionsGroupDelete(deleteSectionModal, questionsGroup.id, questionsGroup.name)">
                  <i class="pe-7s-trash"> </i>
                </button>
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div *ngIf="isQuestionGroupContentLoaded && selectedQuestionsGroupContent.length" class="main-card card">
            <div class="table-responsive">
              <table class="align-middle mb-0 table table-hover inner-table">
                <tbody>
                  <tr *ngFor="let question of selectedQuestionsGroupContent; index as questionIndex">
                    <td>{{ questionTypes[question.questionType].name | translate }}</td>
                    <td>{{ question.name }}</td>
                    <td>{{ question.text }}</td>
                    <td class="text-right">
                      <div role="group" class="actions-group btn-group-sm btn-group">
                        <button class="ml-1 btn-transition btn btn-outline-info" [title]="'TR_UPDATE_QUESTION' | translate" [routerLink]="['question-form', question.id]">
                          <i class="pe-7s-note"></i>
                        </button>
                        <button class="ml-1 btn-transition btn btn-outline-primary" [title]="'TR_DELETE' | translate" (click)="confirmDelete(deleteblock, question.id)">
                          <i class="pe-7s-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <ng-template #deleteblock let-c="close" let-d="dismiss">
    <div class="custom-modal modal-body">
      <p class="word-break-break-word">{{ 'TR_DELETE_ERROR' | translate }} {{ deleteQuestionName }} ? </p>
    </div>
    <div class="custom-modal modal-footer">
      <button type="button" class="mr-2 btn-transition btn btn-outline-info" (click)="delete()">{{ 'TR_YES' | translate }}</button>
      <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate }}</button>
    </div>
  </ng-template>

</ng-container>
