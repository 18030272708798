<div class="main-card mb-3 card">
  <div class="card-body">
    <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="goBack()"></i>
      <h5 class="card-title">{{ isCreateForm ? ('TR_CREATE_TOPIC' | translate) : ('TR_UPDATE_TOPIC' | translate) }}</h5>
    </div>
    <form (submit)="submitData()" [formGroup]="form">
      <div class="form-row">
        <div class="col-lg-6 col-md-8">
          <div class="position-relative form-group">
            <label for="name">{{ 'TR_NAME' | translate }}</label>
            <input
              type="text"
              name="name"
              [placeholder]="'TR_NAME' | translate"
              class="form-control"
              autocomplete="off"
              formControlName="name"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-6 col-md-8">
          <div class="position-relative form-group">
            <label for="description">{{ 'TR_DESCRIPTION' | translate }}</label>
            <textarea
              [rows]="5"
              type="text"
              name="description"
              [placeholder]="'TR_DESCRIPTION' | translate"
              class="form-control"
              autocomplete="off"
              formControlName="description"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-3 col-md-4">
          <div class="position-relative form-group">
            <label for="topicPeriod">{{ 'TR_DURATION_DAYS' | translate }}</label>
            <input
              type="number"
              name="topicPeriod"
              [placeholder]="'TR_DURATION_DAYS' | translate"
              class="form-control"
              autocomplete="off"
              formControlName="topicPeriod"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-3 col-md-4 col-12">
          <div class="mt-3 mb-3 upload-block form-outline">
            <div class="mb-2">
              <button type="button" class="btn-transition btn btn-outline-info upload-btn" (click)="image.click()">
                <i class="pe-7s-plus"></i>
                <p>{{ 'TR_UPLOADAVATAR' | translate }}</p>
              </button>
              <input #image hidden type="file" name="upload-avatar" (change)="handleImageUpload($event.target.files)" />
            </div>
            <div *ngIf="uploadedImage" [ngStyle]="{ 'background-image' : 'url(' + uploadedImage + ')' }" class="upload-avatar"></div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-12">
          <div class="mt-3 mb-3 upload-block form-outline">
            <div class="mb-2">
              <button type="button" class="btn-transition btn btn-outline-info upload-btn" (click)="video.click()">
                <i class="pe-7s-plus"></i>
                <p>{{ 'TR_UPLOAD_VIDEO' | translate }}</p>
              </button>
              <input #video hidden type="file" name="upload-avatar" (change)="handleVideoUpload($event.target.files)" />
            </div>
            <video *ngIf="uploadedVideo" [src]="uploadedVideo" class="upload-avatar" autoplay></video>
          </div>
        </div>
      </div>
      <app-submit-button
        [spinnerId]="submitSpinnerId"
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit"
        [isFormValid]="form.valid"
      ></app-submit-button>
    </form>
  </div>
</div>
