<div class="main-card mb-3 card">
  <div class="card-body">
    <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('filelibrary')"></i>
      <h5 class="card-title">{{ isCreateForm ? ('TR_CREATE_FILE' | translate) : ('TR_UPDATE_FILE' | translate) }}</h5>
    </div>
    <form (submit)="submitData()" [formGroup]="form">
      <div class="form-row">
        <div class="col-lg-6 col-md-8">
          <div class="position-relative form-group">
            <label for="name">{{ 'TR_NAME' | translate }}</label>
            <input type="text" name="name" [placeholder]="'TR_NAME' | translate" class="form-control" autocomplete="off"
              formControlName="name" />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-3 col-md-4 col-12">
          <div class="mt-3 mb-3 upload-block form-outline">
            <div class="mb-2">
              <button type="button" class="btn-transition btn btn-warning upload-btn" (click)="file.click()">
                <i class="pe-7s-plus"></i>
                <p>{{ 'TR_UPLOAD_FILE' | translate }}</p>
              </button>
              <input #file hidden type="file" name="upload-avatar" (change)="handleFileUpload($event.target.files)" />
            </div>
            <video *ngIf="isVideo && uploadedFile" [src]="uploadedFile" autoplay class="upload-avatar"></video>
            <div *ngIf="isImage && uploadedFile" [ngStyle]="{ 'background-image' : 'url(' + uploadedFile + ')' }"
              class="upload-avatar"></div>
            <p *ngIf="isFile && uploadedFile" class="uploaded-file">{{ form.value.fileEntity.name }}</p>
          </div>
        </div>
      </div>
      <app-submit-button [spinnerId]="submitSpinnerId"
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit" [isFormValid]="form.valid">
      </app-submit-button>
    </form>
  </div>
</div>