<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon k-cursor-pointer goback" (click)="mainService.goBack('usertype')"
        [title]="'TR_GOBACK' | translate"><i class="icon pe-7s-left-arrow icon-gradient bg-plum-plate"></i></div>
      <div> {{ isCreateForm ? ('TR_CREATEUSERTYPE' | translate) : ('TR_EDITUSERTYPE' | translate) }}
      </div>
    </div>
  </div>
</div>
<div class="main-card mb-3 card">
  <div class="card-body">
    <div class="title-block mb-3">
      <!-- <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('usertype')"></i> -->
      <!-- <h5 class="card-title">{{ isCreateForm ? ('TR_CREATEUSERTYPE' | translate) : ('TR_EDITUSERTYPE' | translate) }}
      </h5> -->
    </div>
    <form (submit)="submitData()" [formGroup]="form">
      <div class="form-row">
        <div class="col-md-6">
          <div class="position-relative form-group">
            <label for="name" class="mb-2">{{('TR_USERTYPE' | translate) + mainService.asterisk}}</label>
            <!-- [placeholder]="('TR_USERTYPE' | translate) + mainService.asterisk" -->
            <input name="name"
              [class.error-border]="(isInvalidSubmit && (!form.value.name || (form.value.name && !form.value.name.trim()))) || (form.value.name && form.value.name.trim().length && form.value.name.trim().length > nameMaxLength) || isInvalidName"
              type="text" class="form-control" autocomplete="off" formControlName="name" />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6">
          <div class="main-card mb-3 card">
            <div class="card-header">
              <div class="card-header-title font-size-lg font-weight-normal">
                {{ 'TR_SELECTDEF_USERTYPES' | translate }}
              </div>
            </div>
            <div class="table-responsive">
              <table class="align-middle mb-0 table table-striped table-hover">
                <tbody>
                  <tr *ngFor="let item of defaultUserTypes">
                    <td>
                      <label class="pointer" [htmlFor]="item.name">{{ item.name }}</label>
                    </td>
                    <td>
                      <input [id]="item.name" [name]="item.name" type="checkbox" class="mt-2 pointer"
                        [(ngModel)]="item.selected" [ngModelOptions]="{standalone: true}" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <app-submit-button [spinnerId]="submitSpinnerId"
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit" [isFormValid]="form.valid"></app-submit-button>
    </form>
  </div>
</div>