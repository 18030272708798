import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { guid } from '@progress/kendo-angular-common';
import { IGroupData } from 'src/app/Models/IGroupData';
import { IOrganizationData } from 'src/app/Models/IOrganizationData';
import { IUserData } from 'src/app/Models/IUserData';
import { IWebinarData } from 'src/app/Models/IWebinarData';
import { MainService } from 'src/app/Services/main.service';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { DataStorageService } from 'src/app/Services/data-storage.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { AlertService } from 'src/app/Services/alert.service';
import {
  GROUP_VIEW,
  ORGANIZATION_VIEW,
  USER_BY_USERTYPE_VIEW,
  WEBINAR_CREATE,
  WEBINAR_DELETE,
  WEBINAR_UPDATE,
  WEBINAR_VIEW,
  START_TIME_KEY,
  END_TIME_KEY,
  MONTH_ARR_KEY,
  WEEK_DAYS_KEY,
  SUCCESS_KEY,
  TR_UPDATE_MESSAGE_KEY,
  TR_DELETE_MESSAGE_KEY,
  TR_WEBINARS_KEY,
  userTypes
} from 'src/app/Constants/main-keys';
import {
  GROUP_PATH_KEY,
  ORGANIZATIONS_PATH_KEY,
  PAGE_NUMBER_PATH_PARAM_KEY,
  GROUPS_BY_CURATOR_ID_PATH_KEY,
  WEBINAR_BY_WEBINAR_CALENDAR_PATH_KEY,
  GROUPS_BY_ORGANIZATION_ID_PATH_KEY,
  PAGE_SIZE_PATH_PARAM_KEY,
  USERS_PATH_KEY,
  USER_TYPE_ID_PATH_PARAM_KEY,
  WEBINARS_PATH_KEY,
  WEBINARS_BY_CURATOR_ID_PATH_KEY,
  WENINARS_BY_GROUP_ID_PATH_KEY,
  ORG_ID_PATH_PARAM_KEY,
} from 'src/app/Constants/request-path-keys';

@Component({
  selector: 'app-webinars-table',
  templateUrl: './webinars-table.component.html',
  styleUrls: ['./webinars-table.component.scss']
})
export class WebinarsTableComponent implements OnInit {
  currentUser: IUserData;
  userRoles: Array<string> = [];
  user_view: boolean;
  group_view: boolean;
  webinar_view: boolean;
  webinar_create: boolean;
  webinar_update: boolean;
  webinar_delete: boolean;
  organization_view: boolean;
  openCurators: boolean;
  openPage: boolean;
  openWebinars: boolean;
  curators: Array<IUserData> = [];
  groups: Array<IGroupData> = [];
  organizations: Array<IOrganizationData> = [];
  webinars: Array<IWebinarData> = [];
  webinarData: Array<IWebinarData> = [];
  months: Array<any> = [];
  monthDays: Object;
  curatorId: number;
  webinarId: number;
  selectedOrganizationId: number;
  openCalendar: boolean = true;
  calendarContainer: boolean = true;
  selectedGroupName: string;
  selectedOrganizationName: string;
  webinarName: string;
  selectedWebinarName: string;
  weekDays = WEEK_DAYS_KEY;
  monthsNames: Array<string> = MONTH_ARR_KEY;
  timePickerElem: any;
  webinarUpdateSpinnerId: string = guid();
  deleteWebinarSpinnerId: string = guid();
  groupId: number;

  constructor(
    private dataStorageService: DataStorageService,
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private translationService: TranslationService,
    private modalService: NgbModal,
    private mainService: MainService,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.currentUser = this.dataStorageService.getUser();
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_WEBINARS_KEY);
    if (this.currentUser.userTypeId !== userTypes.CURATOR_USER_TYPE_KEY && this.user_view && this.group_view && !this.organization_view) {
      this.getCurators();

      //this.getGroupsByCurator(this.curatorId);

    } else if (this.webinar_view && this.webinar_create) {
      this.curatorId = this.currentUser.id;
      this.openWebinars = true;

      this.openWebinarsByCurrentUser();
      this.dataHandlerService.getDataWithSpinner(GROUP_PATH_KEY).then(response => this.groups = response);
    }

    if (this.organization_view) {
      this.dataHandlerService.getDataWithSpinner(ORGANIZATIONS_PATH_KEY).then(response => this.organizations = response);
    }
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case WEBINAR_VIEW:
          this.webinar_view = true;
          break;
        case WEBINAR_UPDATE:
          this.webinar_update = true;
          break;
        case WEBINAR_CREATE:
          this.webinar_create = true;
          break;
        case WEBINAR_DELETE:
          this.webinar_delete = true;
          break;
        case USER_BY_USERTYPE_VIEW:
          this.user_view = true;
          break;
        case GROUP_VIEW:
          this.group_view = true;
          break;
        case ORGANIZATION_VIEW:
          this.organization_view = true;
          break;
      }
    });
  }

  openWebinarsByCurrentUser(): void {
    if (this.webinar_view) {
      this.openCalendar = false;
      this.calendarContainer = false;
      this.selectedGroupName = null;
      this.dataHandlerService.getDataWithSpinner(WEBINARS_PATH_KEY).then(response => {
        this.webinars = response;
      });
    }
  }

  openWebinarsByCurator(data: any): void {
    this.webinarId = null;
    this.groupId = null;
    if (!this.webinar_view) { return; }
    this.openWebinars = true;
    this.calendarContainer = true;
    this.selectedGroupName = "";

    if (data.id) {
      this.curatorId = data.id;
    } else {
      this.curatorId = this.currentUser.id;
    }

    this.dataHandlerService.getData(`${WEBINARS_BY_CURATOR_ID_PATH_KEY}${this.curatorId}`)
      .then(response => this.webinars = response);

    this.getGroupsByCurator(this.curatorId);
  }

  getCurators(): void {
    this.dataHandlerService.getDataWithSpinner(
      `${USERS_PATH_KEY}?${USER_TYPE_ID_PATH_PARAM_KEY}${userTypes.CURATOR_USER_TYPE_KEY}&${PAGE_NUMBER_PATH_PARAM_KEY}1&${PAGE_SIZE_PATH_PARAM_KEY}100`
    ).then(response => {
      this.openCurators = true;
      this.curators = response;
    });
  }

  getWebinarsByGroup(id: number, name: string): void {
    if (!this.webinar_view) { return; }
    this.webinarId = null;
    this.groupId = id ? id : null;
    this.openCalendar = false;
    this.calendarContainer = false;
    this.selectedGroupName = name;
    this.dataHandlerService.getData(`${WENINARS_BY_GROUP_ID_PATH_KEY}${id}`).then(response => {
      this.openWebinars = true;
      this.calendarContainer = true;
      this.webinars = response;
    });
  }

  async getGroupsByCurator(curatorId: number) {
    if (!this.group_view) { return; }
    this.groups = await this.dataHandlerService.getData(`${GROUPS_BY_CURATOR_ID_PATH_KEY}${curatorId}`);
  }

  getOrganizationCurators(id: number, name: string): void {
    if (!this.user_view || !this.group_view) { return; }
    this.selectedOrganizationId = id;
    this.selectedOrganizationName = name;
    this.getCuratorAndGroups(this.selectedOrganizationId);
  }

  async getCuratorAndGroups(id: number) {
    this.webinars = [];
    this.openWebinars = false;
    this.openCurators = true;
    this.selectedGroupName = '';
    this.calendarContainer = false;
    this.curators = await this.dataHandlerService.getData(
      `${USERS_PATH_KEY}?${ORG_ID_PATH_PARAM_KEY}${id}&${USER_TYPE_ID_PATH_PARAM_KEY}${userTypes.CURATOR_USER_TYPE_KEY}&${PAGE_NUMBER_PATH_PARAM_KEY}${1}&${PAGE_SIZE_PATH_PARAM_KEY}${10}`
    );

    this.groups = await this.dataHandlerService.getData(`${GROUPS_BY_ORGANIZATION_ID_PATH_KEY}${id}`);
  }

  confirmSave(content, timePicker): void {
    this.timePickerElem = timePicker;
    this.modalService.open(content).result.then(() => {
      this.timePickerElem = null;
    }, reject => { });
  }

  confirmDelete(content, timePicker): void {
    this.timePickerElem = timePicker;
    this.modalService.open(content).result.then(() => {
      this.timePickerElem = null;
    }, reject => { });;
  }

  saveChanges(): void {
    if (!this.webinar_update && !this.timePickerElem) { return; }

    this.webinarData.forEach(item => {
      if (item.id === this.timePickerElem.id) {
        const calendarData = { ...item };
        calendarData[START_TIME_KEY] = this.mainService.getUTCTime(this.timePickerElem.startTime, false).time;
        calendarData[END_TIME_KEY] = this.mainService.getUTCTime(this.timePickerElem.endTime, false).time;

        this.dataHandlerService.updateData(calendarData, WEBINAR_BY_WEBINAR_CALENDAR_PATH_KEY, this.webinarUpdateSpinnerId).then(() => {
          const startTime = calendarData[START_TIME_KEY].split(':');
          const endTime = calendarData[END_TIME_KEY].split(':');
          item[START_TIME_KEY] = moment(new Date(new Date(item[START_TIME_KEY]).setHours(+startTime[0])).setMinutes(+startTime[1])).format('YYYY-MM-DD HH:mm:ss');
          item[END_TIME_KEY] = moment(new Date(new Date(item[END_TIME_KEY]).setHours(+endTime[0])).setMinutes(+endTime[1])).format('YYYY-MM-DD HH:mm:ss');
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.timePickerElem = null;
          this.modalService.dismissAll();
        });
      }
    });
  }

  delete(): void {
    if (!this.webinar_delete && !this.timePickerElem) { return; }

    const selectedWebinar: IWebinarData = this.webinarData.find((item: IWebinarData) => item.id === this.timePickerElem.id);
    selectedWebinar && this.dataHandlerService.deleteData(selectedWebinar.id, WEBINAR_BY_WEBINAR_CALENDAR_PATH_KEY, this.deleteWebinarSpinnerId).then(() => {
      this.alertService.translateAndAlertMessage(TR_DELETE_MESSAGE_KEY, SUCCESS_KEY);
      this.modalService.dismissAll();
      if (this.webinarData.length === 1) {
        this.dataHandlerService.getData(WEBINARS_PATH_KEY).then(response => {
          this.webinarData = null;
          this.webinarId = null;
          this.webinarName = null;
          this.webinars = response;
          this.timePickerElem = null;
          this.months = [];
          this.openCalendar = false;
          this.calendarContainer = false;
        });
      } else {
        this.dataHandlerService.getData(`${WEBINARS_PATH_KEY}/${selectedWebinar.webinarId}`).then(response => {
          this.openWebinar(response, null);
          this.timePickerElem = null;
        });
      }
    });
  }

  openWebinar(selectedWebinarData: IWebinarData, content: any) {
    this.months = [];
    if (!selectedWebinarData.days.length) { return; }
    content && this.modalService.open(content, { size: 'lg' });
    this.openCalendar = true;
    this.calendarContainer = true;
    this.selectedWebinarName = selectedWebinarData.name;

    if (selectedWebinarData.periodicity === 1) {
      const dayData = selectedWebinarData.days[0];
      const startTime = this.mainService.convertUTCDateToLocalDate(new Date(dayData.startTime));
      const endTime = this.mainService.convertUTCDateToLocalDate(new Date(dayData.endTime));

      const webinarDate = this.mainService.convertUTCDateToLocalDate(new Date(dayData.webinarDate));
      startTime.getDate() !== webinarDate.getDate() && webinarDate.setDate(startTime.getDate());
      const month = webinarDate.getMonth() + 1;
      const monthData = {
        monthNumber: month,
        monthName: this.monthsNames[month - 1],
        year: webinarDate.getFullYear(),
        days: [
          {
            dayNumber: webinarDate.getDate(),
            dayName: this.weekDays[webinarDate.getDay()],
            content: [
              {
                startTime: `${this.mainService.checkDateNumber(startTime.getHours().toString())}:${this.mainService.checkDateNumber(startTime.getMinutes().toString())}`,
                endTime: `${this.mainService.checkDateNumber(endTime.getHours().toString())}:${this.mainService.checkDateNumber(endTime.getMinutes().toString())}`,
                inProcess: dayData.inProcess,
                isFinished: dayData.isFinished,
              }
            ]
          }
        ]
      };
      this.months.push(monthData);
    } else {
      let startDate = new Date(selectedWebinarData.startDate);
      let endDate = new Date(selectedWebinarData.endDate);

      const days = selectedWebinarData.days.map(day => {
        const startTime = this.mainService.convertUTCDateToLocalDate(new Date(day.startTime));
        const endTime = this.mainService.convertUTCDateToLocalDate(new Date(day.endTime));
        let webinarDate = this.mainService.convertUTCDateToLocalDate(new Date(day.webinarDate));
        if (webinarDate.getTime() !== startTime.getTime()) {
          webinarDate = this.mainService.convertUTCDateToLocalDate(new Date(day.startTime));
          webinarDate.setHours(0);
          webinarDate.setMinutes(0);
        }
        if (webinarDate < startDate) {
          startDate = new Date(webinarDate);
        }
        if (webinarDate > endDate) {
          endDate = new Date(webinarDate);
        }
        return {
          ...day,
          startTime,
          endTime,
          webinarDate
        }
      });

      const startMonth = startDate.getMonth() + 1;
      const endMonth = endDate.getMonth() + 1;
      for (let i = startMonth; i <= endMonth; i++) {
        this.months.push({
          monthNumber: i,
          monthName: this.monthsNames[i - 1],
          year: startDate.getFullYear(),
          days: []
        });
      }

      let currentDate = startDate;
      while (currentDate <= endDate) {
        const currentDateMonth = currentDate.getMonth() + 1;
        const currentMonth = this.months.find(month => month.monthNumber === currentDateMonth);
        const filteredDays = days.filter(day => day.webinarDate.getTime() === currentDate.getTime());
        const dayContent = filteredDays.map(day => ({
          startTime: `${this.mainService.checkDateNumber(day.startTime.getHours().toString())}:${this.mainService.checkDateNumber(day.startTime.getMinutes().toString())}`,
          endTime: `${this.mainService.checkDateNumber(day.endTime.getHours().toString())}:${this.mainService.checkDateNumber(day.endTime.getMinutes().toString())}`,
          inProcess: day.inProcess,
          isFinished: day.isFinished,
        }));

        currentMonth && currentMonth.days.push({
          dayNumber: currentDate.getDate(),
          dayName: this.weekDays[currentDate.getDay()],
          content: dayContent
        });
        currentDate = this.addDays(1, currentDate);
      }
    }
  }

  getMonthDays(month, year) {
    return new Date(year, month, 0).getDate();
  }

  addDays(days, currentDate) {
    var date = new Date(currentDate);
    date.setDate(date.getDate() + days);
    return date;
  }
}
