import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MainService } from 'src/app/Services/main.service';
import { IQuestionData } from 'src/app/Models/IQuestionModel';
import { ANSWER_CASE_TYPES, POSITIVE_GRADES_ARR_KEY } from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-short-answer',
  templateUrl: './short-answer.component.html',
  styleUrls: ['./short-answer.component.scss']
})
export class ShortAnswerComponent implements OnInit, OnChanges {
  form: FormGroup;
  selectedAnswerCaseType: string;
  grades: Array<number> = POSITIVE_GRADES_ARR_KEY;
  answerCaseTypes: Array<string> = ANSWER_CASE_TYPES;

  @Input() questionData: IQuestionData;
  @Output() submitEmmiter = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    public mainService: MainService,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      isCaseSensitive: [false],
      answers: this.formBuilder.array([])
    });

    this.addAnswer();
    this.setSelectedAnswerCaseType(0, this.answerCaseTypes[0]);

    this.mainService.questionSubmitted.subscribe(data => {
      if (data && this.form.valid) { 
        const form = { ...this.form.value };
        form.answers = this.checkAnswers(form.answers);
        this.submitEmmiter.emit(form);
      }
    });

   this.setUpdateQuestionData();
  }

  ngOnChanges() {
    this.setUpdateQuestionData();
  }

  setUpdateQuestionData(): void {
    if(this.form && this.questionData) {
      this.form.patchValue({
        isCaseSensitive: this.questionData.isCaseSensitive,
      });
      this.setSelectedAnswerCaseType(+this.questionData.isCaseSensitive, this.answerCaseTypes[+this.questionData.isCaseSensitive]);

      this.questionData.answers && this.questionData.answers.length && this.setUpdateAnswers();
    }
  }

  setUpdateAnswers(): void {
    const answers = <FormArray>this.form.get('answers')['controls'];

    this.questionData.answers.forEach((answer, index) => {
      if(answers[index]) {
        answers[index].patchValue({
          id: answer.id,
          gradepercent: answer.gradePercent,
          text: answer.text,
          feedback: answer.feedback
        });
      } else {
        answers.push(
          this.formBuilder.group({
            id: [answer.id],
            text: [answer.text],
            gradepercent: [answer.gradePercent],
            feedback: [answer.feedback],
          })
        );
      }
    });

    this.form.patchValue({ answers: answers });
  }

  checkAnswers(answers: Array<any>): Array<any> {
    const newAnswers = answers.filter(data => {
      for(let i in data) {
        data[i] === null && delete data[i];
      }

      if(data.text) {  return data; }
    });
    return newAnswers;
  }

  addAnswer(): void {
    let answers = <FormArray>this.form.controls.answers;
    for (let i = 0; i < 3; i++) {
      answers.push(
        this.formBuilder.group({
          id: [null],
          text: [''],
          gradepercent: [''],
          feedback: ['']
        })
      );
    }
  }

  setSelectedGrade(value: number, answerIndex: number): void {
    if (answerIndex === -1) { return; }
    const answers = <FormArray>this.form.get('answers')['controls'];

    answers[answerIndex].patchValue({ gradepercent: value });
    this.form.patchValue({ answers: answers });
  }

  setSelectedAnswerCaseType(answerIndex: number, name: string): void {
    this.selectedAnswerCaseType = name;
    this.form.patchValue({ isCaseSensitive: Boolean(answerIndex) });
  }
}
