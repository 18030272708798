import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { IQuizData } from 'src/app/Models/IQuizModel';
import { IQuestionData } from 'src/app/Models/IQuestionModel';
import { COURSE_URL_KEY, COURSE_VIEW_URL_KEY } from 'src/app/Constants/request-path-keys';
import { GRADING_METHODS_ARR_KEY, TIME_TYPES_ARR_KEY, EXPIRE_TYPES_ARR_KEY, QUESTION_TYPES_ARR_KEY, CHOISE_NUMBERING_TYPES_ARR_KEY } from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-quiz-view',
  templateUrl: './quiz-view.component.html',
  styleUrls: ['./quiz-view.component.scss']
})
export class QuizViewComponent implements OnInit {
  quizData: IQuizData;
  quizId: number;
  courseId: number;
  currentQuestionData: IQuestionData;
  gradingMethods: Array<string> = GRADING_METHODS_ARR_KEY;
  timeTypes: Array<string> = TIME_TYPES_ARR_KEY;
  expireTypes: Array<string> = EXPIRE_TYPES_ARR_KEY;
  questionTypes: Array<object> = QUESTION_TYPES_ARR_KEY;
  choiseNumberingTypes: Array<object> = CHOISE_NUMBERING_TYPES_ARR_KEY;

  constructor(
    private dataHandlerService: DataHandlerService,
    private route: ActivatedRoute,
    private mainService: MainService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.quizId = +params.get('id');
      this.courseId = +params.get('item.id');
      if (this.quizId) {
        this.dataHandlerService.getDataWithSpinner(`coursequiz/${this.quizId}`).then(response => {
          this.quizData = response;
        });
      }
    });
  }

  goBack() {
    this.mainService.goBackWithParams([COURSE_URL_KEY, COURSE_VIEW_URL_KEY, this.courseId]);
  }

  openQuestionModal(question: IQuestionData, content: any): void {
    this.modalService.open(content, { size: "lg" }).result;
    this.currentQuestionData = question;
  }

}
