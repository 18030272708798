import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { guid } from '@progress/kendo-angular-common';
import { ActivatedRoute } from '@angular/router';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { AlertService } from 'src/app/Services/alert.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { ILanguageData } from 'src/app/Models/ILanguageData';
import { IPageContentData } from 'src/app/Models/IPageContentData';
import {
  ID_PATH_PARAM_KEY,
  LANGUAGES_PATH_KEY,
  LANG_ID_PATH_PARAM_KEY,
  //PAGE_CATEGORIES_PATH_KEY,
  PAGE_GET_FOR_CONTENT_PATH_KEY,
  PAGE_CONTENT_PATH_KEY,
  PAGE_CONTENT_URL_KEY,
  PAGE_CONTENT_BY_LANGPATH_KEY,
  BY_TYPE_PARAM_KEY
} from 'src/app/Constants/request-path-keys';
import {
  //CATEGORY_VIEW,
  PAGE_CONTENT_CREATE,
  PAGE_CONTENT_UPDATE,
  PAGE_CONTENT_VIEW,
  PAGE_VIEW,
  SUCCESS_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_CREATE_PAGE_CONTENT_KEY,
  TR_UPDATE_MESSAGE_KEY,
  TR_UPDATE_PAGE_CONTENT_KEY
} from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-page-content-form',
  templateUrl: './page-content-form.component.html',
  styleUrls: ['./page-content-form.component.scss']
})
export class PageContentFormComponent implements OnInit {
  form: FormGroup;
  selectedImage: any;
  imagePath: any;
  selectedPageName: string;
  selectedLanguageName: string;
  pageContentId: number;
  content_view: boolean;
  page_view: boolean;
  //category_view: boolean;
  content_create: boolean;
  content_update: boolean;
  isInvalidSubmit: boolean;
  isShowRichTextEditor: boolean;
  isCreateForm: boolean = true;
  submitSpinnerId: string = guid();
  userRoles: Array<string> = [];
  pages: Array<any> = [];
  languages: Array<ILanguageData> = [];

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    private translationService: TranslationService,
    public mainService: MainService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_CREATE_PAGE_CONTENT_KEY);
    
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      shortContent: [''],
      fullContent: ['', Validators.required],
      showInSlider: [false],
      // isShowRichTextEditor: [false],
      image: [''],
      buttonLink: [''],
      buttonLinkUnauth: [''],
      languageId: [null, Validators.required],
      pageId: [null, Validators.required]
    });
    
    this.getLanguages();
    this.getPages();

    this.route.paramMap.subscribe(params => {
      this.pageContentId = +params.get('item.id');

      if (this.pageContentId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle(TR_UPDATE_PAGE_CONTENT_KEY);
        this.getPageContentById(+params.get('language.languageId'));
      }
    });
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch (role) {
        case PAGE_CONTENT_VIEW:
          this.content_view = true;
          break;
        case PAGE_CONTENT_CREATE:
          this.content_create = true;
          break;
        case PAGE_CONTENT_UPDATE:
          this.content_update = true;
          break;
        // case CATEGORY_VIEW:
        //   this.category_view = true;
        //   break;
        case PAGE_VIEW:
          this.page_view = true;
          break;
      }
    });
  }

  onCheckboxChange(event:any){
    this.isShowRichTextEditor = event.target.checked;
  }

  async getPages() {
    this.dataHandlerService.getDataWithSpinner(PAGE_GET_FOR_CONTENT_PATH_KEY).then(response => {
      this.pages = response;
      this.pages.forEach(page => {
        this.translationService.translatePages(page.name).subscribe(translatedPageName => {
          if (translatedPageName) {
            page.name = translatedPageName;
          }
        });
      });
    });
  }

  async getLanguages() {
    this.languages = await this.dataHandlerService.getDataWithSpinner(LANGUAGES_PATH_KEY);
  }

  getPageContentById(languageId: number): void {
    if (!this.content_view) { return; }
    this.dataHandlerService.getDataWithSpinner(`${PAGE_CONTENT_BY_LANGPATH_KEY}?${ID_PATH_PARAM_KEY}${this.pageContentId}&${LANG_ID_PATH_PARAM_KEY}${languageId}`).then(response => {
      const pageContentData: IPageContentData = response;
      this.imagePath = pageContentData.imagePath;

      //this.selectedCategoryName = pageContentData.pageCategoryName;
      this.translationService.translatePages(pageContentData.pageName).subscribe(translatedPageName => {
        if (translatedPageName) {
          this.selectedPageName = translatedPageName;
        }
      });
      //this.selectedPageName = pageContentData.pageName;
      this.selectedLanguageName = pageContentData.languageName;

      this.form.patchValue({
        name: pageContentData.name,
        shortContent: pageContentData.shortContent,
        fullContent: pageContentData.fullContent,
        showInSlider: pageContentData.showInSlider,
        //categoryId: pageContentData.categoryId,
        languageId: languageId,
        pageId: pageContentData.pageId,
        image: this.imagePath,
        buttonLink: pageContentData.buttonLink,
        buttonLinkUnauth: pageContentData.buttonLinkUnauth
      });
    });
  }

  submitData(): void {

    if (this.form.valid) {
      const form = { ...this.form.value };
      if(this.pageContentId) {
        form.id = this.pageContentId;
      }
      let formData = new FormData();
      for (let i in form) {
        if (!form[i]) {
          delete form[i];
        } else {
          formData.append(`${i}`, form[i]);
        }
      }

      if (this.pageContentId && this.content_update) {
        this.dataHandlerService.updateFormData(formData, PAGE_CONTENT_PATH_KEY, this.submitSpinnerId).then(() => {
         this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
         this.mainService.goBack(PAGE_CONTENT_URL_KEY);
        });
      } else if (this.content_create) {
        this.dataHandlerService.createFormData(formData, PAGE_CONTENT_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(PAGE_CONTENT_URL_KEY);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }
 

  setSelectedPage(id: number, name: string): void {
    this.selectedPageName = name;
    this.form.patchValue({ pageId: id });
  }

  setSelectedLanguage(id: number, name: string): void {
    this.selectedLanguageName = name;
    this.form.patchValue({ languageId: id });
  }

  async handleFileInput(files: FileList) {
    if (files && files[0]) {
      this.selectedImage = files[0];
      this.imagePath = await this.mainService.imageUpload(files[0]);
      this.form.patchValue({ image: this.selectedImage });
    }
  }
}
