<div class="main-card mb-3 card">
  <div class="card-body">
    <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('templates-info')"></i>
      <h5 class="card-title">{{ isCreateForm ? ('TR_CREATE_TEMPLATE_INFO' | translate) : ('TR_UPDATE_TEMPLATE_INFO' | translate) }}</h5>
    </div>
    <form (submit)="submitData()" [formGroup]="form">
      <div class="wrap-flex form-outline">
        <div *ngIf="template_view" ngbDropdown>
          <div class="flex flex-column">
            <label>{{ ('TR_TEMPLATEE' | translate) + mainService.asterisk }}</label>
            <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary" [class.error-border]="isInvalidSubmit && !form.value.documentTemplateId">
              {{ selectedTemplateName ? selectedTemplateName : ('TR_TEMPLATEE' | translate) + mainService.asterisk }}
            </button>
          </div>
          <div ngbDropdownMenu>
            <button type="button" class="dropdown-item" *ngFor="let item of templates" (click)="setSelectedTemplate(item.id, item.name)">
              {{ item.name }}
            </button>
          </div>
        </div>
        <div ngbDropdown>
          <div class="flex flex-column">
            <label>{{ ('TR_INFO_TYPES' | translate) + mainService.asterisk }}</label>
            <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary" [class.error-border]="isInvalidSubmit && !form.value.type">
              {{ form.value.type ? form.value.type : ('TR_INFO_TYPES' | translate) + mainService.asterisk }}
            </button>
          </div>
          <div ngbDropdownMenu>
            <button type="button" class="dropdown-item" *ngFor="let item of infoTypes;index as i" (click)="setSelectedInfoType(item, i)">
              {{ item }}
            </button>
          </div>
        </div>
        <div *ngIf="library_view && libraries.length" ngbDropdown>
          <div class="flex flex-column">
            <label>{{ 'TR_LIBRARY' | translate }}</label>
            <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary">
              {{ selectedLibraryName ? selectedLibraryName : 'TR_LIBRARY' | translate }}
            </button>
          </div>
          <div ngbDropdownMenu>
            <button type="button" class="dropdown-item" *ngFor="let item of libraries" (click)="setSelectedLibrary(item.id, item.name)">
              {{ item.name }}
            </button>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-4 col-md-6">
          <div class="position-relative form-group">
            <label for="position">{{ ('TR_POSITION' | translate) + mainService.asterisk }}</label>
            <input
              name="position"
              [placeholder]="('TR_POSITION' | translate) + mainService.asterisk"
              type="number"
              class="form-control"
              [class.error-border]="isInvalidSubmit && !form.value.position"
              autocomplete="off"
              formControlName="position"
            />
          </div>
        </div>
      </div>
      <div *ngIf="openPlaceHolderField" class="form-row">
        <div class="col-lg-4 col-md-6">
          <div class="position-relative form-group">
            <label for="placeholder">{{ 'TR_INPUT_PLACEHOLDER' | translate }}</label>
            <input
              name="placeholder"
              [placeholder]="'TR_INPUT_PLACEHOLDER' | translate"
              type="text"
              class="form-control"
              autocomplete="off"
              formControlName="placeholder"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 mt-1 mb-2 checkboxes-block">
          <div class="position-relative form-check">
            <input name="hidden" id="hidden" type="checkbox" class="form-check-input" formControlName="hidden">
            <label for="hidden" class="form-check-label">{{ 'TR_HIDDEN_INFO' | translate }}</label>
          </div>
        </div>
      </div>
      <app-submit-button
        [spinnerId]="submitSpinnerId"
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit"
        [isFormValid]="form.valid"
      ></app-submit-button>
    </form>
  </div>
</div>