import { Component, OnInit } from '@angular/core';
import { guid } from '@progress/kendo-angular-common';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { AlertService } from 'src/app/Services/alert.service';
import { ILanguageData } from 'src/app/Models/ILanguageData';
import { IStreamingSettingsData } from 'src/app/Models/IOrganizationData';
import { MainService } from 'src/app/Services/main.service';
import { LANGUAGE_PATH_KEY, ORGANIZATIONS_PATH_KEY, ORGANIZATION_SETTINGS_PATH_KEY, ORGANIZATION_URL_KEY } from 'src/app/Constants/request-path-keys';
import {
  ORGANIZATION_CREATE,
  ORGANIZATION_VIEW,
  OUTPUT_AUDIO_BITRATE_ARR_KEY,
  SUCCESS_KEY,
  TR_STREAMING_SETTINGS,
  TR_STREAMING_SETTINGS_MESSAGE_KEY,
  VIDEO_BASE_RESOLUTION_ARR_KEY,
  VIDEO_COMMON_FPS_VALUE_ARR_KEY,
  VIDEO_OUTPUT_RESOLUTION_ARR_KEY
} from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-stream-settings',
  templateUrl: './stream-settings.component.html',
  styleUrls: ['./stream-settings.component.scss']
})
export class StreamSettingsComponent implements OnInit {
  form: FormGroup;
  organizationId: number;
  selectedLanguageName: string;
  openUserForm: boolean;
  organization_view: boolean;
  organization_create: boolean;
  languages: Array<ILanguageData> = [];
  userRoles: Array<string> = [];
  isInvalidSubmit: boolean;
  submitSpinnerId: string = guid();
  outputAudioBitrates: Array<number> = OUTPUT_AUDIO_BITRATE_ARR_KEY;
  videoBaseResolutions: Array<string> = VIDEO_BASE_RESOLUTION_ARR_KEY;
  videoOutputResolutions: Array<string> = VIDEO_OUTPUT_RESOLUTION_ARR_KEY;
  videoCommonFpsValues: Array<string> = VIDEO_COMMON_FPS_VALUE_ARR_KEY;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private translationService: TranslationService,
    public mainService: MainService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_STREAMING_SETTINGS);

    this.form = this.formBuilder.group({
      organizationId: [null],
      // generalLanguageId: [null],
      // streamService: [''],
      // videoOutputResolution: [''],
      // outputAudioBitrate: [null],
      // videoBaseResolution: [''],
      // videoCommonFpsvalue: [''],
      // generalStreamingStartConfirmation: [true],
      // generalStreamingStopConfirmation: [true],
      streamServer: [''],
      // streamUseAuthentication: [false],
      // streamUsername: [''],
      // streamPassword: [''],
      // outputVideoBitrate: [null],
      // advancedReconnectEnable: [true],
      // advancedRetryDelay: [10],
      // advancedMaximumRetries: [10],
      // dataServer: ['']
    });

    this.getLanguages();

    this.route.paramMap.subscribe(params => {
      this.organizationId = +params.get('item.id');
      this.getOrganizationSettings();
    });
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case ORGANIZATION_VIEW:
          this.organization_view = true;
          break;
        case ORGANIZATION_CREATE:
          this.organization_create = true;
          break;
      }
    });
  }

  async getLanguages() {
    var allLanguages = await this.dataHandlerService.getDataWithSpinner(LANGUAGE_PATH_KEY);
    this.languages = allLanguages.splice(0, 2);
  }

  getOrganizationSettings() {
    if (this.organization_view) {
      this.dataHandlerService.getDataWithSpinner(`${ORGANIZATION_SETTINGS_PATH_KEY}/${this.organizationId}`).then(response => {
        const settingsData: IStreamingSettingsData = response;
        this.form.patchValue({
          organizationId: this.organizationId,
          // generalStreamingStartConfirmation: settingsData.generalStreamingStartConfirmation,
          // generalStreamingStopConfirmation: settingsData.generalStreamingStopConfirmation,
          streamServer: settingsData.streamServer,
          // streamUseAuthentication: settingsData.streamUseAuthentication,
          // streamUsername: settingsData.streamUsername,
          // streamPassword: settingsData.streamPassword,
          // outputVideoBitrate: settingsData.outputVideoBitrate,
          // advancedReconnectEnable: settingsData.advancedReconnectEnable,
          // advancedRetryDelay: settingsData.advancedRetryDelay,
          // advancedMaximumRetries: settingsData.advancedMaximumRetries,
          // dataServer: settingsData.dataServer,
          // streamService: settingsData.streamService,
          // generalLanguageId: settingsData.generalLanguageId,
          // videoOutputResolution: settingsData.videoOutputResolution,
          // outputAudioBitrate: settingsData.outputAudioBitrate,
          // videoBaseResolution: settingsData.videoBaseResolution,
          // videoCommonFpsvalue: settingsData.videoCommonFpsvalue,
        });
        this.selectedLanguageName = settingsData.generalLanguageName;
      }).catch(e => {
        this.form.patchValue({ organizationId: this.organizationId });
      })
    }
  }

  setSelectedLanguage(id: number, name: string): void {
    this.selectedLanguageName = name;
    this.form.patchValue({ generalLanguageId: id });
  }

  setSelectedAudioBitrate(value: string): void {
    this.form.patchValue({ outputAudioBitrate: +value });
  }

  setSelectedVideoBaseResolution(value: string): void {
    this.form.patchValue({ videoBaseResolution: value });
  }

  setSelectedVideoOutputResolution(value: string): void {
    this.form.patchValue({ videoOutputResolution: value });
  }

  setSelectedVideoCommonFpsValue(value: number): void {
    this.form.patchValue({ videoCommonFpsvalue: value });
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      // if (!form.streamUseAuthentication) {
      //   form.streamUsername = '';
      //   form.streamPassword = '';
      // }
      if (this.organization_create) {
        this.dataHandlerService.createData(form, `${ORGANIZATION_SETTINGS_PATH_KEY}`, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_STREAMING_SETTINGS_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(ORGANIZATION_URL_KEY);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }

  generatePassword() {
    const randomPassword = Math.random().toString(36).slice(-8);
    this.form.patchValue({ password: randomPassword });
  }
}
