import { Component, OnInit, EventEmitter, Output, Input, OnChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MainService } from 'src/app/Services/main.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { IChoiceNumberData, IQuestionData } from 'src/app/Models/IQuestionModel';
import {
  CHOISE_NUMBERING_TYPES_ARR_KEY,
  GRADES_ARR_KEY,
  MULTI_ANSWER_TYPES,
  TR_MULTIPLE_ANSWERS_ALLOWED_KEY,
  TR_ONE_ANSWER_ONLY_KEY
} from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-multi-choise-answer',
  templateUrl: './multi-choise-answer.component.html',
  styleUrls: ['./multi-choise-answer.component.scss']
})
export class MultiChoiseAnswerComponent implements OnInit, OnChanges {
  form: FormGroup;
  isGradeError: boolean;
  selectedAnswerType: string;
  selectedChoiceNumberingType: string;
  grades: Array<number> = GRADES_ARR_KEY;
  answerTypes: Array<string> = MULTI_ANSWER_TYPES;
  choiseNumberingTypes: Array<IChoiceNumberData> = CHOISE_NUMBERING_TYPES_ARR_KEY;

  @Input() questionData: IQuestionData;
  @Output() submitEmmiter = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    public mainService: MainService,
    private translationService: TranslationService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      isMultiAnswer: [false],
      isShuffle: [true],
      choiceNumbering: [0],
      answers: this.formBuilder.array([])
    });

    this.addAnswer();
    this.selectedChoiceNumberingType = this.choiseNumberingTypes[0].name;
    this.translationService.translateDefaults(TR_ONE_ANSWER_ONLY_KEY).subscribe(translatedName => translatedName && this.setSelectedAnswerType(0, translatedName));

    this.mainService.questionSubmitted.subscribe(data => {
      if (data && !this.isGradeError) {
        const form = { ...this.form.value };
        form.answers = this.checkAnswers(form.answers);

        this.submitEmmiter.emit(form);
      }
    });

    this.setUpdateQuestionData();
  }

  ngOnChanges() {
    this.setUpdateQuestionData();
  }

  setUpdateQuestionData(): void {
    if(this.questionData && this.form) {
      this.selectedChoiceNumberingType = this.choiseNumberingTypes[this.questionData.choiceNumbering].name;
      this.setSelectedAnswerType(+this.questionData.isMultiAnswer, this.questionData.isMultiAnswer ? TR_MULTIPLE_ANSWERS_ALLOWED_KEY : TR_ONE_ANSWER_ONLY_KEY);
      this.form.patchValue({
        isMultiAnswer: this.questionData.isMultiAnswer,
        isShuffle: this.questionData.isShuffle,
        choiceNumbering: this.questionData.choiceNumbering
      });

      this.questionData.answers && this.questionData.answers.length && this.setUpdateAnswers();
    }
  }

  setUpdateAnswers(): void {
    const answers = <FormArray>this.form.get('answers')['controls'];

    this.questionData.answers.forEach((answer, index) => {
      if(answers[index]) {
        answers[index].patchValue({ gradepercent: answer.gradePercent, text: answer.text, feedback: answer.feedback, id: answer.id });
      } else {
        answers.push(
          this.formBuilder.group({
            text: [answer.text],
            gradepercent: [answer.gradePercent],
            feedback: [answer.feedback],
            id: [answer.id]
          })
        );
      }
    });

    this.form.patchValue({ answers: answers });
  }

  checkAnswers(answers: Array<any>): Array<any> {
    const newAnswers = answers.filter(data => {
      for(let i in data) {
        data[i] === null && delete data[i];
      }
      if(data.text) { return data; }
    });
    return newAnswers;
  }

  addAnswer(): void {
    let answers = <FormArray>this.form.controls.answers;
    for (let i = 0; i < 3; i++) {
      answers.push(
        this.formBuilder.group({
          text: [''],
          gradepercent: [''],
          feedback: [''],
          id: [null],
        })
      );
    }
  }

  setSelectedGrade(value: number, answerIndex: number): void {
    if (answerIndex === -1) { return; }
    const answers = <FormArray>this.form.get('answers')['controls'];

    answers[answerIndex].patchValue({ gradepercent: value });
    this.form.patchValue({ answers: answers });
    this.checkGrades();
  }

  checkGrades(): void {
    if(this.form.value.isMultiAnswer) {
      const grades = this.form.value.answers.map(data => data.gradepercent >= 0 ? +data.gradepercent : 0);
      const gradesSum = grades.reduce((a: number, b: number) => a + b, 0);
      this.isGradeError = gradesSum > 100;
    } else {
      this.isGradeError = false;
    }
  }

  setSelectedAnswerType(answerIndex: number, name: string): void {
    this.selectedAnswerType = name;
    this.form.patchValue({ isMultiAnswer: Boolean(answerIndex) });
    this.checkGrades();
  }

  setSelectedChoiseNumberingType(index: number, name: string): void {
    this.selectedChoiceNumberingType = name;
    this.form.patchValue({ choiceNumbering: index });
  }

}
