<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon"><i class="icon pe-7s-cash icon-gradient bg-plum-plate"></i></div>
      <div>{{ 'TR_PAYMENT_PACKAGE_PROPERTIES' | translate }}</div>
    </div>
    <div *ngIf="package_property_create" class="page-title-actions d-flex"><button
        class="mr-2 btn btn-success create-btn" [title]="'TR_CREATE_PAYMENT_PACKAGE_PROPERTY' | translate"
        [routerLink]="['package-property-form']">
        <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
      </button>
    </div>
  </div>
</div>
<!-- <div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_PAYMENT_PACKAGE_PROPERTIES' | translate }}</h4>
</div>

<div *ngIf="package_property_create" class="d-inline-block">
  <button class="mb-2 mr-2 btn btn-success create-btn" [title]="'TR_CREATE_PAYMENT_PACKAGE_PROPERTY' | translate"
    [routerLink]="['package-property-form']">
    <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
  </button>
</div> -->

<div class="row">
  <div class="col-12">
    <div *ngIf="package_property_view" class="main-card mb-3 card">
      <div class="table-responsive">
        <table class="align-middle mb-0 table table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ 'TR_DISPALY_NAME' | translate }}</th>
              <th>{{ 'TR_PROPERTY_NAME' | translate }}</th>
              <th>{{ 'TR_IS_PRICE_PER_UNIT' | translate }}</th>
              <th>{{ 'TR_MIN_VALUE' | translate }}</th>
              <th>{{ 'TR_MAX_VALUE' | translate }}</th>
              <th>{{ 'TR_UNIT_NAME' | translate }}</th>
              <th>{{ 'TR_ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of packageProperties let i = index" class="pointer"
              (click)="openPackagePropertyModal($event.target, packagePropertyDiscountModal, item)">
              <td>{{ i + 1 }}</td>
              <td>{{ item.displayName }}</td>
              <td>{{ item.propertyName }}</td>
              <td>
                <input type="checkbox" [checked]='item.isPricePerUnit ? true : false' class="k-cursor-no-drop"
                  (change)="onCkeckboxChange($event)" />
              </td>
              <td>{{ item.minValue ? item.minValue : "" }}</td>
              <td>{{ item.maxValue ? item.maxValue : "" }}</td>
              <td class="">{{ item.unitName ? item.unitName : "" }}</td>
              <td class="text-center">
                <div role="group" class="btn-group-sm btn-group">
                  <button *ngIf="package_property_delete" class="btn-transition btn btn-outline-info px-3"
                    [title]="'TR_PRICE' | translate"
                    (click)="openPackagePropertyPriceModal($event, packagePropertyPriceModal, item)">
                    <i class="fa fa-usd"></i>
                  </button>
                  <button *ngIf="package_property_update" class="ml-1 btn-transition btn btn-outline-info"
                    [title]="'TR_UPDATE_PAYMENT_PACKAGE_PROPERTY' | translate"
                    [routerLink]="['package-property-form', item.id]">
                    <i class="pe-7s-note"></i>
                  </button>
                  <button *ngIf="package_property_delete" class="ml-1 btn-transition btn btn-outline-danger"
                    [title]="'TR_DELETE' | translate" (click)="confirmDelete(deleteconfirm, item.id, item.name)">
                    <i class="pe-7s-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #packagePropertyDiscountModal let-c="close" let-d="dismiss">
  <div class="modal-header modal-list-header">
    <h5 class="modal-title">{{ packagePropertyName }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="closePackagePropertyModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="px-3">
    <div class="main-card my-3 card">
      <div class="table-responsive">
        <div class="p-1">
          <form [formGroup]="form">
            <div class="d-flex flex-column">
              <div class="col-12 col-md-6 mt-3">
                <label for="minValue">{{ ('TR_MIN_VALUE' | translate) + mainService.asterisk }}</label>
                <input name="minValue" type="number" min="1" max="10000" class="form-control"
                  [class.error-border]="isInvalidSubmit && (form.value.minValue <= 0 || form.value.minValue >= 10000)"
                  autocomplete="off" [placeholder]="('TR_MIN_VALUE' | translate) + mainService.asterisk"
                  formControlName="minValue" />
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label for="maxValue">{{ ('TR_MAX_VALUE' | translate) + mainService.asterisk }}</label>
                <input name="maxValue" type="number" min="1" max="10000" class="form-control"
                  [class.error-border]="isInvalidSubmit && (form.value.maxValue <= 0 || form.value.maxValue <= form.value.minValue || form.value.maxValue >= 10000)"
                  autocomplete="off" [placeholder]="('TR_MAX_VALUE' | translate) + mainService.asterisk"
                  formControlName="maxValue" />
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label for="discountPercent">{{ ('TR_DISCOUNT_PERCENTAGE' | translate) }}</label>
                <input name="discountPercent" type="number" min="0" max="100" class="form-control"
                  [class.error-border]="isInvalidSubmit && form.value.discountPercent && form.value.discountPercent > 100"
                  autocomplete="off" [placeholder]="('TR_DISCOUNT_PERCENTAGE' | translate)"
                  formControlName="discountPercent" />
              </div>
            </div>

            <div class="col-12 mt-3 mb-3 d-flex align-items-end">
              <app-submit-button *ngIf="package_property_update" [spinnerId]="submitSpinnerId"
                [buttonName]="!packagePropertyDiscountId ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
                [isInvalidSubmit]="isInvalidSubmit" (click)="submitData()"></app-submit-button>
              <button *ngIf="packagePropertyDiscountId" type="button"
                class="mb-1 ml-2 btn-transition btn btn-outline-danger table-action-btn"
                [title]="'TR_CANCEL' | translate" (click)="cancel()">
                {{ 'TR_CANCEL' | translate }}
              </button>
            </div>
          </form>
          <hr>
        </div>
        <table *ngIf="packagePropertyDiscounts && packagePropertyDiscounts.length && package_property_view"
          class="align-middle mb-0 table table-striped table-hover library-component-body">
          <thead>
            <tr>
              <th>{{ ('TR_MIN_VALUE' | translate) }}</th>
              <th>{{ ('TR_MAX_VALUE' | translate) }}</th>
              <th>{{ 'TR_DISCOUNT_PERCENTAGE' | translate }}</th>
              <th>{{ 'TR_ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of packagePropertyDiscounts">
              <td>{{ item.minValue }}</td>
              <td>{{ item.maxValue }}</td>
              <td>{{ item.discountPercent }}</td>

              <td class="level-table-btns text-right">
                <div role="group" class="btn-group-sm btn-group">
                  <button *ngIf="package_property_update" type="button" class="btn-transition btn btn-outline-info"
                    [title]="'TR_UPDATE' | translate" (click)="setPackagePropertyDiscount(item)">
                    <i class="pe-7s-note"></i>
                  </button>
                  <button *ngIf="package_property_delete" type="submit"
                    class="ml-1 btn-transition btn btn-outline-danger" [title]="'TR_DELETE' | translate"
                    (click)="confirmPackagePropertyDiscountDelete(deletePackagePropertyDiscountConfirm, item.id)">
                    <i class="pe-7s-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #packagePropertyPriceModal let-c="close" let-d="dismiss">
  <div class="modal-header modal-list-header">
    <h5 class="modal-title">{{ packagePropertyName }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="closePropertyPriceModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="px-3">
    <div class="main-card my-3 card">
      <div class="table-responsive">
        <div class="p-1">
          <form [formGroup]="priceForm">
            <div class="d-flex flex-column">
              <div class="col-12 col-md-6 mt-3">
                <div class="mb-1 w-100 mr-1">
                  <label for="currencyName" class="m-0">{{ 'TR_CURRENCY' | translate}} *</label>
                  <div ngbDropdown>
                    <button type="button" ngbDropdownToggle class="mb-2 mr-1 btn btn-outline-primary w-100"
                      [class.error-border]="isInvalidPriceSubmit  && !selectedCurrencyName">
                      {{ selectedCurrencyName ? selectedCurrencyName : 'TR_CURRENCY' | translate
                      }}
                    </button>
                    <div ngbDropdownMenu>
                      <button class="dropdown-item" *ngFor="let item of currencies"
                        (click)="setCurrency(item.id, item.isocode)" type="button">
                        {{ item.isocode }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="position-relative form-group w-100 mr-1">
                  <label for="price">{{ 'TR_PRICE' | translate }} *</label>
                  <input name="price" [placeholder]="'TR_PRICE' | translate" type="number" class="form-control"
                    autocomplete="off" min="1" [max]="maxPrice"
                    [class.error-border]="isInvalidPriceSubmit && (!priceForm.value.price || priceForm.value.price > maxPrice )"
                    formControlName="price" />
                </div>
              </div>
            </div>

            <div class="col-12 mb-3 d-flex align-items-end">
              <app-submit-button *ngIf="package_property_update" [spinnerId]="submitSpinnerId"
                [buttonName]="!selectedCurrencyId ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
                [isInvalidSubmit]="isInvalidPriceSubmit" (click)="submitPriceData()"></app-submit-button>
              <button *ngIf="selectedCurrencyId" type="button"
                class="mb-1 ml-2 btn-transition btn btn-outline-danger table-action-btn"
                [title]="'TR_CANCEL' | translate" (click)="cancel()">
                {{ 'TR_CANCEL' | translate }}
              </button>
            </div>
          </form>
          <hr>
        </div>
        <table *ngIf="packagePropertyPrices && packagePropertyPrices.length && package_property_view"
          class="align-middle mb-0 table table-striped table-hover library-component-body">
          <thead>
            <tr>
              <th>{{ ('TR_CURRENCY' | translate) }}</th>
              <th>{{ ('TR_PRICE' | translate) }}</th>
              <th>{{ ('TR_ACTIONS' | translate) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of packagePropertyPrices">
              <td>{{ item.selectedCurrencyName }}</td>
              <td>{{ item.price }}</td>

              <td class="level-table-btns">
                <div role="group" class="btn-group-sm btn-group">
                  <button *ngIf="package_property_update" type="button" class="btn-transition btn btn-outline-info"
                    [title]="'TR_UPDATE' | translate" (click)="setPackagePropertyPrice(item)">
                    <i class="pe-7s-note"></i>
                  </button>
                  <button *ngIf="package_property_delete" type="submit"
                    class="ml-1 btn-transition btn btn-outline-danger" [title]="'TR_DELETE' | translate"
                    (click)="confirmPackagePropertyPriceDelete(deletePackagePropertyPriceConfirm, item.id)">
                    <i class="pe-7s-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #deleteconfirm let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p>{{ 'TR_DELETE_ERROR' | translate }} <b class="word-break-break-word">{{ deletePackagePropertyName }}</b>?</p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button *ngIf="package_property_delete" [isCurrent]="true" [spinnerId]="deleteSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="delete()"></app-action-button>
    <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #deletePackagePropertyDiscountConfirm let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p>{{ 'TR_DELETE_ERROR' | translate }} ?</p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button *ngIf="package_property_delete" [isCurrent]="true" [spinnerId]="deleteSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="deletePackagePropertyDiscount()"></app-action-button>
    <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #deletePackagePropertyPriceConfirm let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p>{{ 'TR_DELETE_ERROR' | translate }} ?</p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button *ngIf="package_property_delete" [isCurrent]="true" [spinnerId]="deleteSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="deletePackagePropertyPrice()"></app-action-button>
    <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate
      }}</button>
  </div>
</ng-template>