<div class="main-card mb-3 card">
  <div class="card-body">
    <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('document')"></i>
      <h5 class="card-title">{{ 'TR_DOCUMENT_SUBSCRIPTION' | translate }}</h5>
    </div>
    <form (submit)="submitData()">
      <div class="mt-2 mb-3 upload-block">
        <button type="button" class="mb-2 mr-2 btn-transition btn btn-outline-info upload-btn" [class.error-border]="isInvalidSubmit && !selectedFile" (click)="file.click()">
          <i class="pe-7s-plus"></i>
          <p>{{ 'TR_UPLOAD_FILE' | translate }}</p>
        </button>
        <input #file hidden type="file" name="upload-file" (change)="handleFileInput($event.target.files)" />
        <div class="flex flex-column ml-2">
          <div *ngIf="fileName">{{ fileName }}</div>
          <div *ngIf="imgURL" class="uploaded-image" [ngStyle]="{ 'background-image' : 'url(' + imgURL + ')' }" ></div>
          <button *ngIf="selectedFile" type="button" class="btn-transition btn btn-outline-dark remove-btn" (click)="removeFile()">
            {{ 'TR_REMOVE' | translate }}
          </button>
        </div>
      </div>
      <app-submit-button
        [spinnerId]="submitSpinnerId"
        [buttonName]="'TR_SIGN' | translate"
        [isInvalidSubmit]="isInvalidSubmit"
        [isFormValid]="true"
      >
      <app-help-alert alertKey="document_sign"></app-help-alert>
    </app-submit-button>
    </form>
  </div>
</div>