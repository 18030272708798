import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MainService } from 'src/app/Services/main.service';
import { startWith, tap, delay } from 'rxjs/operators';

@Component({
  selector: 'app-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.scss']
})
export class SaveButtonComponent implements OnInit {
  @Output() submit = new EventEmitter();
  @Input() buttonClasses: string;
  @Input() isCurrent: boolean;
  @Input() spinnerId: string;
  @Input() isDisabled: boolean;

  isSubmitted: boolean;

  constructor(
    private mainService: MainService
  ) { }

  ngOnInit() {
    this.mainService.buttonSpinners.pipe(
      startWith(null),
      delay(0),
      tap(spinners => {
        this.isSubmitted = spinners && spinners.find(spinner => spinner === this.spinnerId) && this.isCurrent;
      })
    ).subscribe();
  }

}
