import { Component, Input, OnInit } from '@angular/core';
import { delay, startWith, tap } from 'rxjs/operators';
import { MainService } from 'src/app/Services/main.service';

@Component({
  selector: 'app-modal-loader',
  templateUrl: './modal-loader.component.html',
  styleUrls: ['./modal-loader.component.scss']
})
export class ModalLoaderComponent implements OnInit {
  isModalLoading: boolean;
  @Input() modalSpinners: Array<string>;

  constructor(
    private mainService: MainService,
  ) { }

  ngOnInit() {
    this.mainService.modalSpinners.pipe(
      startWith(null),
      delay(0),
      tap(spinners => {
        this.isModalLoading = spinners?.length && spinners.find(spinnerId => this.modalSpinners?.find(modalSpinner => modalSpinner === spinnerId) === spinnerId) ? true : false;
      })
    ).subscribe();

  }


}
