import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { guid } from '@progress/kendo-angular-common';
import { ActivatedRoute } from '@angular/router';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { AlertService } from 'src/app/Services/alert.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { PAYMENT_PACKAGES_PATH_KEY, PAYMENT_PACKAGE_URL_KEY } from 'src/app/Constants/request-path-keys';
import {
  PAYMENT_PACKAGE_CREATE,
  PAYMENT_PACKAGE_UPDATE,
  PAYMENT_PACKAGE_VIEW,
  SUCCESS_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_CREATE_PAYMENT_PACKAGE_KEY,
  TR_UPDATE_MESSAGE_KEY,
  TR_UPDATE_PAYMENT_PACKAGE_KEY
} from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-payment-package-form',
  templateUrl: './payment-package-form.component.html',
  styleUrls: ['./payment-package-form.component.scss']
})
export class PaymentPackageFormComponent implements OnInit {
  packageId: number;
  form: FormGroup;
  userRoles: Array<string> = [];
  paymentpackage_create: boolean;
  paymentpackage_update: boolean;
  paymentpackage_view: boolean;
  isInvalidSubmit: boolean;
  isCreateForm: boolean = true;
  submitSpinnerId: string = guid();
  selectedTypeName: string = "";
  type: number = 3;

  selectedCurrencyName: string = "";
  currencies: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerservice: DataHandlerService,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    public mainService: MainService,
    private translationService: TranslationService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.getCurrencies();
    this.translationService.setPageTitle(TR_CREATE_PAYMENT_PACKAGE_KEY);

    this.form = this.formBuilder.group({
      packageName: ['', Validators.required],
      description: [''],
      currencyId: [null, Validators.required],
      cost: ['', Validators.required],
      storageSize: ['', Validators.required],
      webinarStudentsMaxCount: ['', Validators.required],
      userMaxCount: ['', Validators.required],
      allowSaveVideo: [false],
      documentService: [false],
      userTypeService: [false],
      courseService: [false],
      webinarService: [false],
      fileLibraryService: [false],
      innerPaymentService: [false],
    });

    this.route.paramMap.subscribe(params => {
      this.packageId = +params.get('item.id');

      if (this.packageId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle(TR_UPDATE_PAYMENT_PACKAGE_KEY);
        this.getPaymentPackageById();
      }
    });
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch (role) {
        case PAYMENT_PACKAGE_CREATE:
          this.paymentpackage_create = true;
          break;
        case PAYMENT_PACKAGE_UPDATE:
          this.paymentpackage_update = true;
          break;
        case PAYMENT_PACKAGE_VIEW:
          this.paymentpackage_view = true;
          break;
      }
    });
  }

  getCurrencies() {
    if (!this.paymentpackage_create) { return; }
    this.dataHandlerservice.getDataWithSpinner("currency").then(response => {
      const data = [...response];
      this.currencies = data;
    });
  }

  getPaymentPackageById(): void {
    if (!this.paymentpackage_view) { return; }
    this.dataHandlerservice.getDataWithSpinner(`${PAYMENT_PACKAGES_PATH_KEY}/${this.packageId}`).then(response => {
      const packageData = response;
      this.type = packageData.type;
      if (packageData.type === 1) {
        this.selectedTypeName = "TR_FOR_SALE"
      }
      if (packageData.type === 3) {
        this.selectedTypeName = "TR_CUSTOM"
      }
      if (this.currencies && this.currencies.length) {
        this.currencies.forEach(currency => {
          if (currency.id === packageData.currencyId) {
            this.selectedCurrencyName = currency.isocode;
          }
        });
      }

      this.form.patchValue({
        packageName: packageData.packageName,
        description: packageData.description,
        cost: packageData.cost,
        storageSize: packageData.storageSize,
        webinarStudentsMaxCount: packageData.webinarStudentsMaxCount,
        userMaxCount: packageData.userMaxCount,
        allowSaveVideo: packageData.allowSaveVideo,
        documentService: packageData.documentService,
        userTypeService: packageData.userTypeService,
        courseService: packageData.courseService,
        webinarService: packageData.webinarService,
        fileLibraryService: packageData.fileLibraryService,
        innerPaymentService: packageData.innerPaymentService,
        currencyId: packageData.currencyId,
      });
    });
  }

  changePaymentPackageType(event: any, type: any, name: string): void {
    event && event.preventDefault();
    this.selectedTypeName = name;
    this.type = type;
  }

  setCurrency(id: number, name: string) {
    this.selectedCurrencyName = name;
    this.form.patchValue({
      currencyId: id,
    });
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      form.cost = +form.cost;
      form.storageSize = +form.storageSize;
      form.webinarStudentsMaxCount = +form.webinarStudentsMaxCount;
      form.userMaxCount = +form.userMaxCount;
      form.type = +this.type;

      if (this.packageId && this.paymentpackage_update) {
        form.id = this.packageId;
        this.dataHandlerservice.updateData(form, PAYMENT_PACKAGES_PATH_KEY, this.submitSpinnerId).then(() => {
          this.mainService.goBack(PAYMENT_PACKAGE_URL_KEY);
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
        });
      } else if (this.paymentpackage_create) {
        this.dataHandlerservice.createData(form, PAYMENT_PACKAGES_PATH_KEY, this.submitSpinnerId).then(() => {
          this.mainService.goBack(PAYMENT_PACKAGE_URL_KEY);
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }
}
