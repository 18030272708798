import { Component, OnInit } from '@angular/core';
import { guid } from '@progress/kendo-angular-common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { AlertService } from 'src/app/Services/alert.service';
import { IEducationLevelData } from 'src/app/Models/IEducationLevelData';
import { ORGANIZATION_EDUCATION_LEVEL_PATH_KEY } from 'src/app/Constants/request-path-keys';
import {
  EDUCATION_LEVEL_CREATE,
  EDUCATION_LEVEL_DELETE,
  EDUCATION_LEVEL_UPDATE,
  EDUCATION_LEVEL_VIEW,
  ORGANIZATION_EDUCATION_LEVEL_PROCESS_TRANSLATION_KEYS,
  SUCCESS_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_DELETE_MESSAGE_KEY,
  TR_ORGANIZATION_SETTINGS_KEY,
  TR_UPDATE_MESSAGE_KEY,
  userTypes
} from 'src/app/Constants/main-keys';
import { IUserData } from 'src/app/Models/IUserData';
import { DataStorageService } from 'src/app/Services/data-storage.service';

@Component({
  selector: 'app-organization-settings-tables',
  templateUrl: './organization-settings-tables.component.html',
  styleUrls: ['./organization-settings-tables.component.scss']
})
export class OrganizationSettingsTablesComponent implements OnInit {
  currentUserData: IUserData;
  userRoles: Array<string> = [];
  education_levels_view: boolean;
  education_levels_create: boolean;
  education_levels_update: boolean;
  education_levels_delete: boolean;
  submitEducationLevelIndex: number;
  updateValue: any;
  deleteLevelValueId: number;
  containsPracticeId: number;
  deleteLevelValueName: string;
  containsPracticeName: string;
  deleteLevelIndex: number;
  selectedSubjectId: number;
  blockId: number;
  selectedSubjectName: string;
  submitSpinnerId: string = guid();
  deleteSpinnerId: string = guid();
  educationLevels: Array<IEducationLevelData> = [];
  educationLevelProcessTranslations: Array<string> = ORGANIZATION_EDUCATION_LEVEL_PROCESS_TRANSLATION_KEYS;
  userTypes = userTypes;

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private dataStorageService: DataStorageService,
    private translationService: TranslationService,
    private modalService: NgbModal,
    private alertService: AlertService
  ) {

  }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.currentUserData = this.dataStorageService.getUser();
    this.setRoles();
    this.translationService.setPageTitle(TR_ORGANIZATION_SETTINGS_KEY);

    this.educationLevelProcessTranslations.forEach((item, index) => {
      this.educationLevels.push({ name: item, inputValue: '', isActive: !index, content: [] });
    });
    this.getEducationLevels();
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case EDUCATION_LEVEL_VIEW:
          this.education_levels_view = true;
          break;
        case EDUCATION_LEVEL_CREATE:
          this.education_levels_create = true;
          break;
        case EDUCATION_LEVEL_UPDATE:
          this.education_levels_update = true;
          break;
        case EDUCATION_LEVEL_DELETE:
          this.education_levels_delete = true;
          break;
      }
    });
  }

  getEducationLevels() {
    if (!this.education_levels_view) { return; }
    this.dataHandlerService.getDataWithSpinner(ORGANIZATION_EDUCATION_LEVEL_PATH_KEY).then(response => {

      this.educationLevels[0].content = response;
    });
  }

  openNextEducationLevel(currentEducationLevel: IEducationLevelData, selectedEducationLevelValue: any, selectedEducationLevelIndex: number, content: any): void {
    currentEducationLevel.content.forEach(item => item.selected = false);

    const currentOrganizationEducationLevelIndex = this.educationLevels.indexOf(currentEducationLevel);
    let nextOrganizationEducationLevelIndex = (currentOrganizationEducationLevelIndex + 1) % this.educationLevels.length;
    if (nextOrganizationEducationLevelIndex) {
      selectedEducationLevelValue.selected = true;
      this.getEducationLevelContentById(selectedEducationLevelValue.id, nextOrganizationEducationLevelIndex, null);
      this.modalService.dismissAll();
      this.selectedSubjectId = null;
      this.selectedSubjectName = null;
    } else {
      this.selectedSubjectId = selectedEducationLevelValue.id;
      this.selectedSubjectName = selectedEducationLevelValue.name;
      this.modalService.open(content).result;
    }

    this.educationLevels.forEach((item, ind) => {
      if (ind > selectedEducationLevelIndex && ind !== nextOrganizationEducationLevelIndex) {
        item.isActive = false;
      }
    });


    this.updateValue = null;
    this.submitEducationLevelIndex = null;
    this.blockId = null;
    currentEducationLevel.inputValue = "";
  }

  getEducationLevelContentById(educationLevelId: number, index: number, currentFieldId: number): void {
    if (!this.education_levels_view) { return; }
    this.dataHandlerService.getData(`${ORGANIZATION_EDUCATION_LEVEL_PATH_KEY}/${educationLevelId}`).then(response => {

      const data = [...response];
      if (data.length && currentFieldId) {
        data.forEach(dataItem => {
          if (+dataItem.id === +currentFieldId) {
            dataItem.selected = true;
          }
        })
      }

      this.educationLevels[index].content = data;
      this.educationLevels[index].isActive = true;

      // this.educationLevels[index].content = response;
      // this.educationLevels[index].isActive = true;
    });
  }



  submitData(educationLevelIndex: number): void {
    this.submitEducationLevelIndex = educationLevelIndex;
    const previousEducationLevelIndex = (educationLevelIndex - 1) % this.educationLevels.length;
    let parentEducationLevelValueId: number;

    if (previousEducationLevelIndex >= 0) {
      this.educationLevels[previousEducationLevelIndex].content.forEach(item => {
        if (item.selected) {
          parentEducationLevelValueId = item.id;
        }
      });
    }
    // if (!parentEducationLevelValueId) { return; }
    if (this.updateValue && this.education_levels_update) {
      const form = { ...this.updateValue };
      form.name = this.educationLevels[educationLevelIndex].inputValue;
      this.dataHandlerService.updateData(form, ORGANIZATION_EDUCATION_LEVEL_PATH_KEY, this.submitSpinnerId).then(() => {
        this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
        this.getEducationLevelContentById(parentEducationLevelValueId, educationLevelIndex, form.id);
        this.cancel(this.educationLevels[educationLevelIndex]);
      });
    } else if (this.education_levels_create) {
      const form = {
        name: this.educationLevels[educationLevelIndex].inputValue.trim(),
        type: educationLevelIndex,
        parentId: parentEducationLevelValueId
      };
      if (form.name && form.name.trim().length) {
        this.dataHandlerService.createData(form, ORGANIZATION_EDUCATION_LEVEL_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.getEducationLevelContentById(parentEducationLevelValueId, educationLevelIndex, null);
          this.cancel(this.educationLevels[educationLevelIndex]);
        });
      }
    }
  }

  confirmDelete(content, selectedEducationLevelValue: any, selectedEducationLevelIndex: number): void {
    if (!selectedEducationLevelValue || !selectedEducationLevelIndex) { return; }
    this.deleteLevelValueId = selectedEducationLevelValue.id;
    this.deleteLevelValueName = selectedEducationLevelValue.name;
    this.deleteLevelIndex = selectedEducationLevelIndex;
    this.modalService.open(content).result;
  }

  onCkeckboxChange(event: any, educationLevelValue: any, containsPracticeconfirm: any) {
    event.stopPropagation();
    this.containsPracticeId = educationLevelValue.id;
    this.containsPracticeName = educationLevelValue.name;
    event.target.checked = !event.target.checked;
    this.modalService.open(containsPracticeconfirm).result;
  }

  onChangeContainsPractice(): void {
    if (!this.containsPracticeId) { return; }
    this.dataHandlerService.updateDataWithoutData(`organizationEducationLevel/containspractice/${this.containsPracticeId}`).then(() => {
      const educationLevelsCopy = [...this.educationLevels];
      educationLevelsCopy.forEach(educationLevelsCopyItem => {
        if (educationLevelsCopyItem.content && educationLevelsCopyItem.content.length) {
          educationLevelsCopyItem.content.forEach(contentItem => {
            if (contentItem.id === this.containsPracticeId) {
              contentItem.containsPractice = !contentItem.containsPractice;
            }
          })
        }
      })
      this.educationLevels = educationLevelsCopy;
      this.modalService.dismissAll();
      this.alertService.translateAndAlertMessage("TR_DATA_SAVED", SUCCESS_KEY);
      this.containsPracticeName = "";
      this.containsPracticeId = null;
      let element = document.getElementById('programCheck');
      let elementStatus = element.getAttribute('checked');
      element.setAttribute("checked", elementStatus);
    });
  }

  delete(): void {
    if (!this.education_levels_delete) { return; }
    const previousEducationLevelIndex = (this.deleteLevelIndex - 1) % this.educationLevels.length;
    let parentEducationLevelValueId: number;

    if (previousEducationLevelIndex >= 0) {
      this.educationLevels[previousEducationLevelIndex].content.forEach(item => {
        if (item.selected) {
          parentEducationLevelValueId = item.id;
        }
      });
    }

    this.dataHandlerService.deleteData(this.deleteLevelValueId, ORGANIZATION_EDUCATION_LEVEL_PATH_KEY, this.deleteSpinnerId).then(() => {
      this.alertService.translateAndAlertMessage(TR_DELETE_MESSAGE_KEY, SUCCESS_KEY);
      this.getEducationLevelContentById(parentEducationLevelValueId, this.deleteLevelIndex, null);
      this.modalService.dismissAll();

    }).catch(e => this.modalService.dismissAll());
  }

  edit(selectedEducationLevelValue: any, selectedEducationLevelIndex: number): void {
    const previouseducationLevelIndex = (selectedEducationLevelIndex - 1) % this.educationLevels.length;

    let parentEducationLevelValueId: number;

    if (previouseducationLevelIndex >= 0) {
      this.educationLevels[previouseducationLevelIndex].content.forEach(item => {
        if (item.selected) {
          parentEducationLevelValueId = item.id;
          this.blockId = item.id;
        }
      });
    }
    if (parentEducationLevelValueId) {
      this.dataHandlerService.getData(`${ORGANIZATION_EDUCATION_LEVEL_PATH_KEY}/${parentEducationLevelValueId}`).then(response => {
        const educationLevelContent = response;
        educationLevelContent.forEach(item => {
          if (item.id === selectedEducationLevelValue.id) {
            this.updateValue = item;
          }
        });
        this.educationLevels[selectedEducationLevelIndex].inputValue = this.updateValue.name;
      });
    }
  }

  cancel(educationLevel: IEducationLevelData): void {
    educationLevel.inputValue = '';
    this.updateValue = null;
    this.submitEducationLevelIndex = null;
    this.blockId = null;
    // educationLevel.isActive = false;
  }
}
