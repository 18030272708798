<div [class]="'app-header header-shadow ' + (config$ | async).headerTheme">
  <a class="logo-src" routerLink="/home">mindalay</a>
  <!-- <div class="mindalay-brand-text">mindalay</div> -->
  <div class="app-header__content">
    <div class="app-header-left">
    </div>
    <div class="app-header-right">
      <button *ngIf="user.isAdmin && user.isOrganizationUser" type="button" class="btn-transition btn btn-primary mr-2">
        <a class="text-white text-decoration-none" [href]="refreshTokenLink">
          {{ 'TR_MAIN' | translate }}</a>
      </button>
      <a href="https://mindalay.com/en/help" target="_blank">{{ 'TR_HELP' | translate }}</a>
      <app-user-box></app-user-box>
      <div class="d-inline-block language-dropdown" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn btn-primary dropdown-toggle">
          <span>{{ currentLanguageIsoCode }}</span>
        </button>
        <div ngbDropdownMenu class="languages-block">
          <button class="dropdown-item" *ngFor="let item of languages" (click)="setLanguage(item.isocode2)">{{ item.name
            }}</button>
        </div>
      </div>
      <div class="question-block pointer" (click)="getUserHelpData()">
        <i aria-hidden="true" class="fa fa-fw" title="question-circle-o"></i>
      </div>
    </div>
  </div>
  <div class="app-header__mobile-menu">
    <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
      [ngClass]="{'is-active' : globals.toggleSidebarMobile}" (click)="toggleSidebarMobile()">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
  </div>
  <div class="app-header__menu">
    <span>
      <button class="btn-icon btn-icon-only btn btn-primary" [ngClass]="{'is-active' : globals.toggleHeaderMobile}"
        (click)="toggleHeaderMobile()">
        <div class="btn-icon-wrapper">
          <!-- <fa name="ellipsis-v" _nghost-ewx-c2="" ng-reflect-name="ellipsis-v"> -->
          <i _ngcontent-ewx-c2="" aria-hidden="true" class="fa fa-ellipsis-v" ng-reflect-klass="fa fa-ellipsis-v"
            ng-reflect-ng-class=""></i>
          <!-- </fa> -->
        </div>
      </button>
    </span>
  </div>
</div>