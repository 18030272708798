import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/Services/alert.service';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { LanguageService } from 'src/app/Services/language.service';
import { IQuestionGroupData, IQuestionData, IQuestionTypeData } from 'src/app/Models/IQuestionModel';
import { QUESTION_TYPES_ARR_KEY } from 'src/app/Constants/main-keys';
import { ISO_CODE_PATH_PARAM_KEY, QUESTIONS_GROUP_ID_PATH_PARAM_KEY, QUESTIONS_GROUP_PATH_KEY, QUESTION_PATH_KEY } from 'src/app/Constants/request-path-keys';

@Component({
  selector: 'app-questions-table',
  templateUrl: './questions-table.component.html',
  styleUrls: ['./questions-table.component.scss']
})
export class QuestionsTableComponent implements OnInit {
  isPageLoaded: boolean;
  isQuestionGroupContentLoaded: boolean;
  deleteQuestionid: number;
  deleteQuestionName: string;
  selectedQuestionsGroupId: number;
  userRoles: Array<string> = [];
  questionsGroups: Array<IQuestionGroupData> = [];
  selectedQuestionsGroupContent: Array<IQuestionData> = [];
  questionTypes: Array<IQuestionTypeData> = QUESTION_TYPES_ARR_KEY;

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private alertService: AlertService,
    private translationService: TranslationService,
    private mainService: MainService,
    private languageService: LanguageService,
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;

    this.getQuestionGroups();
  }

  setQuestionGroupContentLoader(isLoading: boolean): void {
    this.isQuestionGroupContentLoaded = !isLoading;
  }

  async getQuestionGroups() {
    this.questionsGroups = await this.dataHandlerService.getData(QUESTIONS_GROUP_PATH_KEY);
  }

  getQuestionsByQuestionsGroupId(id: number): void {
    if(!id || this.selectedQuestionsGroupId === id) { return; }
    this.setQuestionGroupContentLoader(true);
    this.selectedQuestionsGroupId = id;
    this.dataHandlerService.getData(`${QUESTION_PATH_KEY}?${ISO_CODE_PATH_PARAM_KEY}${this.languageService.currentLanguage}&${QUESTIONS_GROUP_ID_PATH_PARAM_KEY}${this.selectedQuestionsGroupId}`).then(response => {
      this.selectedQuestionsGroupContent = response;
      this.setQuestionGroupContentLoader(false);
    });
  }

}
