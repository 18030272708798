<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon"><i class="icon pe-7s-notebook icon-gradient bg-plum-plate"></i></div>
      <div>{{ 'TR_LIBRARY' | translate }}
        <!-- todo -->
      </div>
    </div>
    <div class="page-title-actions d-flex"><button *ngIf="library_create" class="mr-2 btn btn-success create-btn nowrap"
        [title]="'TR_CREATE' | translate" (click)="openForm(formmodal)">
        <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
      </button>
    </div>
  </div>
</div>

<!-- <div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_LIBRARY' | translate }}</h4>
</div> -->

<!-- <div class="row">
  <div class="col-12">
    <button *ngIf="library_create" class="mb-2 mr-2 btn btn-success create-btn" [title]="'TR_CREATE' | translate"
      (click)="openForm(formmodal)">
      <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
    </button>
  </div>
</div> -->

<div *ngIf="library_view" class="main-card mb-3 card">
  <ngb-tabset [activeId]="selectedTabId" (tabChange)="beforeChange($event)">
    <ngb-tab [id]="language.isocode2" [title]="language.name"
      *ngFor="let language of languages; index as languageIndex">
      <ng-template ngbTabContent>
        <div class="table-responsive">
          <table class="align-middle mb-0 table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>{{ 'TR_NAME' | translate }}</th>
                <th>{{ 'TR_DISPLAY_STRING' | translate }}</th>
                <th>{{ 'TR_ELEMENT_TYPE' | translate }}</th>
                <th>{{ 'TR_PARENT' | translate }}</th>
                <th class="text-right">{{ 'TR_ACTIONS' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="pointer" [class.selected]="this.libraryDataModel.libraryId === item.id"
                (click)="openLibraryData($event.target, librarydataview, item)"
                *ngFor="let item of language.content let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.displayString }}</td>
                <td>{{ libraryTypes[item.type] }}</td>
                <td>{{ item.parentName }}</td>
                <td class="level-table-btns text-right">
                  <div role="group" class="btn-group-sm btn-group">
                    <button *ngIf="library_update" class="btn-transition btn btn-outline-info"
                      [title]="'TR_UPDATE' | translate" (click)="updateLibrary(item, formmodal)">
                      <i class="pe-7s-note"></i>
                    </button>
                    <button *ngIf="library_delete" class="ml-1 btn-transition btn btn-outline-danger"
                      [title]="'TR_DELETE' | translate" (click)="confirmDelete(deleteconfirm, item, 'library')">
                      <i class="pe-7s-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>

<ng-template #deleteconfirm let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p>{{ 'TR_DELETE_ERROR' | translate }} <b class="word-break-break-word">{{ deleteLibraryData.name }}</b>?</p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button [isCurrent]="true" [spinnerId]="deleteSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="delete()"></app-action-button>
    <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #librarydataview let-c="close" let-d="dismiss">
  <div class="modal-header modal-list-header">
    <h5 class="modal-title">{{ libraryName }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeLibraryData()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="librarydata_view" class="main-card mb-3 card">
    <div class="table-responsive">
      <div class="container">
        <div class="row p-1">
          <div class="col-12 col-md-6">
            <label for="name">{{ ('TR_NAME' | translate) + mainService.asterisk }}</label>
            <input id="name" name="name" type="text" class="form-control"
              [class.error-border]="isInvalidLibraryDataSubmit && !libraryDataModel.name" autocomplete="off"
              [placeholder]="('TR_NAME' | translate) + mainService.asterisk" [(ngModel)]="libraryDataModel.name" />
          </div>
          <!-- <div class="col-12 ">
            <label for="name">{{ ('TR_DISPLAY_STRING' | translate) + mainService.asterisk }}</label>
            <input name="displayName" type="text" class="form-control"
              [class.error-border]="isInvalidLibraryDataSubmit && !libraryDataModel.displayName" autocomplete="off"
              [placeholder]="('TR_DISPLAY_STRING' | translate) + mainService.asterisk"
              [(ngModel)]="libraryDataModel.displayName" />
          </div> -->
          <div class="col-12 mt-2">
            <label for="displayName">{{ ('TR_DISPLAY_STRING' | translate) + mainService.asterisk }}</label>
            <textarea name="displayName" class="form-control" rows="3"
              [[class.error-border]="isInvalidLibraryDataSubmit && !libraryDataModel.displayName" autocomplete="off"
              [placeholder]="('TR_DISPLAY_STRING' | translate) + mainService.asterisk"
              [(ngModel)]="libraryDataModel.displayName">
            </textarea>
          </div>
          <div class="col-12 col-md-6 mt-2">
            <div class="position-relative form-check">
              <input name="isShowRichTextEditor" id="isShowRichTextEditor" type="checkbox" class="form-check-input"
                (change)="onCheckboxChange($event)">
              <label for="isShowRichTextEditor" class="form-check-label">{{ 'TR_RICH_TEXT_EDITOR' | translate }}</label>
            </div>
          </div>
          <div class="col-12 mt-2">
            <label for="name">{{ 'TR_DESCRIPTION' | translate }}</label>
            <textarea *ngIf="!isShowRichTextEditor" name="description" type="text" class="form-control" rows="3"
              [class.error-border]="isInvalidLibraryDataSubmit && !libraryDataModel.description" autocomplete="off"
              [placeholder]="('TR_DESCRIPTION' | translate)" [(ngModel)]="libraryDataModel.description"></textarea>
            <div *ngIf="isShowRichTextEditor">
              <ckeditor class="editor" [(ngModel)]="libraryDataModel.description"
                [class.error-border]="isInvalidLibraryDataSubmit && !libraryDataModel.description"></ckeditor>
            </div>
          </div>
          <div class="col-12 col-md-6 mt-3">
            <div class="d-inline-block" ngbDropdown>
              <button type="button" ngbDropdownToggle class="btn btn-primary td-drop-btn"
                [class.error-border]="parentLibraryData.length && isInvalidLibraryDataSubmit && !libraryDataModel.parentId">
                {{ libraryDataModel.parentName ? libraryDataModel.parentName : ('TR_PARENT' | translate) }}
              </button>
              <div ngbDropdownMenu>
                <button type="button" class="dropdown-item td-drop-btn" *ngFor="let item of parentLibraryData"
                  (click)="setSelectedParentLibraryData(item.id, item.name)">
                  {{ item.name }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 mt-3 mb-3 d-flex align-items-end">
            <app-submit-button [spinnerId]="submitSpinnerId" [buttonName]="('TR_SAVE' | translate)"
              [isInvalidSubmit]="isInvalidSubmit" (click)="submitLibData()"></app-submit-button>
            <button type="button" class="mb-1 ml-2 btn-transition btn btn-outline-danger table-action-btn"
              [title]="'TR_CANCEL' | translate" (click)="libraryDataCancel(educationLevel)">
              {{ 'TR_CANCEL' | translate }}
            </button>
          </div>

        </div>

        <table class="align-middle mb-0 table table-striped table-hover library-component-body">
          <thead>
            <tr>
              <th>{{ ('TR_NAME' | translate) + mainService.asterisk }}</th>
              <th>{{ ('TR_DISPLAY_STRING' | translate) + mainService.asterisk }}</th>
              <th>{{ 'TR_DESCRIPTION' | translate }}</th>
              <!-- <th>{{ 'TR_PARENT' | translate }}</th> -->
              <th class="text-center">{{ 'TR_ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr *ngIf="librarydata_update && librarydata_create">
                <td>
                  <input
                    name="name"
                    type="text"
                    class="form-control"
                    [class.error-border]="isInvalidLibraryDataSubmit && !libraryDataModel.name"
                    autocomplete="off"
                    [placeholder]="('TR_NAME' | translate) + mainService.asterisk"
                    [(ngModel)]="libraryDataModel.name"
                  />
                </td>
                <td>
                  <input
                    name="displayName"
                    type="text"
                    class="form-control"
                    [class.error-border]="isInvalidLibraryDataSubmit && !libraryDataModel.displayName"
                    autocomplete="off"
                    [placeholder]="('TR_DISPLAY_STRING' | translate) + mainService.asterisk"
                    [(ngModel)]="libraryDataModel.displayName"
                  />
                </td>
                <td>
                  <textarea
                  *ngIf="!isShowRichTextEditor"
                    name="description"
                    type="text"
                    class="form-control"
                    [class.error-border]="isInvalidLibraryDataSubmit && !libraryDataModel.description"
                    autocomplete="off"
                    [placeholder]="('TR_DESCRIPTION' | translate)"
                    [(ngModel)]="libraryDataModel.description"
                  ></textarea>
                  <div *ngIf="isShowRichTextEditor">
                    <ckeditor class="editor" [(ngModel)]="libraryDataModel.description" [class.error-border]="isInvalidLibraryDataSubmit && !libraryDataModel.description"></ckeditor>
                  </div>
                </td>
    
                <td>
                  <div class="d-inline-block" ngbDropdown>
                    <button type="button" ngbDropdownToggle class="btn btn-primary td-drop-btn" [class.error-border]="parentLibraryData.length && isInvalidLibraryDataSubmit && !libraryDataModel.parentId">
                      {{ libraryDataModel.parentName ? libraryDataModel.parentName : ('TR_PARENT' | translate) }}
                    </button>
                    <div ngbDropdownMenu>
                      <button type="button" class="dropdown-item td-drop-btn" *ngFor="let item of parentLibraryData" (click)="setSelectedParentLibraryData(item.id, item.name)">
                        {{ item.name }}
                      </button>
                    </div>
                  </div>
                </td>
                <td class="level-table-btns text-right">
                  <app-save-button
                    *ngIf="library_create || library_update"
                    [isCurrent]="true"
                    [spinnerId]="submitSpinnerId"
                    [buttonClasses]="'group-btn-padding mb-1 mr-1 btn-transition btn btn-outline-primary'"
                    (submit)="submitLibData()"
                  ></app-save-button>
                  <button type="button" class="mb-1 btn-transition btn btn-outline-danger table-action-btn" [title]="'TR_CANCEL' | translate" (click)="libraryDataCancel(educationLevel)">
                    <i class="pe-7s-close-circle"></i>
                  </button>
                </td>
              </tr> -->
            <tr *ngFor="let item of libraryData">
              <td>
                <p class="max-line-3">{{ item.name }}</p>
              </td>
              <td>
                <p class="max-line-3">{{ item.displayName }}</p>
              </td>
              <td>
                <p class="max-line-3">{{ item.description }}</p>
              </td>
              <!-- <td>
                <p class="max-line-3">{{ item.parentName }}</p>
              </td> -->
              <td class="level-table-btns text-right">
                <div role="group" class="btn-group-sm btn-group">
                  <button *ngIf="librarydata_update" type="button" class="btn-transition btn btn-outline-info"
                    [title]="'TR_UPDATE' | translate" (click)="updateLibraryData(item)">
                    <i class="pe-7s-note"></i>
                  </button>
                  <button *ngIf="librarydata_delete" type="submit" class="ml-1 btn-transition btn btn-outline-danger"
                    [title]="'TR_DELETE' | translate" (click)="confirmDelete(deleteconfirm, item, 'libraryData')">
                    <i class="pe-7s-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #formmodal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">{{ isCreateForm ? ('TR_CREATE' | translate) : ('TR_EDIT' | translate) }}</h5>
    <button type="button" class="close pb-0" aria-label="Close" (click)="c(); closeForm();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="library_update && library_create" class="modal-body p-4">
    <app-library-form [library]="updateLibraryItem" [languageIsocode]="selectedTabId" [languages]="languages"
      (submitSuccess)="getLanguages(); c(); closeForm();"></app-library-form>
  </div>
</ng-template>