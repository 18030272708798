import { Component, OnInit } from '@angular/core';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { ITemplateData, ITemplateInfoData } from 'src/app/Models/ITemplateData';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { DOCUMENT_TEMPLATE_INFO_PATH_KEY, DOCUMENT_TEMPLATE_PATH_KEY, TEMPLATE_ID_PATH_PARAM_KEY } from 'src/app/Constants/request-path-keys';
import { DOCUMENTTEMPLATE_I_CREATE, DOCUMENTTEMPLATE_I_UPDATE, DOCUMENTTEMPLATE_I_VIEW, DOCUMENTTEMPLATE_VIEW, TR_TEMPLATE_INFO_KEY } from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-template-info-table',
  templateUrl: './template-info-table.component.html',
  styleUrls: ['./template-info-table.component.scss']
})
export class TemplateInfoTableComponent implements OnInit {
  templateinfo_create: boolean;
  templateinfo_update: boolean;
  templateinfo_view: boolean;
  template_view: boolean;
  isPageLoaded: boolean;
  selectedTemplateName: string;
  userRoles: Array<string> = [];
  templates: Array<ITemplateData> = [];
  templatesInfo: Array<ITemplateInfoData> = [];

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private translationService: TranslationService,
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_TEMPLATE_INFO_KEY);

    this.getTemplates();
    this.getTemplatesInfo();
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch(role) {
        case DOCUMENTTEMPLATE_I_CREATE:
          this.templateinfo_create = true;
          break;
        case DOCUMENTTEMPLATE_I_UPDATE:
          this.templateinfo_update = true;
          break;
        case DOCUMENTTEMPLATE_I_VIEW:
          this.templateinfo_view = true;
          break;
        case DOCUMENTTEMPLATE_VIEW:
          this.template_view = true;
          break;
      }
    });
  }

  getTemplatesInfo() {
    this.dataHandlerService.getDataWithSpinner(DOCUMENT_TEMPLATE_INFO_PATH_KEY).then(response => {
      this.templatesInfo = response;
    });
  }

  async getTemplates() {
    this.templates = await this.dataHandlerService.getDataWithSpinner(DOCUMENT_TEMPLATE_PATH_KEY);
  }

  setSelectedTemplate(id: number, name: string): void {
    this.selectedTemplateName = name;
    this.getTemplatesInfoByTemplateId(id);
  }

  async getTemplatesInfoByTemplateId(id: number) {
    this.templatesInfo = await this.dataHandlerService.getData(`${DOCUMENT_TEMPLATE_INFO_PATH_KEY}?${TEMPLATE_ID_PATH_PARAM_KEY}${id}`);
  }

}
