import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { guid } from '@progress/kendo-angular-common';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { AlertService } from 'src/app/Services/alert.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { IPaymentPackageData } from 'src/app/Models/IPaymentPackageData';
import { IOrganizationData } from 'src/app/Models/IOrganizationData';
import {
  ERROR_KEY,
  ORGANIZATION_CREATE,
  ORGANIZATION_UPDATE,
  ORGANIZATION_VIEW,
  PAYMENT_PACKAGE_VIEW,
  SUCCESS_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_CREATE_ORGANIZATION_KEY,
  TR_UPDATE_MESSAGE_KEY,
  TR_UPDATE_ORGANIZATION_KEY,
  USER_VIEW,
} from 'src/app/Constants/main-keys';
import {
  ORGANIZATIONS_PATH_KEY,
  PAYMENT_PACKAGES_PATH_KEY,
} from 'src/app/Constants/request-path-keys';

@Component({
  selector: 'app-payment-package-form',
  templateUrl: './payment-package-form.component.html',
  styleUrls: ['./payment-package-form.component.scss']
})

export class OrganizationPaymentPackageFormComponent implements OnInit {

  recurringPayment: boolean = false;
  selectedPaymentPackageId: number;
  monthCount: number;
  selectedPaymentPackageName: string;
  organizationId: number;
  organizations: Array<IOrganizationData>;
  paymentPackages: Array<IPaymentPackageData> = [];
  userRoles: Array<string>;
  organization_view: boolean;
  organization_create: boolean;
  organization_update: boolean;
  paymentpackage_view: boolean;
  user_view: boolean;
  isInvalidSubmit: boolean;
  isCreateForm: boolean = true;
  submitSpinnerId: string = guid();
  monthCounts: [];
  isInvalidCheque: boolean = false;
  uploadedFile: any;
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    private translationService: TranslationService,
    public mainService: MainService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_CREATE_ORGANIZATION_KEY);

    this.getOrganizations();
    this.getPaymentPackages();

    this.form = this.formBuilder.group({
      paymentPackageId: [null],
      monthCount: [null, Validators.required],
      cheque: [null],
      recurringPayment: [false],
    });

    this.route.paramMap.subscribe(params => {
      this.organizationId = +params.get('item.id');
      if (this.organizationId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle(TR_UPDATE_ORGANIZATION_KEY);
        this.getOrganizationById();
      }
    });
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case ORGANIZATION_VIEW:
          this.organization_view = true;
          break;
        case ORGANIZATION_CREATE:
          this.organization_create = true;
          break;
        case ORGANIZATION_UPDATE:
          this.organization_update = true;
          break;
        case USER_VIEW:
          this.user_view = true;
          break;
        case PAYMENT_PACKAGE_VIEW:
          this.paymentpackage_view = true;
          break;
      }
    });
  }

  getOrganizationById() {
    if (!this.organization_view) { return; }
    this.dataHandlerService.getDataWithSpinner(`${ORGANIZATIONS_PATH_KEY}/${this.organizationId}`).then(response => {
      const organizationData: IOrganizationData = response;
      this.selectedPaymentPackageName = organizationData.paymentPackageName;
      this.monthCount = organizationData.monthCount;
      if (organizationData.paymentPackageId) {
        this.setSelectedPaymentPackage(organizationData.paymentPackageId, organizationData.paymentPackageName)
      }
      this.uploadedFile = organizationData.chequePath;
      this.recurringPayment = organizationData.recurringPayment;
      this.form.patchValue({
        paymentPackageId: organizationData.paymentPackageId,
        monthCount: organizationData.monthCount,
        recurringPayment: organizationData.recurringPayment,
      });
    });
  }

  async getOrganizations() {
    if (!this.organization_view) { return; }
    this.organizations = await this.dataHandlerService.getDataWithSpinner(ORGANIZATIONS_PATH_KEY);
  }

  async getPaymentPackages() {
    if (!this.paymentpackage_view) { return; }
    this.paymentPackages = await this.dataHandlerService.getDataWithSpinner(PAYMENT_PACKAGES_PATH_KEY);
  }

  async handleFileUpload(event: any) {
    const files = event.target.files;
    if (files && files[0]) {
      this.mainService.imageUpload(files[0]).then((response: any) => {
        if (response) {
          this.uploadedFile = response;
          this.isInvalidCheque = false;
          this.form.patchValue({ cheque: files[0] });
        }
      });
    }
  }

  setSelectedPaymentPackage(id: number, name: string): void {
    this.selectedPaymentPackageId = id;
    this.selectedPaymentPackageName = name;
    this.form.patchValue({ paymentPackageId: id });
    // request
    this.dataHandlerService.getDataWithSpinner(`paymentPackage/withprices/${id}/USD`
    ).then(response => {
      this.monthCounts = response.prices;
    });
  }

  setSelectedMonthCount(monthCount: any): void {
    if (!monthCount) { return; }
    this.monthCount = monthCount;
    this.form.patchValue({ monthCount: +monthCount });
  }

  onChechboxChange(event: any): void {
    this.recurringPayment = event.target.checked;
    this.form.patchValue({ recurringPayment: event.target.checked });
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      if (!form.paymentPackageId) {
        this.isInvalidSubmit = true;
        return;
      }
      if (!form.cheque && !this.uploadedFile) {
        this.isInvalidCheque = true;
        return;
      }
      const formData = new FormData();
      for (let i in form) {
        formData.append(`${i}`, form[i]);
      }
      if (this.organizationId && this.organization_update) {
        formData.append(`organizationId`, `${this.organizationId}`);
        this.dataHandlerService.createFormData(formData, "OrganizationPaymentPackage/selectpackagebyroot", this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(ORGANIZATIONS_PATH_KEY);
        });
      }
    } else {
      //this.form.controls.streamUrl.disable();
      this.isInvalidSubmit = true;
    }
  }
}
