import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { guid } from '@progress/kendo-angular-common';
import { IStudentData } from 'src/app/Models/IStudentData';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { RolesService } from 'src/app/Services/roles.service';
import { MainService } from 'src/app/Services/main.service';
import { AlertService } from 'src/app/Services/alert.service';
import { STUDENTS_PATH_KEY, STUDENT_URL_KEY } from 'src/app/Constants/request-path-keys';
import { STUDENT_UPDATE, STUDENT_VIEW, SUCCESS_KEY, TR_UPDATE_MESSAGE_KEY, TR_UPDATE_STUDENT_KEY } from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-student-update',
  templateUrl: './student-update.component.html',
  styleUrls: ['./student-update.component.scss']
})
export class StudentUpdateComponent implements OnInit {
  personalDocumentNumber: string;
  studentId: number;
  studentData: IStudentData;
  userRoles: Array<string>;
  student_update: boolean;
  isInvalidSubmit: boolean;
  updateSpinnerId: string = guid();

  constructor(
    private route: ActivatedRoute,
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    public mainService: MainService,
    private translationService: TranslationService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.translationService.setPageTitle(TR_UPDATE_STUDENT_KEY);

    this.student_update = this.userRoles.includes(STUDENT_UPDATE);
    if (!this.student_update) { return; }

    this.route.paramMap.subscribe(params => {
      this.studentId = +params.get('item.id');
      this.getStudnetData();
    });
  }

  getStudnetData() {
    if (!this.userRoles.includes(STUDENT_VIEW)) { return; }
    this.dataHandlerService.getDataWithSpinner(`${STUDENTS_PATH_KEY}/${this.studentId}`).then(response => {
      this.studentData = response;
      this.personalDocumentNumber = response.personalDocumentNumber;
    });
  }

  submitData(): void {
    if (this.personalDocumentNumber) {
      const body = {
        id: this.studentId,
        personalDocumentNumber: this.personalDocumentNumber,
        userId: this.studentData.userId,
        organizationId: this.studentData.organizationId,
        createDate: this.studentData.createDate,
        creatorUserId: this.studentData.creatorUserId
      };

      let formData: any = new FormData();
      for (let i in body) {
        if (!body[i]) {
          delete body[i];
        } else {
          formData.append(`${i}`, body[i]);
        }
      }

      if (this.student_update) {
        this.dataHandlerService.updateFormData(formData, STUDENTS_PATH_KEY, this.updateSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(STUDENT_URL_KEY);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }

}
