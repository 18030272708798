import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { DataStorageService } from 'src/app/Services/data-storage.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { AlertService } from 'src/app/Services/alert.service';
import { IGroupData } from 'src/app/Models/IGroupData';
import { IUserData } from 'src/app/Models/IUserData';
import { IStudentData } from 'src/app/Models/IStudentData';
import {
  GROUP_VIEW,
  STUDENT_ARCHIVE,
  STUDENT_CREATE,
  STUDENT_DELETE,
  STUDENT_UPDATE,
  STUDENT_VIEW,
  SUCCESS_KEY,
  TR_ARCHIVE_SUCCESS_KEY,
  TR_DELETE_MESSAGE_KEY,
  TR_STUDENTS_KEY,
  TR_UNARCHIVE_SUCCESS_KEY,
  userTypes
} from 'src/app/Constants/main-keys';
import {
  GROUP_PATH_KEY,
  GROUP_STUDENTS_BY_GROUP_ID_PATH_KEY,
  STUDENTS_PATH_KEY,
  STUDENT_ARCHIVE_PATH_KEY,
} from 'src/app/Constants/request-path-keys';
import { guid } from '@progress/kendo-angular-common';

@Component({
  selector: 'app-students-table',
  templateUrl: './students-table.component.html',
  styleUrls: ['./students-table.component.scss']
})
export class StudentsTableComponent implements OnInit {
  currentUserData: IUserData;
  students: Array<IStudentData> = [];
  groups: Array<IGroupData> = [];
  userRoles: Array<string> = [];
  student_view: boolean;
  group_view: boolean;
  student_create: boolean;
  student_update: boolean;
  student_delete: boolean;
  student_archive: boolean;
  isArchive: boolean;
  deleteStudent: IStudentData;
  archiveStudent: IStudentData;
  groupId: number;
  groupName: string;
  studentViewId: number;
  userArchiveSpinnerId: string = guid();
  userDeleteSpinnerId: string = guid();
  bulkArchivedSpinnerId: string = guid();
  deleteStudentsSpinnerId: string = guid();
  checkAll: boolean;
  studentIds: Array<number> = [];
  userTypes = userTypes;

  constructor(
    private dataHandlerService: DataHandlerService,
    private dataStorageService: DataStorageService,
    private translationService: TranslationService,
    private modalService: NgbModal,
    private rolesService: RolesService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_STUDENTS_KEY);

    this.currentUserData = this.dataStorageService.getUser();
    this.getAllStudents();

    this.getGroups();
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case STUDENT_VIEW:
          this.student_view = true;
          break;
        case GROUP_VIEW:
          this.group_view = true;
          break;
        case STUDENT_CREATE:
          this.student_create = true;
          break;
        case STUDENT_UPDATE:
          this.student_update = true;
          break;
        case STUDENT_DELETE:
          this.student_delete = true;
          break;
        case GROUP_VIEW:
          this.group_view = true;
          break;
        case STUDENT_ARCHIVE:
          this.student_archive = true;
          break;
      }
    });
  }

  async getGroups() {
    if (!this.group_view) { return; }
    this.groups = await this.dataHandlerService.getData(GROUP_PATH_KEY);
  }

  getAllStudents() {
    if (!this.student_view) { return; }
    this.dataHandlerService.getDataWithSpinner(STUDENTS_PATH_KEY).then(response => {
      if (response && response.length) {
        response.forEach(student => {
          student.checked = false;
        })
        this.students = response;
      } else {
        this.students = [];
      }
    });
  }

  getStudentsByGroup(id: number, name: string): void {
    if (!this.group_view && this.groupId) { return; }
    this.dataHandlerService.getData(`${GROUP_STUDENTS_BY_GROUP_ID_PATH_KEY}/${id}/${false}`).then(response => {
      this.students = response;
      this.groupId = id;
      this.groupName = name;
    });
  }

  confirmDelete(content, id): void {
    this.deleteStudent = this.students.find(data => data.id === id);
    this.modalService.open(content).result;
  }

  confirmArchivation(content, id): void {
    const student = this.students.find(user => user.id === id);
    this.isArchive = !student.isArchived;
    this.archiveStudent = student;
    this.modalService.open(content).result;
  }

  checkAllStudents(): void {
    const studentsIds = []
    this.students.forEach(student => {
      student.checked = this.checkAll;
      if (this.checkAll) {
        if (!student.isArchived) {
          studentsIds.push(student.id);
          this.studentIds = studentsIds;
        }
      } else {
        this.studentIds = [];
      }
    });
  }

  onChangeStudentIsChecked(event: any, id: number) {
    this.students.forEach(student => {
      if (event.target.checked && student.id === id) {
        student.checked = event.target.checked;
        var studentIds = [...this.studentIds, id];
        this.studentIds = studentIds;
      }
      if (!event.target.checked && student.id === id) {
        student.checked = event.target.checked;
        const index = this.studentIds.indexOf(id);
        if (index > -1) {
          this.studentIds.splice(index, 1);
        }
      }
    });
  }

  openbBulkArchivedConfirmModal(studentsBulkArchived: any): void {
    this.modalService.open(studentsBulkArchived).result;
  }

  bulkArchived(): void {
    if (this.studentIds && this.studentIds.length) {
      this.dataHandlerService.bulkUsersArchived({ studentIds: this.studentIds, archive: true, }, "student/bulkarchive", this.bulkArchivedSpinnerId).then(() => {
        this.getAllStudents();
        this.checkAll = false;
        this.studentIds = [];
        this.alertService.translateAndAlertMessage(TR_ARCHIVE_SUCCESS_KEY, SUCCESS_KEY);
        this.modalService.dismissAll();
      }).catch(error => {
        error && this.modalService.dismissAll();
      })
    }
  }

  deleteStudents(): void {
    if (this.studentIds && this.studentIds.length) {
      this.dataHandlerService.deleteStudents({ studentIds: this.studentIds }, "student/bulkdelete", this.deleteStudentsSpinnerId).then(() => {
        this.getAllStudents();
        this.checkAll = false;
        this.studentIds = [];
        this.alertService.translateAndAlertMessage("TR_STUDENTS_DELETED_SUCCESSFULLY", SUCCESS_KEY);
        this.modalService.dismissAll();
      }).catch(error => {
        error && this.modalService.dismissAll();
      })
    }
  }

  openBulkUploadModal(studentsBulkUpload: any): void {
    this.modalService.open(studentsBulkUpload).result;
  }

  openStudentsDeletedModal(bulkDeleteStudents: any): void {
    this.modalService.open(bulkDeleteStudents).result;
  }

  delete(): void {
    if (!this.student_delete) { return; }
    if (this.deleteStudent && this.deleteStudent.id) {
      this.dataHandlerService.deleteData(this.deleteStudent.id, STUDENTS_PATH_KEY, this.userDeleteSpinnerId).then(() => {
        this.getAllStudents();
        this.alertService.translateAndAlertMessage(TR_DELETE_MESSAGE_KEY, SUCCESS_KEY);
        this.modalService.dismissAll();
      });
    }
  }

  triggerArchive(): void {
    if (!this.archiveStudent.id) { return; }
    this.dataHandlerService.userActivation(this.archiveStudent.id, this.isArchive, STUDENT_ARCHIVE_PATH_KEY, this.userArchiveSpinnerId).then(() => {
      const successMessage = this.isArchive ? TR_ARCHIVE_SUCCESS_KEY : TR_UNARCHIVE_SUCCESS_KEY;
      this.alertService.translateAndAlertMessage(successMessage, SUCCESS_KEY);
      const selectedStudent = this.students.find(data => data.id === this.archiveStudent.id);
      if (selectedStudent) {
        selectedStudent.isArchived = this.isArchive;
      }
      this.modalService.dismissAll();
    });
  }

  studentView(target: HTMLElement, content, student: any): void {
    if (target.nodeName === 'TD' && !student.isArchived) {
      this.studentViewId = student.id;
      this.modalService.open(content).result;
    }
  }

}
