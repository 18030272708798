<div class="main-card mb-3 card">
  <div class="card-body">
    <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('question')"></i>
      <h5 class="card-title">{{ isCreateForm ? ('TR_CREATE_QUESTION_GROUP' | translate) : ('TR_UPDATE_QUESTION_GROUP' | translate) }}</h5>
    </div>
    <form (submit)="submitData()" [formGroup]="form">
      <div class="form-row">
        <div class="col-lg-6 col-md-6">
          <div class="position-relative form-group">
            <label for="name">{{ ('TR_NAME' | translate) + mainService.asterisk }}</label>
            <input
              name="name"
              [placeholder]="('TR_NAME' | translate) + mainService.asterisk"
              type="text"
              class="form-control"
              [class.error-border]="isInvalidSubmit && !form.value.name"
              autocomplete="off"
              formControlName="name"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 mt-1 mb-2 checkboxes-block">
          <div class="position-relative form-check">
            <input name="isPrivate" id="isPrivate" type="checkbox" class="form-check-input" formControlName="isPrivate">
            <label for="isPrivate" class="form-check-label">Is private</label>
          </div>
        </div>
      </div>
      <app-submit-button
        [spinnerId]="submitSpinnerId"
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit"
        [isFormValid]="form.valid"
      ></app-submit-button>
    </form>
  </div>
</div>
