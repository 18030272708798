<div class="form-row" [formGroup]="form">
  <div class="col-lg-6 col-md-6 mr-1">
    <div class="wrap-flex form-outline">
      <div ngbDropdown>
        <div class="flex flex-column mr-2">
          <label class="mb-0">{{ 'TR_SELECT_ANSWER_TYPE_SENSITIVITY' | translate }}</label>
          <button type="button" ngbDropdownToggle class="mb-2 btn btn-outline-info compact-width">
            {{ selectedAnswerType ? (selectedAnswerType | translate) || selectedAnswerType : 'TR_SELECT_ANSWER_TYPE_SENSITIVITY' | translate }}
          </button>
        </div>
        <div ngbDropdownMenu>
          <button type="button" class="dropdown-item" *ngFor="let item of answerTypes; index as i" (click)="setSelectedAnswerType(i, item)">
            {{ item | translate }}
          </button>
        </div>
      </div>
      <div ngbDropdown>
        <div class="flex flex-column">
          <label class="mb-0">{{ 'TR_CHOICE_NUMBERING' | translate }}</label>
          <button type="button" ngbDropdownToggle class="mb-2 btn btn-outline-info">
            {{ selectedChoiceNumberingType ? (selectedChoiceNumberingType | translate) || selectedChoiceNumberingType : 'TR_CHOICE_NUMBERING' | translate }}
          </button>
        </div>
        <div ngbDropdownMenu>
          <button type="button" class="dropdown-item" *ngFor="let item of choiseNumberingTypes" (click)="setSelectedChoiseNumberingType(item.value, item.name)">
            {{ (item.name | translate) || item.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 mb-3 mt-1">
    <div class="position-relative form-check">
      <input name="isShuffle" id="isShuffle" type="checkbox" class="form-check-input" formControlName="isShuffle">
      <label for="isShuffle" class="form-check-label">{{ 'TR_SHUFFLE' | translate }}</label>
    </div>
  </div>
  <div class="col-lg-6 col-md-8 mr-1" formArrayName="answers">
    <div [formGroupName]="i" class="answer-block mb-4" *ngFor="let answer of form.get('answers')['controls']; let i=index">
      <div class="form-row">
        <div class="col-12">
          <div class="position-relative form-group">
            <label for="text">{{ ('TR_TEXT' | translate) + mainService.asterisk }}</label>
            <input
              name="text"
              [placeholder]="('TR_TEXT' | translate) + mainService.asterisk"
              type="text"
              class="form-control"
              autocomplete="off"
              formControlName="text"
            />
          </div>
        </div>
      </div>
      <div class="flex-wrap form-outline form-group mt-0 ">
        <div class="flex flex-column">
          <div ngbDropdown>
            <div class="flex flex-column">
              <label>{{ ('TR_GRADE' | translate) + mainService.asterisk }}</label>
              <button
                ngbDropdownToggle
                type="button"
                class="mb-2 mr-2 btn btn-outline-info white-background"
                [class.error-border]="isGradeError && form.value.answers[i].gradepercent && form.value.answers[i].gradepercent >= 0"
              >
                {{
                  form.value.answers[i].gradepercent || form.value.answers[i].gradepercent === 0
                    ? form.value.answers[i].gradepercent === 0
                     ? ('TR_NONE' | translate) : (form.value.answers[i].gradepercent + '%')
                    : ('TR_GRADE' | translate) + mainService.asterisk
                }}
              </button>
            </div>
            <div ngbDropdownMenu>
              <button type="button" class="dropdown-item" *ngFor="let item of grades" (click)="setSelectedGrade(item, i)">
                {{ item === 0 ? ('TR_NONE' | translate) : (item + '%') }}
              </button>
            </div>
          </div>
          <small *ngIf="isGradeError && form.value.answers[i].gradepercent && form.value.answers[i].gradepercent >= 0" class="red">{{ 'TR_GRADES_SUM_EXC' | translate }}</small>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-12">
          <div class="position-relative form-group">
            <label for="feedback">{{ 'TR_FEEDBACK' | translate }}</label>
            <textarea
              name="feedback"
              [placeholder]="'TR_FEEDBACK' | translate"
              type="text"
              class="form-control"
              autocomplete="off"
              formControlName="feedback"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <button type="button" class="mb-2 btn-transition btn btn-outline-info create-btn" (click)="addAnswer()">
      <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_ADD_ANSWER' | translate }}</span>
    </button>
  </div>
</div>