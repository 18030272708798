<div class="main-card mb-3 card">
  <div class="card-body">
    <div class="title-block mb-1">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('quiz')"></i>
      <h4 class="card-title">{{ isCreateForm ? ('TR_CREATE_QUIZ' | translate) : ('TR_UPDATE_QUIZ' | translate) }}</h4>
    </div>
    <form (submit)="submitData()" [formGroup]="form">
      <div class="form-row">
        <div class="col-12">
          <hr />
          <h5 class="mb-3">{{ 'TR_GENERAL' | translate }}</h5>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-6 col-md-8">
          <div class="position-relative form-group">
            <label for="name">{{ ('TR_NAME' | translate) + mainService.asterisk }}</label>
            <input
              name="name"
              [placeholder]="('TR_NAME' | translate) + mainService.asterisk"
              type="text"
              class="form-control"
              [class.error-border]="isInvalidSubmit && !form.value.name"
              autocomplete="off"
              formControlName="name"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-6 col-md-8">
          <div class="position-relative form-group">
            <label for="text">{{ 'TR_DESCRIPTION' | translate }}</label>
            <textarea
              [rows]="4"
              name="text"
              [placeholder]="'TR_DESCRIPTION' | translate"
              type="text"
              class="form-control"
              autocomplete="off"
              formControlName="description"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-12 mb-2">
          <div class="custom-checkbox custom-control checkboxes-block">
            <input type="checkbox" id="displayDescription" class="custom-control-input" formControlName="displayDescription">
            <label for="displayDescription" class="custom-control-label">{{ 'TR_DISPLAY_DESCRIPTION_ON_COURSE_PAGE' | translate }}</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-12">
          <hr />
          <h5 class="mb-3">{{ 'TR_TIMING' | translate }}</h5>
        </div>
      </div>
      <div class="form-row">
        <div class="col-12 form-group">
          <label for="openDate">{{ 'TR_OPEN_QUIZ' | translate }}</label>
          <div class="position-relative form-row">
            <div class="col-lg-3 col-md-4 col-sm-6 mb-2">
              <input
                name="openDate"
                [placeholder]="'TR_OPEN_QUIZ' | translate"
                type="date"
                class="form-control"
                autocomplete="off"
                formControlName="openDate"
              />
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-2">
              <input
                name="openTime"
                type="time"
                class="form-control"
                autocomplete="off"
                formControlName="openTime"
              />
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="custom-checkbox custom-control ml-2">
                <input type="checkbox" id="openDateEnabled" class="custom-control-input" formControlName="openDateEnabled">
                <label for="openDateEnabled" class="custom-control-label">{{ 'TR_ENABLE' | translate }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-12 form-group">
          <label for="closeDate">{{ 'TR_CLOSE_QUIZ' | translate }}</label>
          <div class="position-relative form-row">
            <div class="col-lg-3 col-md-4 col-sm-6 mb-2">
              <input
                name="closeDate"
                [placeholder]="'TR_CLOSE_QUIZ' | translate"
                type="date"
                class="form-control"
                autocomplete="off"
                formControlName="closeDate"
              />
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-2">
              <input
                name="closeTime"
                type="time"
                class="form-control"
                autocomplete="off"
                formControlName="closeTime"
              />
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="custom-checkbox custom-control ml-2">
                <input type="checkbox" id="closeDateEnabled" class="custom-control-input" formControlName="closeDateEnabled">
                <label for="closeDateEnabled" class="custom-control-label">{{ 'TR_ENABLE' | translate }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-12 form-group">
          <label for="timeLimit">{{ 'TR_TIME_LIMIT' | translate }}</label>
          <div class="position-relative form-row">
            <div class="col-lg-3 col-md-4 col-sm-6 mb-2">
              <input
                min="0"
                name="timeLimit"
                [placeholder]="'TR_TIME_LIMIT' | translate "
                type="number"
                class="form-control"
                autocomplete="off"
                formControlName="timeLimit"
              />
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-2">
              <select type="select" id="timeLimitEntity" name="timeLimitEntity" class="custom-select" formControlName="timeLimitEntity">
                <option *ngFor="let item of timeTypes; index as i" [value]="i">{{ item | translate }}</option>
              </select>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="custom-checkbox custom-control ml-2">
                <input type="checkbox" id="timeLimitEnabled" class="custom-control-input" formControlName="timeLimitEnabled">
                <label for="timeLimitEnabled" class="custom-control-label">{{ 'TR_ENABLE' | translate }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-12 form-group">
          <label for="whenTimeExpires">{{ 'TR_WHEN_TIME_EXPIRES' | translate }}</label>
          <div class="position-relative form-row">
            <div class="col-lg-6 col-md-8 mb-2">
              <select type="select" id="whenTimeExpires" name="whenTimeExpires" class="custom-select" formControlName="whenTimeExpires">
                <option *ngFor="let item of expireTypes; index as i" [value]="i">{{ item | translate }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="form.value.whenTimeExpires == 2" class="form-row">
        <div class="col-12 form-group">
          <label for="gracePeriod">{{ 'TR_SUBMISSION_GRACE_PERIOD' | translate }}</label>
          <div class="position-relative form-row">
            <div class="col-lg-3 col-md-4 col-sm-6 mb-2">
              <input
                min="0"
                name="gracePeriod"
                [placeholder]="'TR_SUBMISSION_GRACE_PERIOD' | translate"
                type="number"
                class="form-control"
                autocomplete="off"
                formControlName="gracePeriod"
              />
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-2">
              <select type="select" id="gracePeriodEntity" name="gracePeriodEntity" class="custom-select" formControlName="gracePeriodEntity">
                <option *ngFor="let item of timeTypes; index as i" [value]="i">{{ item | translate }}</option>
              </select>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="custom-checkbox custom-control ml-2">
                <input type="checkbox" id="gracePeriodEnabled" class="custom-control-input" formControlName="gracePeriodEnabled">
                <label for="gracePeriodEnabled" class="custom-control-label">{{ 'TR_ENABLE' | translate }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-12">
          <hr />
          <h5 class="mb-3">{{ 'TR_GRADE' | translate }}</h5>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-6 col-md-8">
          <div class="position-relative form-group">
            <label for="passGrade">{{ ('TR_GRADE_TO_PASS' | translate) + mainService.asterisk }}</label>
            <input
              min="0"
              name="passGrade"
              [placeholder]="('TR_GRADE_TO_PASS' | translate) + mainService.asterisk"
              type="number"
              class="form-control"
              [class.error-border]="isInvalidSubmit && !form.value.passGrade"
              autocomplete="off"
              formControlName="passGrade"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="position-relative form-group">
            <label for="attemptsAllowed">{{ 'TR_ATTEMPTS_ALLOWED' | translate }}</label>
            <select type="select" id="attemptsAllowed" name="attemptsAllowed" class="custom-select" formControlName="attemptsAllowed">
              <option *ngFor="let item of allowedsAttempts; index as i" [value]="i">{{ (item | translate) || item }}</option>
            </select>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="position-relative form-group">
            <label for="gradingMethod">{{ 'TR_GRADING_METHOD' | translate }}</label>
            <select type="select" id="gradingMethod" name="gradingMethod" class="custom-select" formControlName="gradingMethod">
              <option *ngFor="let item of gradingMethods; index as i" [value]="i">{{ item | translate}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-row"> 
        <div class="col-12">
          <hr />
          <h5 class="mb-3">{{ 'TR_QUESTION_BEHAVIOUR' | translate }}</h5>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="position-relative form-group">
            <label for="shuffleEnabled">{{ 'TR_SHUFFLE_WITHIN_QUESTIONS' | translate }}</label>
            <select type="select" id="shuffleEnabled" name="shuffleEnabled" class="custom-select" formControlName="shuffleEnabled">
              <option value="true">{{ 'TR_YES' | translate }}</option>
              <option value="false">{{ 'TR_NO' | translate }}</option>
            </select>
          </div>
        </div>
      </div>
      <app-submit-button
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit"
        [isFormValid]="form.valid"
      ></app-submit-button>
    </form>
  </div>
</div>