<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon"><i class="icon pe-7s-cash icon-gradient bg-plum-plate"></i></div>
      <div>{{ 'TR_PAYMENT_PACKAGES' | translate }}</div>
    </div>
    <div *ngIf="paymentpackage_create" class="page-title-actions d-flex"><button class="mr-2 btn btn-success create-btn"
        [title]="'TR_CREATE_PAYMENT_PACKAGE' | translate" [routerLink]="['paymentpackage-form']">
        <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
      </button>
    </div>
  </div>
</div>
<!-- <div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_PAYMENT_PACKAGES' | translate }}</h4>
</div> -->

<!-- <div *ngIf="paymentpackage_create" class="d-inline-block">
  <button class="mb-2 mr-2 btn btn-success create-btn" [title]="'TR_CREATE_PAYMENT_PACKAGE' | translate"
    [routerLink]="['paymentpackage-form']">
    <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
  </button>
</div> -->

<div class="row">
  <div class="col-12">
    <div *ngIf="paymentpackage_view" class="main-card mb-3 card">
      <div class="table-responsive">
        <table class="align-middle mb-0 table table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ 'TR_NAME' | translate }}</th>
              <th>{{ 'TR_DESCRIPTION' | translate }}</th>
              <th>{{ 'TR_COST' | translate }}</th>
              <th>{{ 'TR_WEBINAR_STUDENTS_MAX_COUNT' | translate }}</th>
              <th>{{ 'TR_ORGANIZATION_USERS_MAX_COUNT' | translate }}</th>
              <th>{{ 'TR_STORAGE_SIZE' | translate }}</th>
              <th>{{ 'TR_ALLOW_VIDEO_SAVE' | translate }}</th>

              <th>{{ 'TR_DOCUMENT_SERVICE' | translate }}</th>
              <th>{{ 'TR_USER_TYPE_SERVICE' | translate }}</th>
              <th>{{ 'TR_COURSE_SERVICE' | translate }}</th>
              <th>{{ 'TR_WEBINAR_SERVICE' | translate }}</th>
              <th>{{ 'TR_FIBRARY_SERVICE' | translate }}</th>
              <th>{{ 'TR_INTERNAL_PAYMENT' | translate }}</th>

              <th>{{ 'TR_PACKAGE_TYPE' | translate }}</th>
              <th class="text-center">{{ 'TR_ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of paymentPackages let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ item.packageName }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.cost }}</td>
              <td>{{ item.webinarStudentsMaxCount }}</td>
              <td>{{ item.userMaxCount }}</td>
              <td>{{ item.storageSize }}</td>
              <td class="text-center">
                <input name="allow-video-save" type="checkbox" class="mt-2 k-cursor-no-drop"
                  [checked]="item.allowSaveVideo" (change)="onCkeckboxChange($event)" />
              </td>
              <td class="text-center">
                <input name="document-service" type="checkbox" class="mt-2 k-cursor-no-drop"
                  [checked]="item.documentService" (change)="onCkeckboxChange($event)" />
              </td>
              <td class="text-center">
                <input name="user-type-service" type="checkbox" class="mt-2 k-cursor-no-drop"
                  [checked]="item.userTypeService" (change)="onCkeckboxChange($event)" />
              </td>
              <td class="text-center">
                <input name="course-service" type="checkbox" class="mt-2 k-cursor-no-drop"
                  [checked]="item.courseService" (change)="onCkeckboxChange($event)" />
              </td>
              <td class="text-center">
                <input name="webinar-service" type="checkbox" class="mt-2 k-cursor-no-drop"
                  [checked]="item.webinarService" (change)="onCkeckboxChange($event)" />
              </td>
              <td class="text-center">
                <input name="file-library-service" type="checkbox" class="mt-2 k-cursor-no-drop"
                  [checked]="item.fileLibraryService" (change)="onCkeckboxChange($event)" />
              </td>
              <td class="text-center">
                <input name="file-library-service" type="checkbox" class="mt-2 k-cursor-no-drop"
                  [checked]="item.innerPaymentService" (change)="onCkeckboxChange($event)" />
              </td>

              <td>{{ item.packageType }}</td>
              <td class="text-center">
                <div role="group" class="btn-group-sm btn-group">
                  <button *ngIf="paymentpackage_update" class="btn-transition btn btn-outline-info"
                    [title]="'TR_UPDATE_PAYMENT_PACKAGE' | translate" [routerLink]="['paymentpackage-form', item.id]">
                    <i class="pe-7s-note"></i>
                  </button>
                  <button *ngIf="paymentpackage_delete" class="ml-1 btn-transition btn btn-outline-danger"
                    [title]="'TR_DELETE' | translate" (click)="confirmDelete(deleteconfirm, item.id, item.name)">
                    <i class="pe-7s-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteconfirm let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p>{{ 'TR_DELETE_ERROR' | translate }} <b class="word-break-break-word">{{ deletePaymentPackageName }}</b>?</p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button *ngIf="paymentpackage_delete" [isCurrent]="true" [spinnerId]="deleteSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="delete()"></app-action-button>
    <button type="button" class="btn-transition btn btn-danger"
      (click)="c('Close click')">{{ 'TR_NO' | translate }}</button>
  </div>
</ng-template>