import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { AlertService } from 'src/app/Services/alert.service';
import { IFileLibraryData } from 'src/app/Models/IFileLibraryData';
import { SUCCESS_KEY, TR_CREATE_MESSAGE_KEY } from 'src/app/Constants/main-keys';
import { COURSE_TOPIC_FILE_PATH_KEY, TOPIC_ID_PATH_KEY } from 'src/app/Constants/request-path-keys';

@Component({
  selector: 'app-topic-attach-file',
  templateUrl: './topic-attach-file.component.html',
  styleUrls: ['./topic-attach-file.component.scss']
})
export class TopicAttachFileComponent implements OnInit {
  files: Array<IFileLibraryData> = [];
  userRoles: Array<string> = [];
  getAttachedFiles: boolean;

  @Input() topicId: number;
  @Input() topicName: string;
  @Output() closeEmit = new EventEmitter(null);

  constructor(
    private rolesService: RolesService,
    private dataHandlerService: DataHandlerService,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;

    this.getFileLibraryByTopic(true);
  }

  async getFileLibraryByTopic(isAllFiles: boolean) {
    if(!this.topicId) { return; }
    this.files = await this.dataHandlerService.getDataWithSpinner(`${COURSE_TOPIC_FILE_PATH_KEY}/${TOPIC_ID_PATH_KEY}/${this.topicId}/${isAllFiles}`);
  }

  attachFile(isBelong: boolean, id: number): void {
    if(!this.topicId) { return; }
    const body = {
      courseTopicId: this.topicId,
      create: isBelong,
      fileLibraryId: id
    };

    this.dataHandlerService.createData(body, COURSE_TOPIC_FILE_PATH_KEY, null).then(() => {
      this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
      this.closeEmit.emit();
    });
  }

}
