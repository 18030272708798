<ng-container *ngIf="courseData">
  <div class="main-card card p-4">
    <div class="row">
      <div class="col-12">
        <div class="title-container flex m-0">
          <div class="title-block mb-3">
            <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('course')"></i>
            <h4 class="card-title">{{ 'TR_COURSE_VIEW' | translate }}</h4>
          </div>
        </div>
        <div class="main-padding-block">
          <div class="course-data-block">
            <div class="row">
              <div class="col-12">
                <div class="data-block">
                  <h4>{{ courseData.name }}</h4>
                  <p>{{ courseData.description }}</p>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6 col-12">
                <div *ngIf="courseData.imagePath" class="course-image" [ngStyle]="{ 'background-image' : 'url(' + courseData.imagePath + ')' }"></div>
              </div>
              <div class="col-md-6 col-12">
                <video *ngIf="courseData.presentationFilePath" [src]="courseData.presentationFilePath" class="course-video" controls></video>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <p class="mb-2">
                  <strong>{{ 'TR_CREATOR' | translate }}: </strong>
                  <span>{{ courseData.creatorUserName }}</span>
                </p>
                <p class="mb-2">
                  <strong>{{ 'TR_LANGUAGE' | translate }}: </strong>
                  <span>{{ courseData.languageName }}</span>
                </p>
                <p class="mb-2">
                  <strong>{{ 'TR_PRICE' | translate }}: </strong>
                  <span>{{ courseData.cost }}</span>
                </p>
                <p *ngIf="courseData.discount" class="mb-2">
                  <strong>{{ 'TR_DISCOUNT_PERCENTAGE' | translate }}: </strong>
                  <span>{{ courseData.discount }}% ({{ courseData.discountStartDate | date }} - {{ courseData.discountEndDate | date }})</span>
                </p>
                <p *ngIf="courseData.period" class="mb-2">
                  <strong>{{ 'TR_DURATION_DAYS' | translate }}: </strong>
                  <span>{{ courseData.period }}</span>
                </p>
                <p class="mb-2">
                  <strong>{{ 'TR_COURSE_CATEGORIES' | translate}}: </strong>
                  <span *ngFor="let category of courseData.categories; index as i" >{{ category.name}}
                    <span *ngIf="i !== courseData.categories.length - 1">, </span>
                  </span>
                </p>
                <p class="mb-2">
                  <strong>{{ 'TR_COURSE_KEY_WORDS' | translate }}: </strong>
                  <span *ngFor="let keyword of courseData.keywordsValues; index as i">{{ keyword }}
                    <span *ngIf="i !== courseData.keywordsValues.length - 1">, </span>
                  </span>
                </p>
                <p class="mb-2">
                  <strong>{{ 'TR_EXPERIENCE_LEVEL' | translate }}: </strong>
                  <span>{{ courseLevels[courseData.experienceLevel] | translate }}</span>
                </p>
                <p class="mb-2">
                  <strong>{{ 'TR_WHAT_YOU_WILL_LEARN_DESC' | translate }}: </strong>
                  <span *ngFor="let whatYouWillLearn of courseData.whatYouWillLearnValues; index as i">{{ whatYouWillLearn }}
                    <span *ngIf="i !== courseData.whatYouWillLearnValues.length - 1">, </span>
                  </span>
                </p>
                <p class="mb-2">
                  <strong>{{ 'TR_REQUIREMENTS' | translate }}: </strong>
                  <span *ngFor="let requirement of courseData.requirementsValues; index as i">{{ requirement }}
                    <span *ngIf="i !== courseData.requirementsValues.length - 1">, </span>
                  </span>
                </p>
                <p class="mb-2">
                  <strong>{{ 'TR_TARGETED_STUDENTS' | translate }}: </strong>
                  <span *ngFor="let targetStudent of courseData.targetStudentsValues; index as i">{{ targetStudent }}
                    <span *ngIf="i !== courseData.targetStudentsValues.length - 1">, </span>
                  </span>
                </p>
                <p class="mb-2">
                  <strong>{{ 'TR_WELCOME_MESSAGE' | translate }}: </strong>
                  <span>{{ courseData.welcomeMessage }} </span>
                </p>
                <p class="mb-2">
                  <strong>{{ 'TR_CONGRETULATION_MESSAGE' | translate }}: </strong>
                  <span>{{ courseData.congratulationMessage }} </span>
                </p>
                <p *ngIf="courseData.isLifeTime" class="mb-2">{{ 'TR_FILL_LIFETIME_ACCESS' | translate }}</p>
              </div>
            </div>
          </div>

          <hr />
          <mat-accordion>
            <mat-expansion-panel
              *ngFor="let section of courseData.sections; index as sectionIndex"
              (opened)="setSelectedSection(section.id)"
              (closed)="selectedTopicInfo = null; removeSelectedSection(section.id);"
              [expanded]="courseBreadCrumb && courseBreadCrumb.sectionId === section.id"
            >
              <mat-expansion-panel-header class="section-header">
                <mat-panel-title>
                  <span class="section-name mr-1">{{ 'TR_SECTION' | translate }} {{ sectionIndex + 1 }}: </span> {{ section.name }}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div *ngIf="section.topics?.length" class="main-card card">
                <div class="table-responsive">
                  <div class="align-middle mb-0 table table-hover inner-table">
                    <mat-accordion>
                      <mat-expansion-panel
                        *ngFor="let topic of section.topics; index as topicIndex"
                        (opened)="openTopicInfo(topic.id, section.id)"
                        (closed)="selectedTopicInfo = null; removeSelectedTopic(topic.id)"
                        [expanded]="courseBreadCrumb && courseBreadCrumb.topicId === topic.id"
                      >
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <div class="flex small-block pointer">
                              <div class="mr-2 count">{{ topicIndex + 1 }}.</div>
                              <div class="flex flex-column">
                                <p class="m-0">{{ topic.name }}</p>
                                <small *ngIf="!selectedTopicInfo || selectedTopicInfo.id != topic.id">{{ topic.description }}</small>
                              </div>
                            </div>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="flex full-block w-100">
                          <div class="flex flex-column topic-info w-100">
                            <div class="row">
                              <div class="col-12">
                                <p>{{ topic.description }}</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6 col-12">
                                <div class="topic-image" [ngStyle]="{ 'background-image' : 'url(' + topic.imagePath + ')' }" ></div>
                              </div>
                              <div class="col-md-6 col-12">
                                <video class="topic-video" [src]="topic.presentationFilePath" controls></video>
                              </div>
                            </div>
                            <h4 class="mt-3 ml-2">{{ 'TR_RESOURCES' | translate }}</h4>
                            <div class="wrap-flex flex-column">
                              <div class="border-bottom file-block" *ngFor="let file of attachedFiles" (click)="openFile(file, fileview)">
                                <div *ngIf="file.mimeType.includes('video')" class="font-icon-wrapper font-icon-lg" [title]="'TR_ATTACHED_VIDEO' | translate">
                                  <i class="pe-7s-film icon-gradient bg-malibu-beach"></i>
                                </div>
                                <div *ngIf="file.mimeType.includes('file') || file.mimeType.includes('text')" class="font-icon-wrapper font-icon-lg" [title]="'TR_ATTACHED_FILE' | translate">
                                  <i class="pe-7s-file icon-gradient bg-grow-early"></i>
                                </div>
                                <div *ngIf="file.mimeType.includes('image')" class="font-icon-wrapper font-icon-lg" [title]="'TR_ATTACHED_IMAGE' | translate">
                                  <i class="pe-7s-photo icon-gradient bg-plum-plate"></i>
                                </div>
                                <div class="file-data">
                                  <p class="mb-0">{{ file.nameAsResource }}</p>
                                  <small>{{ file.description }}</small>
                                </div>
                              </div>
                              <h4 class="mt-3 ml-2">{{ 'TR_QUIZZES' | translate }}</h4>
                              <div class="border-bottom file-block pointer" *ngFor="let quiz of topicQuizzes" (click)="openQuizView(quiz.id)">
                                <div class="font-icon-wrapper font-icon-lg" [title]="'TR_ATTACHED_VIDEO' | translate">
                                  <i class="pe-7s-news-paper icon-gradient bg-malibu-beach"></i>
                                </div>
                                <div class="quiz-data-container">
                                  <div class="quiz-data">
                                    <p class="mb-0">{{ quiz.name }}</p>
                                    <small>{{ quiz.description }}</small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <hr />
          <div class="wrap-flex flex-column course-quizzes" *ngFor="let quiz of courseQuizzes">
            <div class="file-block">
              <div class="font-icon-wrapper font-icon-lg" [title]="'TR_ATTACHED_VIDEO' | translate">
                <i class="pe-7s-news-paper icon-gradient bg-malibu-beach"></i>
              </div>
              <div class="quiz-data-container">
                <div class="quiz-data">
                  <p class="mb-0">{{ quiz.name }}</p>
                  <small>{{ quiz.description }}</small>
                </div>
              </div>
            </div>
          </div>

          <div class="wrap-flex mt-2">
            <button
              *ngIf="courseData.verified"
              type="button"
              class="btn-transition btn action-btn ml-1 mb-2 btn-danger"
              (click)="confirmAction(verification)"
            >
              <i class="pe-7s-close-circle"></i>
              <span class="ml-2">{{ 'TR_CANCEL_VERIFICATION' | translate }}</span>
            </button>
            <button
              *ngIf="courseData.submitedForVerification"
              type="button"
              class="btn-transition btn action-btn ml-1 mb-2 btn-success"
              (click)="confirmAction(verification)"
            >
              <i class="pe-7s-check"> </i>
              <span class="ml-2">{{ 'TR_VERIFY' | translate }}</span>
            </button>
            <button
              *ngIf="courseData.verificationDenied"
              type="button"
              class="btn-transition btn action-btn ml-1 mb-2 btn-primary"
              (click)="confirmAction(verificationDenied)"
            >
              <i class="pe-7s-check"> </i>
              <span class="ml-2">{{ TR_CANCEL_VERIFICATION_DENIAL' | translate }}</span>
            </button>
            <button
              *ngIf="courseData.submitedForVerification"
              type="button"
              class="btn-transition btn action-btn ml-1 mb-2 btn-danger"
              (click)="confirmAction(verificationDenied)"
            >
              <i class="pe-7s-close-circle"> </i>
              <span class="ml-2">{{ 'TR_DENY' | translate }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #fileview let-c="close" let-d="dismiss">
    <div class="custom-modal modal-body">
      <div class="file-view-wrapper">
        <video *ngIf="selectedTopicFile.mimeType.includes('video')" [src]="selectedTopicFile.filePath" class="video" controls></video>
        <div *ngIf="selectedTopicFile.mimeType.includes('image')" [ngStyle]="{ 'background-image' : 'url(' + selectedTopicFile.filePath + ')' }" class="image"></div>
        <div *ngIf="selectedTopicFile.mimeType.includes('file') || selectedTopicFile.mimeType.includes('text')" class="document">
          <i class="pe-7s-photo icon-gradient bg-plum-plate"></i>
        </div>
        <p class="mt-2 text-center">{{ selectedTopicFile.nameAsResource }}</p>
      </div>
    </div>
    <div class="custom-modal modal-footer">
      <button type="button" class="mr-2 btn-transition btn btn-outline-dark" (click)="c('Close click'); selectedTopicFile = null;">{{ 'TR_CLOSE' | translate }}</button>
    </div>
  </ng-template>

  <ng-template #verification let-c="close" let-d="dismiss">
    <div class="custom-modal modal-body">
      <p>
        {{ courseData.verified ? ('TR_COURSE_UNVERIFY_CONFIRM_MSG' | translate) : "" }}
        {{ courseData.submitedForVerification ? ('TR_COURSE_VERIFY_CONFIRM_MSG' | translate) : "" }}
    </p>
    </div>
    <div class="custom-modal modal-footer">
      <button type="button" class="mr-2 btn-transition btn btn-outline-info" (click)="toggleCourseVerification()">{{ 'TR_YES' | translate }}</button>
      <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate }}</button>
    </div>
  </ng-template>

  <ng-template #verificationDenied let-c="close" let-d="dismiss">
    <div class="deny-modal modal-body">
      <p>
        {{ courseData.verificationDenied ? ('TR_COURSE_DENY_CANCEL_CONFIRM_MSG' | translate) : "" }}
        {{ courseData.submitedForVerification ? ('TR_COURSE_DENY_CONFIRM_MSG' | translate) : "" }}
    </p>
      <textarea class="deny-message" [(ngModel)]="denyMessage" [class.error-border]="isDenyMessageError && !denyMessage"></textarea>
    </div>
    <div class="custom-modal modal-footer">
      <button type="button" class="mr-2 btn-transition btn btn-outline-info" (click)="toggleCourseVerificationDeny()">{{ 'TR_YES' | translate }}</button>
      <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate }}</button>
    </div>
  </ng-template>
</ng-container>
