<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon k-cursor-pointer goback" (click)="mainService.goBack('user')"
        [title]="'TR_GOBACK' | translate"><i class="icon pe-7s-left-arrow icon-gradient bg-plum-plate"></i></div>
      <div>{{ isCreateForm ? ('TR_CREATE_ORGANIZATION' | translate) : ('TR_UPDATE_ORGANIZATION' |
        translate) }}
      </div>
    </div>
  </div>
</div>
<div class="main-card mb-3 card">
  <div class="card-body">
    <!-- <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('organization')"></i>
      <h5 class="card-title">{{ isCreateForm ? ('TR_CREATE_ORGANIZATION' | translate) : ('TR_UPDATE_ORGANIZATION' |
        translate) }}</h5>
    </div> -->

    <!--  -->
    <div *ngIf="!organizationId">
      <div class="row">
        <div class="form-outline flex-wrap w-100">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div *ngIf="paymentpackage_view" ngbDropdown>
              <div class="flex flex-column">
                <!-- <label>{{ ('TR_PAYMENT_PACKAGES' | translate) + mainService.asterisk}}</label> -->
                <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary"
                  [class.error-border]="isInvalidSubmit && !selectedPaymentPackageId">
                  {{ selectedPaymentPackageName ? selectedPaymentPackageName : ('TR_PAYMENT_PACKAGES' | translate) +
                  mainService.asterisk}}
                </button>
              </div>
              <div ngbDropdownMenu>
                <button class="dropdown-item" *ngFor="let item of paymentPackages"
                  (click)="setSelectedPaymentPackage(item.id, item.packageName, item.type)">
                  {{ item.packageName }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div
              *ngIf="monthCounts && monthCounts.length && selectedPaymentPackageName && selectedPaymentPackageType != 0"
              ngbDropdown>
              <div class="flex flex-column">
                <label>{{ ('TR_MONTH_COUNT' | translate) + mainService.asterisk}}</label>
                <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary"
                  [class.error-border]="isInvalidSubmit && !monthCount">
                  {{ monthCount ? monthCount : ('TR_MONTH_COUNT' | translate) + mainService.asterisk}}
                </button>
              </div>
              <div ngbDropdownMenu>
                <button class="dropdown-item" *ngFor="let item of monthCounts"
                  (click)="setSelectedMonthCount(item.monthCount)">
                  Months: {{ item.monthCount }}, Price: {{ item.priceAsString }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedPaymentPackageType != 0" class="col-12">
          <div class="position-relative form-check">
            <input name="recurringPayment" id="recurringPayment" type="checkbox" class="form-check-input"
              [checked]="recurringPayment" (change)="onChechboxChange($event)">
            <label for="recurringPayment" class="form-check-label">{{ 'TR_RECURRING_PAYMENT' | translate }}</label>
          </div>
        </div>
      </div>
      <div *ngIf="selectedPaymentPackageType != 0" class="form-row">
        <div class="col-lg-3 col-md-4 col-12">
          <div class="mt-3 mb-3 upload-block form-outline">
            <div class="mb-2">
              <button type="button" class="btn-transition btn btn-warning upload-btn"
                [class.error-border]="isInvalidCheque" (click)="file.click()">
                <i class="pe-7s-plus"></i>
                <p>{{ ('TR_UPLOAD_FILE' | translate) + mainService.asterisk }}</p>
              </button>
              <input #file hidden type="file" name="upload-avatar" (change)="handleFileUpload($event)" />
            </div>
            <div *ngIf="uploadedFile" [ngStyle]="{ 'background-image' : 'url(' + uploadedFile + ')' }"
              class="upload-avatar"></div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="wrap-flex form-outline">
      <div *ngIf="user_view" ngbDropdown>
        <div class="flex flex-column">
          <!-- <label>{{ ('TR_SELECT_ADMIN' | translate) + mainService.asterisk }}</label> -->
          <button type="button" ngbDropdownToggle class="mb-2 mr-3 btn btn-outline-primary"
            [class.error-border]="isInvalidSubmit && !selectedAdminId">
            {{ selectedAdminName ? selectedAdminName : ('TR_SELECT_ADMIN' | translate) + mainService.asterisk }}
          </button>
        </div>
        <div ngbDropdownMenu>
          <button class="dropdown-item" *ngFor="let item of admins" (click)="setSelectedAdmin(item.id, item.fullName)">
            {{ item.fullName }}
          </button>
        </div>
      </div>
    </div>
    <!--  -->
    <form (submit)="submitData()" [formGroup]="form">
      <div class="form-row">
        <div class="col-lg-3 col-md-4">
          <div class="position-relative form-group">
            <label for="name">{{ ('TR_NAME' | translate) + mainService.asterisk }}</label>
            <!-- [placeholder]="('TR_NAME' | translate) + mainService.asterisk" -->
            <input name="name" type="text" class="form-control"
              [class.error-border]="isInvalidSubmit && (!form.value.name || !form.value.name.trim())" autocomplete="off"
              formControlName="name" />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-3 col-md-4">
          <div class="position-relative form-group">
            <label for="description">{{ 'TR_DESCRIPTION' | translate }}</label>
            <!-- [placeholder]="'TR_DESCRIPTION' | translate" -->
            <input name="description" type="text" class="form-control" autocomplete="off"
              formControlName="description" />
          </div>
        </div>
      </div>
      <!-- <div class="form-row">
        <div class="col-lg-3 col-md-4">
          <div class="position-relative form-group">
            <label for="deadline">{{ ('TR_DEADLINE' | translate) + mainService.asterisk }}</label>
            <input name="deadline" type="date" [min]="today" class="form-control"
              [class.error-border]="isInvalidSubmit && !form.value.deadline" autocomplete="off"
              formControlName="deadline" />
            <p class="warning-message">{{ 'TR_DATE_WARNING_MSG' | translate }}</p>
          </div>
        </div>
      </div> -->
      <div class="form-row">
        <div class="col-lg-3 col-md-4">
          <div class="position-relative form-group">
            <label for="email">{{ ('TR_EMAIL' | translate) + mainService.asterisk }}</label>
            <input name="email" type="email" class="form-control"
              [class.error-border]="(isInvalidSubmit && (!form.value.email || (form.value.email && !form.value.email.trim()))) || form.controls['email'].hasError('email')"
              autocomplete="off" formControlName="email" />
            <p class="warning-message">{{ 'TR_EMAIL_WARNING_MSG' | translate }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-4">
          <div class="position-relative form-group">
            <label for="address">{{ 'TR_ADDRESS' | translate }}</label>
            <!-- [placeholder]="'TR_ADDRESS' | translate" -->
            <input name="address" type="text" class="form-control" autocomplete="off" formControlName="address" />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-3 col-md-4">
          <div class="position-relative form-group">
            <label for="phone">{{ 'TR_PHONE' | translate }}</label>
            <!-- [placeholder]="'TR_PHONE' | translate" -->
            <input name="phone" type="tel" autocomplete="off" class="form-control"
              [class.error-border]="form.controls['phone'].hasError('pattern')" autocomplete="off"
              formControlName="phone" />
            <p class="warning-message">{{ 'TR_PHONE_WARNING_MSG' | translate }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-4">
          <div class="position-relative form-group">
            <label for="mobilePhone">{{ 'TR_MOBILEPHONE' | translate }}</label>
            <!-- [placeholder]="'TR_MOBILEPHONE' | translate" -->
            <input name="mobilePhone" type="tel" class="form-control"
              [class.error-border]="form.controls['mobilePhone'].hasError('pattern')" autocomplete="off"
              formControlName="mobilePhone" />
            <p class="warning-message">{{ 'TR_PHONE_WARNING_MSG' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-3 col-md-4">
          <div class="position-relative form-group">
            <label for="shortName">{{ 'TR_SHORT_NAME' | translate }}</label>
            <!-- [placeholder]="'TR_SHORT_NAME' | translate" -->
            <input name="shortName" type="text" autocomplete="off" class="form-control" formControlName="shortName" />
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-3 col-md-4">
          <div class="position-relative form-group">
            <label for="streamUrl">{{ ('TR_APPLICATION_NAME' | translate) + mainService.asterisk }}</label>
            <input
              name="streamUrl"
              [placeholder]="('TR_APPLICATION_NAME' | translate) + mainService.asterisk"
              type="text"
              class="form-control"
              [class.error-border]="isInvalidSubmit && !form.value.streamUrl || isInvalidStreamUrl"
              autocomplete="off"
              formControlName="streamUrl"
            />
            <p class="warning-message">{{ 'TR_STREAM_URL_WARNING_MSG' | translate }}</p>
          </div>
        </div>
      </div>
      <div *ngIf="!hideFields" class="form-row">
        <div class="col-lg-3 col-md-4">
          <div class="position-relative form-group">
            <label for="pushLogin">{{ 'TR_LOGIN' | translate }}</label>
            <input
              name="pushLogin"
              [placeholder]="'TR_LOGIN' | translate"
              type="text"
              autocomplete="new-password"
              class="form-control"
              autocomplete="off"
              formControlName="pushLogin"
            />
            <p class="warning-message">{{ 'TR_STREAMING_SERVER_LOGIN_WARNING_MSG' | translate }}</p>
          </div>
        </div>
      </div>
      <div *ngIf="!hideFields" class="form-row">
        <div class="col-lg-6 col-md-8">
          <label for="pushPassword">{{ 'TR_PASSWORD' | translate }}</label>
          <div class="input-group row m-0">
            <div class="col-6 p-0">
              <input
                name="pushPassword"
                [placeholder]="'TR_PASSWORD' | translate"
                type="text"
                class="form-control"
                autocomplete="new-password"
                formControlName="pushPassword"
              />
            </div>
            <div class="input-group-append col-6 p-0">
              <button type="button" class="btn btn-primary" (click)="generatePassword()">
                {{ 'TR_GENERATE_PASSWORD' | translate }}
              </button>
            </div>
          </div>
          <p class="warning-message">{{ 'TR_PASSWORD_WARNING_MSG' | translate }}</p>
        </div>
      </div> -->
      <div class="form-row">
        <div class="col-md-6 mt-1 mb-2 checkboxes-block">
          <div class="position-relative form-check">
            <input name="isActive" id="isActive" type="checkbox" class="form-check-input" formControlName="isActive">
            <label for="isActive" class="form-check-label">{{ 'TR_IS_ACTIVE' | translate }}</label>
          </div>
          <div class="position-relative form-check">
            <input name="sendEmail" id="sendEmail" type="checkbox" class="form-check-input" formControlName="sendEmail">
            <label for="sendEmail" class="form-check-label">{{ 'TR_SEND_EMAIL' | translate }}</label>
          </div>
          <div class="position-relative form-check">
            <input name="sendNotification" id="sendNotification" type="checkbox" class="form-check-input"
              formControlName="sendNotification">
            <label for="sendNotification" class="form-check-label">{{ 'TR_SEND_NOTIFICATION' | translate }}</label>
          </div>
        </div>
      </div>
      <app-submit-button [spinnerId]="submitSpinnerId"
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit" [isFormValid]="form.valid"></app-submit-button>
    </form>
  </div>
</div>