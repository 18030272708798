<div class="h-100 bg-plum-plate bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div *ngIf="isPageLoaded" class="mx-auto app-login-box col-md-6">
      <div class="app-logo-inverse mx-auto mb-3"></div>
      <div class="modal-dialog w-100">
        <form [formGroup]="form" (submit)="sendEmailData()">
          <div class="modal-content">
            <div class="modal-header">
              <div class="h5 modal-title">
                {{ 'TR_FORGOT_YOUR_PASSWORD' | translate }}
                <h6 class="mt-1 mb-0 opacity-8"><span>{{ 'TR_RECOVER_MSG' | translate }}</span></h6>
              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <fieldset class="form-group" id="__BVID__132">
                    <div tabindex="-1" role="group">
                      <label for="exampleEmail">{{ 'TR_ENTER_EMAIL' | translate }}</label>
                      <input id="exampleEmail" name="email" type="email" [placeholder]="'TR_ENTER_EMAIL' | translate"
                        [class.error-border]="(isInvalidSubmit && !form.value.email) || (isInvalidSubmit && form.controls['email'].hasError('email'))"
                        class="form-control" autocomplete="off" formControlName="email" />
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="float-left">
                <a [routerLink]="[languageService.currentLanguage]" class="btn-lg btn btn-link p-0 text-primary">{{
                  'TR_SIGN_IN_EXISTING_ACCOUNT' | translate }}</a>
              </div>
            </div>
            <div class="modal-footer clearfix">
              <div class="float-right">
                <app-submit-button [buttonName]="'TR_RECOVER_PASSWORD' | translate" [buttonColor]="'btn-wa'">
                </app-submit-button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="text-center text-white opacity-8 mt-3">
        © {{ currentYear }} Mindalay
      </div>
    </div>
  </div>
</div>