import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { guid } from '@progress/kendo-angular-common';
import { ActivatedRoute } from '@angular/router';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { AlertService } from 'src/app/Services/alert.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { CONFIG_PATH_KEY, CONFIG_URL_KEY, CURRENCY_PATH_KEY } from 'src/app/Constants/request-path-keys';
import {
  CONFIG_CREATE,
  CONFIG_UPDATE,
  CONFIG_VIEW,
  SUCCESS_KEY,
  CURRENCY_CREATE,
  CURRENCY_DELETE,
  CURRENCY_UPDATE,
  CURRENCY_VIEW,
  TR_CREATE_MESSAGE_KEY,
  TR_CREATE_CONFIG_KEY,
  TR_UPDATE_MESSAGE_KEY,
  TR_UPDATE_CONFIG_KEY
} from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-currency-form',
  templateUrl: './currency-form.component.html',
  styleUrls: ['./currency-form.component.scss']
})
export class CurrencyFormComponent implements OnInit {
  currencyId: number;
  form: FormGroup;
  userRoles: Array<string> = [];
  currency_create: boolean;
  currency_update: boolean;
  currency_view: boolean;
  isInvalidSubmit: boolean;
  isCreateForm: boolean = true;
  submitSpinnerId: string = guid();
  isDisabledIsMain: Boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerservice: DataHandlerService,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    public mainService: MainService,
    private translationService: TranslationService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_CREATE_CONFIG_KEY);

    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      isocode: ['', Validators.required],
      symbol: ['', Validators.required],
      decimalCount: ['', Validators.required],
      rate: ['', Validators.required],
      symbolPosition: [false],
      isMain: [false]
    });

    this.route.paramMap.subscribe(params => {
      this.currencyId = +params.get('item.id');

      if (this.currencyId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle(TR_UPDATE_CONFIG_KEY);
        this.getConfigById();
        this.isDisabledIsMain = true;
      }
    });
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch (role) {
        case CONFIG_CREATE:
          this.currency_create = true;
          break;
        case CONFIG_UPDATE:
          this.currency_update = true;
          break;
        case CONFIG_VIEW:
          this.currency_view = true;
          break;
      }
    });
  }

  getConfigById(): void {
    if (!this.currency_view) { return; }
    this.dataHandlerservice.getDataWithSpinner(`${CURRENCY_PATH_KEY}/${this.currencyId}`).then(response => {
      const configData = response;
      this.form.patchValue({
        name: configData.name,
        decimalCount: configData.decimalCount,
        isMain: configData.isMain,
        isocode: configData.isocode,
        rate: configData.rate,
        symbol: configData.symbol,
        symbolPosition: configData.symbolPosition,
      });
    });
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      if (this.currencyId) {
        form.id = this.currencyId;
        delete form.isMain;
      }
      form.name = form.name.trim();
      form.isocode = form.isocode.trim();
      form.symbol = form.symbol.trim();
      if (this.currencyId && this.currency_update) {
        this.dataHandlerservice.updateFormData(form, CURRENCY_PATH_KEY, this.submitSpinnerId).then(() => {
          this.mainService.goBack(CURRENCY_PATH_KEY);
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
        });
      } else if (this.currency_create) {
        this.dataHandlerservice.createFormData(form, CURRENCY_PATH_KEY, this.submitSpinnerId).then(() => {
          this.mainService.goBack(CURRENCY_PATH_KEY);
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }
}
