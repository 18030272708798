<div class="form-row" [formGroup]="form">
  <div class="col-12 mb-3 mt-1">
    <div class="position-relative form-check">
      <input name="isShuffle" id="isShuffle" type="checkbox" class="form-check-input" formControlName="isShuffle">
      <label for="isShuffle" class="form-check-label">{{ 'TR_SHUFFLE' | translate }}</label>
    </div>
  </div>
  <div class="col-lg-6 col-md-8 mr-1" formArrayName="answers">
    <div [formGroupName]="i" class="answer-block mb-2" *ngFor="let answer of form.get('answers')['controls']; let i=index">
      <div class="form-row">
        <div class="col-lg-12">
          <div class="position-relative form-group">
            <label for="question">{{ 'TR_QUESTION' | translate }}</label>
            <textarea
              name="question"
              [placeholder]="'TR_QUESTION' | translate"
              type="text"
              class="form-control"
              autocomplete="off"
              formControlName="question"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-12">
          <div class="position-relative form-group">
            <label for="answer">{{ 'TR_ANSWER' | translate }}</label>
            <input
              name="answer"
              [placeholder]="'TR_ANSWER' | translate"
              type="text"
              class="form-control"
              autocomplete="off"
              formControlName="text"
            />
          </div>
        </div>
      </div>
    </div>
    <button type="button" class="mb-2 btn-transition btn btn-outline-info create-btn" (click)="addAnswer()">
      <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_ADD_ANSWER' | translate }}</span>
    </button>
  </div>
</div>