import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { guid } from '@progress/kendo-angular-common';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { IStudentData } from 'src/app/Models/IStudentData';
import { STUDENTS_PATH_KEY } from 'src/app/Constants/request-path-keys';
import { ORGANIZATION_VIEW, STUDENT_VIEW } from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-student-view',
  templateUrl: './student-view.component.html',
  styleUrls: ['./student-view.component.scss']
})
export class StudentViewComponent implements OnInit {
  @Input() studentId: number;
  @Output() closeEmit = new EventEmitter();

  student: IStudentData;
  userRoles: Array<string> = [];
  student_view: boolean;
  organization_view: boolean;
  studentSpinnerId: string = guid();

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.organization_view = this.userRoles.includes(ORGANIZATION_VIEW);
    this.student_view = this.userRoles.includes(STUDENT_VIEW);

    this.getStudentData();
  }

  getStudentData() {
    if (!this.studentId && !this.student_view) { return; }
    this.dataHandlerService.getDataWithModalSpinner(`${STUDENTS_PATH_KEY}/${this.studentId}`, this.studentSpinnerId)
      .then(response => this.student = response);
  }
}
