import { Component, Input, OnInit } from '@angular/core';
import { MainService } from 'src/app/Services/main.service';
import { startWith, tap, delay } from 'rxjs/operators';

@Component({
  selector: 'app-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss']
})
export class SubmitButtonComponent implements OnInit {
  @Input() isInvalidSubmit: boolean;
  @Input() isFormValid: boolean;
  @Input() buttonName: string;
  @Input() buttonColor: string;
  @Input() isFloatRight: boolean;
  @Input() spinnerId: string;

  isSubmitted: boolean;

  constructor(
    private mainService: MainService
  ) { }

  ngOnInit() {
    this.mainService.buttonSpinners.pipe(
      startWith(null),
      delay(0),
      tap(spinners => this.isSubmitted = spinners?.length && spinners.find(spinner => spinner === this.spinnerId) ? true : false)
    ).subscribe();
  }

}
