import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { AlertService } from 'src/app/Services/alert.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { IDocumentCategoryData, IDocumentData } from 'src/app/Models/IDocumentData';
import { CATEGORY_ID_PATH_PARAM_KEY, DOCUMENT_CATEGORY_PATH_KEY, ORGANIZATION_DOCUMENT_PATH_KEY } from 'src/app/Constants/request-path-keys';
import {
  DOCUMENTCATEGORY_VIEW,
  ORGANIZATIONDOCUMENT_CREATE,
  ORGANIZATIONDOCUMENT_DELETE,
  ORGANIZATIONDOCUMENT_SIGN,
  ORGANIZATIONDOCUMENT_UPDATE,
  ORGANIZATIONDOCUMENT_VIEW,
  SELECTED_CATEGORY_ID_KEY,
  SUCCESS_KEY,
  TR_DELETE_MESSAGE_KEY,
  TR_EDUCATIONAL_MATERIALS_KEY
} from 'src/app/Constants/main-keys';
import { guid } from '@progress/kendo-angular-common';
import { LanguageService } from 'src/app/Services/language.service';
import { ADMIN_KEY } from './../../../Constants/main-keys';

@Component({
  selector: 'app-documents-table',
  templateUrl: './documents-table.component.html',
  styleUrls: ['./documents-table.component.scss']
})
export class DocumentsTableComponent implements OnInit {
  document_view: boolean;
  document_create: boolean;
  document_update: boolean;
  document_delete: boolean;
  document_sign: boolean;
  documentcategory_view: boolean;
  selectedCategoryId: number;
  deleteDocumentId: number;
  deleteDocumentName: string;
  selectedDocument: IDocumentData;
  userRoles: Array<string> = [];
  documents: Array<IDocumentData> = [];
  documentCategories: Array<IDocumentCategoryData> = [];
  deleteSpinnerId: string = guid();

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private mainService: MainService,
    private modalService: NgbModal,
    private translationService: TranslationService,
    private alertService: AlertService,
    private router: Router,
    private languageService: LanguageService,
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_EDUCATIONAL_MATERIALS_KEY);

    this.getDocumentCategories();

    this.mainService.selectedDocumentCategory.subscribe(data => {
      if (data) {
        const selectedDocumentCategoryId = +localStorage.getItem(SELECTED_CATEGORY_ID_KEY);
        selectedDocumentCategoryId && this.getDocumentsByCategory(selectedDocumentCategoryId);
      }
    });
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case ORGANIZATIONDOCUMENT_VIEW:
          this.document_view = true;
          break;
        case ORGANIZATIONDOCUMENT_CREATE:
          this.document_create = true;
          break;
        case ORGANIZATIONDOCUMENT_UPDATE:
          this.document_update = true;
          break;
        case ORGANIZATIONDOCUMENT_DELETE:
          this.document_delete = true;
          break;
        case ORGANIZATIONDOCUMENT_SIGN:
          this.document_sign = true;
          break;
        case DOCUMENTCATEGORY_VIEW:
          this.documentcategory_view = true;
          break;
      }
    });
  }

  ngOnDestroy() {
    this.mainService.selectedDocumentCategory.next(null);
  }

  getDocumentCategories() {
    if (!this.documentcategory_view) { return; }
    this.dataHandlerService.getDataWithSpinner(DOCUMENT_CATEGORY_PATH_KEY).then(response => {
      // this.documentCategories = response;
      const data = [...response];
      const otherData = {
        description: "TR_OTHER",
        name: "TR_OTHER",
      }
      data.push(otherData);
      this.documentCategories = data;
      const selectedDocumentCategoryId = +localStorage.getItem(SELECTED_CATEGORY_ID_KEY);
      selectedDocumentCategoryId && this.getDocumentsByCategory(selectedDocumentCategoryId);
      localStorage.removeItem(SELECTED_CATEGORY_ID_KEY);
    });
  }

  async getDocumentsByCategory(categoryId: number) {
    if (categoryId) {
      this.selectedCategoryId = categoryId;
      localStorage.setItem(SELECTED_CATEGORY_ID_KEY, categoryId.toString());
      this.selectedDocument = null;
      if (!this.document_view) { return; }
      this.documents = await this.dataHandlerService.getData(`${ORGANIZATION_DOCUMENT_PATH_KEY}?${CATEGORY_ID_PATH_PARAM_KEY}${categoryId}`);
    } else {
      this.router.navigate([this.languageService.currentLanguage, ADMIN_KEY, "document", "file-library"]);
    }
  }

  async getAllDocuments() {
    this.selectedDocument = null;
    localStorage.removeItem(SELECTED_CATEGORY_ID_KEY);
    this.documents = await this.dataHandlerService.getData(ORGANIZATION_DOCUMENT_PATH_KEY);
  }

  openSelectedDocumentView(target: HTMLElement, content, documentData: IDocumentData): void {
    if (target.nodeName === 'TD') {
      if (documentData) {
        this.selectedDocument = documentData;
        this.modalService.open(content, { size: 'lg' }).result;
      }
    }
  }

  confirmDelete(content, deleteDocument: IDocumentData) {
    this.modalService.open(content).result;
    this.deleteDocumentId = deleteDocument.id;
    this.deleteDocumentName = deleteDocument.name;
  }

  deleteDocument(): void {
    if (this.deleteDocumentId && this.document_delete) {
      this.dataHandlerService.deleteData(this.deleteDocumentId, ORGANIZATION_DOCUMENT_PATH_KEY, this.deleteSpinnerId).then(() => {
        this.getDocumentsByCategory(this.selectedCategoryId);
        this.alertService.translateAndAlertMessage(TR_DELETE_MESSAGE_KEY, SUCCESS_KEY);
        this.modalService.dismissAll();
      });
    }
  }
}
