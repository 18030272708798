import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { IGroupData } from 'src/app/Models/IGroupData';
import { IOrganizationData } from 'src/app/Models/IOrganizationData';
import { GROUP_CREATE, GROUP_UPDATE, GROUP_VIEW, ORGANIZATION_VIEW, TR_GROUPS_KEY, userTypes } from 'src/app/Constants/main-keys';
import { ORGANIZATIONS_PATH_KEY, GROUPS_PATH_KEY, GROUPS_BY_ORGANIZATION_ID_PATH_KEY } from 'src/app/Constants/request-path-keys';
import { IUserData } from 'src/app/Models/IUserData';
import { DataStorageService } from 'src/app/Services/data-storage.service';

@Component({
  selector: 'app-group-table',
  templateUrl: './group-table.component.html',
  styleUrls: ['./group-table.component.scss']
})
export class GroupTableComponent implements OnInit {
  currentUserData: IUserData;
  groups: Array<IGroupData> = [];
  groupId: number;
  organizationId: number;
  organizations: Array<IOrganizationData> = [];
  organization_view: boolean;
  group_view: boolean;
  group_create: boolean;
  group_update: boolean;
  isPageLoaded: boolean;
  userRoles: Array<string> = [];
  userTypes = userTypes;

  constructor(
    private dataHandlerService: DataHandlerService,
    private modalService: NgbModal,
    private rolesService: RolesService,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private dataStorageService: DataStorageService,
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.currentUserData = this.dataStorageService.getUser();
    this.setRoles();
    this.translationService.setPageTitle(TR_GROUPS_KEY);

    this.getOrganizations();
    this.route.paramMap.subscribe(params => {
      this.organizationId = +params.get('item.id');
      if(this.organizationId) {
        this.getGroupsByOrganizationId(this.organizationId);
      } else {
        this.getAllGroups(true);
      }
    });
  }

  setRoles() {
    this.userRoles.forEach(item => {
      switch (item) {
        case GROUP_VIEW:
          this.group_view = true;
          break;
        case GROUP_CREATE:
          this.group_create = true;
          break;
        case GROUP_UPDATE:
          this.group_update = true;
          break;
        case ORGANIZATION_VIEW:
          this.organization_view = true;
          break;
      }
    });
  }

  async getOrganizations() {
    if (!this.organization_view) { return; }
    this.organizations = await this.dataHandlerService.getData(ORGANIZATIONS_PATH_KEY);
  }

  getAllGroups(hasSpinner: boolean) {
    if (!this.group_view) { return; }
    (hasSpinner
      ? this.dataHandlerService.getDataWithSpinner(GROUPS_PATH_KEY)
      : this.dataHandlerService.getData(GROUPS_PATH_KEY)).then(response => {
      this.groupId = null;
      this.organizationId = null;
      this.groups = response;
    });
  }

  getGroupsByOrganizationId(organizationId: number) {
    if (!this.group_view) { return; }
    this.dataHandlerService.getData(`${GROUPS_BY_ORGANIZATION_ID_PATH_KEY}${organizationId}`).then(response => this.groups = response);
  }

  getGroupStudents(target: HTMLElement, content, groupId: number): void {
    if(target.nodeName === 'TD') {
      this.groupId = groupId;
      this.modalService.open(content).result;
    }
  }

}
