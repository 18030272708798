import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { guid } from '@progress/kendo-angular-common';
import { ActivatedRoute } from '@angular/router';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { AlertService } from 'src/app/Services/alert.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { CONFIG_PATH_KEY, CONFIG_URL_KEY } from 'src/app/Constants/request-path-keys';
import {
  CONFIG_CREATE,
  CONFIG_UPDATE,
  CONFIG_VIEW,
  SUCCESS_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_CREATE_CONFIG_KEY,
  TR_UPDATE_MESSAGE_KEY,
  TR_UPDATE_CONFIG_KEY
} from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-config-form',
  templateUrl: './config-form.component.html',
  styleUrls: ['./config-form.component.scss']
})
export class ConfigFormComponent implements OnInit {
  configId: number;
  form: FormGroup;
  userRoles: Array<string> = [];
  config_create: boolean;
  config_update: boolean;
  config_view: boolean;
  isInvalidSubmit: boolean;
  isCreateForm: boolean = true;
  submitSpinnerId: string = guid();

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerservice: DataHandlerService,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    public mainService: MainService,
    private translationService: TranslationService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_CREATE_CONFIG_KEY);

    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      value: ['', Validators.required],
      description: [''],
      isdefault: [false],
      isreadonly: [false]
    });

    this.route.paramMap.subscribe(params => {
      this.configId = +params.get('item.id');

      if (this.configId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle(TR_UPDATE_CONFIG_KEY);
        this.getConfigById();
      }
    });
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch (role) {
        case CONFIG_CREATE:
          this.config_create = true;
          break;
        case CONFIG_UPDATE:
          this.config_update = true;
          break;
        case CONFIG_VIEW:
          this.config_view = true;
          break;
      }
    });
  }

  getConfigById(): void {
    if (!this.config_view) { return; }
    this.dataHandlerservice.getDataWithSpinner(`${CONFIG_PATH_KEY}/${this.configId}`).then(response => {
      const configData = response;
      this.form.patchValue({
        name: configData.name,
        value: configData.value,
        description: configData.description,
        isdefault: configData.isDefault,
        isreadonly: configData.isReadonly
      });
    });
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      if(this.configId) {
        form.id = this.configId;
      }
      let formData = new FormData();
      for (let i in form) {
        if (!form[i]) {
          delete form[i];
        } else {
          formData.append(`${i}`, form[i]);
        }
      }
      // form.name = +form.name;
      // form.value = +form.value;
      // form.description = +form.description;
      // form.isdefault = +form.isdefault;
      // form.isreadonly = +form.isreadonly;

      if (this.configId && this.config_update) {
        //form.id = this.configId;
        this.dataHandlerservice.updateFormData(formData, CONFIG_PATH_KEY, this.submitSpinnerId).then(() => {
          this.mainService.goBack(CONFIG_URL_KEY);
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
        });
      } else if (this.config_create) {
        this.dataHandlerservice.createFormData(formData, CONFIG_PATH_KEY, this.submitSpinnerId).then(() => {
          this.mainService.goBack(CONFIG_URL_KEY);
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }
}
