<div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_PAGES' | translate }}</h4>
</div>

<div class="flex flex-wrap">
  <div *ngIf="page_create">
    <button class="mb-2 mr-2 btn btn-success create-btn" [title]="'TR_CREATE_PAGE' | translate"
      [routerLink]="['page-form']">
      <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
    </button>
  </div>

  <div *ngIf="page_view" ngbDropdown>
    <button type="button" ngbDropdownToggle
      class="mb-2 mr-2 btn btn-outline-primary">{{ selectedPageCategoryName ? selectedPageCategoryName : 'TR_PAGES' | translate }}</button>
    <div ngbDropdownMenu>
      <button class="dropdown-item" *ngFor="let item of pageCategories"
        (click)="setSelectedPageCategory(item.name)">{{ item.name }}</button>
    </div>
  </div>

  <div ngbDropdown>
    <button type="button" ngbDropdownToggle
      class="mb-2 mr-2 btn btn-outline-primary">{{ selectedMenuGroupName ? selectedMenuGroupName : 'TR_MENU_GROUPS' | translate }}</button>
    <div ngbDropdownMenu>
      <button class="dropdown-item" (click)="getPagesByType(false)">{{ 'TR_ALL' | translate }}</button>
      <button class="dropdown-item" *ngFor="let item of menuGroups"
        (click)="setSelectedMenuGroup(item.id, item.groupName)">{{ item.groupName }}</button>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12 col-sm-12 col-12">
    <div class="main-card mb-3 card">
      <div class="table-responsive">
        <table class="align-middle mb-0 table table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ 'TR_NAME' | translate }}</th>
              <th class="text-right">{{ 'TR_ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let page of pages let i = index" [class.disabled]="!page.enabled">
              <td>{{ i + 1 }}</td>
              <td class="w-100">
                {{ page.name }}
                <div *ngIf="page.children && page.children.length" class="main-card mb-2 mt-2 card border-none">
                  <div class="table-responsive child-table">
                    <table class="align-middle mb-0 table table-striped table-hover">
                      <tbody>
                        <tr class="pointer" *ngFor="let item of page.children" [class.disabled]="!item.enabled">
                          <td class="w-100">
                            {{ item.name }}
                            <div *ngIf="item.children && item.children.length"
                              class="main-card mb-2 mt-2 card border-none">
                              <div class="table-responsive child-table">
                                <table class="align-middle mb-0 table table-striped table-hover">
                                  <tbody>
                                    <tr *ngFor="let itemChild of item.children">
                                      <td class="w-100">
                                        {{ itemChild.name }}
                                        <div *ngIf="itemChild && itemChild.children.length"
                                          class="main-card mb-2 mt-2 card border-none">
                                          <div class="table-responsive child-table">
                                            <table class="align-middle mb-0 table table-striped table-hover">
                                              <tbody>
                                                <tr *ngFor="let itemSubChild of itemChild.children"
                                                  class="d-flex justify-content-between">
                                                  <td>
                                                    {{ itemSubChild.name }}
                                                  </td>
                                                  <td>
                                                    <div role="group" class="btn-group-sm btn-group">
                                                      <button *ngIf="itemSubChild.enabled && page_update"
                                                        class="mr-1 btn-transition btn btn-outline-info"
                                                        [title]="'TR_UPDATE_PAGE' | translate"
                                                        [routerLink]="['page-form', itemSubChild.id]">
                                                        <i class="pe-7s-note"></i>
                                                      </button>
                                                      <button class="btn-transition btn"
                                                        [ngClass]="itemSubChild.enabled ? 'btn-outline-danger' : 'btn-outline-success'"
                                                        [title]="itemSubChild.enabled ? ('TR_DISABLE' | translate) : ('TR_ENABLE' | translate)"
                                                        (click)="enableDisable(itemSubChild.id, itemSubChild.enabled)">
                                                        <i
                                                          [ngClass]="itemSubChild.enabled ? 'pe-7s-close-circle' : 'pe-7s-check'"></i>
                                                      </button>
                                                      <button *ngIf="page_delete" type="button"
                                                        class="ml-1 btn-transition btn btn-outline-danger"
                                                        [title]="'TR_DELETE' | translate"
                                                        (click)="confirmDelete(deleteContent, itemSubChild)">
                                                        <i class="pe-7s-trash"></i>
                                                      </button>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div role="group" class="btn-group-sm btn-group">
                                          <button *ngIf="itemChild.enabled && page_update"
                                            class="mr-1 btn-transition btn btn-outline-info"
                                            [title]="'TR_UPDATE_PAGE' | translate"
                                            [routerLink]="['page-form', itemChild.id]">
                                            <i class="pe-7s-note"></i>
                                          </button>
                                          <button class="btn-transition btn"
                                            [ngClass]="itemChild.enabled ? 'btn-outline-danger' : 'btn-outline-success'"
                                            [title]="itemChild.enabled ? ('TR_DISABLE' | translate) : ('TR_ENABLE' | translate)"
                                            (click)="enableDisable(itemChild.id, itemChild.enabled)">
                                            <i [ngClass]="itemChild.enabled ? 'pe-7s-close-circle' : 'pe-7s-check'"></i>
                                          </button>
                                          <button *ngIf="page_delete" type="button"
                                            class="ml-1 btn-transition btn btn-outline-danger"
                                            [title]="'TR_DELETE' | translate"
                                            (click)="confirmDelete(deleteContent, itemChild)">
                                            <i class="pe-7s-trash"></i>
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </td>
                          <td class="d-flex justify-content-between">
                            <div role="group" class="btn-group-sm btn-group">
                              <button *ngIf="item.enabled && page_update"
                                class="mr-1 btn-transition btn btn-outline-info" [title]="'TR_UPDATE_PAGE' | translate"
                                [routerLink]="['page-form', item.id]">
                                <i class="pe-7s-note"></i>
                              </button>
                              <button class="btn-transition btn"
                                [ngClass]="item.enabled ? 'btn-outline-danger' : 'btn-outline-success'"
                                [title]="item.enabled ? ('TR_DISABLE' | translate) : ('TR_ENABLE' | translate)"
                                (click)="enableDisable(item.id, item.enabled)">
                                <i [ngClass]="item.enabled ? 'pe-7s-close-circle' : 'pe-7s-check'"></i>
                              </button>
                              <button *ngIf="page_delete" type="button"
                                class="ml-1 btn-transition btn btn-outline-danger" [title]="'TR_DELETE' | translate"
                                (click)="confirmDelete(deleteContent, item)">
                                <i class="pe-7s-trash"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
              <td class="text-right">
                <div role="group" class="btn-group-sm btn-group">
                  <button *ngIf="page.enabled && page_update" class="mr-1 btn-transition btn btn-outline-info"
                    [title]="'TR_UPDATE_PAGE' | translate" [routerLink]="['page-form', page.id]">
                    <i class="pe-7s-note"></i>
                  </button>
                  <button class="btn-transition btn"
                    [ngClass]="page.enabled ? 'btn-outline-danger' : 'btn-outline-success'"
                    [title]="page.enabled ? ('TR_DISABLE' | translate) : ('TR_ENABLE' | translate)"
                    (click)="enableDisable(page.id, page.enabled)">
                    <i [ngClass]="page.enabled ? 'pe-7s-close-circle' : 'pe-7s-check'"></i>
                  </button>
                  <button *ngIf="page_delete" type="button" class="ml-1 btn-transition btn btn-outline-danger"
                    [title]="'TR_DELETE' | translate" (click)="confirmDelete(deleteContent, page)">
                    <i class="pe-7s-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<ng-template #deleteContent let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p>{{ 'TR_DELETE_ERROR' | translate }} <b *ngIf="deletePageName"
        class="word-break-break-word">{{ deletePageName }}</b> ? </p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button [isCurrent]="true" [spinnerId]="userDeleteSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="delete();"></app-action-button>
    <button type="button" class="btn-transition btn btn-danger"
      (click)="c('Close click')">{{ 'TR_NO' | translate }}</button>
  </div>
</ng-template>