import { Injectable } from "@angular/core";
import jwt_decode from 'jwt-decode';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { map } from 'rxjs/operators';
import { DataStorageService } from "./data-storage.service";
import { ILoginData } from "../Models/IUserData";
import { API_URL_IDENTITY_DOMAIN_KEY, API_URL_IDENTITY_RENEW_KEY } from "../Constants/domain-keys";
import { AlertService } from "./alert.service";
import { Router } from "@angular/router";
import { LanguageService } from "./language.service";
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    readonly _userData$ = new BehaviorSubject<ILoginData>(null);
    public menuData = new BehaviorSubject<any>(null);

    get userToken() {
        return this._userData$.getValue();
    }

    constructor(
        private dataStorage: DataStorageService,
        private http: HttpClient,
        private alertService: AlertService,
        private router: Router,
        private languageService: LanguageService,
    ) {
        this.getLoggedInUser();
    }

    signInUser(userData: ILoginData, cb: any) {
        const options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };

        const body = new URLSearchParams();
        body.set('Username', userData.username);
        body.set('Password', userData.password);

        return this.http.post<ILoginData>(API_URL_IDENTITY_DOMAIN_KEY, body.toString(), options)
            .pipe(catchError(error => {
                console.error('Error occurred during sign in:', error);
                cb && cb();
                return throwError('An error occurred during sign in');
            }), map(user => {
                if (user['error']) {
                    return;
                }
                this.setToken(user);
                return user;
            }));
    }

    signInUserByRefreshToken(refreshToken) {
        const formData = new URLSearchParams();
        formData.set('refreshToken', refreshToken);
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http.post(API_URL_IDENTITY_RENEW_KEY, formData.toString(), options)
            .pipe(map(user => {
                if (user['error']) {
                    this.router.navigate([this.languageService.currentLanguage]);
                    return;
                }
                this.setToken(user);
                return user;
            }));
    }

    isTokenExpired(token?: string): boolean {
        if (!token) token = this.userToken['accessToken'];
        if (!token) return true;

        const date = this.getTokenExpirationDate(token);
        if (date === undefined) return false;
        return !(date.valueOf() > new Date().valueOf());
    }

    private getTokenExpirationDate(token: string): Date {
        let decoded = this.getDecodedAccessToken(token);

        if (decoded.exp === undefined) return null;

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        }
        catch (Error) {
            return null;
        }
    }

    setToken(userData): void {
        this.dataStorage.setToken(userData);
        this._userData$.next(userData);
    }

    getLoggedInUser(): void {
        const user = this.dataStorage.getToken();
        this._userData$.next(user);
    }

    logout() {
        this.alertService.translateAndAlertMessage(null, null);
        localStorage.clear();
        this._userData$.next(null);
    }
}
