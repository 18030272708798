import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { IPageContentData } from 'src/app/Models/IPageContentData';
import { AlertService } from 'src/app/Services/alert.service';
import { ILanguagePageContentData } from 'src/app/Models/ILanguageData';
import { LANGUAGES_PATH_KEY, PAGE_CONTENT_PATH_KEY, BY_LANG_PARAM_KEY } from 'src/app/Constants/request-path-keys';
import {
  PAGE_CONTENT_CREATE,
  PAGE_CONTENT_DELETE,
  PAGE_CONTENT_UPDATE,
  PAGE_CONTENT_VIEW,
  SUCCESS_KEY,
  TR_DELETE_MESSAGE_KEY,
  TR_PAGE_CONTENT_KEY
} from 'src/app/Constants/main-keys';
import { guid } from '@progress/kendo-angular-common';

@Component({
  selector: 'app-page-content-table',
  templateUrl: './page-content-table.component.html',
  styleUrls: ['./page-content-table.component.scss']
})
export class PageContentTableComponent implements OnInit {
  deletePageContentId: number;
  deletePageContentName: string;
  pageContents: Array<IPageContentData> = [];
  languages: Array<ILanguagePageContentData> = [];
  userRoles: Array<string> = [];
  content_view: boolean;
  content_create: boolean;
  content_update: boolean;
  content_delete: boolean;
  deleteSpinnerId: string = guid();

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private translationService: TranslationService,
    private modalService: NgbModal,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_PAGE_CONTENT_KEY);

    this.getLanguages();
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch (role) {
        case PAGE_CONTENT_VIEW:
          this.content_view = true;
          break;
        case PAGE_CONTENT_CREATE:
          this.content_create = true;
          break;
        case PAGE_CONTENT_UPDATE:
          this.content_update = true;
          break;
        case PAGE_CONTENT_DELETE:
          this.content_delete = true;
          break;
      }
    });
  }

  getLanguages(): void {
    this.dataHandlerService.getDataWithSpinner(LANGUAGES_PATH_KEY).then(response => {
      // this.languages = response;
      this.languages = response.splice(0, 2);
      this.GetPageContentData();
    });
  }

  GetPageContentData(): void {
    this.languages.forEach(item => {
      this.dataHandlerService.getDataWithSpinner(`${PAGE_CONTENT_PATH_KEY}/${BY_LANG_PARAM_KEY}/${item.languageId}`).then(response => {
        item.content = response;
      });
    });
  }

  confirmDelete(content: any, id: number, name: string): void {
    this.modalService.open(content).result;
    this.deletePageContentId = id;
    this.deletePageContentName = name;
  }

  delete(): void {
    if (!this.content_delete) { return; }
    this.dataHandlerService.deleteData(this.deletePageContentId, PAGE_CONTENT_PATH_KEY, this.deleteSpinnerId).then(() => {
      this.alertService.translateAndAlertMessage(TR_DELETE_MESSAGE_KEY, SUCCESS_KEY);
      this.GetPageContentData();
      this.modalService.dismissAll();
    }).catch(() => this.modalService.dismissAll());
  }

}
