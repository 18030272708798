<div class="relative-container">
  <div
    id="Mindalay_document"
    *ngIf="templateData && templateData.body && openView"
    class="template-container"
    (input)="setFormValues($event)"
    (focusout)="focusOut($event)"
    (click)="buttonClick($event)"
    [innerHTML]="templateData.body | sanitizeHtml"
  >
  </div>
  <button type="button" class="close" aria-label="Close" (click)="closeEmit.emit(null)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
