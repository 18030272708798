import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { USER_KEY } from 'src/app/Constants/main-keys';
import { IUserData } from 'src/app/Models/IUserData';
import { AlertService } from 'src/app/Services/alert.service';
import { AuthService } from 'src/app/Services/auth.service';
import { LanguageService } from 'src/app/Services/language.service';
import {ThemeOptions} from '../../../../../theme-options';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
  styleUrls: ['./user-box.component.scss']
})
export class UserBoxComponent implements OnInit {
  user: IUserData = JSON.parse(localStorage.getItem (USER_KEY));

  constructor(
    public globals: ThemeOptions,
    private authService: AuthService,
    private router: Router,
    private languageService: LanguageService,
    private alertService: AlertService,
  ) {
  }

  ngOnInit() { }

  signOut() {
    this.authService.logout();
    this.alertService.clear();
    this.router.navigate([this.languageService.currentLanguage]);
  }

}
