<div class="modal-header modal-list-header">
  <h5 class="modal-title">{{ 'TR_FILE_LIBRARY' | translate }}</h5>
  <button type="button" class="close pb-0" aria-label="Close" (click)="closeEmit.emit(null)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-list">
  <div class="list-row">
    <label for="getAttachedFiles" class="mr-2 pointer font-500">{{ 'TR_GET_ATTACHED_FILES' | translate }}</label>
    <input
      id="getAttachedFiles"
      name="getAttachedFiles"
      type="checkbox"
      class="mt-2 pointer float-right"
      [(ngModel)]="getAttachedFiles"
    />
  </div>
  <div class="list-row" *ngFor="let file of files">
    <label [htmlFor]="file.id" class="mr-2 pointer">{{ file.name }}</label>
    <input
      [id]="file.id"
      [name]="file.id"
      type="checkbox"
      class="mt-2 pointer float-right"
      [(ngModel)]="file.isBelongToCurrentTopic"
      (ngModelChange)="attachFile(file.isBelongToCurrentTopic, file.id)"
    />
  </div>
</div>
<!-- <div *ngIf="!isModalLoaded" class="modal-body min-modal">
  <app-page-loader [isModalLoader]="true"></app-page-loader>
</div> -->