<div *ngIf="org_contract_create || org_contract_update" class="main-card mb-3 card">
  <div class="card-body">
    <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('organization')"></i>
      <h5 class="card-title">{{ !contractId ? ('TR_CREATE_CONTRACT' | translate) : ('TR_UPDATE_CONTRACT' | translate)
        }}</h5>
    </div>

    <div>
      <div class="row">
        <div class="form-outline flex-wrap w-100">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div ngbDropdown>
              <div class="flex flex-column">
                <label class="mb-1">{{ ('TR_CHOOSE_CURRENCY' | translate) + mainService.asterisk}}</label>
                <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary"
                  [class.error-border]="isInvalidSubmit && !selectedCurrencyId" [disabled]="contractId ? true : false">
                  {{ selectedCurrencyName ? selectedCurrencyName : ('TR_CHOOSE_CURRENCY' | translate) +
                  mainService.asterisk}}
                </button>
              </div>
              <div ngbDropdownMenu>
                <button class="dropdown-item" *ngFor="let item of currencies"
                  (click)="setCurrency(item.id, item.isocode)">
                  {{ item.isocode }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column mt-2">
      <label for="isFeeByPercent" class=" pointer">
        <input type="radio" name="feeBy" id="isFeeByPercent" [checked]="isFeeByPercent ? true : false" class="mr-1"
          (change)="onRadioChange($event, 'isFeeByPercent')">
        {{ 'TR_FEE_BY_PERCENT' | translate }}
      </label>
      <label for="isFeeByFixPrice" class="pointer">
        <input type="radio" name="feeBy" id="isFeeByFixPrice" [checked]="isFeeByFixPrice ? true : false" class="mr-1"
          (change)="onRadioChange($event, 'isFeeByFixPrice')">
        {{ 'TR_FEE_BY_FIX_PRICE' | translate }}
      </label>
    </div>

    <form (submit)="submitData()" [formGroup]="form" class="mt-2">

      <div *ngIf="isFeeByPercent ? true : false" class="form-row">
        <div class="col-lg-4 col-md-6">
          <div class="position-relative form-group">
            <label for="feeByFixPrice">{{ ('TR_FEE_BY_PERCENT' | translate)+
              mainService.asterisk }}</label>
            <input name="feeByPercent" [placeholder]="('TR_FEE_BY_PERCENT' | translate)+
            mainService.asterisk" type="number" min="1" max="100"
              class="form-control"
              [class.error-border]="isInvalidSubmit && (!form.value.feeByPercent && !form.value.feeByFixPrice)"
              autocomplete="off" formControlName="feeByPercent" />
          </div>
        </div>
      </div>

      <div class="form-row">
        <div *ngIf="isFeeByFixPrice ? true : false" class="col-lg-4 col-md-6">
          <div class="position-relative form-group">
            <label for="feeByFixPrice">{{ ('TR_FEE_BY_FIX_PRICE' | translate)+
              mainService.asterisk }}</label>
            <input name="feeByFixPrice" [placeholder]="('TR_FEE_BY_FIX_PRICE' | translate)+
            mainService.asterisk" type="number" min="1"
              max="100000" class="form-control"
              [class.error-border]="isInvalidSubmit && (!form.value.feeByPercent && !form.value.feeByFixPrice)"
              autocomplete="off" formControlName="feeByFixPrice" />
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-lg-4 col-md-6">
          <div class="position-relative form-group">
            <label for="endDate">{{ ('TR_END_DATE' | translate) +
              mainService.asterisk }}</label>
            <input type="date" name="endDate" [placeholder]="'TR_END_DATE' | translate" class="form-control"
              [class.error-border]="isInvalidSubmit && !form.value.endDate" autocomplete="off" [min]="today"
              formControlName="endDate" />
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-lg-4 col-md-6">
          <div class="position-relative form-group">
            <label for="routingNumber">{{ 'TR_ROUTING_NUMBER' | translate }}</label>
            <input type="text" name="routingNumber" [placeholder]="'TR_ROUTING_NUMBER' | translate" class="form-control"
              [class.error-border]="isInvalidSubmit && form.value.routingNumber.trim().length > fieldsMaxLength"
              autocomplete="off" [min]="today" formControlName="routingNumber" />
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="position-relative form-group">
            <label for="accountNumber">{{ 'TR_ACCOUNT_NUMBER' | translate }}</label>
            <input type="text" name="accountNumber" [placeholder]="'TR_ACCOUNT_NUMBER' | translate" class="form-control"
              [class.error-border]="isInvalidSubmit && form.value.accountNumber.trim().length > fieldsMaxLength"
              autocomplete="off" [min]="today" formControlName="accountNumber" />
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-lg-4 col-md-6">
          <div class="position-relative form-group">
            <label for="bankName">{{ 'TR_BANK_NAME' | translate }}</label>
            <input type="text" name="bankName" [placeholder]="'TR_BANK_NAME' | translate" class="form-control"
              [class.error-border]="isInvalidSubmit && form.value.bankName.trim().length > fieldsMaxLength"
              autocomplete="off" [min]="today" formControlName="bankName" />
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="position-relative form-group">
            <label for="swiftCode">{{ 'TR_SWIFT_CODE' | translate }}</label>
            <input type="text" name="swiftCode" [placeholder]="'TR_SWIFT_CODE' | translate" class="form-control"
              [class.error-border]="isInvalidSubmit && form.value.swiftCode.trim().length > fieldsMaxLength"
              autocomplete="off" [min]="today" formControlName="swiftCode" />
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-lg-4 col-md-6">
          <div class="position-relative form-group">
            <label for="address">{{ 'TR_ADDRESS' | translate }}</label>
            <input type="text" name="address" [placeholder]="'TR_ADDRESS' | translate" class="form-control"
              [class.error-border]="isInvalidSubmit && form.value.address.trim().length > fieldsMaxLength"
              autocomplete="off" [min]="today" formControlName="address" />
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="position-relative form-group">
            <label for="receiverName">{{ 'TR_RECEIVER_NAME' | translate }}</label>
            <input type="text" name="receiverName" [placeholder]="'TR_RECEIVER_NAME' | translate" class="form-control"
              [class.error-border]="isInvalidSubmit && form.value.receiverName.trim().length > fieldsMaxLength"
              autocomplete="off" [min]="today" formControlName="receiverName" />
          </div>
        </div>
      </div>

      <app-submit-button [spinnerId]="submitSpinnerId"
        [buttonName]="!contractId ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit" [isFormValid]="form.valid"></app-submit-button>
    </form>
  </div>
</div>

<!-- form.routingNumber = form.routingNumber ? form.routingNumber : null;
form.accountNumber = form.accountNumber ? form.accountNumber : null;
form.bankName = form.bankName ? form.bankName : null;
form.swiftCode = form.swiftCode ? form.swiftCode : null;
form.address = form.address ? form.address : null;
form.receiverName = form.receiverName ? form.receiverName : null; -->