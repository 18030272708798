import { Component, OnInit } from '@angular/core';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { ILanguageData } from 'src/app/Models/ILanguageData';
import { LANGUAGES_PATH_KEY } from 'src/app/Constants/request-path-keys';
import { LANGUAGE_CREATE, LANGUAGE_UPDATE, TR_LANGUAGES_KEY } from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-language-table',
  templateUrl: './language-table.component.html',
  styleUrls: ['./language-table.component.scss']
})
export class LanguageTableComponent implements OnInit {
  language_create: boolean;
  language_view: boolean;
  language_update: boolean;
  userRoles: Array<string> = [];
  languages: Array<ILanguageData> = [];

  constructor(
    private dataHendlerService: DataHandlerService,
    private rolesService: RolesService,
    private translationService: TranslationService,
    private mainService: MainService,
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_LANGUAGES_KEY);

    this.getLanguages();
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch(role) {
        case LANGUAGE_CREATE:
          this.language_create = true;
          break;
        case LANGUAGE_UPDATE:
          this.language_update = true;
          break;
      }
    });
  }

  getLanguages() {
    this.dataHendlerService.getDataWithSpinner(LANGUAGES_PATH_KEY).then(response => {
      // this.languages = response;
      this.languages = response.splice(0, 2);
    });
  }
}
