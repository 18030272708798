import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { startWith, tap, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Services/auth.service';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { DataStorageService } from 'src/app/Services/data-storage.service';
import { LanguageService } from 'src/app/Services/language.service';
import { MainService } from 'src/app/Services/main.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { MENU_KEY, TOKEN_KEY, TR_LOGIN_KEY } from 'src/app/Constants/main-keys';
import { PAGE_STRUCT_PATH_KEY, USER_CURRENT_PATH_KEY } from 'src/app/Constants/request-path-keys';
import { guid } from "@progress/kendo-angular-common";

@Component({
  selector: 'app-login-boxed',
  templateUrl: './login-boxed.component.html',
  styleUrls: ['./login-boxed.component.scss']
})
export class LoginBoxedComponent implements OnInit {
  form: FormGroup;
  username: string;
  errorMessage: string;
  currentYear: any;
  submitSpinnerId: string = guid();
  isDisabledSignButton: boolean = false;
  currentLanguageIsoCode: string;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private dataHandlerService: DataHandlerService,
    private dataStorageService: DataStorageService,
    public languageService: LanguageService,
    private mainService: MainService,
    private translationService: TranslationService,
  ) {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.currentYear = new Date().getFullYear()
  }

  ngOnInit() {
    this.translationService.setPageTitle(TR_LOGIN_KEY);
    this.isDisabledSignButton = false;
    // this.translationService.isPageLoading.pipe(
    //   startWith(null),
    //   delay(0),
    //   tap(isLoading => this.isPageLoaded = !isLoading)
    // ).subscribe();
  }

  extractButtonSpiner = () => {
    this.mainService.removeButtonSpinner(this.submitSpinnerId);
    this.isDisabledSignButton = false;
  }

  signIn() {
    if (!this.form.valid || this.isDisabledSignButton) { return; }
    this.mainService.addButtonSpinner(this.submitSpinnerId);
    this.isDisabledSignButton = true;
    this.authService.signInUser(this.form.value, this.extractButtonSpiner).subscribe(data => {
      if (data) {
        this.dataHandlerService.getData(USER_CURRENT_PATH_KEY).then(response => {
          if (response) {
            const data = { ...response };
            if (data.isAdmin && !data.isOrganizationActive) {
              let token = this.dataStorageService.getToken();
              let refreshTokenLink = `https://${window.location.hostname.replace("admin.", "")}/${this.languageService.currentLanguage}/authentication/${token.refreshToken}`;
              window.location.href = refreshTokenLink;
              localStorage.removeItem(TOKEN_KEY);
            } else {
              this.dataStorageService.setUser(data);
              this.dataHandlerService.getData(`${PAGE_STRUCT_PATH_KEY}${false}`).then(response => {
                const menuData = response;
                localStorage.setItem(MENU_KEY, JSON.stringify(menuData));
                this.router.navigate(['/']);
              });
            }
          }
          this.extractButtonSpiner();
        }).catch((e) => {
          this.extractButtonSpiner()
          this.errorMessage = e;
          this.authService.setToken(null);
          localStorage.clear();
        });
      } else {
        this.extractButtonSpiner()
        this.errorMessage = 'Incorrect username or password';
      }
    });
  }

}
