<ng-container *ngIf="student_view">
  <div class="modal-header">
    <div *ngIf="student" class="absolute-container">
      <div class="modal-image" [ngStyle]="{ 'background-image' : 'url(' + student.avatarFilePath + ')' }"></div>
      <p class="modal-title">{{ student.studentName }}</p>
    </div>
    <button type="button" class="close mb-2" aria-label="Close" (click)="closeEmit.emit(null)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0">
    <app-modal-loader [modalSpinners]="[studentSpinnerId]"></app-modal-loader>
    <div *ngIf="student" class="p-4">
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{ 'TR_DOCUMENT_NUMBER' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right word-break-break-word">{{ student.personalDocumentNumber }}</span>
        </div>
      </div>
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{ 'TR_USERNAME' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right word-break-break-word">{{ student.username }}</span>
        </div>
      </div>
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{ 'TR_GROUP_NAME' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right word-break-break-word">{{ student.groupName }}</span>
        </div>
      </div>
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{ 'TR_ORGANIZATION' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right word-break-break-word">{{ student.organizationName }}</span>
        </div>
      </div>
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{ 'TR_EMAIL' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right word-break-break-word">{{ student.email }}</span>
        </div>
      </div>
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{ 'TR_PHONE' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right word-break-break-word">{{ student.phone }}</span>
        </div>
      </div>
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{ 'TR_MOBILEPHONE' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right word-break-break-word">{{ student.mobilePhone }}</span>
        </div>
      </div>
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{ 'TR_DOB' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right word-break-break-word">{{ student.dob | date }}</span>
        </div>
      </div>
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{ 'TR_ADDRESSRESIDENCE' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right word-break-break-word">{{ student.addressResidence }}</span>
        </div>
      </div>
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{ 'TR_ADDRESSREGISTRATION' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right word-break-break-word">{{ student.addressRegistration }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- <div *ngIf="!isModalLoaded" class="modal-body min-modal">
  <app-page-loader [isModalLoader]="true"></app-page-loader>
</div> -->