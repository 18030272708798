<div [formGroup]="form">
  <label for="trueVal" class="mr-4">
    <input type="radio" name="correctAnswer" id="trueVal" [value]="true" class="mr-1" formControlName="correctAnswer">
    {{ 'TR_TRUE' | translate }}
  </label>
  <label for="falseVal">
    <input type="radio" name="correctAnswer" id="falseVal" [value]="false" class="mr-1" formControlName="correctAnswer">
    {{ 'TR_FALSE' | translate }}
  </label>
</div>
