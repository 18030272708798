import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { AlertService } from 'src/app/Services/alert.service';
import { DataStorageService } from 'src/app/Services/data-storage.service';
import { LanguageService } from 'src/app/Services/language.service';
import { IFileLibraryData } from 'src/app/Models/IFileLibraryData';
import { ICourseData, ICourseSectionData, ICourseTopicData } from 'src/app/Models/ICourseData';
import { ILanguageData } from 'src/app/Models/ILanguageData';
import { IQuizData } from 'src/app/Models/IQuizModel';
import { ADMIN_KEY, SUCCESS_KEY, TR_UPDATE_MESSAGE_KEY, COURSE_LEVELS } from 'src/app/Constants/main-keys';
import {
  COURSE_PATH_KEY,
  COURSE_TOPIC_FILE_PATH_KEY,
  COURSE_URL_KEY,
  COURSE_VIEW_URL_KEY,
  DENY_VERIFICATION_PATH_KEY,
  LANGUAGE_PATH_KEY,
  QUIZ_PATH_KEY,
  TOPIC_ID_PATH_KEY,
  VERIFY_PATH_KEY
} from 'src/app/Constants/request-path-keys';

@Component({
  selector: 'app-course-view',
  templateUrl: './course-view.component.html',
  styleUrls: ['./course-view.component.scss'],
})
export class CourseViewComponent implements OnInit {
  denyMessage: string;
  isDenyMessageError: boolean;
  isPageLoaded: boolean;
  isViewMode: boolean = true;
  courseId: number;
  deleteSectionId: number;
  deleteSectionName: string;
  deleteTopicId: number;
  deleteTopicName: string;
  fileLibraryTopicId: number;
  fileLibraryTopicName: string;
  settingsTopicId: number;
  settingsTopicName: string;
  selectedTopicInfo: ICourseTopicData;
  courseData: ICourseData;
  selectedTopicFile: any;
  userRoles: Array<string> = [];
  attachedFiles: Array<IFileLibraryData> = [];
  courseQuizzes: Array<IQuizData> = [];
  topicQuizzes: Array<IQuizData> = [];
  languages: Array<ILanguageData> = [];
  courseLevels: Array<string> = COURSE_LEVELS;
  courseBreadCrumb: object = this.dataStorageService.getCourseBreadCrumb();

  constructor(
    private dataHandlerService: DataHandlerService,
    private dataStorageService: DataStorageService,
    private rolesService: RolesService,
    public mainService: MainService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private alertService: AlertService,
    private router: Router,
    private languageService: LanguageService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.getLanguages();

    this.route.paramMap.subscribe(params => {
      this.courseId = +params.get('item.id');
      if (this.courseId) {
        this.getCourseData();
        this.getQuizzes(0, this.courseId);
      }
    });
  }

  async getLanguages() {
    var allLanguages = await this.dataHandlerService.getData(LANGUAGE_PATH_KEY);
    this.languages = allLanguages.splice(0, 2);
  }

  getCourseData(): void {
    this.dataHandlerService.getDataWithSpinner(`${COURSE_PATH_KEY}/${ADMIN_KEY}/${this.courseId}`).then(response => {
      this.courseData = response;
      this.courseData.keywordsValues = Object.values(JSON.parse(this.courseData.keywords));
      this.courseData.whatYouWillLearnValues = Object.values(JSON.parse(this.courseData.whatYouWillLearn));
      this.courseData.requirementsValues = Object.values(JSON.parse(this.courseData.requirements));
      this.courseData.targetStudentsValues = Object.values(JSON.parse(this.courseData.targetStudents));
      this.courseData.languageName = this.languages?.find(language => language.languageId === this.courseData.languageId)?.name;
    });
  }

  async getFileLibraryByTopic() {
    if (!this.selectedTopicInfo.id) { return; }
    this.attachedFiles = await this.dataHandlerService.getData(`${COURSE_TOPIC_FILE_PATH_KEY}/${TOPIC_ID_PATH_KEY}/${this.selectedTopicInfo.id}/${false}`);
  }

  getQuizzes(isTopic: number, id: number) {
    this.dataHandlerService.getData(`${QUIZ_PATH_KEY}?entitytype=${isTopic}&entityId=${id}`).then(response => {
      if (isTopic) {
        this.topicQuizzes = response;
      } else {
        this.courseQuizzes = response;
      }
    });
  }

  confirmAction(content): void {
    this.modalService.open(content).result;
  }

  toggleCourseVerification(): void {
    if (!this.courseId) { return; }
    this.dataHandlerService.updateDataByQuery(this.courseId, `${COURSE_PATH_KEY}/${VERIFY_PATH_KEY}/`).then(() => {
      this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
      this.courseData.verified = !this.courseData.verified;
      this.modalService.dismissAll();
    });
  }

  toggleCourseVerificationDeny(): void {
    if (!this.courseId) { return; }
    if (!this.denyMessage) { this.isDenyMessageError = true; return; }
    const form = {
      id: this.courseId,
      verificationDenialMessage: this.denyMessage
    };
    this.dataHandlerService.updateData(form, `${COURSE_PATH_KEY}/${DENY_VERIFICATION_PATH_KEY}`, null).then(() => {
      this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
      this.courseData.verificationDenied = !this.courseData.verificationDenied;
      this.modalService.dismissAll();
      this.denyMessage = '';
      this.isDenyMessageError = false;
    })
  }

  openTopicInfo(topicId: number, sectionId: number): void {
    const selectedSection: ICourseSectionData = this.courseData.sections.find(data => data.id === sectionId);
    if (selectedSection) {
      this.selectedTopicInfo = selectedSection.topics.find(data => data.id === topicId);
    }
    this.getFileLibraryByTopic();
    this.getQuizzes(1, topicId);
    this.setSelectedTopic(topicId);
  }

  setSelectedSection(sectionId: number): void {
    this.courseBreadCrumb["sectionId"] = sectionId;
    this.dataStorageService.setCourseBreadCrumb(this.courseBreadCrumb);
  }

  removeSelectedSection(sectionId) {
    if(sectionId !== this.courseBreadCrumb['sectionId']) { return; }
    this.courseBreadCrumb['sectionId'] = null;
    this.dataStorageService.setCourseBreadCrumb(this.courseBreadCrumb);
  }

  setSelectedTopic(topicId: number): void {
    this.courseBreadCrumb["topicId"] = topicId;
    this.dataStorageService.setCourseBreadCrumb(this.courseBreadCrumb);
  }

  removeSelectedTopic(topicId) {
    if(topicId !== this.courseBreadCrumb['topicId']) { return; }
    this.courseBreadCrumb['topicId'] = null;
    this.dataStorageService.setCourseBreadCrumb(this.courseBreadCrumb);
  }

  openFile(file: any, content): void {
    this.selectedTopicFile = file;
    this.modalService.open(content).result;
  }

  openQuizView(id: number): void {
    this.router.navigate([this.languageService.currentLanguage, ADMIN_KEY, COURSE_URL_KEY, COURSE_VIEW_URL_KEY, this.courseId, 'quiz', id]);
  }

  convertStrToArray = (str) => {
    str = JSON.parse(str);
    const keys = Object.keys(str);
    const values = Object.values(str);
    return keys.map((key, index) => ({ [key]: values[index] }));
  }

}
