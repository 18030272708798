<div class="main-card mb-3 card">
    <div class="card-body">
      <div class="title-block mb-3">
        <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('config')"></i>
        <h5 class="card-title">{{ isCreateForm ? ('TR_CREATE_CONFIG' | translate) : ('TR_UPDATE_CONFIG' | translate) }}</h5> 
      </div>
      <form (submit)="submitData()" [formGroup]="form">
        <div class="form-row">
          <div class="col-lg-4 col-md-4">
            <div class="position-relative form-group">
              <label for="name">{{ ('TR_NAME' | translate) + mainService.asterisk }}</label>
              <input
                name="name"
                [placeholder]="('TR_NAME' | translate) + mainService.asterisk"
                type="text"
                class="form-control"
                [class.error-border]="isInvalidSubmit && !form.value.name"
                autocomplete="off"
                formControlName="name"
              />
            </div>
          </div>
        </div>
        <div class="form-row">
            <div class="col-lg-4 col-md-4">
              <div class="position-relative form-group">
                <label for="value">{{('TR_VALUE' | translate) + mainService.asterisk}}</label>
                <input
                  name="value"
                  [placeholder]="('TR_VALUE' | translate) + mainService.asterisk"
                  type="text"
                  class="form-control"
                  [class.error-border]="isInvalidSubmit && !form.value.value"
                  autocomplete="off"
                  formControlName="value"
                />
              </div>
            </div>
          </div>
        <div class="form-row">
          <div class="col-lg-4 col-md-4">
            <div class="position-relative form-group">
              <label for="description">{{ 'TR_DESCRIPTION' | translate }}</label>
              <input
                name="description"
                [placeholder]="'TR_DESCRIPTION' | translate"
                type="text"
                class="form-control"
                autocomplete="off"
                formControlName="description"
              />
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6 mt-1 mb-2 checkboxes-block">
            <div class="position-relative form-check">
              <input name="isDefault" id="isDefault" type="checkbox" class="form-check-input" formControlName="isdefault">
              <label for="isDefault" class="form-check-label">{{ 'TR_IS_DEFAULT' | translate }}</label>
            </div>
            <div class="position-relative form-check">
              <input name="isReadonly" id="isReadonly" type="checkbox" class="form-check-input" formControlName="isreadonly">
              <label for="isReadonly" class="form-check-label">{{ 'TR_IS_READONLY' | translate }}</label>
            </div>
          </div>
        </div>
        <app-submit-button
          [spinnerId]="submitSpinnerId"
          [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
          [isInvalidSubmit]="isInvalidSubmit"
          [isFormValid]="form.valid"
        ></app-submit-button>
      </form>
    </div>
  </div>