import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IQuestionData } from 'src/app/Models/IQuestionModel';
import { MainService } from 'src/app/Services/main.service';

@Component({
  selector: 'app-true-false-answer',
  templateUrl: './true-false-answer.component.html',
  styleUrls: ['./true-false-answer.component.scss']
})
export class TrueFalseAnswerComponent implements OnInit, OnChanges {
  form: FormGroup;

  @Input() questionData: IQuestionData;
  @Output() submitEmmiter = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private mainService: MainService,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      correctAnswer: [''],
    });

   this.setUpdateQuestionData();

    this.mainService.questionSubmitted.subscribe(data => {
      if (data && this.form.valid) {
        const form = { ...this.form.value };
        form.correctAnswer = Boolean(form.correctAnswer);
        if(typeof form.correctAnswer !== undefined) {
          this.submitEmmiter.emit(form);
        }
      }
    });
  }

  ngOnChanges() {
    this.setUpdateQuestionData();
  }

  setUpdateQuestionData(): void {
    if(this.questionData && this.form) {
      this.form.patchValue({
        correctAnswer: this.questionData.correctAnswer,
      });
    }
  }

}
