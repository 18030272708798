import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { filter } from 'rxjs/operators';
import { ERROR_KEY, SUCCESS_KEY } from "../Constants/main-keys";
import { IAlertModel, AlertType } from '../Models/IAlertModel';
import { TranslationService } from "./translation.service";

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private subject = new Subject<IAlertModel>();
  private defaultId = 'default-alert';
  isShowAlert: boolean = false;

  constructor(
    private translationService: TranslationService
  ) { }

  onAlert(id = this.defaultId): Observable<IAlertModel> {
    return this.subject.asObservable().pipe(filter(x => x && x.id === id));
  }

  success(message: string, options?: any) {
    if (this.isShowAlert) {
      this.alert(new IAlertModel({ ...options, type: AlertType.Success, message }));
      this.isShowAlert = false;
    }
  }

  error(message: string, options?: any) {
    if (this.isShowAlert) {
      this.alert(new IAlertModel({ ...options, type: AlertType.Error, message }));
      this.isShowAlert = false;
    }
  }

  alert(alert: IAlertModel) {
    alert.id = alert.id || this.defaultId;
    this.subject.next(alert);
  }

  clear(id = this.defaultId) {
    this.subject.next(new IAlertModel({ id }));
  }

  translateAndAlertMessage(message: string, type: string) {
    this.isShowAlert = true;
    switch (type) {
      case SUCCESS_KEY:
        (this.translationService.translateDefaults(message)).subscribe(translatedMessage => {
          if (translatedMessage) {
            this.success(translatedMessage ? translatedMessage : message, { autoClose: true })
          } else {
            this.translationService.translate(message).subscribe(translatedMessage => this.success(translatedMessage ? translatedMessage : message, { autoClose: true }))
          }
        });
        break;
      case ERROR_KEY:
        // this.translationService.translate(message).subscribe(translatedMessage => this.error(translatedMessage ? translatedMessage : message, { autoClose: true }));
        this.translationService.translate(message).subscribe(translatedMessage => {
          if (translatedMessage) {
            this.error(translatedMessage, { autoClose: true })
          } else {
            this.translationService.translateExeption(message).subscribe(translatedMsg => {
              if (translatedMsg) {
                this.error(translatedMsg, { autoClose: true })
              } else {
                this.error(message, { autoClose: true })
              }
            })
          }
        }
        );
        break;
    }
  }
}
