import { Injectable } from "@angular/core";
import { TOKEN_KEY, USER_KEY, COURSE_BREAD_CRUMB_KEY } from '../Constants/main-keys';

@Injectable({
    providedIn: 'root'
})
export class DataStorageService {
    setUser(data): void {
        localStorage.setItem(USER_KEY, JSON.stringify(data));
    }

    getUser() {
        const data = JSON.parse(localStorage.getItem(USER_KEY));
        return data || null;
    }

    setToken(userData): void {
        localStorage.setItem(TOKEN_KEY, JSON.stringify(userData));
    }

    getToken() {
        const data = JSON.parse(localStorage.getItem(TOKEN_KEY));
        return data || null;
    }

    setCourseBreadCrumb(data) {
        localStorage.setItem(COURSE_BREAD_CRUMB_KEY, JSON.stringify(data));
    }

    getCourseBreadCrumb() {
        const data = JSON.parse(localStorage.getItem(COURSE_BREAD_CRUMB_KEY));
        return data || { sectionId: null, topicId: null };
    }

    removeCourseBreadCrumb() {
        localStorage.removeItem(COURSE_BREAD_CRUMB_KEY);
    }
 }