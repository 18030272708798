import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { guid } from '@progress/kendo-angular-common';
import { AlertService } from 'src/app/Services/alert.service';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { ILanguageLibrary } from 'src/app/Models/ILanguageData';
import { ILibraryData } from 'src/app/Models/ILibraryData';
import { LIBRARY_PATH_KEY } from 'src/app/Constants/request-path-keys';
import { LIBRARY_CREATE, LIBRARY_UPDATE, SUCCESS_KEY, TR_CREATE_MESSAGE_KEY, TR_UPDATE_MESSAGE_KEY } from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-library-form',
  templateUrl: './library-form.component.html',
  styleUrls: ['./library-form.component.scss']
})
export class LibraryFormComponent implements OnInit {
  library_update: boolean;
  library_create: boolean;

  isInvalidSubmit: boolean;
  form: FormGroup;
  submitSpinnerId: string = guid();
  libraryTypes: Array<string> = ['Text', 'Single list', 'Multilist', 'Radio list', 'Checkbox list'];
  libraries: Array<ILibraryData> = [];
  userRoles: Array<string> = [];

  @Input() library: ILibraryData;
  @Input() languages: Array<ILanguageLibrary>;
  @Input() languageIsocode: string;

  @Output() submitSuccess = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    public mainService: MainService,
    private dataHandlerService: DataHandlerService,
    private alertService: AlertService,
    private rolesService: RolesService
  ) { }

  ngOnInit(): void {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();

    const currentLanguage = this.languages.find(data => data.isocode2 === this.languageIsocode);
    this.libraries = currentLanguage ? currentLanguage.content : [];

    this.form = this.formBuilder.group({
      languageId: [(currentLanguage && currentLanguage.languageId) || null, Validators.required],
      type: ['', Validators.required],
      name: ['', Validators.required],
      displayString: ['', Validators.required],
      parentId: [null],
    });

    if(this.library) {
      this.form.patchValue({
        languageId: this.library.languageId,
        type: this.library.type,
        parentId: this.library.parentId,
        name: this.library.name,
        displayString: this.library.displayString,
      });
    }
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch (role) {
        case LIBRARY_CREATE:
          this.library_create = true;
          break;
        case LIBRARY_UPDATE:
          this.library_update = true;
          break;
      }
    });
  }

  submitData(): void {
    if(this.form.valid) {
      const form = { ...this.form.value };
      form.languageId = +form.languageId;
      form.parentId = +form.parentId || null;
      form.type = +form.type;

      if (this.library && this.library.id && this.library_update) {
        form.id = +this.library.id;
        this.dataHandlerService.updateData(form, LIBRARY_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.submitSuccess.emit();
        });
      } else if(this.library_create) {
        this.dataHandlerService.createData(form, LIBRARY_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.submitSuccess.emit();
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }

}
