<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon"><i class="icon pe-7s-global icon-gradient bg-plum-plate"></i></div>
      <div>{{ 'TR_TRANSLATIONS' | translate }}</div>
    </div>
    <!-- <div class="page-title-actions d-flex"><button *ngIf="translation_create" class="mr-2 btn btn-success create-btn"
        [title]="'TR_CREATE' | translate" (click)="create(formmodal)">
        <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
      </button>
    </div> -->
  </div>
</div>
<ng-container *ngIf="translation_view">
  <!-- <div class="title-block mb-3">
    <h4 class="card-title">{{ 'TR_TRANSLATIONS' | translate }}</h4>
  </div> -->

  <div class="row">
    <div class="col-lg-3 col-md-3 col-12">
      <button *ngIf="translation_create" class="mb-2 mr-2 btn btn-success create-btn" [title]="'TR_CREATE' | translate"
        (click)="create(formmodal)">
        <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
      </button>
    </div>
    <div class="col-lg-9 col-md-9 col-12">
      <div class="filter-block">
        <div ngbDropdown>
          <button type="button" ngbDropdownToggle
            class="mb-2 mr-2 btn btn-primary">{{ selectedTypeName ? selectedTypeName : 'TR_TYPE' | translate }}</button>
          <div ngbDropdownMenu>
            <button type="button" class="dropdown-item"
              (click)="getTranslationsData()">{{ 'TR_ALL' | translate }}</button>
            <button type="button" class="dropdown-item" *ngFor="let item of translationTypes; index as i"
              (click)="setSelectedType(item, i)">{{ item }}</button>
          </div>
        </div>
        <div class="search-box">
          <input name="search" [placeholder]="'TR_SEARCH' | translate" type="text" class="form-control"
            autocomplete="off" [(ngModel)]="searchValue" (ngModelChange)="filter()" />
          <i class="pe-7s-search"></i>
        </div>
      </div>
    </div>
  </div>

  <div class="main-card mb-3 card">
    <ngb-tabset [activeId]="selectedTabId" (tabChange)="beforeChange($event)">
      <ngb-tab [id]="language.isocode2" [title]="language.name"
        *ngFor="let language of searchLanguages; index as languageIndex">
        <ng-template ngbTabContent>
          <div class="table-responsive">
            <table class="align-middle mb-0 table table-striped table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{ 'TR_KEY' | translate }}</th>
                  <th>{{ 'TR_TEXT' | translate }}</th>
                  <th>{{ 'TR_TYPE' | translate }}</th>
                  <th class="text-right">{{ 'TR_ACTIONS' | translate }}</th>
                </tr>
              </thead>
              <tbody *ngIf="language.content && language.content.length">
                <ng-container *ngFor="let translation of language.content let i = index">
                  <tr *ngIf="form.value.id !== translation.id ">
                    <td>{{ i + 1 }}</td>
                    <td style="max-width: 220px;">{{ translation.shortKey }}</td>
                    <td style="min-width: 280px;">{{ translation.translation1 }}</td>
                    <td>{{ translationTypes[translation.type] }}</td>
                    <td class="level-table-btns text-right">
                      <div role="group" class="btn-group-sm btn-group">
                        <button *ngIf="translation_update" class="mr-1 btn-transition btn btn-outline-info"
                          [title]="'TR_UPDATE' | translate" (click)="update(language.languageId, translation)">
                          <i class="pe-7s-note"></i>
                        </button>
                        <button *ngIf="translation_delete" class="btn-transition btn btn-outline-danger"
                          [title]="'TR_DELETE' | translate"
                          (click)="confirmDelete(deleteconfirm, translation.id, translation.shortKey)">
                          <i class="pe-7s-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>

                  <tr [formGroup]="form" *ngIf="form.value.id === translation.id" class="selected-yellow">
                    <td style="max-width: 220px;">{{ translation.shortKey }}</td>
                    <td style="min-width: 280px;">
                      <div class="position-relative form-group mb-1">
                        <textarea id="translation" rows="4" name="translation1"
                          [placeholder]="('TR_TEXT' | translate) + mainService.asterisk" type="text"
                          class="form-control translate-text scroll"
                          [class.error-border]="isInvalidSubmit && !form.value.translation1" autocomplete="off"
                          value="form.value.translation1" formControlName="translation1"></textarea>
                        <button type="button"
                          class="mb-2 mr-2 border-0 btn-transition btn btn-outline-secondary remove-content-btn"
                          [title]="('TR_REMOVE' | translate) + ' ' + ('TR_TEXT' | translate)"
                          (click)="removeTranslationText()">
                          <i class="pe-7s-close"></i>
                        </button>
                        <button id="btnCopy" type="button"
                          class="mb-2 mr-2 border-0 btn-transition btn btn-outline-secondary copy-btn"
                          [title]="'TR_COPY' | translate" data-clipboard-target="#translation">
                          <i class="pe-7s-copy-file"></i>
                        </button>
                      </div>
                      <span *ngIf="language.isocode2 != 'en'">{{ englishTranslation }}</span>
                    </td>
                    <td>
                      <div ngbDropdown>
                        <div class="flex flex-column">
                          <button type="button" ngbDropdownToggle class="btn btn-primary td-drop-btn compact-width"
                            [class.error-border]="isInvalidSubmit && !form.value.type && form.value.type !== 0">
                            {{ selectedFormTypeName ? selectedFormTypeName : ('TR_TYPE' | translate) + mainService.asterisk }}
                          </button>
                        </div>
                        <div ngbDropdownMenu>
                          <button type="button" class="dropdown-item td-drop-btn"
                            *ngFor="let item of translationTypes;index as i" (click)="setSelectedFormType(item, i)">
                            {{ item }}
                          </button>
                        </div>
                      </div>
                    </td>
                    <td class="level-table-btns text-right">
                      <app-save-button *ngIf="translation_update" [isCurrent]="true" [spinnerId]="updateSpinnerId"
                        [buttonClasses]="'group-btn-padding mb-1 mr-1 btn-transition btn btn-outline-primary'"
                        (submit)="submitUpdate()"></app-save-button>
                      <button *ngIf="translation_update"
                        class="mb-1 btn-transition btn btn-outline-danger table-action-btn"
                        [title]="'TR_CANCEL' | translate" (click)="cancelUpdate()">
                        <i class="pe-7s-close-circle"></i>
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>

  <ng-template #deleteconfirm let-c="close" let-d="dismiss">
    <div class="custom-modal modal-body">
      <p>{{ 'TR_DELETE_ERROR' | translate }} <b class="word-break-break-word">{{ deleteTranslationName }}</b>?</p>
    </div>
    <div class="custom-modal modal-footer">
      <app-action-button [isCurrent]="true" [spinnerId]="deleteSpinnerId"
        buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
        [buttonName]="'TR_YES'" (clicked)="delete()"></app-action-button>
      <button type="button" class="btn-transition btn btn-danger"
        (click)="c('Close click')">{{ 'TR_NO' | translate }}</button>
    </div>
  </ng-template>

  <ng-template #formmodal let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h5 class="modal-title">
        {{ isCreateForm ? ('TR_TRANSLATION_CREATE' | translate) : ('TR_UPDATE_TRANSLATION' | translate) }}</h5>
      <button type="button" class="close pb-0" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-0">
      <app-translation-form [languageIsocode]="selectedTabId"
        [translations]="searchLanguages[0] && searchLanguages[0].content" [languages]="languages"
        (createEmit)="createTranslation()"></app-translation-form>
    </div>
  </ng-template>

</ng-container>