import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { LanguageService } from 'src/app/Services/language.service';
import { AlertService } from 'src/app/Services/alert.service';
import { ILanguageData } from 'src/app/Models/ILanguageData';
import { IQuestionData, IQuestionGroupData, IQuestionTypeData } from 'src/app/Models/IQuestionModel';
import { LANGUAGE_PATH_KEY, QUESTIONS_GROUP_PATH_KEY, QUESTION_PATH_KEY, QUESTION_URL_KEY } from 'src/app/Constants/request-path-keys';
import {
  QUESTION_TYPES_ARR_KEY,
  SUCCESS_KEY,
  TR_COMPLETE_FIELDS_MESSAGE_ERROR_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_CREATE_QUESTION_KEY,
  TR_UPDATE_MESSAGE_KEY,
  TR_UPDATE_QUESTION_KEY
} from 'src/app/Constants/main-keys';
import { guid } from '@progress/kendo-angular-common';

@Component({
  selector: 'app-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.scss']
})
export class QuestionFormComponent implements OnInit {
  form: FormGroup;
  submitSpinnerId: string = guid();
  isInvalidSubmit: boolean;
  isEmptyQuestionsGroups: boolean;
  isCreateForm: boolean = true;
  questionId: number;
  errorMessage: string;
  selectedLanguageName: string;
  selectedQuestionTypeName: string;
  selectedQuestionsGroupName: string;
  questionData: IQuestionData;
  userRoles: Array<string> = [];
  languages: Array<ILanguageData> = [];
  questionsGroups: Array<IQuestionGroupData> = [];
  questionTypes: Array<IQuestionTypeData> = QUESTION_TYPES_ARR_KEY;

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    public mainService: MainService,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private alertService: AlertService,
    private languageService: LanguageService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.translationService.setPageTitle(TR_CREATE_QUESTION_KEY);

    this.form = this.formBuilder.group({
      languageId: [null, Validators.required],
      questionType: [null, Validators.required],
      questionsGroupId: [null, Validators.required],
      name: ['', Validators.required],
      text: ['', Validators.required],
      value: [1, Validators.required],
      feedback: [''],
    });

    this.getLanguages();
    this.getQuestionsGroups();

    this.route.paramMap.subscribe(params => {
      this.questionId = +params.get('item.id');
      const questionsGroupId = +params.get('questionsGroup.id');
      if(questionsGroupId) {
        this.form.patchValue({ questionsGroupId });
        this.questionsGroups.length && this.setQuestionsGroupName(this.form.value.questionsGroupId);
      }

      if(this.questionId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle(TR_UPDATE_QUESTION_KEY);
      }
    });
  }

  getLanguages(): void {
    this.dataHandlerService.getData(LANGUAGE_PATH_KEY).then(response => {
      // this.languages = response;
      this.languages = response.splice(0, 2);
      this.getCurrentLanguage(this.languages);
    });
  }

  getCurrentLanguage(languages): void {
    const userLanguageIsocode = this.languageService.currentLanguage;
    if(userLanguageIsocode) {
      const selectedLanguage = languages.find(data => data.isocode2 === userLanguageIsocode);
      if(selectedLanguage) {
        this.setSelectedLanguage(selectedLanguage.languageId, selectedLanguage.name);
      }
    }
  }

  getQuestionsGroups(): void {
    this.dataHandlerService.getData(QUESTIONS_GROUP_PATH_KEY).then(response => {
      this.questionsGroups = response;
      this.isEmptyQuestionsGroups = !this.questionsGroups.length;
      this.form.value.questionsGroupId && this.setQuestionsGroupName(this.form.value.questionsGroupId);
    });
  }

  getQuestionById(langaugeId: number): void {
    if(this.questionId) {
      this.dataHandlerService.getDataWithSpinner(`${QUESTION_PATH_KEY}/${langaugeId}/${this.questionId}`).then(response => {
        this.questionData = response;
        this.form.patchValue({
          langaugeId: this.questionData.languageId,
          questionType: this.questionData.questionType,
          questionsGroupId: this.questionData.questionsGroupId,
          name: this.questionData.name,
          text: this.questionData.text,
          value: this.questionData.value,
          feedback: this.questionData.feedback,
        });
        this.selectedQuestionTypeName = this.questionTypes[this.questionData.questionType].name;
        this.questionsGroups.length && this.setQuestionsGroupName(this.questionData.questionsGroupId);
      });
    }
  }

  setSelectedLanguage(id: number, name: string): void {
    this.selectedLanguageName = name;
    this.form.patchValue({ languageId: id });
    this.questionId && this.getQuestionById(id);
  }

  setSelectedQuestionType(id: number, name: string): void {
    this.selectedQuestionTypeName = name;
    this.form.patchValue({ questionType: id });
  }

  setSelectedQuestionsGroup(id: number, name: string): void {
    this.selectedQuestionsGroupName = name;
    this.form.patchValue({ questionsGroupId: id });
  }

  setQuestionsGroupName(questionGroupId: number): void {
    const selectedQuestionGroup = this.questionsGroups.find(data => data.id === questionGroupId);
    if(selectedQuestionGroup) {
      this.selectedQuestionsGroupName = selectedQuestionGroup.name;
    }
  }

  submitData() {
    if(this.form.valid) {
      this.mainService.questionSubmitted.next(true);
    } else {
      this.isInvalidSubmit = true;
    }
  }

  answersEmitted(emittedForm): void {
    const form = { ...this.form.value };
    if(emittedForm) {
      if(emittedForm.answers && emittedForm.answers.length) { form.answers = emittedForm.answers }
      if(emittedForm.isCaseSensitive === true || emittedForm.isCaseSensitive === false) { form.isCaseSensitive = emittedForm.isCaseSensitive }
      if(emittedForm.isMultiAnswer === true || emittedForm.isMultiAnswer === false) { form.isMultiAnswer = emittedForm.isMultiAnswer }
      if(emittedForm.isShuffle === true || emittedForm.isShuffle === false) { form.isShuffle = emittedForm.isShuffle }
      if(emittedForm.correctAnswer === true || emittedForm.correctAnswer === false) { form.correctAnswer = emittedForm.correctAnswer }
      if(emittedForm.choiceNumbering || emittedForm.choiceNumbering === 0) { form.choiceNumbering = emittedForm.choiceNumbering }
    }

    if((form.answers && form.answers.length) || form.correctAnswer !== undefined || form.correctAnswer !== null) {
      this.compleateSubmit(form);
    } else {
      this.isInvalidSubmit = true;
      this.translationService.translate(TR_COMPLETE_FIELDS_MESSAGE_ERROR_KEY).subscribe(translatedName => this.errorMessage = translatedName);
    }
  }

  compleateSubmit(form): void {
    if(this.questionId) {
      form.id = this.questionId;
      this.dataHandlerService.updateData(form, QUESTION_PATH_KEY, this.submitSpinnerId).then(() => {
        this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
        this.mainService.goBack(QUESTION_URL_KEY);
        this.mainService.questionSubmitted.next(false);
      });
    } else {
      this.dataHandlerService.createData(form, QUESTION_PATH_KEY, this.submitSpinnerId).then(() => {
        this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
        this.mainService.goBack(QUESTION_URL_KEY);
        this.mainService.questionSubmitted.next(false);
      });
    }
  }

}
