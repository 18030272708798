<div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_CATEGORIES' | translate }}</h4>
</div>

<div class="row">
  <div class="col-md-8 col-12 mb-2">
    <div ngbDropdown>
      <button type="button" ngbDropdownToggle class="btn btn-primary">
        {{ (filterCategoryName | translate) || ('TR_FILTER' | translate) }}
      </button>
      <div ngbDropdownMenu>
        <button class="dropdown-item" (click)="getCategories(false)">
          {{ 'TR_ALL' | translate }}
        </button>
        <button class="dropdown-item" *ngFor="let item of categoryTypes" (click)="filterCategories(item)">
          {{ item.name | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-8 col-12">
    <div *ngIf="category_view" class="main-card mb-3 card">
      <div class="table-responsive">
        <table class="align-middle mb-0 table table-hover">
          <thead>
            <tr>
              <th>{{ ('TR_NAME' | translate) + mainService.asterisk }}</th>
              <th class="text-right">{{ 'TR_ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="category_create && category_update">
              <td>
                <div class="flex mt-1 mb-1">
                  <div class="mr-2" ngbDropdown>
                    <button type="button" ngbDropdownToggle class="btn btn-primary td-drop-btn">
                      {{ categoryModel.parentName ? categoryModel.parentName : 'TR_PARENT_CATEGORY' | translate }}
                    </button>
                    <div ngbDropdownMenu>
                      <button class="dropdown-item td-drop-btn" *ngFor="let item of categories" (click)="setSelectedParentCategory(item.id, item.name)">
                        {{ item.name }}
                      </button>
                    </div>
                  </div>
                  <input
                    name="name"
                    type="text"
                    class="form-control"
                    [class.error-border]="isInvalidSubmit && !categoryModel.name"
                    autocomplete="off"
                    [placeholder]="('TR_NAME' | translate) + mainService.asterisk"
                    [(ngModel)]="categoryModel.name"
                  />
                </div>
              </td>
              <td class="level-table-btns text-right">
                <div role="group" class="btn-group-sm btn-group">
                  <app-save-button
                    *ngIf="category_create"
                    [spinnerId]="saveSpinnerId"
                    [isCurrent]="true"
                    buttonClasses="btn-transition btn btn-outline-primary right-border-0 group-btn-padding"
                    (submit)="submitData()"
                  ></app-save-button>
                  <button class="ml-1 btn-transition btn btn-outline-danger" [title]="'TR_CANCEL' | translate" (click)="cancel()">
                    <i class="pe-7s-close-circle"></i>
                  </button>
                </div>
              </td>
            </tr>
            <tr *ngFor="let category of categories">
              <td>
                {{ category.name }}
                <div *ngIf="category.children && category.children.length" class="main-card mb-2 mt-2 card border-none">
                  <div class="table-responsive child-table">
                    <table class="align-middle mb-0 table table-hover">
                      <tbody>
                        <tr *ngFor="let item of category.children" >
                          <td>{{ item.name }}</td>
                          <td class="level-table-btns text-right">
                            <div role="group" class="btn-group-sm btn-group">
                              <button
                                *ngIf="category_update"
                                class="btn-transition btn btn-outline-info"
                                [title]="'TR_UPDATE' | translate"
                                (click)="update(item)"
                              >
                                <i class="pe-7s-note"></i>
                              </button>
                              <button
                                *ngIf="category_delete"
                                class="ml-1 btn-transition btn btn-outline-danger"
                                [title]="'TR_DELETE' | translate"
                                (click)="confirmDelete(deleteconfirm, item.id, item.name)"
                              >
                                <i class="pe-7s-trash"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
              <td class="level-table-btns text-right">
                <div role="group" class="btn-group-sm btn-group">
                  <button
                    *ngIf="category_update"
                    class="btn-transition btn btn-outline-info"
                    [title]="'TR_UPDATE' | translate"
                    (click)="update(category)"
                  >
                    <i class="pe-7s-note"></i>
                  </button>
                  <button
                    *ngIf="category_delete"
                    class="ml-1 btn-transition btn btn-outline-danger"
                    [title]="'TR_DELETE' | translate"
                    (click)="confirmDelete(deleteconfirm, category.id, category.name)"
                  >
                    <i class="pe-7s-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteconfirm let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p>{{ 'TR_DELETE_ERROR' | translate }} <b class="word-break-break-word">{{ deleteCategoryName }}</b>?</p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button
      [isCurrent]="true"
      [spinnerId]="deleteSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary"
      spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'"
      (clicked)="delete()"
    ></app-action-button>
    <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate }}</button>
  </div>
</ng-template>
