import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { guid } from '@progress/kendo-angular-common';
import { ActivatedRoute } from '@angular/router';
import { ICourseData } from 'src/app/Models/ICourseData';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { AlertService } from 'src/app/Services/alert.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { COURSE_PATH_KEY, COURSE_URL_KEY } from 'src/app/Constants/request-path-keys';
import { SUCCESS_KEY, TR_CREATE_COURSE_KEY, TR_CREATE_MESSAGE_KEY, TR_UPDATE_COURSE_KEY, TR_UPDATE_MESSAGE_KEY } from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-course-form',
  templateUrl: './course-form.component.html',
  styleUrls: ['./course-form.component.scss']
})
export class CourseFormComponent implements OnInit {
  form: FormGroup;
  courseId: number;
  uploadedImage: any;
  uploadedVideo: any;
  isInvalidSubmit: boolean;
  isCreateForm: boolean = true;
  submitSpinnerId: string = guid();
  userRoles: Array<string> = [];

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private translationService: TranslationService,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    public mainService: MainService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.translationService.setPageTitle(TR_CREATE_COURSE_KEY);

    this.form = this.formBuilder.group({
      name: [''],
      description: [''],
      image: [''],
      presentationFile: [''],
      period: [''],
      cost: [''],
      discount: [''],
      discountStartDate: [''],
      discountEndDate: [''],
      isLifeTime: [''],
    });


    this.route.paramMap.subscribe(params => {
      this.courseId = +params.get('item.id');

      if (this.courseId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle(TR_UPDATE_COURSE_KEY);
        this.getCourseDataById(this.courseId);
      }
    });
  }

  getCourseDataById(id: number): void {
    this.dataHandlerService.getDataWithSpinner(`${COURSE_PATH_KEY}/${id}`).then(response => {
      const courseData: ICourseData = response;
      this.uploadedImage = courseData.imagePath;
      this.uploadedVideo = courseData.presentationFilePath;
      this.form.patchValue({
        name: courseData.name,
        description: courseData.description,
        image: courseData.imagePath,
        presentationFile: courseData.presentationFilePath,
        period: courseData.period,
        cost: courseData.cost,
        discount: courseData.discount,
        discountStartDate: courseData.discountStartDate,
        discountEndDate: courseData.discountEndDate,
        isLifeTime: courseData.isLifeTime,
      });
    });
  }

  async handleImageUpload(files: FileList) {
    if (files && files[0]) {
      this.uploadedImage = await this.mainService.imageUpload(files[0]);
      this.form.patchValue({ image: files[0] });
    }
  }

  async handleVideoUpload(files: FileList) {
    if (files && files[0]) {
      this.uploadedVideo = await this.mainService.videoUpload(files[0]);
      this.form.patchValue({ presentationFile: files[0] });
    }
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      const formData = new FormData();
      for (let i in form) {
        if(!form[i]) {
          delete form[i];
        } else {
          formData.append(`${i}`, form[i]);
        }
      }
      if (this.courseId) {
        formData.append('id', this.courseId.toString());
        this.dataHandlerService.updateFormData(formData, COURSE_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(COURSE_URL_KEY);
        });
      } else {
        this.dataHandlerService.createFormData(formData, COURSE_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(COURSE_URL_KEY);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }
}
