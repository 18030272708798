<div class="app-page-title">
  <div class="page-title-wrapper flex-wrap">
    <div class="page-title-heading">
      <div class="page-title-icon"><i class="icon pe-7s-star icon-gradient bg-plum-plate"></i></div>
      <div>
        {{ 'TR_STUDENTS' | translate }}
        <page-main-info-component id="main_info"
          [attr.data-page-name]="currentUserData.userTypeId === userTypes.ADMIN_USER_TYPE_KEY ? 'angular_admin_selection_committee' : currentUserData.userTypeId === userTypes.MET_DEPARTMENT_WORKER_KEY ? 'angular_mdw_selection_committee' : ''">
        </page-main-info-component>
      </div>
    </div>
    <div class="page-title-actions d-flex flex-wrap"><button *ngIf="student_create"
        class="mb-2 mr-2 btn btn-success create-btn no-wrap" [title]="'TR_CREATE_STUDENT' | translate"
        [routerLink]="['student-form']">
        <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
      </button><button *ngIf="student_create" class="mb-2 mr-2 btn btn-warning create-user-btn"
        (click)="openBulkUploadModal(studentsBulkUpload)">
        <span class="ml-2">{{ 'TR_STUDENTS_BULK_UPLOAD' | translate }}</span>
        <app-help-alert alertKey="bulk_enroll_students"></app-help-alert>
      </button>
      <button *ngIf="student_create && studentIds && studentIds.length"
        class="mb-2 mr-2 btn btn-warning create-user-btn" (click)="openStudentsDeletedModal(bulkDeleteStudents)">
        <i class="pe-7s-trash"> </i>
        <span class="ml-2">{{ 'TR_DELETE' | translate }}</span>
        <app-help-alert alertKey="bulk_enroll_students"></app-help-alert>
      </button>

      <button *ngIf="studentIds && studentIds.length" type="button" class="mb-2 mr-2 btn btn-warning create-user-btn"
        [title]="'TR_ARCHIVE' | translate" (click)="openbBulkArchivedConfirmModal(studentsBulkArchived)">
        <i class="pe-7s-close-circle"> </i><span class="ml-2">{{ 'TR_ARCHIVE' | translate }}</span>
      </button>
    </div>
  </div>
</div>
<!-- <div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_STUDENTS' | translate }}</h4>
</div> -->

<div class="row">
  <div class="col-12">
    <div class="d-flex flex-wrap w-100 m-0">
      <div class="d-flex flex-1">
        <!-- <button *ngIf="student_create" class="mb-2 mr-2 btn btn-success create-user-btn"
          [title]="'TR_CREATE_STUDENT' | translate" [routerLink]="['student-form']">
          <i class="pe-7s-add-user"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
        </button>
        <button *ngIf="student_create" class="mb-2 mr-2 btn btn-warning create-user-btn"
          (click)="openBulkUploadModal(studentsBulkUpload)">
          <span class="ml-2">{{ 'TR_STUDENTS_BULK_UPLOAD' | translate }}</span>
          <app-help-alert alertKey="bulk_enroll_students"></app-help-alert>
        </button> -->

        <!-- <button *ngIf="student_create && studentIds && studentIds.length"
          class="mb-2 mr-2 btn btn-warning create-user-btn" (click)="openStudentsDeletedModal(bulkDeleteStudents)">
          <span class="ml-2">{{ 'TR_DELETE' | translate }}</span>
          <app-help-alert alertKey="bulk_enroll_students"></app-help-alert>
        </button>

        <button *ngIf="studentIds && studentIds.length" type="button" class="mb-2 mr-2 btn btn-warning create-user-btn"
          [title]="'TR_ARCHIVE' | translate" (click)="openbBulkArchivedConfirmModal(studentsBulkArchived)">
          <i class="pe-7s-close-circle"> </i><span class="ml-2">{{ 'TR_ARCHIVE' | translate }}</span>
        </button> -->

      </div>
      <div class="filter-block" *ngIf="group_view" ngbDropdown>
        <button type="button" ngbDropdownToggle class="mb-2 btn btn-primary">
          {{ groupName ? groupName : ('TR_GROUPS' | translate) }}
          <app-help-alert alertKey="sort_students_by_groups"></app-help-alert>
        </button>
        <div ngbDropdownMenu>
          <button class="dropdown-item" (click)="getAllStudents()">{{ 'TR_ALL' | translate }}</button>
          <button class="dropdown-item" *ngFor="let item of groups"
            (click)="getStudentsByGroup(item.id, item.groupName)">{{ item.groupName }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="main-card mb-3 card">
  <div class="table-responsive scroll">
    <table class="align-middle mb-0 table table-striped table-hover">
      <thead>
        <tr>
          <th>#</th>
          <th>
            <input *ngIf="student_archive" name="checkItem" type="checkbox" class="mt-2 pointer" [(ngModel)]="checkAll"
              (ngModelChange)="checkAllStudents()" />
            <app-help-alert alertKey="message-checkbox"></app-help-alert>
          </th>
          <th>{{ 'TR_DOCUMENT_NUMBER' | translate }}</th>
          <th>{{ 'TR_USERNAME' | translate }}</th>
          <th>{{ 'TR_FULLNAME' | translate }}</th>
          <th>{{ 'TR_GROUP_NAME' | translate }}</th>
          <th *ngIf="student_update || student_archive || student_delete">
            {{ 'TR_ACTIONS' | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr [ngClass]="item.isArchived ? 'default' : 'pointer'" title="Click to see full data"
          *ngFor="let item of students; let i = index" (click)="studentView($event.target, studentview, item)">
          <td>{{ i + 1 }}</td>
          <td>
            <input *ngIf="!item.isArchived && student_archive" name="checkItem" type="checkbox" class="mt-2 pointer"
              [(ngModel)]="item.checked" (change)="onChangeStudentIsChecked($event, item.id)" />
          </td>
          <td class="word-break-break-word">{{ item.personalDocumentNumber }}</td>
          <td>{{ item.username }}</td>
          <td>{{ item.lastname }} {{ item.firstname }} {{ item.middlename }}</td>
          <td>{{ item.groupName }}</td>
          <td *ngIf="student_update || student_archive || student_delete" class="text-center">
            <div role="group" class="btn-group-sm btn-group">
              <button *ngIf="student_archive" class="ml-1 btn-transition btn"
                [ngClass]="item.isArchived ? 'btn-outline-success' : 'btn-outline-danger'"
                [title]="(item.isArchived ? 'TR_UNARCHIVE' : 'TR_ARCHIVE') | translate"
                (click)="confirmArchivation(archiveContent, item.id)">
                <i [ngClass]="item.isArchived ? 'pe-7s-check' : 'pe-7s-close-circle'"> </i>
                <div *ngIf="i == 0">
                  <app-help-alert alertKey="student_archive_unzip"></app-help-alert>
                </div>
              </button>
              <button *ngIf="student_update" class="ml-1 btn-transition btn btn-outline-info"
                [title]="'TR_CHANGE_STUDENT_DOCUMENT_NUMBER' | translate" [routerLink]="['student-form', item.id]">
                <i class="pe-7s-note"> </i>
              </button>
              <!-- <button *ngIf="student_delete" class="ml-1 btn-transition btn btn-outline-danger"
                [title]="'TR_DELETE' | translate" (click)="confirmDelete(deleteContent, item.id)">
                <i class="pe-7s-trash"> </i>
              </button> -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #studentsBulkUpload let-c="close" let-d="dismiss">
  <app-students-bulk-upload (closeEmit)="c('Close click')"></app-students-bulk-upload>
</ng-template>

<ng-template #bulkDeleteStudents let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p class="word-break-break-word">{{'TR_DELETE_ERROR' | translate}} ?</p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button [isCurrent]="true" [spinnerId]="userArchiveSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="deleteStudents()"></app-action-button>
    <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate
      }}</button>
  </div>
</ng-template>
<ng-template #studentsBulkArchived let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p class="word-break-break-word">{{ 'TR_CONFIRM_ARCHIVE' | translate }} ? </p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button [isCurrent]="true" [spinnerId]="userArchiveSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="bulkArchived()"></app-action-button>
    <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #deleteContent let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p class="word-break-break-word">{{ 'TR_DELETE_ERROR' | translate }} {{ deleteStudent.lastname }} {{
      deleteStudent.firstname }} {{
      deleteStudent.middlename }} ? </p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button [isCurrent]="true" [spinnerId]="userDeleteSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="delete()"></app-action-button>
    <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #archiveContent let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p class="word-break-break-word">{{ 'TR_ARE_YOU_SUTE' | translate }}
      {{ (isArchive ? 'TR_ARCHIVE' : 'TR_UNARCHIVE') | translate }}
      {{ archiveStudent.lastname }} {{ archiveStudent.firstname }} {{ archiveStudent.middlename }}?
    </p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button [isCurrent]="true" [spinnerId]="bulkArchivedSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="triggerArchive()"></app-action-button>
    <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #studentview let-c="close" let-d="dismiss">
  <app-student-view [studentId]="studentViewId" (closeEmit)="c('Close click')"></app-student-view>
</ng-template>