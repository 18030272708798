<div class="main-card mb-3 card">
  <div class="card-body">
    <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('document')"></i>
      <h5 class="card-title">{{ 'TR_PRINT_OUT' | translate }} {{ 'TR_DOCUMENT' | translate }}</h5>
    </div>
    <iframe id="ifmcontentstoprint" style="height: 0px; width: 0px; position: absolute"></iframe>
    <kendo-pdf-export #pdf paperSize="A4" forcePageBreak=".page-break">
      <div #contentToConvert *ngIf="documentData" id="doc" class="d-flex justify-content-center align-items-center doc">
        <div *ngIf="documentData.signed && documentData.signFilePath && !documentData.filePath" class="print">
          <img id="image" [src]="documentData.signFilePath"/>
        </div>
        <div *ngIf="documentData.filePath && !documentData.signFilePath" class="print">
          <img id="image" [src]="documentData.filePath"/>
        </div>

        <div *ngIf="!documentData.signed && !documentData.documentTemplateId && !documentData.filePath && !documentData.signFilePath && documentData.data" class="print" [innerHTML]="documentData.data"></div>
        <div *ngIf="!documentData.signed && documentData.documentTemplateId && !documentData.filePath && !documentData.signFilePath" class="document-container">
          <app-template-document-view [templateContent]="documentData.data"
            [templateId]="documentData.documentTemplateId"></app-template-document-view>
        </div>
      </div>
    </kendo-pdf-export>

    <button
      type="button"
      class="mt-2 btn btn-primary"
      (click)="printDocument(documentData)"
    >
      {{ 'TR_PRINT_OUT' | translate }}
    </button>
    <button #btn kendo-button type="button" class="ml-2 mt-2 btn btn-primary" (click)="pdf.saveAs(documentName)">{{ 'TR_DOWNLOAD' | translate }} PDF</button>
  </div>
</div>