<div class="main-card mb-3 card">
  <div class="card-body">
    <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('question')"></i>
      <h5 class="card-title">{{ isCreateForm ? ('TR_CREATE_QUESTION' | translate) : ('TR_UPDATE_QUESTION' | translate) }}</h5>
    </div>
    <form (submit)="submitData()" [formGroup]="form">
      <div class="form-row">
        <div class="col-12">
          <h6 *ngIf="isEmptyQuestionsGroups" class="red">{{ 'TR_EMPTY_QUESTION_GROUP_WARN_MSG' | translate }}</h6>
        </div>
      </div>
      <div class="wrap-flex form-outline">
        <div ngbDropdown>
          <div class="flex flex-column"> 
            <label>{{ ('TR_LANGUAGES' | translate) + mainService.asterisk }}</label>
            <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-info" [class.error-border]="isInvalidSubmit && !form.value.languageId">
              {{ selectedLanguageName ? selectedLanguageName : ('TR_LANGUAGES' | translate) + mainService.asterisk }}
            </button>
          </div>
          <div ngbDropdownMenu>
            <button type="button" class="dropdown-item" *ngFor="let item of languages" (click)="setSelectedLanguage(item.languageId, item.name)">{{ item.name }}</button>
          </div>
        </div>
        <div ngbDropdown>
          <div class="flex flex-column">
            <label>{{ ('TR_QUESTIONS_GROUP' | translate) + mainService.asterisk }}</label>
            <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-info" [class.error-border]="isInvalidSubmit && !form.value.questionsGroupId">
              {{ selectedQuestionsGroupName ? selectedQuestionsGroupName : ('TR_QUESTIONS_GROUP' | translate) + mainService.asterisk }}
            </button>
          </div>
          <div ngbDropdownMenu>
            <button type="button" class="dropdown-item" *ngFor="let item of questionsGroups" (click)="setSelectedQuestionsGroup(item.id, item.name)">
              {{ item.name }}
            </button>
          </div>
        </div>
        <div ngbDropdown>
          <div class="flex flex-column">
            <label>{{ ('TR_QUESTION_TYPE' | translate) + mainService.asterisk }}</label>
            <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-info" [class.error-border]="isInvalidSubmit && !form.value.questionType && form.value.questionType !== 0">
              {{ selectedQuestionTypeName ? (selectedQuestionTypeName | translate) : ('TR_QUESTION_TYPE' | translate) + mainService.asterisk }}
            </button>
          </div>
          <div ngbDropdownMenu>
            <button type="button" class="dropdown-item" *ngFor="let item of questionTypes" (click)="setSelectedQuestionType(item.value, item.name)">
              {{ item.name | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-6 col-md-8">
          <div class="position-relative form-group">
            <label for="name">{{ ('TR_NAME' | translate) + mainService.asterisk }}</label>
            <input
              name="name"
              [placeholder]="('TR_NAME' | translate) + mainService.asterisk"
              type="text"
              class="form-control"
              [class.error-border]="isInvalidSubmit && !form.value.name"
              autocomplete="off"
              formControlName="name"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-6 col-md-8">
          <div class="position-relative form-group">
            <label for="text">{{ ('TR_TEXT' | translate) + mainService.asterisk }}</label>
            <textarea
              name="text"
              [placeholder]="('TR_TEXT' | translate) + mainService.asterisk"
              type="text"
              class="form-control"
              [class.error-border]="isInvalidSubmit && !form.value.text"
              autocomplete="off"
              formControlName="text"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-6 col-md-8">
          <div class="position-relative form-group">
            <label for="value">{{ ('TR_DEFAULT_MARK' | translate) + mainService.asterisk }}</label>
            <input
              min="1"
              name="value"
              [placeholder]="('TR_DEFAULT_MARK' | translate) + mainService.asterisk"
              type="number"
              class="form-control"
              [class.error-border]="isInvalidSubmit && !form.value.value"
              autocomplete="off"
              formControlName="value"
            />
          </div>
        </div>
      </div>
      <div *ngIf="form.value.questionType !== 1" class="form-row">
        <div class="col-lg-6 col-md-8">
          <div class="position-relative form-group">
            <label for="feedback">{{ 'TR_GENERAL_FEEDBACK' | translate}}</label>
            <textarea
              name="feedback"
              [placeholder]="'TR_GENERAL_FEEDBACK' | translate"
              type="text"
              class="form-control"
              autocomplete="off"
              formControlName="feedback"
            ></textarea>
          </div>
        </div>
      </div>
      <div *ngIf="form.value.questionType || form.value.questionType === 0" class="form-row">
        <div class="col-12">
          <hr />
          <h5>{{ 'TR_ANSWERS' | translate }}</h5>
          <app-multi-choise-answer *ngIf="form.value.questionType === 0" [questionData]="questionData" (submitEmmiter)="answersEmitted($event)"></app-multi-choise-answer>
          <app-matching-question-answer *ngIf="form.value.questionType === 1" [questionData]="questionData" (submitEmmiter)="answersEmitted($event)"></app-matching-question-answer>
          <app-short-answer *ngIf="form.value.questionType === 2" [questionData]="questionData" (submitEmmiter)="answersEmitted($event)"></app-short-answer>
          <app-true-false-answer *ngIf="form.value.questionType === 3" [questionData]="questionData" (submitEmmiter)="answersEmitted($event)"></app-true-false-answer>
        </div>
      </div>
      <app-submit-button
        [spinnerId]="submitSpinnerId"
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit"
        [isFormValid]="form.valid"
      ></app-submit-button>
    </form>
  </div>
</div>