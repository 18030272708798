import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { guid } from '@progress/kendo-angular-common';
import { ActivatedRoute } from '@angular/router';
import { IGroupData } from 'src/app/Models/IGroupData';
import { IOrganizationData } from 'src/app/Models/IOrganizationData';
import { IUserData } from 'src/app/Models/IUserData';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { AlertService } from 'src/app/Services/alert.service';
import { TranslationService } from 'src/app/Services/translation.service';
import {
  GROUPS_PATH_KEY,
  GROUP_URL_KEY,
  ORGANIZATION_EDUCATION_LEVEL_PATH_KEY,
  PAGE_NUMBER_PATH_PARAM_KEY,
  PAGE_SIZE_PATH_PARAM_KEY,
  USERS_PATH_KEY,
  USER_TYPE_ID_PATH_PARAM_KEY
} from 'src/app/Constants/request-path-keys';
import {
  EDUCATION_LEVEL_VIEW,
  SUCCESS_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_UPDATE_MESSAGE_KEY,
  USERTYPE_VIEW,
  USER_CREATE,
  USER_UPDATE,
  USER_VIEW,
  ORGANIZATION_EDUCATION_LEVEL_PROCESS_TRANSLATION_WITHOUT_DISCIPLINE_KEYS,
  TR_CREATE_GROUP_KEY,
  TR_UPDATE_GROUP_KEY,
} from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-group-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.scss']
})
export class GroupFormComponent implements OnInit {
  groupId: number;
  selectedCuratorId: number;
  selectedCuratorName: string;
  form: FormGroup;
  groupData: IGroupData;
  group_view: boolean;
  group_create: boolean;
  group_update: boolean;
  education_level_view: boolean;
  user_view: boolean;
  isInvalidSubmit: boolean;
  isCreateForm: boolean = true;
  submitSpinnerId: string = guid();
  curators: Array<IUserData> = [];
  programs: Array<any> = [];
  userRoles: Array<string> = [];
  organizations: Array<IOrganizationData> = [];
  settingsItems: Array<any> = [];
  settings: Array<string> = ORGANIZATION_EDUCATION_LEVEL_PROCESS_TRANSLATION_WITHOUT_DISCIPLINE_KEYS;
  organizationEducationLevelId: number;
  groupNameMaxLength: number = 200;

  constructor(
    private dataHandlerService: DataHandlerService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    private translationService: TranslationService,
    public mainService: MainService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_CREATE_GROUP_KEY);

    this.settings.forEach((item, index) => {
      this.settingsItems.push({ name: item, removeSelectedValue: false, disabled: index, content: [] });
    });

    this.getEducationLevelData();
    this.getCurators();

    this.form = this.formBuilder.group({
      organizationEducationLevelId: [null, Validators.required],
      ownerUserId: [null, Validators.required],
      // groupName: ['', Validators.required],
      groupName: ['', Validators.maxLength(this.groupNameMaxLength)],
    });

    this.route.paramMap.subscribe(params => {
      this.groupId = +params.get('item.id');

      if (this.groupId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle(TR_UPDATE_GROUP_KEY);
        this.getGroupById();
      }
    });
  }

  setRoles() {
    this.userRoles.forEach(item => {
      switch (item) {
        case USER_CREATE:
          this.group_create = true;
          break;
        case USER_UPDATE:
          this.group_update = true;
          break;
        case USERTYPE_VIEW:
          this.group_view = true;
          break;
        case EDUCATION_LEVEL_VIEW:
          this.education_level_view = true;
          break;
        case USER_VIEW:
          this.user_view = true;
          break;
      }
    });
  }

  async getEducationLevelData() {
    if (!this.education_level_view) { return; }
    this.settingsItems[0].content = await this.dataHandlerService.getDataWithSpinner(ORGANIZATION_EDUCATION_LEVEL_PATH_KEY);
  }

  async getCurators() {
    if (!this.user_view) { return; }
    this.curators = await this.dataHandlerService.getDataWithSpinner(
      `${USERS_PATH_KEY}?${USER_TYPE_ID_PATH_PARAM_KEY}${3}&${PAGE_NUMBER_PATH_PARAM_KEY}${1}&${PAGE_SIZE_PATH_PARAM_KEY}${100}`
    );
  }

  getGroupById() {
    if (!this.group_view) { return; }
    this.dataHandlerService.getDataWithSpinner(`${GROUPS_PATH_KEY}/${this.groupId}`).then(response => {
      this.groupData = response;
      this.selectedCuratorId = this.groupData.ownerUserId;
      this.selectedCuratorName = this.groupData.ownerName;
      this.organizationEducationLevelId = this.groupData.organizationEducationLevelId;
      if (this.organizationEducationLevelId) {
        this.settingsItems[this.settingsItems.length - 1].selectedName = this.groupData.organizationEducationLevelName;
        this.settingsItems.forEach((item, index) => {
          item.disabled = index;
        });
      }
      this.form.patchValue({
        groupName: this.groupData.groupName,
        ownerUserId: this.groupData.ownerUserId,
        organizationEducationLevelId: this.groupData.organizationEducationLevelId
      });
    });
  }

  openNextDropDown(settingData, selectedElem, index): void {
    this.organizationEducationLevelId = null;
    this.form.patchValue({ organizationEducationLevelId: null });
    const currentIndex = this.settingsItems.indexOf(settingData);
    if (currentIndex > -1) {
      settingData.selectedName = selectedElem.name;
      const nextIndex = currentIndex + 1;
      if (nextIndex > -1 && this.settingsItems[nextIndex]) {
        this.settingsItems[nextIndex].disabled = false;
        this.settingsItems[nextIndex].selectedName = '';
        this.getBoxData(selectedElem.id, nextIndex);
      }
      if (index === this.settingsItems.length - 1) {
        this.organizationEducationLevelId = selectedElem.id;
        this.form.patchValue({ organizationEducationLevelId: selectedElem.id });
      }
      this.settingsItems.forEach((item, ind) => {
        if (ind > index) {
          item.selectedName = '';
          item.disabled = ind !== nextIndex;
        }
      });
    }
  }

  async getBoxData(id: number, index: number) {
    this.settingsItems[index].content = await this.dataHandlerService.getData(`${ORGANIZATION_EDUCATION_LEVEL_PATH_KEY}/${id}`);
  }

  setSelectedCurator(id: number, name: string): void {
    this.selectedCuratorId = id;
    this.selectedCuratorName = name;
    this.form.patchValue({ ownerUserId: id });
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      if (!form.groupName.trim().length) {
        this.isInvalidSubmit = true;
        return;
      }
      if (this.groupId && this.group_update) {
        form.id = this.groupId;
        form.createTime = this.groupData.createTime;
        this.dataHandlerService.updateData(form, GROUPS_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(GROUP_URL_KEY);
        });
      } else if (this.group_create) {
        this.dataHandlerService.createData(form, GROUPS_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(GROUP_URL_KEY);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }

}
