<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon"><i class="icon pe-7s-star icon-gradient bg-plum-plate"></i></div>
      <div>
        {{ 'TR_ORGANIZATION_SETTINGS' | translate }}
        <page-main-info-component id="main_info" 
          [attr.data-page-name]="currentUserData.userTypeId === userTypes.ADMIN_USER_TYPE_KEY ?
          'angular_admin_organization_structure' : currentUserData.userTypeId === userTypes.MET_DEPARTMENT_WORKER_KEY ?
          'angular_mdw_organization_structure' : ''">
        </page-main-info-component>
      </div>
      <div class="position-relative mt-2">
        <app-help-alert alertKey="organization_structure_set"></app-help-alert>
      </div>
    </div>
  </div>
</div>
<!-- <div class="title-block mb-3 d-flex flex-column align-items-baseline">
  <h4 class="card-title">
    {{ 'TR_ORGANIZATION_SETTINGS' | translate }}
  </h4>
  <div class="position-relative mt-2">
    <app-help-alert alertKey="organization_structure_set"></app-help-alert>
  </div>
</div> -->

<div class="row">
  <div class="col-lg-4 col-md-4 col-sm-6 col-12"
    *ngFor="let educationLevel of educationLevels;index as educationLevelIndex ">
    <div *ngIf="educationLevel.isActive" class="main-card mb-3 card">
      <div class="table-responsive">
        <table class="align-middle mb-0 table table-striped table-hover">
          <thead>
            <tr>
              <th>{{ (educationLevel.name | translate) ? (educationLevel.name | translate) : educationLevel.name |
                translate }}</th>
              <th *ngIf="educationLevelIndex > 1" class="text-right">{{ 'TR_ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="educationLevelIndex > 1">
              <td>
                <input name="name" type="text" class="form-control" autocomplete="off"
                  [placeholder]="'TR_NAME' | translate" [(ngModel)]="educationLevel.inputValue"
                  [disabled]="updateValue && educationLevel.content[0]?.parentId != blockId ? true : false" />
              </td>
              <td class="level-table-btns text-right">
                <app-save-button *ngIf="education_levels_create" [spinnerId]="submitSpinnerId"
                  [isCurrent]="submitEducationLevelIndex === educationLevelIndex"
                  [buttonClasses]="'small-padding mb-1 mr-1 btn-transition btn btn-outline-primary'"
                  [isDisabled]="updateValue && educationLevel.content[0]?.parentId != blockId ? true : false"
                  (submit)="submitData(educationLevelIndex)">
                </app-save-button>
                <button class="mb-1 btn-transition btn btn-outline-danger" [title]="'TR_CANCEL' | translate"
                  (click)="cancel(educationLevel)">
                  <!-- [disabled]="updateValue && educationLevel.content[0]?.parentId != blockId ? true : false" -->
                  <i class="pe-7s-close-circle"></i>
                </button>
              </td>
            </tr>
            <tr [class.selected]="educationLevelValue.selected"
              *ngFor="let educationLevelValue of educationLevel.content">
              <td class="clickable" class="pointer"
                (click)="openNextEducationLevel(educationLevel, educationLevelValue, educationLevelIndex, lecturerview)">
                <!-- {{ educationLevelValue.name }} -->
                {{ (educationLevelValue.name | translate) ? (educationLevelValue.name | translate) :
                educationLevelValue.name }}
              </td>
              <td *ngIf="educationLevelIndex > 1" class="level-table-btns text-right d-flex align-items-center">
                <div *ngIf="educationLevelValue.type == 3 && education_levels_update" class="d-inline m-2">
                  <input id="programCheck" name=" " type="checkbox" [checked]="educationLevelValue.containsPractice"
                    class="mt-2 pointer"
                    (change)="onCkeckboxChange($event, educationLevelValue, containsPracticeconfirm)" />
                </div>
                <button *ngIf="education_levels_update" class="mb-1 mr-1 btn-transition btn btn-outline-info"
                  [title]="'TR_UPDATE' | translate" (click)="edit( educationLevelValue, educationLevelIndex)"
                  [disabled]="updateValue ? true : false">
                  <i class="pe-7s-note"></i>
                </button>
                <button *ngIf="education_levels_delete" class="mb-1 btn-transition btn btn-outline-danger"
                  [title]="'TR_DELETE' | translate"
                  (click)="confirmDelete(deleteconfirm, educationLevelValue, educationLevelIndex)">
                  <i class="pe-7s-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #containsPracticeconfirm let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p>{{ 'TR_CONTAINS_PRACTICE_CONFIRM' | translate }} <b class="word-break-break-word">{{ containsPracticeName }}</b>?
    </p>
  </div>
  <div class="custom-modal modal-footer">
    <button type="button" class="btn-transition btn btn-outline-primary" (click)="onChangeContainsPractice()">{{ 'TR_YES' | translate
      }}</button>
    <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #deleteconfirm let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p>{{ 'TR_DELETE_ERROR' | translate }} <b class="word-break-break-word">{{ deleteLevelValueName }}</b>?</p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button [isCurrent]="true" [spinnerId]="deleteSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="delete()"></app-action-button>
    <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #lecturerview let-c="close" let-d="dismiss">
  <app-subject-lecturer [subjectId]="selectedSubjectId" [subjectName]="selectedSubjectName"
    (closeEmit)="c('Close click')"></app-subject-lecturer>
</ng-template>