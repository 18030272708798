import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { STUDENTS_PATH_KEY } from 'src/app/Constants/request-path-keys';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from './../../../Services/alert.service';
import { SUCCESS_KEY } from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-students-bulk-upload',
  templateUrl: './students-bulk-upload.component.html',
  styleUrls: ['./students-bulk-upload.component.scss']
})
export class StudentsBulkUploadComponent implements OnInit {
  uploadedFile: any;
  uploadedFileName: any;

  @Output() closeEmit = new EventEmitter();

  constructor(
    private mainService: MainService,
    private dataHandlerService: DataHandlerService,
    private modalService: NgbModal,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
  }

  async handleFileInput(files: FileList) {

    if (files && files[0]) {
      // this.uploadedFile = await this.mainService.csvFileUpload(files[0]);
      this.uploadedFile = files[0];
      this.uploadedFileName = files[0].name;
    }
  }

  submit(): void {
    if (this.uploadedFile) {
      const formData = new FormData();
      formData.append('file', this.uploadedFile);
      this.dataHandlerService.createFormData(formData, `${STUDENTS_PATH_KEY}/studentbulkupload`, null).then(() => {
        this.modalService.dismissAll();
        this.alertService.translateAndAlertMessage("TR_DATA_SAVED", SUCCESS_KEY);
      });
    }
    this.modalService.dismissAll();
  }

}
