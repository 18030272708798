<div class="modal-header">
  <div *ngIf="user" class="absolute-container">
    <div class="modal-image" [ngStyle]="{ 'background-image' : 'url(' + user.avatarFilePath + ')' }"></div>
    <p class="modal-title">{{ user.fullName }}</p>
  </div>
  <button type="button" class="close mb-2" aria-label="Close" (click)="closeEmit.emit(null)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-0">
  <app-modal-loader [modalSpinners]="[userSpinnerId]"></app-modal-loader>
  <div *ngIf="user" class="p-4">
    <div class="modal-row">
      <div>
        <strong class="mr-2">{{'TR_USERNAME' | translate }}:</strong>
      </div>
      <div class="right">
        <span class="float-right word-break-break-word">{{user.username}}</span>
      </div>
    </div>
    <div class="modal-row">
      <div>
        <strong class="mr-2">{{'TR_EMAIL' | translate }}:</strong>
      </div>
      <div class="right">
        <span class="float-right word-break-break-word">{{user.email}}</span>
      </div>
    </div>
    <div class="modal-row">
      <div>
        <strong class="mr-2">{{'TR_PHONE' | translate }}:</strong>
      </div>
      <div class="right">
        <span class="float-right word-break-break-word">{{user.phone}}</span>
      </div>
    </div>
    <div class="modal-row">
      <div>
        <strong class="mr-2">{{'TR_MOBILEPHONE' | translate }}:</strong>
      </div>
      <div class="right">
        <span class="float-right word-break-break-word">{{user.mobilePhone}}</span>
      </div>
    </div>
    <div class="modal-row">
      <div>
        <strong class="mr-2">{{'TR_DOB' | translate }}:</strong>
      </div>
      <div class="right">
        <span class="float-right word-break-break-word">{{user.dob | date}}</span>
      </div>
    </div>
    <div class="modal-row">
      <div>
        <strong class="mr-2">{{'TR_ADDRESSRESIDENCE' | translate }}:</strong>
      </div>
      <div class="right">
        <span class="float-right word-break-break-word">{{user.addressResidence}}</span>
      </div>
    </div>
    <div class="modal-row">
      <div>
        <strong class="mr-2">{{'TR_ADDRESSREGISTRATION' | translate }}:</strong>
      </div>
      <div class="right">
        <span class="float-right word-break-break-word">{{user.addressRegistration}}</span>
      </div>
    </div>
    <div class="modal-row">
      <div>
        <strong class="mr-2">{{'TR_ORGANIZATION' | translate }}:</strong>
      </div>
      <div class="right">
        <span class="float-right word-break-break-word">{{user.organization}}</span>
      </div>
    </div>
    <div class="modal-row">
      <div>
        <strong class="mr-2">{{'TR_USERTYPE' | translate }}:</strong>
      </div>
      <div class="right">
        <span class="float-right word-break-break-word">{{user.userTypeName}}</span>
      </div>
    </div>
    <div class="modal-row">
      <div>
        <strong class="mr-2">{{'TR_TIME_ZONE' | translate }}:</strong>
      </div>
      <div class="right">
        <span class="float-right word-break-break-word">{{user.tziidDisplayname}}</span>
      </div>
    </div>
    <div *ngIf="user_password_view" class="modal-row">
      <div>
        <strong class="mr-2">{{'TR_PASSWORD' | translate }}:</strong>
      </div>
      <div class="right">
        <span class="float-right word-break-break-word">{{user.password}}</span>
      </div>
    </div>
  </div>
</div>
