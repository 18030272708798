import { Pipe, PipeTransform, ChangeDetectorRef } from "@angular/core";
import { TranslationService } from "../Services/translation.service";
import { AsyncPipe } from "@angular/common";

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {
  private readonly asyncPipe: AsyncPipe;

  constructor(
    private translationService: TranslationService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    this.asyncPipe = new AsyncPipe(changeDetectorRef);
  }

  transform(value: string): string {
    return this.asyncPipe.transform(this.translationService.translate(value)) || this.asyncPipe.transform(this.translationService.translateDefaults(value));
  }
}