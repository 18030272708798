import { Injectable } from "@angular/core";
import { guid } from "@progress/kendo-angular-common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AlertService } from "./alert.service";
import { MainService } from "./main.service";
import { IResponseModel } from '../Models/IResponseModel';
import { ERROR_KEY } from '../Constants/main-keys';
import { API_URL_DOMAIN_KEY, API_URL_NODE_SERVER_DOMAIN_KEY } from "../Constants/domain-keys";

@Injectable({
  providedIn: 'root'
})
export class DataHandlerService {
  options = { headers: new HttpHeaders().set('Content-Type', 'application/json'), withCredentials: true };

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private mainService: MainService
  ) { }

  getDataWithSpinner(path: string, isFullResponse: boolean = false): Promise<any> {
    const spinnerId = guid();
    this.mainService.addPageSpinner(spinnerId);
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(`${API_URL_DOMAIN_KEY}/${path}`, { withCredentials: true }).subscribe((response: IResponseModel) => {
        if (!response) { return; }
        if (!response.respcode) {
          resolve(isFullResponse ? response : response.data);
        } else {
          this.alertService.translateAndAlertMessage(response.respmess, ERROR_KEY);
          reject(response.respmess);
        }
      });
      this.mainService.removePageSpinner(spinnerId);
    });
  }

  getDataWithModalSpinner(path: string, spinnerId: string, isFullResponse: boolean = false): Promise<any> {
    this.mainService.addModalSpinner(spinnerId);
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(`${API_URL_DOMAIN_KEY}/${path}`, { withCredentials: true }).subscribe((response: IResponseModel) => {
        if (!response) { return; }
        if (!response.respcode) {
          resolve(isFullResponse ? response : response.data);
        } else {
          this.alertService.translateAndAlertMessage(response.respmess, ERROR_KEY);
          reject(response.respmess);
        }
        this.mainService.removeModalSpinner(spinnerId);
      });
    });
  }

  // getData(path: string, isFullResponse: boolean = false): Promise<any> {
  //   return new Promise<any>((resolve, reject) => {
  //     this.http.get<any>(`${API_URL_DOMAIN_KEY}/${path}`, { withCredentials: true }).subscribe((response: IResponseModel) => {
  //       if (!response) { return; }
  //       if (!response.respcode) {
  //         resolve(isFullResponse ? response : response.data);
  //       } else {
  //         this.alertService.translateAndAlertMessage(response.respmess, ERROR_KEY);
  //         reject(response.respmess);
  //       }
  //     }),(error: any) => {
  //       console.log(error,"error");
  //     };
  //   });
  // }

  getData(path: string, isFullResponse: boolean = false): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(`${API_URL_DOMAIN_KEY}/${path}`, { withCredentials: true })
        .subscribe(
          (response: IResponseModel) => {
            if (!response) { return; }
            if (!response.respcode) {
              resolve(isFullResponse ? response : response.data);
            } else {
              this.alertService.translateAndAlertMessage(response.respmess, ERROR_KEY);
              reject(response.respmess);
            }
          },
          (error: any) => {
            reject('Error');
          }
        );
    });
  }

  userActivation(id: number, isRight: boolean, path: string, spinnerId: string): Promise<any> {
    spinnerId && this.mainService.addButtonSpinner(spinnerId);
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${API_URL_DOMAIN_KEY}/${path}/${id}/${isRight}`, { withCredentials: true }).subscribe((response: IResponseModel) => {
        if (!response) { return; }
        if (!response.respcode) {
          resolve(response.data);
        } else {
          this.alertService.translateAndAlertMessage(response.respmess, ERROR_KEY);
          reject(response.respmess);
        }
        spinnerId && this.mainService.removeButtonSpinner(spinnerId);
      });
    });
  }

  bulkUsersArchived(data: any, path: string, spinnerId: string): Promise<any> {
    spinnerId && this.mainService.addModalSpinner(spinnerId);
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${API_URL_DOMAIN_KEY}/${path}`, data).subscribe((response: IResponseModel) => {
        if (!response) { return; }
        if (!response.respcode) {
          resolve(response.data);
        } else {
          this.alertService.translateAndAlertMessage(response.respmess, ERROR_KEY);
          reject(response.respmess);
        }
        spinnerId && this.mainService.addModalSpinner(spinnerId);
      });
    });
  }

  deleteStudents(data: any, path: string, spinnerId: string): Promise<any> {
    spinnerId && this.mainService.addModalSpinner(spinnerId);
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${API_URL_DOMAIN_KEY}/${path}`, data).subscribe((response: IResponseModel) => {
        if (!response) { return; }
        if (!response.respcode) {
          resolve(response.data);
        } else {
          this.alertService.translateAndAlertMessage(response.respmess, ERROR_KEY);
          reject(response.respmess);
        }
        spinnerId && this.mainService.addModalSpinner(spinnerId);
      });
    });
  }

  updateData(data: any, path: string, spinnerId: string): Promise<any> {
    spinnerId && this.mainService.addButtonSpinner(spinnerId);
    return new Promise<any>((resolve, reject) => {
      this.http.put<any>(`${API_URL_DOMAIN_KEY}/${path}`, data, this.options).subscribe((response: IResponseModel) => {
        if (!response) { return; }
        if (!response.respcode) {
          resolve(response.data);
        } else {
          this.alertService.translateAndAlertMessage(response.respmess, ERROR_KEY);
          reject(response.respmess);
        }
        spinnerId && this.mainService.removeButtonSpinner(spinnerId);
      });
    });
  }

  updateDataWithoutData(path: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.put<any>(`${API_URL_DOMAIN_KEY}/${path}`, this.options).subscribe((response: IResponseModel) => {
        if (!response) { return; }
        if (!response.respcode) {
          resolve(response.data);
        } else {
          this.alertService.translateAndAlertMessage(response.respmess, ERROR_KEY);
          reject(response.respmess);
        }
      });
    });
  }

  createData(data: any, path: string, spinnerId: string, cb = null): Promise<any> {
    spinnerId && this.mainService.addButtonSpinner(spinnerId);
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${API_URL_DOMAIN_KEY}/${path}`, data, this.options).subscribe((response: IResponseModel) => {
        if (!response) { return; }
        if (!response.respcode) {
          resolve(response.data);
        } else {
          this.alertService.translateAndAlertMessage(response.respmess, ERROR_KEY);
          reject(response.respmess);
          cb && cb(response)
        }
        spinnerId && this.mainService.removeButtonSpinner(spinnerId);
      });
    });
  }

  createDataById(id: any, path: string, spinnerId: string): Promise<any> {
    spinnerId && this.mainService.addButtonSpinner(spinnerId);
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${API_URL_DOMAIN_KEY}/${path}/${id}`, this.options).subscribe((response: IResponseModel) => {
        if (!response) { return; }
        if (!response.respcode) {
          resolve(response.data);
        } else {
          this.alertService.translateAndAlertMessage(response.respmess, ERROR_KEY);
          reject(response.respmess);
        }
        spinnerId && this.mainService.removeButtonSpinner(spinnerId);
      });
    });
  }

  createDataWithoutSpinnerId(data: any, path: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${API_URL_DOMAIN_KEY}/${path}`, data, this.options).subscribe((response: IResponseModel) => {
        if (!response) { return; }
        if (!response.respcode) {
          resolve(response.data);
        } else {
          this.alertService.translateAndAlertMessage(response.respmess, ERROR_KEY);
          reject(response.respmess);
        }
      });
    });
  }

  createFormData(data: any, path: string, spinnerId: string): Promise<any> {
    spinnerId && this.mainService.addButtonSpinner(spinnerId);
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${API_URL_DOMAIN_KEY}/${path}`, data).subscribe((response: IResponseModel) => {
        if (!response) { return; }
        if (!response.respcode) {
          resolve(response.data);
        } else {
          this.alertService.translateAndAlertMessage(response.respmess, ERROR_KEY);
          reject(response.respmess);
        }
        spinnerId && this.mainService.removeButtonSpinner(spinnerId);
      });
    });
  }

  updateFormData(data: any, path: string, spinnerId: string): Promise<any> {
    spinnerId && this.mainService.addButtonSpinner(spinnerId);
    return new Promise<any>((resolve, reject) => {
      this.http.put<any>(`${API_URL_DOMAIN_KEY}/${path}`, data).subscribe((response: IResponseModel) => {
        if (!response) { return; }
        if (!response.respcode) {
          resolve(response.data);
        } else {
          this.alertService.translateAndAlertMessage(response.respmess, ERROR_KEY);
          reject(response.respmess);
        }
        spinnerId && this.mainService.removeButtonSpinner(spinnerId);
      });
    });
  }

  createDataByQuery(data: any, path: string) {
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${API_URL_DOMAIN_KEY}/${path}${data}`, { withCredentials: true }).subscribe((response: IResponseModel) => {
        if (!response) { return; }
        if (!response.respcode) {
          resolve(response.data);
        } else {
          this.alertService.translateAndAlertMessage(response.respmess, ERROR_KEY);
          reject(response.respmess);
        }
      });
    });
  }

  updateDataByQuery(data: any, path: string) {
    return new Promise<any>((resolve, reject) => {
      this.http.put<any>(`${API_URL_DOMAIN_KEY}/${path}${data}`, { withCredentials: true }).subscribe((response: IResponseModel) => {
        if (!response) { return; }
        if (!response.respcode) {
          resolve(response.data);
        } else {
          this.alertService.translateAndAlertMessage(response.respmess, ERROR_KEY);
          reject(response.respmess);
        }
      });
    });
  }

  deleteData(id: number, path: string, spinnerId: string): Promise<any> {
    spinnerId && this.mainService.addButtonSpinner(spinnerId);
    return new Promise<any>((resolve, reject) => {
      this.http.delete<any>(`${API_URL_DOMAIN_KEY}/${path}/${id}`, { withCredentials: true }).subscribe((response: IResponseModel) => {
        if (!response) { return; }
        if (!response.respcode) {
          resolve(response.data);
        } else {
          this.alertService.translateAndAlertMessage(response.respmess, ERROR_KEY);
          reject(response.respmess);
        }
        spinnerId && this.mainService.removeButtonSpinner(spinnerId);
      });
    });
  }

  //////////////// NODE SERVER REQUESTS
  nodeGetData(firstId: any, secondId: any, firtsPath: string, secondPath: string, generalPath: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(`${API_URL_NODE_SERVER_DOMAIN_KEY}/${generalPath}/${firtsPath}${firstId}&${secondPath}${secondId}`).subscribe((response: IResponseModel) => {
        if (!response) { return; }
        if (!response.respcode) {
          resolve(response);
        } else {
          this.alertService.translateAndAlertMessage(response.respmess, ERROR_KEY);
          reject(response.respmess);
        }
      });
    });
  }

}