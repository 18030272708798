import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { guid } from '@progress/kendo-angular-common';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { AlertService } from 'src/app/Services/alert.service';
import { ICourseTopicData } from 'src/app/Models/ICourseData';
import { COURSE_TOPIC_PATH_KEY, COURSE_URL_KEY, COURSE_VIEW_URL_KEY } from 'src/app/Constants/request-path-keys';
import { SUCCESS_KEY, TR_CREATE_TOPIC_KEY, TR_CREATE_MESSAGE_KEY, TR_UPDATE_TOPIC_KEY, TR_UPDATE_MESSAGE_KEY } from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-topic-form',
  templateUrl: './topic-form.component.html',
  styleUrls: ['./topic-form.component.scss']
})
export class TopicFormComponent implements OnInit {
  form: FormGroup;
  courseId: number;
  topicId: number;
  sectionId: number;
  uploadedImage: any;
  uploadedVideo: any;
  isInvalidSubmit: boolean;
  isCreateForm: boolean = true;
  submitSpinnerId: string = guid();
  userRoles: Array<string> = [];

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private translationService: TranslationService,
    private route: ActivatedRoute,
    private mainService: MainService,
    private rolesService: RolesService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.translationService.setPageTitle(TR_CREATE_TOPIC_KEY);

    this.form = this.formBuilder.group({
      courseId: [null],
      courseSectionId: [null],
      name: [''],
      description: [''],
      topicImage: [''],
      presentationFile: [''],
      topicPeriod: [''],
      visibilityRule: [0],
      passRule: [0],
    });

    this.route.paramMap.subscribe(params => {
      this.courseId = +params.get('item.id');
      this.sectionId = +params.get('section.id')
      this.topicId = +params.get('topic.id');
      this.courseId && this.form.patchValue({ courseId: this.courseId });
      this.sectionId && this.form.patchValue({ courseSectionId: this.sectionId });

      if(this.topicId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle(TR_UPDATE_TOPIC_KEY);
        this.getTopicDataById(this.topicId);
      }
    });
  }

  getTopicDataById(id: number): void {
    this.dataHandlerService.getDataWithSpinner(`${COURSE_TOPIC_PATH_KEY}/${id}`).then(response => {
      const courseData: ICourseTopicData = response;
      this.form.patchValue({
        name: courseData.name,
        description: courseData.description,
        topicImage: courseData.topicImage,
        presentationFile: courseData.presentationFile,
        topicPeriod: courseData.topicPeriod,
        visibilityRule: courseData.visibilityRule,
        passRule: courseData.passRule
      });
      this.uploadedImage = courseData.imagePath;
      this.uploadedVideo = courseData.presentationFilePath;
    });
  }

  async handleImageUpload(files: FileList) {
    if (files && files[0]) {
      this.uploadedImage = await this.mainService.imageUpload(files[0]);
      this.form.patchValue({ topicImage: files[0] });
    }
  }

  async handleVideoUpload(files: FileList) {
    if (files && files[0]) {
      this.uploadedVideo = await this.mainService.videoUpload(files[0]);
      this.form.patchValue({ presentationFile: files[0] });
    }
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      const formData = new FormData();
      for (let i in form) {
        if(!form[i] && i !== 'passRule' && i !== 'visibilityRule') {
          delete form[i];
        } else {
          formData.append(`${i}`, form[i]);
        }
      }
      if (this.topicId) {
        formData.append('id', this.topicId.toString());
        this.dataHandlerService.updateFormData(formData, COURSE_TOPIC_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.goBack();
        });
      } else {
        this.dataHandlerService.createFormData(formData, COURSE_TOPIC_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.goBack();
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }

  goBack(): void {
    this.mainService.goBackWithParams([COURSE_URL_KEY, COURSE_VIEW_URL_KEY, this.courseId])
  }
}
