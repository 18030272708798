<div class="main-card mb-3 card">
  <div class="card-body">
    <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="goBack()"></i>
      <h5 class="card-title">{{ isCreateForm ? ('TR_CREATE_SECTION' | translate) : ('TR_UPDATE_SECTION' | translate) }}</h5>
    </div>
    <form (submit)="submitData()" [formGroup]="form">
      <div class="form-row">
        <div class="col-lg-4 col-md-6">
          <div class="position-relative form-group">
            <label for="name">{{ 'TR_NAME' | translate }}</label>
            <input
              type="text"
              name="name"
              [placeholder]="'TR_NAME' | translate"
              class="form-control"
              autocomplete="off"
              formControlName="name"
            />
          </div>
        </div>
      </div>
      <app-submit-button
        [spinnerId]="submitSpinnerId"
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit"
        [isFormValid]="form.valid"
      ></app-submit-button>
    </form>
  </div>
</div>
