<div class="main-card mb-3 card">
  <div class="card-body">
    <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('page')"></i>
      <h5 class="card-title">{{ isCreateForm ? ('TR_CREATE_PAGE' | translate) : ('TR_UPDATE_PAGE' | translate) }}</h5>
    </div>
    <form (submit)="submitData()" [formGroup]="form">
      <div class="wrap-flex form-outline">
        <div ngbDropdown>
          <div class="flex flex-column">
            <label>{{ 'TR_SELECT_PARENT_PAGE' | translate }}</label>
            <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary">
              {{ selectedParentPageName ? selectedParentPageName : 'TR_SELECT_PARENT_PAGE' | translate }}
            </button>
          </div>
          <div ngbDropdownMenu>
            <button type="button" class="dropdown-item" (click)="removeSelectedParentPage()">{{ 'TR_NOTHING' | translate }}</button>
            <button type="button" class="dropdown-item" *ngFor="let item of pages" (click)="setSelectedParentPage(item.id, item.name, item.menuGroupId)">
              {{ item.name }}
            </button>
          </div>
        </div>
      </div>
      <div class="mt-2 mb-3 upload-block form-outline">
        <button type="button" class="mb-2 mr-2 btn-transition btn btn-outline-info upload-btn" (click)="avatar.click()">
          <i class="pe-7s-plus"></i>
          <p>{{ 'TR_UPLOADAVATAR' | translate }}</p>
        </button>
        <input #avatar hidden type="file" name="upload-avatar" (change)="handleFileInput($event.target.files)" />
        <div *ngIf="imagePath" [ngStyle]="{ 'background-image' : 'url(' + imagePath + ')' }" class="upload-avatar"></div>
      </div>
      <div class="form-row">
        <div class="col-lg-4 col-md-6">
          <div class="position-relative form-group">
            <label for="name">{{ ('TR_PAGE_NAME' | translate) + mainService.asterisk }}</label>
            <input
              name="name"
              [placeholder]="('TR_PAGE_NAME' | translate) + mainService.asterisk"
              type="text"
              class="form-control"
              [class.error-border]="isInvalidSubmit && !form.value.name"
              autocomplete="off"
              formControlName="name"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-4 col-md-6">
          <div class="position-relative form-group">
            <label for="uri">{{ 'URI' + mainService.asterisk }}</label>
            <input
              name="uri"
              [placeholder]="'URI' + mainService.asterisk"
              type="text"
              class="form-control"
              [class.error-border]="isInvalidSubmit && !form.value.uri"
              autocomplete="off"
              formControlName="uri"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 mt-1 mb-2 checkboxes-block">
          <div class="position-relative form-check">
            <input name="isFrontEndPage" id="isFrontEndPage" type="checkbox" class="form-check-input" formControlName="isFrontEndPage">
            <label for="isFrontEndPage" class="form-check-label">{{ 'TR_SITE_PAGE' | translate }}</label>
          </div>
          <div class="position-relative form-check">
            <input name="isDefault" id="isDefault" type="checkbox" class="form-check-input" formControlName="isDefault">
            <label for="isDefault" class="form-check-label">{{ 'TR_ISDEFAULT' | translate }}</label>
          </div>
          <div class="position-relative form-check">
            <input name="onlyForAuthorizedUsers" id="onlyForAuthorizedUsers" type="checkbox" class="form-check-input" formControlName="onlyForAuthorizedUsers">
            <label for="onlyForAuthorizedUsers" class="form-check-label">{{ 'TR_ONLY_FOR_AUTH_USERS' | translate }}</label>
          </div>
        </div>
      </div>
      <div *ngIf="form.value.isFrontEndPage">
        <div class="form-row">
          <div class="col-12"><hr /></div>
        </div>
        <div class="wrap-flex form-outline">
          <div *ngIf="hasMenuGroup" ngbDropdown>
            <div class="flex flex-column">
              <label>{{ 'TR_MENU_GROUP' | translate }}</label>
              <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary">
                {{ selectedMenuGroupName ? selectedMenuGroupName : 'TR_MENU_GROUP' | translate }}
              </button>
            </div>
            <div ngbDropdownMenu>
              <button type="button" class="dropdown-item" *ngFor="let item of menuGroups" (click)="setSelectedMenuGroup(item.id, item.groupName)">
                {{ item.groupName }}
              </button>
            </div>
          </div>
          <div ngbDropdown>
            <div class="flex flex-column">
              <label>{{ 'TR_PAGE_TYPE' | translate }}</label>
              <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary">
                {{ selectedPageTypeName ? selectedPageTypeName : 'TR_PAGE_TYPE' | translate }}
              </button>
            </div>
            <div ngbDropdownMenu>
              <button type="button" class="dropdown-item" *ngFor="let item of pageTypes" (click)="setSelectedPageType(item.value, item.key)">
                {{ item.key }}
              </button>
            </div>
          </div>
          <div *ngIf="openCategories" ngbDropdown>
            <div class="flex flex-column">
              <label>{{ 'TR_CATEGORY' | translate }}</label>
              <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary">
                {{ selectedCategoryName ? selectedCategoryName : 'TR_CATEGORY' | translate }}
              </button>
            </div>
            <div ngbDropdownMenu>
              <button type="button" class="dropdown-item" *ngFor="let item of categories" (click)="setSelectedCategory(item.id, item.name)">
                {{ item.name }}
              </button>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg-4 col-md-6">
            <div class="position-relative form-group">
              <label for="pageTitle">{{ 'TR_PAGE_TITLE' | translate }}</label>
              <input
                name="pageTitle"
                [placeholder]="'TR_PAGE_TITLE' | translate"
                type="text"
                class="form-control"
                autocomplete="off"
                formControlName="pageTitle"
              />
              <p class="warning-message">{{ 'TR_PAGE_TITLE_WARNING_MSG' | translate }}</p>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg-4 col-md-6">
            <div class="position-relative form-group">
              <label for="pageKeywords">{{ 'TR_METTA_KEYWORD' | translate }}</label>
              <input
                name="pageKeywords"
                [placeholder]="'TR_METTA_KEYWORD' | translate"
                type="text"
                class="form-control"
                autocomplete="off"
                formControlName="pageKeywords"
              />
              <p class="warning-message">{{ 'TR_PAGE_KEYWORDS_WARNING_MSG' | translate }}</p>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg-4 col-md-6">
            <div class="position-relative form-group">
              <label for="name">{{ ('TR_PAGE_CONTENT' | translate) + mainService.asterisk }}</label>
              <textarea
                name="pageContent"
                [placeholder]="('TR_PAGE_CONTENT' | translate) + mainService.asterisk"
                type="text"
                class="form-control"
                [class.error-border]="isInvalidSubmit && !form.value.pageContent"
                autocomplete="off"
                formControlName="pageContent"
              ></textarea>
            </div>
          </div>
        </div>
        
        <div class="form-row">
          <div class="col-12">
            <ckeditor *ngIf="openWysiwyg" class="editor" formControlName="pageContent"></ckeditor>
          </div>
        </div>
      </div>
      <app-submit-button
        [spinnerId]="submitSpinnerId"
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit"
        [isFormValid]="form.valid"
      ></app-submit-button>
    </form>
  </div>
</div>