<div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_CONFIGS' | translate }}</h4>
</div>

<div *ngIf="config_create" class="d-inline-block">
  <button class="mb-2 mr-2 btn btn-success create-btn" [title]="'TR_CREATE_CONFIG' | translate"
    [routerLink]="['config-form']">
    <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
  </button>
</div>

<div class="row">
  <div class="col-12">
    <div *ngIf="config_view" class="main-card mb-3 card">
      <div class="table-responsive">
        <table class="align-middle mb-0 table table-striped table-hover config-table">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ 'TR_NAME' | translate }}</th>
              <th>{{ 'TR_VALUE' | translate }}</th>
              <th>{{ 'TR_DESCRIPTION' | translate }}</th>
              <th>{{ 'TR_IS_DEFAULT' | translate }}</th>
              <th>{{ 'TR_IS_READONLY' | translate }}</th>
              <th class="text-center">{{ 'TR_ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of configs let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.value }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.isdefault }}</td>
              <td>{{ item.isreadonly }}</td>
              <td class="text-center">
                <div role="group" class="btn-group-sm btn-group">
                  <button *ngIf="config_update" class="btn-transition btn btn-outline-info"
                    [title]="'TR_UPDATE_CONFIG' | translate" [routerLink]="['config-form', item.id]">
                    <i class="pe-7s-note"></i>
                  </button>
                  <button *ngIf="config_delete" class="ml-1 btn-transition btn btn-outline-danger"
                    [title]="'TR_DELETE' | translate" (click)="confirmDelete(deleteconfirm, item.id, item.name)">
                    <i class="pe-7s-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteconfirm let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p>{{ 'TR_DELETE_ERROR' | translate }} <b class="word-break-break-word">{{ deleteConfigName }}</b>?</p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button *ngIf="config_delete" [isCurrent]="true" [spinnerId]="deleteSpinnerId"
      buttonClasses="mr-2 btn-transition btn btn-outline-info" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="delete()"></app-action-button>
    <button type="button" class="mr-2 btn-transition btn btn-outline-dark"
      (click)="c('Close click')">{{ 'TR_NO' | translate }}</button>
  </div>
</ng-template>