import { Injectable } from "@angular/core";
import { Router, Route, CanLoad, ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { AuthService } from "./auth.service";
import { LanguageService } from './language.service';
import { ADMIN_KEY } from '../Constants/main-keys';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanLoad, CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private languageService: LanguageService
  ) { }

  canLoad(route: Route): boolean {
    const { path } = route;
    return this.activate(path);
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const path = route.routeConfig.path;
    return this.activate(path);
  }

  private activate(path: string): boolean {
    const loggedIn = !!this.authService.userToken;
    const canLoad = path === ':language/admin' && loggedIn || path === ':language' && !loggedIn;
    let currentLanguage = this.languageService.currentLanguage;

    if (!currentLanguage) {
      this.languageService.setLanguage('language', 'en');
      currentLanguage = this.languageService.currentLanguage;
    }

    if (loggedIn && this.authService.isTokenExpired()) {
      this.authService.logout();
      this.router.navigate([currentLanguage, ADMIN_KEY]);
    }

    if (!canLoad) {
      const newPath = path === ':language/admin' ? '' : ADMIN_KEY;
      newPath ? this.router.navigate([currentLanguage, newPath]) : this.router.navigate([currentLanguage]);
    }

    return canLoad;
  }
}
