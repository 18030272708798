import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { guid } from '@progress/kendo-angular-common';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { AlertService } from 'src/app/Services/alert.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { ILanguageData, ITranslationData } from 'src/app/Models/ILanguageData';
import { TRANSLATIONS_PATH_KEY } from 'src/app/Constants/request-path-keys';
import {
  SUCCESS_KEY,
  TRANSLATION_CREATE,
  TRANSLATION_DELETE,
  TRANSLATION_TYPES_KEY,
  TRANSLATION_UPDATE,
  TRANSLATION_VIEW,
  TR_CREATE_MESSAGE_KEY,
  TR_CREATE_TRANSLATIONS_KEY,
} from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-translation-form',
  templateUrl: './translation-form.component.html',
  styleUrls: ['./translation-form.component.scss']
})
export class TranslationFormComponent implements OnInit {
  form: FormGroup;
  selectedTypeName: string;
  selectedLanguageName: string;
  createSpinnerId: string = guid();
  translation_view: boolean;
  translation_create: boolean;
  translation_update: boolean;
  translation_delete: boolean;
  isInvalidSubmit: boolean;
  hasKeyUniqueError: boolean;
  isCreateForm: boolean = true;
  userRoles: Array<string> = [];
  translationTypes: Array<string> = TRANSLATION_TYPES_KEY;

  @Input() languageId: number;
  @Input() languageIsocode: string;
  @Input() translationId: number;
  @Input() translations: Array<ITranslationData> = [];
  @Input() languages: Array<ILanguageData> = [];
  @Output() updateEmit = new EventEmitter();
  @Output() createEmit = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private translationService: TranslationService,
    private alertService: AlertService,
    public mainService: MainService,
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_CREATE_TRANSLATIONS_KEY);

    this.form = this.formBuilder.group({
      languageId: [null, Validators.required],
      shortKey: ['', [Validators.required, Validators.pattern("^[a-zA-Z_]*$")]],
      translation1: ['', Validators.required],
      type: ['', Validators.required],
    });

    this.languageId && this.form.patchValue({ languageId: this.languageId });
    this.languages.length && this.getUpdateLanguageName();
    this.languages.length && this.setCreateLanguageData();
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch (role) {
        case TRANSLATION_VIEW:
          this.translation_view = true;
          break;
        case TRANSLATION_CREATE:
          this.translation_create = true;
          break;
        case TRANSLATION_UPDATE:
          this.translation_update = true;
          break;
        case TRANSLATION_DELETE:
          this.translation_delete = true;
          break;
      }
    });
  }

  getUpdateLanguageName(): void {
    const selectedLanguage = this.languages.find(data => data.languageId === this.form.value.languageId);
    if (selectedLanguage) {
      this.selectedLanguageName = selectedLanguage.name;
    }
  }

  setCreateLanguageData(): void {
    if (this.languageIsocode) {
      const selectedLanguage: ILanguageData = this.languages.find(data => data.isocode2 === this.languageIsocode);
      if (selectedLanguage) {
        this.form.patchValue({ languageId: selectedLanguage.languageId });
        this.selectedLanguageName = selectedLanguage.name;
      }
    }
  }

  setSelectedType(name: string, index: number): void {
    this.form.patchValue({ type: index });
    this.selectedTypeName = name;
  }

  setSelectedLanguage(id: number, name: string): void {
    this.form.patchValue({ languageId: id });
    this.selectedLanguageName = name;
  }

  removeTranslationText() {
    this.form.patchValue({ translation1: '' });
  }

  checkUniqueKey(value: string): void {
    if (!this.isCreateForm) { return; }
    const selectedTranslation = this.translations.find(data => data.shortKey === value);
    if (selectedTranslation) {
      this.hasKeyUniqueError = true;
    } else {
      this.hasKeyUniqueError = false;
    }
  }

  submitData(): void {
    if (this.translation_create) {
      if (this.form.valid) {
        const form = { ...this.form.value };
        this.dataHandlerService.createData(form, TRANSLATIONS_PATH_KEY, this.createSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.createEmit.emit();
        });
      } else {
        this.isInvalidSubmit = true;
      }
    }
  }

}
