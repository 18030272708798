import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { IQuestionData } from 'src/app/Models/IQuestionModel';
import { MainService } from 'src/app/Services/main.service';

@Component({
  selector: 'app-matching-question-answer',
  templateUrl: './matching-question-answer.component.html',
  styleUrls: ['./matching-question-answer.component.scss']
})
export class MatchingQuestionAnswerComponent implements OnInit, OnChanges {
  form: FormGroup;

  @Input() questionData: IQuestionData;
  @Output() submitEmmiter = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    public mainService: MainService,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      isShuffle: [true],
      answers: this.formBuilder.array([])
    });

    this.addAnswer();

    this.mainService.questionSubmitted.subscribe(data => {
      if (data) {
        const form = { ...this.form.value };
        form.answers = this.checkAnswers(form.answers);
        this.submitEmmiter.emit(form);
      }
    });

    this.setUpdateQuestionData();
  }

  ngOnChanges() {
    this.setUpdateQuestionData();
  }

  setUpdateQuestionData(): void {
    if(this.form && this.questionData) {
      this.form.patchValue({
        isShuffle: this.questionData.isShuffle,
      });

      this.questionData.answers && this.questionData.answers.length && this.setUpdateAnswers();
    }
  }

  setUpdateAnswers(): void {
    const answers = <FormArray>this.form.get('answers')['controls'];

    this.questionData.answers.forEach((answer, index) => {
      if(answers[index]) {
        answers[index].patchValue({ text: answer.text, question: answer.question, id: answer.id });
      } else {
        answers.push(
          this.formBuilder.group({
            text: [answer.text],
            question: [answer.question],
            id: [answer.id]
          })
        );
      }
    });

    this.form.patchValue({ answers: answers });
  }

  checkAnswers(answers: Array<any>): Array<any> {
    const newAnswers = answers.filter(data => {
      for(let i in data) {
        data[i] === null && delete data[i];
      }

      if(data.text && data.question) { return data; }
    });
    return newAnswers;
  }

  addAnswer(): void {
    let answers = <FormArray>this.form.controls.answers;
    for (let i = 0; i < 3; i++) {
      answers.push(
        this.formBuilder.group({
          id: [null],
          text: [''],
          question: ['']
        })
      );
    }
  }

}
