import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { guid } from '@progress/kendo-angular-common';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { AlertService } from 'src/app/Services/alert.service';
import { IDocumentData } from 'src/app/Models/IDocumentData';
import { DOCUMENT_SIGN_PATH_KEY, DOCUMENT_URL_KEY, ORGANIZATION_DOCUMENT_PATH_KEY } from 'src/app/Constants/request-path-keys';
import { ERROR_KEY, ORGANIZATIONDOCUMENT_SIGN, TR_DOCUMENT_SUBSCRIPTION_KEY, TR_VALID_IMAGE_MESSAGE_ERROR_KEY, VALID_IMAGE_TYPES_KEY } from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-document-subscription',
  templateUrl: './document-subscription.component.html',
  styleUrls: ['./document-subscription.component.scss']
})
export class DocumentSubscriptionComponent implements OnInit {
  documentId: number;
  documentData: IDocumentData;
  selectedFile: File;
  fileName: string;
  imgURL: any;
  userRoles: Array<string>;
  document_sign: boolean;
  isInvalidSubmit: boolean;
  submitSpinnerId: string = guid();

  constructor(
    private route: ActivatedRoute,
    private dataHandlerService: DataHandlerService,
    private translationService: TranslationService,
    private rolesService: RolesService,
    public mainService: MainService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.document_sign = this.userRoles.includes(ORGANIZATIONDOCUMENT_SIGN);
    this.translationService.setPageTitle(TR_DOCUMENT_SUBSCRIPTION_KEY);

    this.route.paramMap.subscribe(params => {
      this.documentId = +params.get('item.id');
      if (this.documentId) {
        this.getDocumentData(this.documentId);
      }
    });
  }

  async getDocumentData(id: number) {
    this.documentData = await this.dataHandlerService.getDataWithSpinner(`${ORGANIZATION_DOCUMENT_PATH_KEY}/${id}`);
  }

  submitData(): void {
    if (this.selectedFile) {
      let formData: any = new FormData();
      formData.append('documentId', this.documentId);
      formData.append('DocumentSignedFile', this.selectedFile);

      this.dataHandlerService.updateFormData(formData, DOCUMENT_SIGN_PATH_KEY, this.submitSpinnerId).then(() => {
        this.mainService.goBack(DOCUMENT_URL_KEY);
      });
    } else {
      this.isInvalidSubmit = true;
    }
  }

  handleFileInput(files: FileList): void {
    const file = files[0];
    if (!file) { return; }
    const validTypes = VALID_IMAGE_TYPES_KEY;
    this.fileName = file.name;
    this.selectedFile = file;
    const lastDotIndex = this.fileName.lastIndexOf('.');
    const fileExtention = lastDotIndex !== -1 ? this.fileName.substring(lastDotIndex + 1) : ' ';
    const isValid = validTypes.find(data => data === fileExtention);

    if (!isValid) {
      this.alertService.translateAndAlertMessage(TR_VALID_IMAGE_MESSAGE_ERROR_KEY, ERROR_KEY);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.imgURL = reader.result;
      }
    }
  }

  removeFile(): void {
    this.fileName = null;
    this.selectedFile = null;
    this.imgURL = null;
  }
}
