<div class="main-card mb-3 card">
  <div class="card-body">
    <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('language')"></i>
      <h5 class="card-title">{{ isCreateForm ? ('TR_CREATE_LANGUAGE' | translate) : ('TR_UPDATE_LANGUAGE' | translate) }}</h5> 
    </div>
    <form (submit)="submitData()" [formGroup]="form">
      <div class="form-row">
        <div class="col-lg-4 col-md-4">
          <div class="position-relative form-group">
            <label for="name">{{ ('TR_NAME' | translate) + mainService.asterisk }}</label>
            <input
              [class.error-border]="isInvalidSubmit && !form.value.name"
              name="name"
              [placeholder]="('TR_NAME' | translate) + mainService.asterisk"
              type="text"
              class="form-control"
              autocomplete="off"
              formControlName="name"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-4 col-md-4">
          <div class="position-relative form-group">
            <label for="isocode">{{ ('TR_ISO_CODE' | translate) + mainService.asterisk }}</label>
            <input
              name="isocode"
              [placeholder]="('TR_ISO_CODE' | translate) + mainService.asterisk"
              type="text"
              class="form-control"
              [class.error-border]="isInvalidSubmit && !form.value.isocode"
              autocomplete="off"
              formControlName="isocode"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-4 col-md-4">
          <div class="position-relative form-group">
            <label for="isocode2">{{ ('TR_ISO_CODE_TWO' | translate) + mainService.asterisk }}</label>
            <input
              name="isocode2"
              [placeholder]="('TR_ISO_CODE_TWO' | translate) + mainService.asterisk"
              type="text"
              class="form-control"
              [class.error-border]="isInvalidSubmit && !form.value.isocode2"
              autocomplete="off"
              formControlName="isocode2"
            />
          </div>
        </div>
      </div>
      <app-submit-button
        [spinnerId]="submitSpinnerId"
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit"
        [isFormValid]="form.valid"
      ></app-submit-button>
    </form>
  </div>
</div>