import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { guid } from '@progress/kendo-angular-common';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { AlertService } from 'src/app/Services/alert.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { IUserData } from 'src/app/Models/IUserData';
import { IPaymentPackageData } from 'src/app/Models/IPaymentPackageData';
import { IOrganizationData } from 'src/app/Models/IOrganizationData';
import {
  ERROR_KEY,
  ORGANIZATION_CREATE,
  ORGANIZATION_UPDATE,
  ORGANIZATION_VIEW,
  PAYMENT_PACKAGE_VIEW,
  SUCCESS_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_CREATE_ORGANIZATION_KEY,
  TR_UPDATE_MESSAGE_KEY,
  TR_UPDATE_ORGANIZATION_KEY,
  USER_VIEW,
  VALID_IMAGE_TYPES_KEY
} from 'src/app/Constants/main-keys';
import {
  ORGANIZATIONS_PATH_KEY,
  PAGE_NUMBER_PATH_PARAM_KEY,
  PAGE_SIZE_PATH_PARAM_KEY,
  PAYMENT_PACKAGES_PATH_KEY,
  USERS_PATH_KEY,
  USER_TYPE_ID_PATH_PARAM_KEY
} from 'src/app/Constants/request-path-keys';

@Component({
  selector: 'app-organization-form',
  templateUrl: './organization-form.component.html',
  styleUrls: ['./organization-form.component.scss']
})
export class OrganizationFormComponent implements OnInit {
  form: FormGroup;
  selectedAdminId: number;
  selectedAdminName: string;
  selectedPaymentPackageId: number;
  selectedPaymentPackageType: number;
  recurringPayment: boolean = false;
  monthCount: number;
  selectedPaymentPackageName: string;
  organizationId: number;
  admins: Array<IUserData>;
  organizations: Array<IOrganizationData>;
  paymentPackages: Array<IPaymentPackageData> = [];
  userRoles: Array<string>;
  organization_view: boolean;
  organization_create: boolean;
  organization_update: boolean;
  paymentpackage_view: boolean;
  user_view: boolean;
  hideFields: boolean;
  //isInvalidStreamUrl: boolean;
  isInvalidSubmit: boolean;
  isCreateForm: boolean = true;
  submitSpinnerId: string = guid();
  today: String = new Date().toISOString().split('T')[0];
  monthCounts: [];
  uploadedFile: any;
  isInvalidCheque: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    private translationService: TranslationService,
    public mainService: MainService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_CREATE_ORGANIZATION_KEY);

    this.getOrganizations();
    this.getAdmins();
    this.getPaymentPackages();

    this.form = this.formBuilder.group({
      adminUserId: [null, Validators.required],
      paymentPackageId: [null],
      name: ['', Validators.required],
      email: ['', Validators.email],
      // streamUrl: ['', Validators.required],
      // deadline: ['', Validators.required],
      // pushLogin: [''],
      // pushPassword: [''],
      description: [''],
      address: [''],
      phone: ['', Validators.pattern(`^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$`)],
      mobilePhone: ['', Validators.pattern(`^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$`)],
      shortName: [''],
      isActive: [true],
      sendEmail: [true],
      sendNotification: [true],
      recurringPayment: [false],
      // monthCount: this.selectedPaymentPackageType != 0 ? [null, Validators.required] : [null],
      monthCount: [null],
      cheque: [null],
    });

    // this.form.get('streamUrl').valueChanges.subscribe(data => {
    //   if (this.organizations && !this.isInvalidStreamUrl && !this.organizationId) {
    //     const streamItem = this.organizations.find(item => item.streamUrl === data);
    //     if (streamItem) {
    //       this.isInvalidStreamUrl = true;
    //     }
    //   }
    // });

    this.route.paramMap.subscribe(params => {
      this.organizationId = +params.get('item.id');
      if (this.organizationId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle(TR_UPDATE_ORGANIZATION_KEY);
        this.getOrganizationById();
      }
    });
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case ORGANIZATION_VIEW:
          this.organization_view = true;
          break;
        case ORGANIZATION_CREATE:
          this.organization_create = true;
          break;
        case ORGANIZATION_UPDATE:
          this.organization_update = true;
          break;
        case USER_VIEW:
          this.user_view = true;
          break;
        case PAYMENT_PACKAGE_VIEW:
          this.paymentpackage_view = true;
          break;
      }
    });
  }

  getOrganizationById() {
    if (!this.organization_view) { return; }
    this.dataHandlerService.getDataWithSpinner(`${ORGANIZATIONS_PATH_KEY}/${this.organizationId}`).then(response => {
      const organizationData: IOrganizationData = response;

      this.selectedPaymentPackageName = organizationData.paymentPackageName;
      this.selectedAdminName = organizationData.organizationAdminName;
      this.selectedAdminId = organizationData.adminUserId;
      this.monthCount = organizationData.monthCount;
      if (organizationData.paymentPackageId) {
        this.setSelectedPaymentPackage(organizationData.paymentPackageId, organizationData.paymentPackageName, organizationData.type)
      }
      this.uploadedFile = organizationData.chequePath;
      this.recurringPayment = organizationData.recurringPayment;
      this.form.patchValue({
        adminUserId: organizationData.adminUserId,
        paymentPackageId: organizationData.paymentPackageId,
        monthCount: organizationData.monthCount,
        recurringPayment: organizationData.recurringPayment,
        name: organizationData.name,
        // streamUrl: organizationData.streamUrl,
        description: organizationData.description,
        // deadline: this.mainService.getDateFormat(new Date(organizationData.deadline)),
        isActive: organizationData.isActive,
        email: organizationData.email,
        address: organizationData.address,
        phone: organizationData.phone,
        mobilePhone: organizationData.mobilePhone,
        shortName: organizationData.shortName,
        // pushLogin: organizationData.pushLogin,
        // pushPassword: organizationData.pushPassword,
        sendEmail: organizationData.sendEmail,
        sendNotification: organizationData.sendNotification,
        // cheque: organizationData.chequePath,
      });
      // this.form.controls.streamUrl.disable();
      this.hideFields = true;
    });
  }

  async getOrganizations() {
    if (!this.organization_view) { return; }
    this.organizations = await this.dataHandlerService.getDataWithSpinner(ORGANIZATIONS_PATH_KEY);
  }

  async getPaymentPackages() {
    if (!this.paymentpackage_view) { return; }
    this.paymentPackages = await this.dataHandlerService.getDataWithSpinner(PAYMENT_PACKAGES_PATH_KEY);
  }

  async getAdmins() {
    if (!this.user_view) { return; }
    this.admins = await this.dataHandlerService.getDataWithSpinner(
      // `${USERS_PATH_KEY}?${USER_TYPE_ID_PATH_PARAM_KEY}${2}&${PAGE_NUMBER_PATH_PARAM_KEY}${1}&${PAGE_SIZE_PATH_PARAM_KEY}${100}`
      `user/admins`
    );
  }

  async handleFileUpload(event: any) {
    const files = event.target.files;
    if (files && files[0]) {
      this.mainService.imageUpload(files[0]).then((response: any) => {
        if (response) {
          this.uploadedFile = response;
          this.isInvalidCheque = false;
          this.form.patchValue({ cheque: files[0] });
        }
      });
    }
  }

  setSelectedAdmin(id: number, name: string): void {
    this.selectedAdminId = id;
    this.selectedAdminName = name;
    this.form.patchValue({ adminUserId: id });
  }

  setSelectedPaymentPackage(id: number, name: string, type: number): void {
    this.selectedPaymentPackageId = id;
    this.selectedPaymentPackageName = name;
    this.selectedPaymentPackageType = type;

    this.form.patchValue({ paymentPackageId: id });
    // request
    this.dataHandlerService.getDataWithSpinner(`paymentPackage/withprices/${id}/USD`
    ).then(response => {
      this.monthCounts = response.prices;
    });
  }

  setSelectedMonthCount(monthCount: any): void {
    if (!monthCount) { return; }
    this.monthCount = monthCount;
    this.form.patchValue({ monthCount: +monthCount });
  }

  onChechboxChange(event: any): void {
    this.recurringPayment = event.target.checked;
    this.form.patchValue({ recurringPayment: event.target.checked });
  }

  submitData(): void {
    //this.form.controls.streamUrl.enable();
    // if (this.form.valid && !this.isInvalidStreamUrl)
    if (this.form.valid) {
      const form = { ...this.form.value };
      if (!form.name.trim() || !form.email.trim() || !form.paymentPackageId || (this.selectedPaymentPackageType != 0 && !form.monthCount)) {
        this.isInvalidSubmit = true;
        return;
      }
      form.address = form.address ? form.address.trim() : "";
      form.description = form.description ? form.description.trim() : "";
      form.shortName = form.shortName ? form.shortName.trim() : "";
      if (!form.cheque && !this.uploadedFile && this.selectedPaymentPackageType != 0) {
        this.isInvalidCheque = true;
        return;
      }
      if (!form.mobilePhone) delete form.mobilePhone;
      if (!form.phone) delete form.phone;
      if (this.selectedPaymentPackageType == 0) delete form.monthCount;
      const formData = new FormData();
      if (this.organizationId) {
        delete form.paymentPackageId;
        delete form.cheque;
        delete form.recurringPayment;
        delete form.monthCount;
      }
      for (let i in form) {
        formData.append(`${i}`, form[i]);
      }
      if (this.organizationId && this.organization_update) {
        // form.id = this.organizationId;
        formData.append(`id`, `${this.organizationId}`);
        this.dataHandlerService.updateFormData(formData, ORGANIZATIONS_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(ORGANIZATIONS_PATH_KEY);
        });
      } else if (this.organization_create) {
        this.dataHandlerService.createFormData(formData, ORGANIZATIONS_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(ORGANIZATIONS_PATH_KEY);
        });
      }
    } else {
      //this.form.controls.streamUrl.disable();
      this.isInvalidSubmit = true;
    }
  }

  generatePassword() {
    const randomPassword = Math.random().toString(36).slice(-8);
    this.form.patchValue({ password: randomPassword });
  }
}
