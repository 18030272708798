<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon k-cursor-pointer goback" (click)="mainService.goBack('user')"
        [title]="'TR_GOBACK' | translate"><i class="icon pe-7s-left-arrow icon-gradient bg-plum-plate"></i></div>
      <div>{{ isCreateForm ? ('TR_CREATEUSER' | translate) : ('TR_UPDATEUSER' | translate) }}
      </div>
    </div>
  </div>
</div>
<div class="main-card mb-3 card">
  <div class="card-body">
    <!-- <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('user')"></i>
      <h5 class="card-title">{{ isCreateForm ? ('TR_CREATEUSER' | translate) : ('TR_UPDATEUSER' | translate) }}</h5>
    </div> -->
    <div class="wrap-flex form-outline">
      <div *ngIf="organization_view" ngbDropdown>
        <div class="flex flex-column mr-2">
          <!-- <label>{{ 'TR_ORGANIZATIONS' | translate }}</label> -->
          <button type="button" ngbDropdownToggle
            class="mb-2 btn btn-outline-primary dropdown-toggle form-dropdown-color">
            {{ selectedOrganizationName ? selectedOrganizationName : 'TR_ORGANIZATIONS' | translate }}
          </button>
        </div>
        <div ngbDropdownMenu>
          <button type="button" class="dropdown-item" *ngFor="let item of organizations"
            (click)="setSelectedOrganization(item.id, item.name)">
            {{ item.name }}
          </button>
        </div>
      </div>
      <div *ngIf="usertype_view" ngbDropdown>
        <div class="flex flex-column mr-2">
          <!-- <label>{{ ('TR_USERTYPES' | translate) + mainService.asterisk }}</label> -->
          <button type="button" ngbDropdownToggle class="mb-2 btn btn-outline-primary dropdown-toggle"
            [class.error-border]="isInvalidSubmit && !selectedUserTypeId">
            {{ selectedUserTypeName ? selectedUserTypeName : ('TR_USERTYPES' | translate) + mainService.asterisk }}
          </button>
        </div>
        <div ngbDropdownMenu>
          <button type="button" class="dropdown-item" *ngFor="let item of userTypes"
            (click)="setSelectedUserType(item.id, item.displayName || item.name)">
            {{ item.displayName || item.name }}
          </button>
        </div>

      </div>

      <div ngbDropdown>
        <div class="flex flex-column mr-2">
          <!-- <label>{{ 'TR_TIME_ZONE' | translate }}</label> -->
          <button type="button" ngbDropdownToggle class="mb-2 btn btn-outline-primary dropdown-toggle">
            {{ selectedTimeZone ? selectedTimeZone : 'TR_TIME_ZONE' | translate }}
          </button>
        </div>
        <!-- TimeZone Data -->
        <div ngbDropdownMenu>
          <div class="search-input-block">
            <input class="search-input" type="text" (keyup)=searchTimeZone($event) />
          </div>
          <button type="button" class="dropdown-item" *ngFor="let item of timeZoneData"
            (click)="setSelectedTimeZone(item.name)">
            {{ item.displayName }}
          </button>
        </div>
      </div>
      <div ngbDropdown>
        <div class="flex flex-column mr-2">
          <!-- <label>{{ 'TR_LANGUAGES' | translate }}</label> -->
          <button type="button" ngbDropdownToggle class="mb-2 btn btn-outline-primary dropdown-toggle">
            {{ selectedLanguageName ? selectedLanguageName : 'TR_LANGUAGES' | translate }}
          </button>
        </div>
        <div ngbDropdownMenu>
          <button type="button" class="dropdown-item" *ngFor="let item of languages"
            (click)="setSelectedLanguage(item.languageId, item.name)">
            {{ item.name }}
          </button>
        </div>
      </div>
    </div>
    <hr>
    <div class="my-2 upload-block form-outline">
      <button type="button" class="mb-2 mr-3 btn-transition btn btn-warning upload-btn" (click)="avatar.click()">
        <i class="pe-7s-plus"></i>
        <p>{{ 'TR_UPLOADAVATAR' | translate }}</p>
      </button>
      <input #avatar hidden type="file" name="upload-avatar" (change)="handleFileInput($event.target.files)" />
      <div *ngIf="uploadedAvatarPath" [ngStyle]="{ 'background-image' : 'url(' + uploadedAvatarPath + ')' }"
        class="upload-avatar"></div>
    </div>
    <form (submit)="submitData()" [formGroup]="form">
      <div class="form-row">
        <div [ngClass]="!userId && users.length ? 'col-md-4' : 'col-md-8'">
          <div class="position-relative form-group">
            <label for="username">{{ 'TR_USERNAME' | translate }}</label>
            <!-- [placeholder]="'TR_USERNAME' | translate" -->
            <input name="username" type="text" class="form-control" autocomplete="off" formControlName="username"
              (ngModelChange)="filter($event, 'username')" />
            <p class="warning-message mt-1">{{ 'TR_USERNAME_WARNING_MSG' | translate }}</p>
          </div>
        </div>
        <div [ngClass]="!userId && users.length ? 'col-md-4' : 'col-md-4'" *ngIf="!userId && users.length">
          <!-- <hr /> -->
          <div class="existing-users-block form-group">
            <p class="m-0">{{ 'TR_SELECTEXISTINGUSER' | translate }}</p>
            <div *ngFor="let item of users">
              <button class="button" class="mb-2 mr-2 btn btn-focus active w-100" (click)="getUserData(item)">
                {{ item.lastname }} {{ item.firstname }} {{ item.middlename }}
              </button>
            </div>
          </div>
          <!-- <hr /> -->
        </div>
        <div [ngClass]="!userId && users.length ? 'col-md-4' : 'col-md-4'">
          <div class="position-relative form-group">
            <label for="password">{{ 'TR_PASSWORD' | translate }}</label>
            <!-- <div class="input-group row m-0"> -->
            <div class="input-button-group">
              <!-- [placeholder]="'TR_PASSWORD' | translate" -->
              <input name="password" type="text" class="form-control" autocomplete="new-password"
                formControlName="password" />
              <button type="button" class="btn btn-primary" (click)="generatePassword()">
                {{ 'TR_GENERATE_PASSWORD' | translate }}
              </button>
            </div>
            <!-- <div class="input-group-append col-6 p-0">
              </div> -->
            <!-- </div> -->
            <p class="warning-message mt-1">{{ 'TR_PASSWORD_WARNING_MSG' | translate }}</p>
          </div>
        </div>
        <!-- <div class="col-md-4">
          <label for="password">{{ 'TR_PASSWORD' | translate }}</label>
          <div class="input-group row m-0">
            <div class="col-6 p-0">
              [placeholder]="'TR_PASSWORD' | translate"
              <input name="password" type="text" class="form-control" autocomplete="new-password"
                formControlName="password" />
            </div>
            <div class="input-group-append col-6 p-0">
              <button type="button" class="btn btn-primary" (click)="generatePassword()">
                {{ 'TR_GENERATE_PASSWORD' | translate }}
              </button>
            </div>
          </div>
          <p class="warning-message mt-1">{{ 'TR_PASSWORD_WARNING_MSG' | translate }}</p>
        </div> -->
      </div>
      <div class="form-row">
        <div class="col-md-4 mt-2">
          <div class="position-relative form-group">
            <label for="lastname">{{ ('TR_LASTNAME' | translate) + mainService.asterisk }}</label>
            <!-- [placeholder]="('TR_LASTNAME' | translate) + mainService.asterisk" -->
            <input name="lastname" type="text" class="form-control"
              [class.error-border]="(isInvalidSubmit && (!form.value.lastname || !form.value.lastname.trim() || form.value.lastname.trim().length < 2 || form.value.lastname.trim().length >= 100)) || isInvalidLastname"
              autocomplete="off" formControlName="lastname" (blur)="checkFieldIsValid($event,'lastname')" />
            <p class="warning-message mt-1">{{ 'TR_LAST_NAME_WARNING_MSG' | translate }}</p>
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="position-relative form-group">
            <label for="firstname">{{ ('TR_FIRSTNAME' | translate) + mainService.asterisk }}</label>
            <!-- [placeholder]="('TR_FIRSTNAME' | translate) + mainService.asterisk" -->
            <input name="firstname" type="text" class="form-control"
              [class.error-border]="(isInvalidSubmit && (!form.value.firstname || !form.value.firstname.trim() || form.value.firstname.trim().length < 2 || form.value.firstname.trim().length >=100)) || isInvalidFirstname"
              autocomplete="off" formControlName="firstname" (blur)="checkFieldIsValid($event,'firstname')" />
            <p class="warning-message mt-1">{{ 'TR_FIRST_NAME_WARNING_MSG' | translate }}</p>
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="position-relative form-group">
            <label for="middlename">{{ 'TR_MIDDLENAME' | translate }}</label>
            <!-- [placeholder]="'TR_MIDDLENAME' | translate" -->
            <input name="middlename" type="text" class="form-control" autocomplete="off" formControlName="middlename" />
            <p class="warning-message mt-1">{{ 'TR_MIDDLE_NAME_WARNING_MSG' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4 mt-2">
          <div class="position-relative form-group">
            <label for="email">{{ ('TR_EMAIL' | translate) + mainService.asterisk }}</label>
            <!-- [placeholder]="('TR_EMAIL' | translate) + mainService.asterisk" -->
            <input name="email" type="email" class="form-control"
              [class.error-border]="(isInvalidSubmit && !form.value.email) || form.controls['email'].hasError('email')"
              autocomplete="off" formControlName="email" (ngModelChange)="filter($event, 'email')" />
            <p class="warning-message mt-1">{{ 'TR_EMAIL_WARNING_MSG' | translate }}</p>
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="position-relative form-group">
            <label for="dob">{{ 'TR_DOB' | translate }}</label>
            <!-- [placeholder]="'TR_DOB' | translate" -->
            <input name="dob" type="date" [max]="today" class="form-control" autocomplete="off" formControlName="dob"
              [class.error-border]="isInvalidDob" (blur)="checkDates($event)" />
            <p class="warning-message mt-1">{{ 'TR_DATE_WARNING_MSG' | translate }}</p>
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="position-relative form-group">
            <label for="phone">{{ 'TR_PHONE' | translate }}</label>
            <!-- [placeholder]="'TR_PHONE' | translate" -->
            <input name="phone" type="tel" autocomplete="off" class="form-control"
              [class.error-border]="form.controls['phone'].hasError('pattern') || (isInvalidPhonenumber && form.value.phone)"
              autocomplete="off" formControlName="phone" (ngModelChange)="filter($event, 'phone')"
              (blur)="checkFieldIsValid($event,'phonenumber')" />
            <p class="warning-message mt-1">{{ 'TR_PHONE_WARNING_MSG' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4 mt-2">
          <div class="position-relative form-group">
            <label for="mobilePhone">{{ 'TR_MOBILEPHONE' | translate }}</label>
            <!-- [placeholder]="'TR_MOBILEPHONE' | translate" -->
            <input name="mobilePhone" type="tel" class="form-control"
              [class.error-border]="form.controls['mobilePhone'].hasError('pattern') || (isInvalidPhonenumber && form.value.mobilePhone)"
              autocomplete="off" formControlName="mobilePhone" (ngModelChange)="filter($event, 'mobilePhone')"
              (blur)="checkFieldIsValid($event,'phonenumber')" />
            <p class="warning-message mt-1">{{ 'TR_PHONE_WARNING_MSG' | translate }}</p>
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="position-relative form-group">
            <label for="addressResidence">{{ 'TR_ADDRESSRESIDENCE' | translate }}</label>
            <!-- [placeholder]="'TR_ADDRESSRESIDENCE' | translate" -->
            <input name="addressResidence" type="text" class="form-control" autocomplete="off"
              formControlName="addressResidence" />
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="position-relative form-group">
            <label for="addressRegistration">{{ 'TR_ADDRESSREGISTRATION' | translate }}</label>
            <!-- [placeholder]="'TR_ADDRESSREGISTRATION' | translate" -->
            <input name="addressRegistration" type="text" class="form-control" autocomplete="off"
              formControlName="addressRegistration" />
          </div>
        </div>
      </div>
      <hr>
      <div class="form-row">
        <div class="col-md-12 mb-2 checkboxes-block">
          <div class="position-relative form-check">
            <input name="sendEmail" id="sendEmail" type="checkbox" class="form-check-input" formControlName="sendEmail">
            <label for="sendEmail" class="form-check-label">{{ 'TR_SEND_EMAIL' | translate }}</label>
          </div>
          <div class="position-relative form-check">
            <input name="sendNotification" id="sendNotification" type="checkbox" class="form-check-input"
              formControlName="sendNotification">
            <label for="sendNotification" class="form-check-label">{{ 'TR_SEND_NOTIFICATION' | translate }}</label>
          </div>
        </div>
      </div>
      <div class="form-row">
      </div>
      <div class="form-row">
      </div>
      <app-submit-button [spinnerId]="submitSpinnerId"
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit" [isFormValid]="form.valid"></app-submit-button>
    </form>
  </div>
</div>