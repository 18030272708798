import { NgModule } from "@angular/core";
import { SanitizeHtml } from './sanitize.pipe';
import { TranslatePipe } from "./translate.pipe";

@NgModule({
  declarations: [
    TranslatePipe,
    SanitizeHtml,
  ],
  exports: [
    TranslatePipe,
    SanitizeHtml,
  ]
})
export class PipeModule { }