<div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_TEMPLATE_INFO' | translate }}</h4>
</div>

<div class="wrap-flex">
  <div *ngIf="templateinfo_create">
    <button class="mb-2 mr-2 btn btn-success create-btn" [title]="'TR_CREATE_TEMPLATE_INFO' | translate"
      [routerLink]="['templates-info-form']">
      <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
    </button>
  </div>

  <div *ngIf="template_view" ngbDropdown>
    <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary">
      {{ selectedTemplateName ? selectedTemplateName : 'TR_TEMPLATEE' | translate }}
    </button>
    <div ngbDropdownMenu>
      <button class="dropdown-item" (click)="getTemplatesInfo()">{{ 'TR_ALL' | translate }}</button>
      <button class="dropdown-item" *ngFor="let item of templates"
        (click)="setSelectedTemplate(item.id, item.name)">{{ item.name }}</button>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div *ngIf="templateinfo_view" class="main-card mb-3 card">
      <div class="table-responsive">
        <table class="align-middle mb-0 table table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ 'TR_INFO_TYPE' | translate }}</th>
              <th>{{ 'TR_TEMPLATE_NAME' | translate }}</th>
              <th>{{ 'TR_POSITION' | translate }}</th>
              <th *ngIf="templateinfo_update" class="text-right">{{ 'TR_ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of templatesInfo let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.templateName }}</td>
              <td>{{ item.position }}</td>
              <td *ngIf="templateinfo_update" class="text-right">
                <div role="group" class="btn-group-sm btn-group">
                  <button class="btn-transition btn btn-outline-primary" [title]="'TR_UPDATE_TEMPLATE_INFO' | translate"
                    [routerLink]="['templates-info-form', item.id]">
                    <i class="pe-7s-note"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>