import { Component, OnInit } from '@angular/core';
import { animate, query, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { LanguageService } from 'src/app/Services/language.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { MainService } from 'src/app/Services/main.service';
import { ILanguageData } from 'src/app/Models/ILanguageData';
import { LANGUAGE_KEY } from 'src/app/Constants/main-keys';
import { LANGUAGES_PATH_KEY } from 'src/app/Constants/request-path-keys';

@Component({
  selector: 'app-pages-layout',
  templateUrl: './pages-layout.component.html',
  styleUrls: ['./pages-layout.component.scss'],
  animations: [

    trigger('architectUIAnimation', [
      transition('* <=> *', [
        query(':enter, :leave', [
          style({
            opacity: 0,
            display: 'flex',
            flex: '1',
            flexDirection: 'column'

          }),
        ], { optional: true }),
        query(':enter', [
          animate('600ms ease', style({ opacity: 1 })),
        ], { optional: true }),

        query(':leave', [
          animate('600ms ease', style({ opacity: 0 })),
        ], { optional: true })
      ]),
    ])
  ]
})
export class PagesLayoutComponent implements OnInit {
  languages: Array<ILanguageData> = [];
  currentLanguageName: string;

  constructor(
    private dataHandlerService: DataHandlerService,
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private router: Router,
    private translationService: TranslationService,
    private mainService: MainService,
  ) { }

  ngOnInit() {
    this.getLanguages();
  }

  getLanguages() {
    this.dataHandlerService.getData(LANGUAGES_PATH_KEY).then(response => {
      // this.languages = response;
      this.languages = response.splice(0, 2);
      this.getLanguageName();
    });
  }

  getLanguageName() {
    this.currentLanguageName = this.languages.find(data => data.isocode2 === this.languageService.currentLanguage).name;
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  setLanguage(isocode: string): void {
    this.languageService.setLanguage(LANGUAGE_KEY, isocode);
    const currentLanguage = this.languageService.currentLanguage;
    this.getLanguageName();
    const str = this.route.snapshot['_routerState']['url'].toString();
    const changedPath = str.substr(0, 1) + currentLanguage.toString() + str.substr(1 + currentLanguage.toString().length);
    this.translationService.languageChange.next();
    if (currentLanguage) {
      this.router.navigate([changedPath]);
    }
  }

}
