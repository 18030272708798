<div class="alert-wrapper k-cursor-default" [ngStyle]="alertPositions" (click)="clickParentComponent($event)">
  <div class="alert-block" [ngClass]="alertBlockClassList">
    <div class="d-flex mb-1">
      <div class="flex-1 text-left">
      </div>
      <button type="button" class="btn-transition btn btn-outline-secondary mr-1" [title]="'TR_NEXT' | translate"  (click)="next($event)">
        <i class="pe-7s-next"></i>
      </button>
      <button type="button" class="btn-transition btn btn-outline-secondary" [title]="'TR_CLOSE' | translate" (click)="close($event)">
        <i class="pe-7s-close"></i>
      </button>
    </div>
    <hr />
    <p *ngIf="data" [innerHTML]="data.description"></p>
  </div>
</div>


