import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { guid } from '@progress/kendo-angular-common';
import { ActivatedRoute } from '@angular/router';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { MainService } from 'src/app/Services/main.service';
import { IPageData } from 'src/app/Models/IPageData';
import { MENU_KEY, PAGE_CREATE, PAGE_UPDATE, PAGE_VIEW, TR_CREATE_PAGE_KEY, TR_UPDATE_PAGE_KEY } from 'src/app/Constants/main-keys';
import {
  CATEGORIES_FOR_PAGES_PATH_KEY,
  ID_PATH_PARAM_KEY,
  IS_FRONT_END_PAGE_PATH_PARAM_KEY,
  MENU_GROUPS_FOR_PAGES_PATH_KEY,
  PAGE_CREATE_PATH_KEY,
  PAGE_GET_PATH_KEY,
  CURRENT_PAGE_GET_PATH_KEY,
  PAGE_STRUCT_PATH_KEY,
  PAGE_TYPES_FOR_PAGES_PATH_KEY,
  PAGE_UPDATE_PATH_KEY,
  PAGE_URL_KEY
} from 'src/app/Constants/request-path-keys';

@Component({
  selector: 'app-page-form',
  templateUrl: './page-form.component.html',
  styleUrls: ['./page-form.component.scss']
})
export class PageFormComponent implements OnInit {
  selectedImage: any;
  imagePath: any;
  openCategories: boolean;
  openWysiwyg: boolean;
  pageId: number;
  parentId: number;
  selectedParentPageName: string;
  selectedCategoryName: string;
  selectedMenuGroupName: string;
  selectedPageTypeName: string;
  pageData: IPageData;
  form: FormGroup;
  userRoles: Array<string>;
  pages: Array<IPageData> = [];
  pageTypes: Array<any> = [];
  categories: Array<any> = [];
  menuGroups: Array<any> = [];
  hasMenuGroup: boolean = true;
  isCreateForm: boolean = true;
  page_view: boolean;
  page_create: boolean;
  page_update: boolean;
  isInvalidSubmit: boolean;
  submitSpinnerId: string = guid();

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    private translationService: TranslationService,
    public mainService: MainService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_CREATE_PAGE_KEY);

    this.form = this.formBuilder.group({
      pageType: [null],
      menuGroupId: [null],
      pageCategoryId: [null],
      parentId: [null],
      isFrontEndPage: [false],
      isDefault: [false],
      image: [''],
      name: ['', Validators.required],
      uri: ['', Validators.required],
      onlyForAuthorizedUsers: [false],
      pageTitle: [''],
      pageKeywords: [''],
      pageContent: [''],
    });

    this.form.get('isFrontEndPage').valueChanges.subscribe(data => {
      if (!this.page_view) { return; }
      this.pages = [];
      this.selectedParentPageName = '';
      this.form.patchValue({ parentId: null });
      this.getPagesByPageType(this.form.value.isFrontEndPage);
    });

    if (!this.page_view) { return; }
    this.getPagesByPageType(false);
    this.getMenuGroups();
    this.getPageTypes();
    this.getCategories();

    this.route.paramMap.subscribe(params => {
      this.pageId = +params.get('item.id');
      if (this.pageId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle(TR_UPDATE_PAGE_KEY);
        this.getPageDataById();
      }
    });
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch (role) {
        case PAGE_VIEW:
          this.page_view = true;
          break;
        case PAGE_CREATE:
          this.page_create = true;
          break;
        case PAGE_UPDATE:
          this.page_update = true;
          break;
      }
    });
  }

  getAllPages(allPages: any) {
    allPages.forEach(page => {
      this.translationService.translatePages(page.name).subscribe(translatedPageName => {
        if (translatedPageName) {
          page.name = translatedPageName;
        }
        this.pages.push(page);
      })
      if (page.children && page.children.length) {
        this.getAllPages(page.children)
      }
    });
  }

  // getPagesByPageType(isFrontEndPage): void {
  //   this.dataHandlerService.getData(`page/GetParents?${IS_FRONT_END_PAGE_PATH_PARAM_KEY}${isFrontEndPage}`).then(response => {
  //     this.pages = response;
  //     this.pages.forEach(page => {
  //       this.translationService.translatePages(page.name).subscribe(translatedPageName => {
  //         if (translatedPageName) {
  //           page.name = translatedPageName;
  //         }
  //         if (page.children.length) {
  //           page.children.forEach(children => {
  //             if (children.name) {
  //               this.translationService.translatePages(children.name).subscribe(translatedChildrenPageName => {
  //                 if (translatedChildrenPageName) {
  //                   children.name = translatedChildrenPageName;
  //                 }
  //               });
  //             }
  //           });
  //         }
  //       });
  //     });
  //   });
  // }


  getPagesByPageType(isFrontEndPage): void {
    this.dataHandlerService.getData(`page/GetParents?${IS_FRONT_END_PAGE_PATH_PARAM_KEY}${isFrontEndPage}`).then(response => {
      this.getAllPages(response);
    });
  }

  getPageDataById(): void {
    this.dataHandlerService.getDataWithSpinner(`${CURRENT_PAGE_GET_PATH_KEY}/${this.pageId}`).then(response => {
      const pageData: IPageData = response;
      this.pageData = pageData;
      this.imagePath = pageData.imagePath;

      this.pages?.forEach(item => {
        if (item.id === pageData.parentId) {
          this.selectedParentPageName = item.name;
        }
      });

      this.form.patchValue({
        name: pageData.name,
        uri: pageData.uri,
        isDefault: pageData.isDefault,
        isFrontEndPage: pageData.isFrontEndPage
      });

      if (pageData.isFrontEndPage) {

        this.form.patchValue({
          pageType: pageData.pageType,
          menuGroupId: pageData.menuGroupId,
          pageCategoryId: pageData.pageCategoryId,
          isFrontEndPage: pageData.isFrontEndPage,
          pageTitle: pageData.pageTitle,
          pageKeywords: pageData.pageKeywords,
          pageContent: pageData.pageContent,
          image: this.imagePath,
          onlyForAuthorizedUsers: pageData.onlyForAuthorizedUsers,
          isDefault: pageData.isDefault,
          parentId: pageData.parentId,

        });
        this.selectedPageTypeName = pageData.pageTypeName;
        this.selectedCategoryName = pageData.pageCategoryName;
        this.selectedMenuGroupName = pageData.menuGroupName;

        if (pageData.pageType === 0) {
          this.openWysiwyg = true;
          this.openCategories = false;
        } else if (pageData.pageType === 1 || pageData.pageType === 3) {
          this.openCategories = true;
          this.openWysiwyg = false;
        }
      }
    });
  }

  async getMenuGroups() {
    this.menuGroups = await this.dataHandlerService.getDataWithSpinner(MENU_GROUPS_FOR_PAGES_PATH_KEY);
  }

  async getPageTypes() {
    this.pageTypes = await this.dataHandlerService.getDataWithSpinner(PAGE_TYPES_FOR_PAGES_PATH_KEY);
  }

  async getCategories() {
    this.categories = await this.dataHandlerService.getDataWithSpinner(CATEGORIES_FOR_PAGES_PATH_KEY);
  }

  setSelectedParentPage(id: number, name: string, menuGroupId: number): void {
    this.selectedParentPageName = name;
    this.hasMenuGroup = !menuGroupId;
    this.form.patchValue({ parentId: id, menuGroupId: menuGroupId });
  }

  removeSelectedParentPage(): void {
    this.selectedParentPageName = '';
    this.hasMenuGroup = true;
    this.form.patchValue({ parentId: null, menuGroupId: null });
  }

  setSelectedMenuGroup(id: number, name: string): void {
    this.selectedMenuGroupName = name;
    this.form.patchValue({ menuGroupId: id });
  }

  setSelectedCategory(id: number, name: string): void {
    this.selectedCategoryName = name;
    this.form.patchValue({ categoryId: id });
  }

  setSelectedPageType(id: number, name: string): void {
    this.selectedPageTypeName = name;
    this.form.patchValue({ pageType: id });
    if (id === 0) {
      this.openWysiwyg = true;
      this.openCategories = false;
      this.selectedCategoryName = '';
      this.form.patchValue({ categoryId: id });
    } else {
      this.openCategories = true;
      this.openWysiwyg = false;
      this.form.patchValue({ pageContent: null });
    }
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      if (!form.isFrontEndPage) {
        delete form.pageTitle;
        delete form.pageKeywords;
        delete form.onlyForAuthorizedUsers;
        delete form.pageContent;
        delete form.menuGroupId;
        delete form.pageType;
        delete form.pageCategoryId;
      }
      let formData = new FormData();
      if (this.pageId && this.page_update) {
        form.id = this.pageId;
        form.enabled = this.pageData.enabled ? this.pageData.enabled : null;

        for (let i in form) {
          if (!form[i]) {
            delete form[i];
          } else {
            formData.append(`${i}`, form[i]);
          }
        }
        this.dataHandlerService.updateFormData(formData, PAGE_UPDATE_PATH_KEY, this.submitSpinnerId).then(() => {
          this.setMenuDataInStorage();
          this.mainService.goBack(PAGE_URL_KEY);
        });
      } else if (this.page_create) {
        for (let i in form) {
          if (!form[i]) {
            delete form[i];
          } else {
            formData.append(`${i}`, form[i]);
          }
        }
        this.dataHandlerService.createFormData(formData, PAGE_CREATE_PATH_KEY, this.submitSpinnerId).then(() => {
          this.setMenuDataInStorage();
          this.mainService.goBack(PAGE_URL_KEY);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }

  setMenuDataInStorage(): void {
    if (!this.page_view) { return; }
    this.dataHandlerService.getData(`${PAGE_STRUCT_PATH_KEY}${false}`).then(response => {
      localStorage.setItem(MENU_KEY, JSON.stringify(response));
    });
  }

  async handleFileInput(files: FileList) {
    if (files && files[0]) {
      this.selectedImage = files[0];
      this.imagePath = await this.mainService.imageUpload(files[0]);
      this.form.patchValue({ image: this.selectedImage });
    }
  }

}
