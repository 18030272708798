import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { guid } from '@progress/kendo-angular-common';
import { ActivatedRoute } from '@angular/router';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { AlertService } from 'src/app/Services/alert.service';
import { TranslationService } from 'src/app/Services/translation.service';
import {
  PACKAGE_PROPERTY_CREATE,
  PACKAGE_PROPERTY_DELETE,
  PACKAGE_PROPERTY_UPDATE,
  PACKAGE_PROPERTY_VIEW,
  SUCCESS_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_UPDATE_MESSAGE_KEY,
} from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-package-properties-form',
  templateUrl: './package-property-form.component.html',
  styleUrls: ['./package-property-form.component.scss']
})
export class PackagePropertyForm implements OnInit {
  form: FormGroup;
  userRoles: Array<string> = [];

  package_property_view: boolean;
  package_property_create: boolean;
  package_property_update: boolean;
  package_property_delete: boolean;



  isCreateForm: boolean = true;
  submitSpinnerId: string = guid();
  type: number = 3;

  packagePropertyId: number;
  maxPrice: number = 100000;
  selectedPropertyName: string = "";
  propertynames: Array<string> = [];

  isInvalidSubmit: boolean;


  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerservice: DataHandlerService,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    public mainService: MainService,
    private translationService: TranslationService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle("TR_CREATE_PAYMENT_PACKAGE_PROPERTY");

    this.form = this.formBuilder.group({
      displayName: ['', [Validators.required, Validators.maxLength(100)]],
      isPricePerUnit: [false],
      minValue: [null, [Validators.min(1), Validators.max(this.maxPrice)]],
      maxValue: [null, [Validators.min(1), Validators.max(this.maxPrice)]],
      unitName: ['', Validators.max(100)],
    });

    this.propertyNames();

    this.route.paramMap.subscribe(params => {
      this.packagePropertyId = +params.get('item.id');
      if (this.packagePropertyId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle("TR_UPDATE_PAYMENT_PACKAGE_PROPERTY");
        this.getPackagePropertyIdById();
      }
    });
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch (role) {
        case PACKAGE_PROPERTY_VIEW:
          this.package_property_view = true;
          break;
        case PACKAGE_PROPERTY_CREATE:
          this.package_property_create = true;
          break;
        case PACKAGE_PROPERTY_UPDATE:
          this.package_property_update = true;
          break;
        case PACKAGE_PROPERTY_DELETE:
          this.package_property_delete = true;
          break;
      }
    });
  }

  propertyNames() {
    if (!this.package_property_view) { return; }
    this.dataHandlerservice.getDataWithSpinner("PackageProperty/propertynames").then(response => {
      const data = [...response];
      if (data.length) {
        this.selectedPropertyName = data[0];
      }
      this.propertynames = data;
    });
  }

  setPropertyName(item: string) {
    if (!item) { return; }
    this.selectedPropertyName = item;
  }

  getPackagePropertyIdById(): void {
    if (!this.package_property_view) { return; }
    this.dataHandlerservice.getDataWithSpinner(`${"PackageProperty"}/${this.packagePropertyId}`).then(response => {
      const propertyData = response;
      this.selectedPropertyName = propertyData.propertyName;
      this.form.patchValue({
        displayName: propertyData.displayName,
        isPricePerUnit: propertyData.isPricePerUnit,
        minValue: propertyData.minValue,
        maxValue: propertyData.maxValue,
        unitName: propertyData.unitName,
      });
    });
  }


  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      if (
        form.isPricePerUnit &&
        (
          (!form.minValue || !form.maxValue) ||
          (form.minValue && form.maxValue && (form.maxValue <= 0 || form.maxValue < form.minValue)) ||
          (!form.unitName.trim().length)
        )
      ) {
        this.isInvalidSubmit = true;
        return;
      }
      if (!form.isPricePerUnit) {
        form.minValue = null;
        form.maxValue = null;
      }
      form.propertyName = this.selectedPropertyName;
      if (this.packagePropertyId && this.package_property_update) {
        form.id = this.packagePropertyId;
        this.dataHandlerservice.updateData(form, "PackageProperty", this.submitSpinnerId).then(() => {
          this.mainService.goBack("package-property");
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
        });
      } else {
        this.dataHandlerservice.createData(form, "PackageProperty", this.submitSpinnerId).then(() => {
          this.mainService.goBack("package-property");
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }
}
