<div class="page-title-subheading pr-2">
  <div *ngIf="pageInfoData">
    <span>{{pageInfoData.displayName}}</span>
    <span *ngIf="pageInfoData.description && pageInfoData.description.trim().length" class="underline pointer"
      (click)="open(helpModal)">{{ 'TR_LEARN_MORE' | translate}}</span>


    <ng-template #helpModal let-c="close">
      <div class="modal-header align-items-center">
        <h5 class="modal-title">{{ 'TR_INFO' | translate }}</h5>
        <button type="button" class="close pb-0" aria-label="Close" (click)="c()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      <div class="file-view-wrapper" [innerHTML]="pageInfoData.description"></div>
      </div>
      <div class="modal-footer">
        <button type="button" class="mr-2 btn-transition btn btn-outline-dark"
          (click)="c()">{{ 'TR_CLOSE' | translate }}</button>
      </div>
    </ng-template>
  </div>
</div>