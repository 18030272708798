<button
  type="submit"
  class="mt-2 btn btn-lg"
  [ngClass]="buttonColor ? buttonColor : 'btn-success'"
  [class.float-right]="isFloatRight"
  [class.error-border]="isInvalidSubmit && !isFormValid"
  [class.run-snipper]="isSubmitted"
  [disabled]="isSubmitted"
>
  {{ buttonName }}
  <div *ngIf="isSubmitted" class="spinner-border" role="status">
    <span class="sr-only">Loading...</span> 
  </div>
</button>
