import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { AlertService } from 'src/app/Services/alert.service';
import { MainService } from 'src/app/Services/main.service';
import { IUserTypeData } from 'src/app/Models/IUserData';
import { DEFAULT_USER_TYPES_PATH_KEY, USER_TYPE_PATH_KEY, USER_TYPE_URL_KEY } from 'src/app/Constants/request-path-keys';
import {
  SUCCESS_KEY,
  TR_CREATEUSERTYPE_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_EDITUSERTYPE_KEY,
  TR_UPDATE_MESSAGE_KEY,
  USERTYPE_CREATE,
  USERTYPE_UPDATE,
  USERTYPE_VIEW
} from 'src/app/Constants/main-keys';
import { guid } from '@progress/kendo-angular-common';

@Component({
  selector: 'app-user-type-form',
  templateUrl: './user-type-form.component.html',
  styleUrls: ['./user-type-form.component.scss']
})
export class UserTypeFormComponent implements OnInit {
  form: FormGroup;
  userTypeId: number;
  userTypeData: IUserTypeData;
  defaultUserTypes: Array<IUserTypeData> = [];
  selectedUserTypesArr: Array<number> = [];
  userRoles: Array<string>;
  usertype_view: boolean;
  usertype_create: boolean;
  usertype_update: boolean;
  isInvalidSubmit: boolean;
  isCreateForm: boolean = true;
  submitSpinnerId: string = guid();
  nameMaxLength: number = 40;
  isInvalidName: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    private translationService: TranslationService,
    public mainService: MainService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_CREATEUSERTYPE_KEY);

    this.form = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(this.nameMaxLength)]],
      defaultUserTypeIds: [[]]
    });

    if (!this.usertype_view) { return; }

    this.getDefaultUserTypes();

    this.route.paramMap.subscribe(params => {
      this.userTypeId = +params.get('item.id');
      if (this.userTypeId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle(TR_EDITUSERTYPE_KEY);
        this.getUserTypeById();
      }
    });
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch (role) {
        case USERTYPE_VIEW:
          this.usertype_view = true;
          break;
        case USERTYPE_CREATE:
          this.usertype_create = true;
          break;
        case USERTYPE_UPDATE:
          this.usertype_update = true;
          break;
      }
    });
  }

  getUserTypeById() {
    if (!this.usertype_view) { return; }
    this.dataHandlerService.getDataWithSpinner(`${USER_TYPE_PATH_KEY}/${this.userTypeId}`).then(response => {
      this.userTypeData = response;
      if (this.userTypeData.defaultUserTypeIds) {
        this.selectedUserTypesArr = this.userTypeData.defaultUserTypeIds;
        this.selectedUserTypesArr.forEach(item => {
          this.defaultUserTypes.forEach(defaultUserType => defaultUserType.selected = defaultUserType.id === item);
        });
      }

      this.form.patchValue({ name: this.userTypeData.name });
    });
  }

  getDefaultUserTypes(): void {
    this.dataHandlerService.getData(DEFAULT_USER_TYPES_PATH_KEY).then(response => {
      this.defaultUserTypes = response;
      // this.defaultUserTypes.splice(this.defaultUserTypes.findIndex(data => data.name === 'student'), 1);
      // this.defaultUserTypes.splice(this.defaultUserTypes.findIndex(data => data.name === 'lecturer'), 1);
      this.defaultUserTypes.forEach(userType => {
        this.translationService.translateUserTypes(userType.displayName).pipe(
          map(data => {
            if (data) {
              userType.name = data
            }
          })
        ).subscribe();
      });
      this.selectedUserTypesArr.forEach(item => {
        this.defaultUserTypes.forEach(defaultUserType => {
          if (defaultUserType.id === item) {
            defaultUserType.selected = true;
          }
        });
      });
    });
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      if (!form.name.trim().length) {
        this.isInvalidName = true;
        return;
      }
      this.defaultUserTypes.forEach(userType => userType.selected && form.defaultUserTypeIds.push(userType.id));
      if (this.userTypeId && this.usertype_update) {
        form.id = this.userTypeId;
        this.dataHandlerService.updateData(form, USER_TYPE_PATH_KEY, this.submitSpinnerId).then(() => {
          this.mainService.goBack(USER_TYPE_URL_KEY);
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
        });
      } else if (this.usertype_create) {
        this.dataHandlerService.createData(form, USER_TYPE_PATH_KEY, this.submitSpinnerId).then(() => {
          this.mainService.goBack(USER_TYPE_URL_KEY);
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }
}
