import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { guid } from '@progress/kendo-angular-common';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { AlertService } from 'src/app/Services/alert.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { MainService } from 'src/app/Services/main.service';
import { LanguageService } from 'src/app/Services/language.service';
import { ILibraryData } from 'src/app/Models/ILibraryData';
import { ILanguageLibrary } from 'src/app/Models/ILanguageData';
import { ISO_CODE_PATH_PARAM_KEY, LANGUAGES_PATH_KEY, LIBRARY_DATA_PATH_KEY, LIBRARY_PATH_KEY, LIB_ID_PATH_PARAM_KEY } from 'src/app/Constants/request-path-keys';
import {
  LIBRARY_CREATE,
  LIBRARY_DATA_CREATE,
  LIBRARY_DATA_DELETE,
  LIBRARY_DATA_UPDATE,
  LIBRARY_DATA_VIEW,
  LIBRARY_DELETE,
  LIBRARY_UPDATE,
  LIBRARY_VIEW,
  SUCCESS_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_DELETE_MESSAGE_KEY,
  TR_LIBRARY_KEY,
  TR_UPDATE_MESSAGE_KEY
} from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {
  library_view: boolean;
  library_update: boolean;
  library_create: boolean;
  library_delete: boolean;
  librarydata_view: boolean;
  librarydata_update: boolean;
  librarydata_create: boolean;
  librarydata_delete: boolean;

  isLibraryDataUpdate: boolean;
  isInvalidLibraryDataSubmit: boolean;
  selectedTabId: string;
  libraryId: number;
  libraryName: string;
  deleteLibraryPath: string;
  deleteLibraryData: ILibraryData;
  updateLibraryItem: ILibraryData;
  libraryData: Array<ILibraryData> = [];
  parentLibraryData: Array<ILibraryData> = [];
  userRoles: Array<string> = [];
  languages: Array<ILanguageLibrary> = [];
  libraryTypes: Array<string> = ['Text', 'Single list', 'Multilist', 'Radio list', 'Checkbox list'];
  submitSpinnerId: string = guid();
  deleteSpinnerId: string = guid();
  isShowRichTextEditor : boolean = false;

  libraryDataModel = {
    languageId: null,
    parentId: null,
    parentName: '',
    libraryId: null,
    name: '',
    displayName: '',
    description: ''
  };

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private translationService: TranslationService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private languageService: LanguageService,
    private mainService: MainService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_LIBRARY_KEY);

    this.getLanguages();

    this.translationService.languageChange.subscribe(() => {
      this.getLibraries();
      this.libraryDataModel.libraryId && this.getLibraryData(this.libraryDataModel.libraryId);
    });
    
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch (role) {
        case LIBRARY_VIEW:
          this.library_view = true;
          break;
        case LIBRARY_CREATE:
          this.library_create = true;
          break;
        case LIBRARY_UPDATE:
          this.library_update = true;
          break;
        case LIBRARY_DELETE:
          this.library_delete = true;
          break;
        case LIBRARY_DATA_VIEW:
          this.librarydata_view = true;
          break;
        case LIBRARY_DATA_UPDATE:
          this.librarydata_update = true;
          break;
        case LIBRARY_DATA_CREATE:
          this.librarydata_create = true;
          break;
        case LIBRARY_DATA_DELETE:
          this.librarydata_delete = true;
          break;
      }
    });
  }

  getLanguages() {
    this.dataHandlerService.getDataWithSpinner(LANGUAGES_PATH_KEY).then(response => {
      // this.languages = response;
      this.languages = response.splice(0, 2);
      this.selectedTabId = this.languageService.currentLanguage;
      this.getLibraries();
    });
  }

  getLibraries() {
    this.languages.forEach(language => {
      this.dataHandlerService.getDataWithSpinner(`${LIBRARY_PATH_KEY}/byiso?${ISO_CODE_PATH_PARAM_KEY}${language.isocode2}`).then(response => {
        language.content = response;
        language.content.forEach(item => {
          // item.parentId && this.getLibraryParentNameById(item.parentId).then(name => item.parentName = name);
        });
      });
    })
  }

  getLibraryData(id: number): void {
    if (!this.librarydata_view) { return; }
    this.dataHandlerService.getData(`${LIBRARY_DATA_PATH_KEY}/byiso?${LIB_ID_PATH_PARAM_KEY}${id}&${ISO_CODE_PATH_PARAM_KEY}${this.selectedTabId}`).then(response => {
      this.libraryData = response;
      this.libraryData.forEach(item => {
        item.parentId && this.getLibraryDataParentNameById(item.parentId).then(name => item.parentName = name);
      });
    });
  }

  onCheckboxChange(event:any){
    this.isShowRichTextEditor = event.target.checked;
  }

  beforeChange($event) {
    this.selectedTabId = $event.nextId;
  }

  async getLibraryParentNameById(id: number) {
    return await (await (this.dataHandlerService.getData(`${LIBRARY_PATH_KEY}/${id}`))).displayString;
  }

  async getLibraryDataParentNameById(id: number) {
    return await (await (this.dataHandlerService.getData(`${LIBRARY_DATA_PATH_KEY}/${id}`))).name;
  }

  async getParentLibraryData(id: number) {
    this.parentLibraryData = await this.dataHandlerService.getData(`${LIBRARY_DATA_PATH_KEY}?${LIB_ID_PATH_PARAM_KEY}${id}`);
  }

  openForm(content: any): void {
    this.modalService.open(content).result;
  }

  closeForm() {
    this.updateLibraryItem = null;
  }

  openLibraryData(target: HTMLElement, content, data: ILibraryData): void {
    if (!this.librarydata_view || target.nodeName !== 'TD') { return; }

    this.parentLibraryData = [];
    this.removeLibraryDataModelValue();

    this.isInvalidLibraryDataSubmit = false;
    this.libraryDataModel.libraryId = data.id;
    this.libraryId = this.libraryDataModel.libraryId;
    this.libraryName = data.displayString;
    this.libraryId && this.getLibraryData(this.libraryId);

    data.parentId && this.getParentLibraryData(data.parentId);
    this.modalService.open(content, { size: 'lg' }).result;
  }

  closeLibraryData(): void {
    this.parentLibraryData = [];
    this.libraryData = [];
    this.libraryId = null;
    this.libraryName = '';
    this.removeLibraryDataModelValue();
    this.modalService.dismissAll();
  }

  libraryDataCancel(): void {
    this.isLibraryDataUpdate = false;
    this.isInvalidLibraryDataSubmit = false;
    this.removeLibraryDataModelValue();

    this.libraryId && this.getLibraryData(this.libraryId);
  }

  removeLibraryDataModelValue(): void {
    this.libraryDataModel = {
      languageId: null,
      parentId: null,
      parentName: '',
      libraryId: null,
      name: '',
      displayName: '',
      description: ''
    };
  }

  updateLibrary(library: ILibraryData, content: any): void {
    this.updateLibraryItem = library;
    this.openForm(content);
  }

  updateLibraryData(data): void {
    this.isLibraryDataUpdate = true;
    this.libraryDataModel = data;
  }

  delete(): void {
    if (!this.librarydata_delete && !this.library_delete) { return; }
    this.dataHandlerService.deleteData(this.deleteLibraryData.id, this.deleteLibraryPath, this.deleteSpinnerId).then(() => {
      this.alertService.translateAndAlertMessage(TR_DELETE_MESSAGE_KEY, SUCCESS_KEY);
      if (this.deleteLibraryData.libraryId) {
        this.getLibraryData(this.deleteLibraryData.libraryId);
      } else {
        this.getLibraries();
      }
      this.modalService.dismissAll();
    });
  }

  setSelectedParentLibraryData(id: number, name: string): void {
    this.libraryDataModel.parentId = id;
    this.libraryDataModel.parentName = name;
  }

  confirmDelete(content, library: ILibraryData, deletePath: string): void {
    this.modalService.open(content).result;
    this.deleteLibraryData = library;
    this.deleteLibraryPath = deletePath;
  }

  submitLibData(): void {
    if (!this.libraryDataModel.name || !this.libraryDataModel.displayName || !this.libraryId) {
      this.isInvalidLibraryDataSubmit = true;
    } else {
      this.libraryDataModel.libraryId = this.libraryId;
      const libraryDataModel = { ...this.libraryDataModel };
      libraryDataModel.languageId = this.languages.find(data => data.isocode2 === this.selectedTabId).languageId;
      delete libraryDataModel.parentName;
      if (!this.isLibraryDataUpdate && this.librarydata_create) {
        this.dataHandlerService.createData(libraryDataModel, LIBRARY_DATA_PATH_KEY, this.submitSpinnerId).then(response => {
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.libraryDataCancel();
          this.libraryData.push(response);
        });
      } else if(this.librarydata_update) {
        this.dataHandlerService.updateData(libraryDataModel, LIBRARY_DATA_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.libraryDataCancel();
        });
      }
    }
  }

}
