import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { guid } from '@progress/kendo-angular-common';
import { AlertService } from 'src/app/Services/alert.service';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { IQuestionGroupData } from 'src/app/Models/IQuestionModel';
import { QUESTIONS_GROUP_PATH_KEY, QUESTION_URL_KEY } from 'src/app/Constants/request-path-keys';
import { SUCCESS_KEY, TR_CREATE_MESSAGE_KEY, TR_UPDATE_MESSAGE_KEY } from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-question-group-form',
  templateUrl: './question-group-form.component.html',
  styleUrls: ['./question-group-form.component.scss']
})
export class QuestionGroupFormComponent implements OnInit {
  form: FormGroup;
  questionsGroupId: number;
  submitSpinnerId: string = guid();
  isInvalidSubmit: boolean;
  isCreateForm: boolean = true;
  userRoles: Array<string> = [];

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    public mainService: MainService,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;

    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      isPrivate: [false]
    });

    this.route.paramMap.subscribe(params => {
      this.questionsGroupId = +params.get('item.id');
      if(this.questionsGroupId) {
        this.isCreateForm = false;
        this.getQuestionsGroupById();
      }
    });
  }

  getQuestionsGroupById(): void {
    this.dataHandlerService.getDataWithSpinner(`${QUESTIONS_GROUP_PATH_KEY}/${this.questionsGroupId}`).then(response => {
      const questionCategoryData: IQuestionGroupData = response;
      this.form.patchValue({
        name: questionCategoryData.name,
        isPrivate: questionCategoryData.isPrivate
      });
    });
  }

  submitData(): void {
    if(this.form.valid) {
      const form = { ...this.form.value };
      if(this.questionsGroupId) {
        form.id = this.questionsGroupId;
        this.dataHandlerService.updateData(form, QUESTIONS_GROUP_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(QUESTION_URL_KEY);
        });
      } else {
        this.dataHandlerService.createData(form, QUESTIONS_GROUP_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(QUESTION_URL_KEY);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }

}
