<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon"><i class="vsm-icon pe-7s-display1 icon-gradient bg-plum-plate"></i></div>
      <div>{{ 'TR_WEBINARS' | translate }}
        <page-main-info-component id="main_info" data-page-name="angular_admin_webinars">
        </page-main-info-component>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div *ngIf="webinar_create" class="col-md-3 col-12">
    <button class="mb-2 mr-2 btn btn-success create-btn" [title]="'TR_WEBINARCREATE' | translate"
      [routerLink]="['webinar-form']">
      <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
    </button>
  </div>

  <div class="col-md-9 col-12">
    <div [ngClass]="webinar_create ? 'filter-block' : 'wrap-flex'">
      <div *ngIf="organization_view" ngbDropdown>
        <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-primary">
          {{ selectedOrganizationName ? selectedOrganizationName : 'TR_ORGANIZATIONS' | translate }}
        </button>
        <div ngbDropdownMenu>
          <button class="dropdown-item" (click)="openWebinarsByCurrentUser()">{{ 'TR_ALL' | translate }}</button>
          <button class="dropdown-item" *ngFor="let item of organizations"
            (click)="getOrganizationCurators(item.id, item.name)">
            {{ item.name }}
          </button>
        </div>
      </div>

      <div *ngIf="group_view" ngbDropdown>
        <button type="button" ngbDropdownToggle
          [ngClass]="webinar_create ? 'mb-2 ml-2 btn btn-primary' : 'mb-2 btn btn-primary'">
          {{ selectedGroupName ? selectedGroupName : 'TR_GROUPS' | translate }}
        </button>
        <div ngbDropdownMenu>
          <button class="dropdown-item" (click)="openWebinarsByCurrentUser()">{{ 'TR_ALL' | translate }}</button>
          <button class="dropdown-item" *ngFor="let item of groups"
            (click)="getWebinarsByGroup(item.id, item.groupName)">
            {{ item.groupName }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div *ngIf="openCurators" class="col-md-4 col-12">
    <div class="main-card mb-3 card table-max-height">
      <div class="table-responsive scroll">
        <table class="align-middle mb-0 table table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ 'TR_CURATORNAME' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of curators let i = index" [class.selected]="item.id === curatorId">
              <td>{{ i + 1 }}</td>
              <td class="pointer" (click)="openWebinarsByCurator(item)">
                {{ item.lastname }} {{ item.firstname }} {{ item.middlename }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="openWebinars" [ngClass]="openCurators ? 'col-md-8 col-12' : 'col-12'">
    <div class="main-card mb-3 card table-max-height">
      <div class="table-responsive scroll">
        <table class="align-middle mb-0 table table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ 'TR_WEBINARNAME' | translate }}</th>
              <th>{{ 'TR_GROUP_NAME' | translate }}</th>
              <th>{{ 'TR_WEBINAR_LECTURER' | translate }}</th>
              <th *ngIf="webinar_update" class="text-right">{{ 'TR_ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of webinars let i = index" [class.selected]="item.id === webinarId" class="pointer"
              (click)="openWebinar(item, calendar)">
              <td>{{ i + 1 }}</td>
              <td class="word-break-break-word">{{ item.name }}</td>
              <td>{{ item.groupName }}</td>
              <td>{{ item.lecturerName }}</td>
              <td *ngIf="webinar_update" class="text-right">
                <div role="group" class="btn-group-sm btn-group">
                  <button *ngIf="webinar_update" class="btn-transition btn btn-outline-info"
                    [title]="'TR_WEBINARUPDATE' | translate" [routerLink]="['webinar-form', item.id]">
                    <i class="pe-7s-note"> </i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p *ngIf='!webinars.length && groupId' class="m-2">{{'TR_NO_WEBINARS_FOR_THIS_GROUP' | translate}}</p>
    </div>
  </div>
</div>
<div class="calendar-block">
  <ng-template #calendar let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h5 class="modal-title word-break-break-word">{{ selectedWebinarName }}</h5>
      <button type="button" class="close pb-0" aria-label="Close" (click)="c('close')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="custom-modal modal-body">
      <ng-container *ngIf="openCalendar && calendarContainer">
        <div class="month-container" *ngFor="let month of months">
          <div class="row">
            <div class="col-12">
              <h4 class="month-name-title">{{ month.monthName | translate }}, {{ month.year }}</h4>
              <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6 col-12 custom-padding"
                  *ngFor="let day of month.days; index as i">
                  <div class="scrollable-container">
                    <div class="month-day"
                      [class.rest-month-days]="day.dayName === 'TR_SATURDAY' || day.dayName === 'TR_SUNDAY'">
                      <p class="day" [class.rest-days]="day.dayName === 'TR_SATURDAY' || day.dayName === 'TR_SUNDAY'">
                        {{ day.dayName | translate }}, {{ day.dayNumber }}
                      </p>
                      <div class="time-pickers-container">
                        <div class="has-feedback relative-container"
                          title="{{ timePicker.inProcess ? ('TR_WEBINAR_IS_IN_PROCESS' | translate) : ('TR_WEBINAR_DATA' | translate) }}"
                          title="{{ timePicker.isFinished ? ('TR_WEBINAR_IS_FINISHED' | translate) : ('TR_WEBINAR_DATA' | translate) }}"
                          *ngFor="let timePicker of day.content">
                          <div class="form-container">
                            <input type="time" name="startTimeCustom" class="time-input" autocomplete="off"
                              [(ngModel)]="timePicker.startTime" [disabled]="true"
                              (ngModelChange)="timePicker.changedTime = true">
                            -
                            <input type="time" name="endTimeCustom" class="time-input" autocomplete="off"
                              [(ngModel)]="timePicker.endTime" [disabled]="true"
                              (ngModelChange)="timePicker.changedTime = true">
                          </div>
                          <div *ngIf="!timePicker.isFinished && !timePicker.inProcess" class="buttons-container">
                            <button *ngIf="webinar_update" type="button" class="btn btn-outline-primary"
                              [title]="'TR_SAVE' | translate" [disabled]="!timePicker.changedTime"
                              (click)="confirmSave(save, timePicker)">
                              <i class="pe-7s-diskette"></i>
                            </button>
                            <button *ngIf="webinar_delete" type="button" class="btn btn-outline-danger"
                              [title]="'TR_DELETE' | translate" (click)="confirmDelete(deleteConfirm, timePicker)">
                              <i class="pe-7s-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>

<ng-template #save let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p class="word-break-break-word">{{ 'TR_WEBINAR_SAVE_CHANGES_QUESTION' | translate }}</p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button *ngIf="webinar_update" [isCurrent]="true" [spinnerId]="webinarUpdateSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="saveChanges()"></app-action-button>
    <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #deleteConfirm let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p class="word-break-break-word">{{ 'TR_WEBINAR_DELETE_QUESTION' | translate }}</p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button *ngIf="webinar_delete" [isCurrent]="true" [spinnerId]="deleteWebinarSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="delete()"></app-action-button>
    <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate
      }}</button>
  </div>
</ng-template>