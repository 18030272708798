import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';

import { AuthGuardService } from './Services/auth-guard.service';
import { UsersTableComponent } from './Pages/Users/users-table/users-table.component';

//Pages Components
import { UserFormComponent } from './Pages/Users/user-form/user-form.component';
import { HomeComponent } from './Pages/Home/home/home.component';
import { WebinarFormComponent } from './Pages/Webinars/webinar-form/webinar-form.component';
import { WebinarsTableComponent } from './Pages/Webinars/webinars-table/webinars-table.component';
import { StudentFormComponent } from './Pages/Students/student-form/student-form.component';
import { StudentsTableComponent } from './Pages/Students/students-table/students-table.component';
import { StudentUpdateComponent } from './Pages/Students/student-update/student-update.component';
import { GroupTableComponent } from './Pages/Groups/group-table/group-table.component';
import { GroupFormComponent } from './Pages/Groups/group-form/group-form.component';
import { UserTypesTableComponent } from './Pages/UserTypes/user-types-table/user-types-table.component';
import { UserTypeFormComponent } from './Pages/UserTypes/user-type-form/user-type-form.component';
import { OrganizationFormComponent } from './Pages/Organizations/organization-form/organization-form.component';
import { OrganizationsTableComponent } from './Pages/Organizations/organizations-table/organizations-table.component';
import { StreamSettingsComponent } from './Pages/Organizations/stream-settings/stream-settings.component';
import { OrganizationSettingsTablesComponent } from './Pages/OrganizationSettings/organization-settings-tables/organization-settings-tables.component';
import { DocumentsTableComponent } from './Pages/Documents/documents-table/documents-table.component';
import { DocumentFormComponent } from './Pages/Documents/document-form/document-form.component';
import { DocumentSubscriptionComponent } from './Pages/Documents/document-subscription/document-subscription.component';
import { DocumentPrintComponent } from './Pages/Documents/document-print/document-print.component';
import { PagesTableComponent } from './Pages/Pages/pages-table/pages-table.component';
import { PageFormComponent } from './Pages/Pages/page-form/page-form.component';
import { PaymentPackagesTableComponent } from './Pages/PaymentPackages/payment-packages-table/payment-packages-table.component';
import { PaymentPackageFormComponent } from './Pages/PaymentPackages/payment-package-form/payment-package-form.component';
import { ConfigTableComponent } from './Pages/Config/config-table/config-table.component';
import { CurrencyTableComponent } from './Pages/Currency/currency-table/currency-table.component';
import { ConfigFormComponent } from './Pages/Config/config-form/config-form.component';
import { CurrencyFormComponent } from './Pages/Currency/currency-form/currency-form.component';
import { LibraryComponent } from './Pages/Library/library/library.component';
import { TranslationsComponent } from './Pages/Translations/translations/translations.component';
import { CategoryComponent } from './Pages/Category/category/category.component';
import { PageContentTableComponent } from './Pages/PageContent/page-content-table/page-content-table.component';
import { PageContentFormComponent } from './Pages/PageContent/page-content-form/page-content-form.component';
import { TemplatesTableComponent } from './Pages/Templates/templates-table/templates-table.component';
import { TemplateFormComponent } from './Pages/Templates/template-form/template-form.component';
import { TemplateInfoTableComponent } from './Pages/TemplateInfo/template-info-table/template-info-table.component';
import { TemplateInfoFormComponent } from './Pages/TemplateInfo/template-info-form/template-info-form.component';
import { TemplateInfoDataTableComponent } from './Pages/TemplateInfoData/template-info-data-table/template-info-data-table.component';
import { TemplateInfoDataFormComponent } from './Pages/TemplateInfoData/template-info-data-form/template-info-data-form.component';
import { DocumentCategoryComponent } from './Pages/DocumentCategory/document-category/document-category.component';
import { LanguageTableComponent } from './Pages/Languages/language-table/language-table.component';
import { LanguageFormComponent } from './Pages/Languages/language-form/language-form.component';
import { TranslationFormComponent } from './Pages/Translations/translation-form/translation-form.component';
// import { CourseFormComponent } from './Pages/Courses/course-form/course-form.component';
import { CoursesTableComponent } from './Pages/Courses/courses-table/courses-table.component';
import { CourseViewComponent } from './Pages/Courses/course-view/course-view.component';
// import { TopicFormComponent } from './Pages/Courses/topic-form/topic-form.component';
// import { SectionFormComponent } from './Pages/Courses/section-form/section-form.component';
import { FileLibraryTableComponent } from './Pages/FileLibrary/file-library-table/file-library-table.component';
import { FileLibraryFormComponent } from './Pages/FileLibrary/file-library-form/file-library-form.component';
import { ProfileComponent } from './Pages/Profile/profile/profile.component';
import { FailLogsTableComponent } from './Pages/FailLogs/fail-logs-table/fail-logs-table.component';
// import { QuestionsTableComponent } from './Pages/Tests/Questions/questions-table/questions-table.component';
// import { QuestionFormComponent } from './Pages/Tests/Questions/question-form/question-form.component';
// import { QuestionGroupFormComponent } from './Pages/Tests/Questions/question-group-form/question-group-form.component';
// import { QuizFormComponent } from './Pages/Tests/Quizzes/quiz-form/quiz-form.component';
import { QuizViewComponent } from './Pages/Tests/Quizzes/quiz-view/quiz-view.component';
import { LoginBoxedComponent } from './UserPages/login-boxed/login-boxed.component';
import { ForgotPasswordBoxedComponent } from './UserPages/forgot-password-boxed/forgot-password-boxed.component';
import { ResetPasswordComponent } from './UserPages/reset-password/reset-password.component';
import { RoleFormComponent } from './Pages/Role/role-form/role-form.component';
import { RolesTableComponent } from './Pages/Role/roles-table/roles-table.component';
import { FileLibraryComponent } from './Pages/Documents/file-library/file-library.component';
import { FileLibraryForm } from './Pages/Documents/file-library-form/file-library-form.component';
import { OrganizationPaymentPackageFormComponent } from "./Pages/Organizations/payment-package/payment-package-form.component";
import { PackagePropertiesTableComponent } from './Pages/PaymentPackages/package-property-table/package-properties-table.component';
import { PackagePropertyForm } from './Pages/PaymentPackages/package-property-form/package-property-form.component';
import { OrganizationContractComponent } from './Pages/Organizations/organization-contract/organization-contract.component';
import { AuthenticationComponent } from './UserPages/authentication/authentication.component';

const routes: Routes = [

  {
    // Auth
    path: ':language/authentication',
    // canLoad: [AuthGuardService],
    // canActivate: [AuthGuardService],
    component: null,
    children: [
      { path: ':refreshToken', component: AuthenticationComponent, data: { extraParameter: '' } },
    ]
  },
  {
    path: ':language/admin',
    canLoad: [AuthGuardService],
    canActivate: [AuthGuardService],
    component: BaseLayoutComponent,
    children: [
      //Home
      { path: '', component: HomeComponent, data: { extraParameter: '' } },

      //Role
      { path: 'role/role-form/:item.id', component: RoleFormComponent, data: { extraParameter: '' } },
      { path: 'role/role-form', component: RoleFormComponent, data: { extraParameter: '' } },
      { path: 'role', component: RolesTableComponent, data: { extraParameter: '' } },

      //Users
      { path: 'user/user-form/:item.id', component: UserFormComponent, data: { extraParameter: '' } },
      { path: 'user/user-form', component: UserFormComponent, data: { extraParameter: '' } },
      { path: 'user', component: UsersTableComponent, data: { extraParameter: '' } },

      //Webinars
      { path: 'webinar/webinar-form/:item.id', component: WebinarFormComponent, data: { extraParameter: '' } },
      { path: 'webinar/webinar-form', component: WebinarFormComponent, data: { extraParameter: '' } },
      { path: 'webinar', component: WebinarsTableComponent, data: { extraParameter: '' } },

      //Students
      { path: 'student/student-form/:item.id', component: StudentUpdateComponent, data: { extraParameter: '' } },
      { path: 'student/student-form', component: StudentFormComponent, data: { extraParameter: '' } },
      { path: 'student', component: StudentsTableComponent, data: { extraParameter: '' } },

      //Groups
      { path: 'group/group-form/:item.id', component: GroupFormComponent, data: { extraParameter: '' } },
      { path: 'group/group-form', component: GroupFormComponent, data: { extraParameter: '' } },
      { path: 'group/:item.id', component: GroupTableComponent, data: { extraParameter: '' } },
      { path: 'group', component: GroupTableComponent, data: { extraParameter: '' } },

      //User Types
      { path: 'usertype/usertype-form/:item.id', component: UserTypeFormComponent, data: { extraParameter: '' } },
      { path: 'usertype/usertype-form', component: UserTypeFormComponent, data: { extraParameter: '' } },
      { path: 'usertype', component: UserTypesTableComponent, data: { extraParameter: '' } },

      //Organizations
      { path: 'organization/payment-package/:item.id', component: OrganizationPaymentPackageFormComponent, data: { extraParameter: '' } },
      { path: 'organization/organization-form/:item.id', component: OrganizationFormComponent, data: { extraParameter: '' } },
      { path: 'organization/organization-form', component: OrganizationFormComponent, data: { extraParameter: '' } },
      { path: 'organization/settings/:item.id', component: StreamSettingsComponent, data: { extraParameter: '' } },
      { path: 'organization/contract/:item.id', component: OrganizationContractComponent, data: { extraParameter: '' } },
      { path: 'organization', component: OrganizationsTableComponent, data: { extraParameter: '' } },

      //Organization Education levels(Organization Settings)
      { path: 'organizationSettings', component: OrganizationSettingsTablesComponent, data: { extraParameter: '' } },

      //Documents
      { path: 'document', component: DocumentsTableComponent, data: { extraParameter: '' } },
      { path: 'document/file-library', component: FileLibraryComponent, data: { extraParameter: '' } },
      { path: 'document/file-library/file-library-form', component: FileLibraryForm, data: { extraParameter: '' } },
      { path: 'document/document-form', component: DocumentFormComponent, data: { extraParameter: '' } },
      { path: 'document/document-form/:item.id', component: DocumentFormComponent, data: { extraParameter: '' } },
      { path: 'document/document-form/type/:item.id', component: DocumentFormComponent, data: { extraParameter: '' } },
      { path: 'document/subscribe/:item.id', component: DocumentSubscriptionComponent, data: { extraParameter: '' } },
      { path: 'document/print/:item.id', component: DocumentPrintComponent, data: { extraParameter: '' } },
      { path: 'document/download/:item.id', component: DocumentPrintComponent, data: { extraParameter: '' } },

      //Pages
      { path: 'page/page-form/:item.id', component: PageFormComponent, data: { extraParameter: '' } },
      { path: 'page/page-form', component: PageFormComponent, data: { extraParameter: '' } },
      { path: 'page', component: PagesTableComponent, data: { extraParameter: '' } },

      //Payment Packages
      { path: 'paymentpackage/paymentpackage-form/:item.id', component: PaymentPackageFormComponent, data: { extraParameter: '' } },
      { path: 'paymentpackage/paymentpackage-form', component: PaymentPackageFormComponent, data: { extraParameter: '' } },
      { path: 'paymentpackage', component: PaymentPackagesTableComponent, data: { extraParameter: '' } },
      { path: 'package-property', component: PackagePropertiesTableComponent, data: { extraParameter: '' } },
      { path: 'package-property/package-property-form', component: PackagePropertyForm, data: { extraParameter: '' } },
      { path: 'package-property/package-property-form/:item.id', component: PackagePropertyForm, data: { extraParameter: '' } },

      //Configs
      { path: 'config/config-form/:item.id', component: ConfigFormComponent, data: { extraParameter: '' } },
      { path: 'config/config-form', component: ConfigFormComponent, data: { extraParameter: '' } },
      { path: 'config', component: ConfigTableComponent, data: { extraParameter: '' } },

      //Currency
      { path: 'currency/currency-form/:item.id', component: CurrencyFormComponent, data: { extraParameter: '' } },
      { path: 'currency/currency-form', component: CurrencyFormComponent, data: { extraParameter: '' } },
      { path: 'currency', component: CurrencyTableComponent, data: { extraParameter: '' } },

      //Library
      { path: 'library', component: LibraryComponent, data: { extraParameter: '' } },

      //Translations
      { path: 'translation/translation-form/:language.languageId/:item.id', component: TranslationFormComponent, data: { extraParameter: '' } },
      { path: 'translation/translation-form', component: TranslationFormComponent, data: { extraParameter: '' } },
      { path: 'translation', component: TranslationsComponent, data: { extraParameter: '' } },

      //Category
      { path: 'category', component: CategoryComponent, data: { extraParameter: '' } },

      //Document Category
      { path: 'document-category', component: DocumentCategoryComponent, data: { extraParameter: '' } },

      //Page Content
      { path: 'content/page-content-form/:language.languageId/:item.id', component: PageContentFormComponent, data: { extraParameter: '' } },
      { path: 'content/page-content-form', component: PageContentFormComponent, data: { extraParameter: '' } },
      { path: 'content', component: PageContentTableComponent, data: { extraParameter: '' } },

      //Templates
      { path: 'templates/template-form/:item.id', component: TemplateFormComponent, data: { extraParameter: '' } },
      { path: 'templates/template-form', component: TemplateFormComponent, data: { extraParameter: '' } },
      { path: 'templates', component: TemplatesTableComponent, data: { extraParameter: '' } },

      //Templates Info
      { path: 'templates-info/templates-info-form/:item.id', component: TemplateInfoFormComponent, data: { extraParameter: '' } },
      { path: 'templates-info/templates-info-form', component: TemplateInfoFormComponent, data: { extraParameter: '' } },
      { path: 'templates-info', component: TemplateInfoTableComponent, data: { extraParameter: '' } },

      //Templates Info Data
      { path: 'templates-info-data/templates-info-data-form/:item.id', component: TemplateInfoDataFormComponent, data: { extraParameter: '' } },
      { path: 'templates-info-data/templates-info-data-form', component: TemplateInfoDataFormComponent, data: { extraParameter: '' } },
      { path: 'templates-info-data', component: TemplateInfoDataTableComponent, data: { extraParameter: '' } },

      //Languages
      { path: 'language/language-form/:item.id', component: LanguageFormComponent, data: { extraParameter: '' } },
      { path: 'language/language-form', component: LanguageFormComponent, data: { extraParameter: '' } },
      { path: 'language', component: LanguageTableComponent, data: { extraParameter: '' } },

      //Courses
      { path: 'course', component: CoursesTableComponent, data: { extraParameter: '' } },
      // { path: 'course/course-form/:item.id', component: CourseFormComponent, data: { extraParameter: '' } },
      // { path: 'course/course-form', component: CourseFormComponent, data: { extraParameter: '' } },
      {
        path: 'course/course-view/:item.id', children: [
          { path: '', component: CourseViewComponent, data: { extraParameter: '' } },
          { path: 'quiz/:id', component: QuizViewComponent, data: { extraParameter: '' } },
          // { path: 'section-form/:section.id', component: SectionFormComponent, data: { extraParameter: '' } },
          // { path: 'section-form', component: SectionFormComponent, data: { extraParameter: '' } },
          // { path: 'topic-form/:section.id', component: TopicFormComponent, data: { extraParameter: '' } },
          // { path: 'topic-form/:section.id/:topic.id', component: TopicFormComponent, data: { extraParameter: '' } },
          // { path: 'quiz-form/:item.id', component: QuizFormComponent, data: { extraParameter: '' } },
          // { path: ':settingsTopicId/quiz-form', component: QuizFormComponent, data: { extraParameter: '' } },
          // { path: 'quiz-form', component: QuizFormComponent, data: { extraParameter: '' } },
        ], data: { extraParameter: '' }
      },

      //File library
      { path: 'filelibrary/filelibrary-form/:item.id', component: FileLibraryFormComponent, data: { extraParameter: '' } },
      { path: 'filelibrary/filelibrary-form', component: FileLibraryFormComponent, data: { extraParameter: '' } },
      { path: 'filelibrary', component: FileLibraryTableComponent, data: { extraParameter: '' } },

      //Profile
      { path: 'profile', component: ProfileComponent, data: { extraParameter: '' } },

      //Fail logs
      { path: 'fail-logs', component: FailLogsTableComponent, data: { extraParameter: '' } },

      // //Questions
      // { path: 'question/questiongroup-form/:item.id', component: QuestionGroupFormComponent, data: { extraParameter: '' } },
      // { path: 'question/questiongroup-form', component: QuestionGroupFormComponent, data: { extraParameter: '' } },
      // { path: 'question/question-form/createbygroup/:questionsGroup.id', component: QuestionFormComponent, data: { extraParameter: '' } },
      // { path: 'question/question-form/:item.id', component: QuestionFormComponent, data: { extraParameter: '' } },
      // { path: 'question/question-form', component: QuestionFormComponent, data: { extraParameter: '' } },
      // { path: 'question', component: QuestionsTableComponent, data: { extraParameter: '' } },
    ]

  },
  {
    // Auth
    path: ':language',
    canLoad: [AuthGuardService],
    canActivate: [AuthGuardService],
    component: PagesLayoutComponent,
    children: [
      { path: '', component: LoginBoxedComponent, data: { extraParameter: '' } },
      { path: 'recover-password', component: ForgotPasswordBoxedComponent, data: { extraParameter: '' } },
      // { path: 'resetPassword/accessToNewPassword/:guid', component: ResetPasswordComponent, data: { extraParameter: '' } },
      { path: 'user/changecredentials/:guid', component: ResetPasswordComponent, data: { extraParameter: '' } },
      // { path: 'authentication/:refreshToken', component: AuthenticationComponent, data: { extraParameter: '' } },
    ]
  },
  {
    path: '',
    canLoad: [AuthGuardService],
    canActivate: [AuthGuardService],
    component: BaseLayoutComponent,
    children: [
      //Home
      { path: '', component: HomeComponent, data: { extraParameter: '' } },
      //Authentication
      // { path: 'authentication/:refreshToken', component: AuthenticationComponent, data: { extraParameter: '' } },
      //Role
      { path: 'role/role-form/:item.id', component: RoleFormComponent, data: { extraParameter: '' } },
      { path: 'role/role-form', component: RoleFormComponent, data: { extraParameter: '' } },
      { path: 'role', component: RolesTableComponent, data: { extraParameter: '' } },

      //Users
      { path: 'users/user-form/:item.id', component: UserFormComponent, data: { extraParameter: '' } },
      { path: 'users/user-form', component: UserFormComponent, data: { extraParameter: '' } },
      { path: 'users', component: UsersTableComponent, data: { extraParameter: '' } },

      //Webinars
      { path: 'webinar/webinar-form/:item.id', component: WebinarFormComponent, data: { extraParameter: '' } },
      { path: 'webinar/webinar-form', component: WebinarFormComponent, data: { extraParameter: '' } },
      { path: 'webinar', component: WebinarsTableComponent, data: { extraParameter: '' } },

      //Students
      { path: 'student/student-form/:item.id', component: StudentUpdateComponent, data: { extraParameter: '' } },
      { path: 'student/student-form', component: StudentFormComponent, data: { extraParameter: '' } },
      { path: 'student', component: StudentsTableComponent, data: { extraParameter: '' } },

      //Groups
      { path: 'group/group-form/:item.id', component: GroupFormComponent, data: { extraParameter: '' } },
      { path: 'group/group-form', component: GroupFormComponent, data: { extraParameter: '' } },
      { path: 'group/:item.id', component: GroupTableComponent, data: { extraParameter: '' } },
      { path: 'group', component: GroupTableComponent, data: { extraParameter: '' } },

      //User Types
      { path: 'usertype/usertype-form/:item.id', component: UserTypeFormComponent, data: { extraParameter: '' } },
      { path: 'usertype/usertype-form', component: UserTypeFormComponent, data: { extraParameter: '' } },
      { path: 'usertype', component: UserTypesTableComponent, data: { extraParameter: '' } },

      //Organizations
      { path: 'organization/payment-package/:item.id', component: OrganizationPaymentPackageFormComponent, data: { extraParameter: '' } },
      { path: 'organization/organization-form/:item.id', component: OrganizationFormComponent, data: { extraParameter: '' } },
      { path: 'organization/settings/:item.id', component: StreamSettingsComponent, data: { extraParameter: '' } },
      { path: 'organization/organization-form', component: OrganizationFormComponent, data: { extraParameter: '' } },
      { path: 'organization', component: OrganizationsTableComponent, data: { extraParameter: '' } },

      //Organization Education levels(Organization Settings)
      { path: 'organizationSettings', component: OrganizationSettingsTablesComponent, data: { extraParameter: '' } },

      //Documents
      { path: 'document', component: DocumentsTableComponent, data: { extraParameter: '' } },
      { path: 'document/document-form', component: DocumentFormComponent, data: { extraParameter: '' } },
      { path: 'document/document-form/:item.id', component: DocumentFormComponent, data: { extraParameter: '' } },
      { path: 'document/document-form/type/:item.id', component: DocumentFormComponent, data: { extraParameter: '' } },
      { path: 'document/subscribe/:item.id', component: DocumentSubscriptionComponent, data: { extraParameter: '' } },
      { path: 'document/print/:item.id', component: DocumentPrintComponent, data: { extraParameter: '' } },
      { path: 'document/download/:item.id', component: DocumentPrintComponent, data: { extraParameter: '' } },

      //Pages
      { path: 'page/page-form/:item.id', component: PageFormComponent, data: { extraParameter: '' } },
      { path: 'page/page-form', component: PageFormComponent, data: { extraParameter: '' } },
      { path: 'page', component: PagesTableComponent, data: { extraParameter: '' } },

      //Payment Packages
      { path: 'paymentpackage/paymentpackage-form/:item.id', component: PaymentPackageFormComponent, data: { extraParameter: '' } },
      { path: 'paymentpackage/paymentpackage-form', component: PaymentPackageFormComponent, data: { extraParameter: '' } },
      { path: 'paymentpackage', component: PaymentPackagesTableComponent, data: { extraParameter: '' } },
      { path: 'paymentpackage/property', component: PackagePropertiesTableComponent, data: { extraParameter: '' } },

      //Configs
      { path: 'config/config-form/:item.id', component: ConfigFormComponent, data: { extraParameter: '' } },
      { path: 'config/config-form', component: ConfigFormComponent, data: { extraParameter: '' } },
      { path: 'config', component: ConfigTableComponent, data: { extraParameter: '' } },

      //Currency
      { path: 'currency/currency-form/:item.id', component: CurrencyFormComponent, data: { extraParameter: '' } },
      { path: 'currency/currency-form', component: CurrencyFormComponent, data: { extraParameter: '' } },
      { path: 'currency', component: CurrencyTableComponent, data: { extraParameter: '' } },

      //Library
      { path: 'library', component: LibraryComponent, data: { extraParameter: '' } },

      //Translations
      { path: 'translation/translation-form/:language.languageId/:item.id', component: TranslationFormComponent, data: { extraParameter: '' } },
      { path: 'translation/translation-form', component: TranslationFormComponent, data: { extraParameter: '' } },
      { path: 'translation', component: TranslationsComponent, data: { extraParameter: '' } },

      //Category
      { path: 'category', component: CategoryComponent, data: { extraParameter: '' } },

      //Document Category
      { path: 'document-category', component: DocumentCategoryComponent, data: { extraParameter: '' } },

      //Page Content
      { path: 'content/page-content-form/:language.languageId/:item.id', component: PageContentFormComponent, data: { extraParameter: '' } },
      { path: 'content/page-content-form', component: PageContentFormComponent, data: { extraParameter: '' } },
      { path: 'content', component: PageContentTableComponent, data: { extraParameter: '' } },

      //Templates
      { path: 'templates/template-form/:item.id', component: TemplateFormComponent, data: { extraParameter: '' } },
      { path: 'templates/template-form', component: TemplateFormComponent, data: { extraParameter: '' } },
      { path: 'templates', component: TemplatesTableComponent, data: { extraParameter: '' } },

      //Templates Info
      { path: 'templates-info/templates-info-form/:item.id', component: TemplateInfoFormComponent, data: { extraParameter: '' } },
      { path: 'templates-info/templates-info-form', component: TemplateInfoFormComponent, data: { extraParameter: '' } },
      { path: 'templates-info', component: TemplateInfoTableComponent, data: { extraParameter: '' } },

      //Templates Info Data
      { path: 'templates-info-data/templates-info-data-form/:item.id', component: TemplateInfoDataFormComponent, data: { extraParameter: '' } },
      { path: 'templates-info-data/templates-info-data-form', component: TemplateInfoDataFormComponent, data: { extraParameter: '' } },
      { path: 'templates-info-data', component: TemplateInfoDataTableComponent, data: { extraParameter: '' } },

      //Languages
      { path: 'language/language-form/:item.id', component: LanguageFormComponent, data: { extraParameter: '' } },
      { path: 'language/language-form', component: LanguageFormComponent, data: { extraParameter: '' } },
      { path: 'language', component: LanguageTableComponent, data: { extraParameter: '' } },

      //Courses
      { path: 'course', component: CoursesTableComponent, data: { extraParameter: '' } },
      // { path: 'course/course-form/:item.id', component: CourseFormComponent, data: { extraParameter: '' } },
      // { path: 'course/course-form', component: CourseFormComponent, data: { extraParameter: '' } },
      {
        path: 'course/course-view/:item.id', children: [
          { path: '', component: CourseViewComponent, data: { extraParameter: '' } },
          { path: 'quiz/:id', component: QuizViewComponent, data: { extraParameter: '' } },
          // { path: 'section-form/:section.id', component: SectionFormComponent, data: { extraParameter: '' } },
          // { path: 'section-form', component: SectionFormComponent, data: { extraParameter: '' } },
          // { path: 'topic-form/:section.id', component: TopicFormComponent, data: { extraParameter: '' } },
          // { path: 'topic-form/:section.id/:topic.id', component: TopicFormComponent, data: { extraParameter: '' } },
          // { path: 'quiz-form/:item.id', component: QuizFormComponent, data: { extraParameter: '' } },
          // { path: ':settingsTopicId/quiz-form', component: QuizFormComponent, data: { extraParameter: '' } },
          // { path: 'quiz-form', component: QuizFormComponent, data: { extraParameter: '' } },
        ], data: { extraParameter: '' }
      },

      //File library
      { path: 'filelibrary/filelibrary-form/:item.id', component: FileLibraryFormComponent, data: { extraParameter: '' } },
      { path: 'filelibrary/filelibrary-form', component: FileLibraryFormComponent, data: { extraParameter: '' } },
      { path: 'filelibrary', component: FileLibraryTableComponent, data: { extraParameter: '' } },

      //Profile
      { path: 'profile', component: ProfileComponent, data: { extraParameter: '' } },

      //Fail logs
      { path: 'fail-logs', component: FailLogsTableComponent, data: { extraParameter: '' } },

      // //Questions
      // { path: 'question/questiongroup-form/:item.id', component: QuestionGroupFormComponent, data: { extraParameter: '' } },
      // { path: 'question/questiongroup-form', component: QuestionGroupFormComponent, data: { extraParameter: '' } },
      // { path: 'question/question-form/createbygroup/:questionsGroup.id', component: QuestionFormComponent, data: { extraParameter: '' } },
      // { path: 'question/question-form/:item.id', component: QuestionFormComponent, data: { extraParameter: '' } },
      // { path: 'question/question-form', component: QuestionFormComponent, data: { extraParameter: '' } },
      // { path: 'question', component: QuestionsTableComponent, data: { extraParameter: '' } },

    ]

  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
