import { Component, OnInit } from '@angular/core';
import { guid } from '@progress/kendo-angular-common';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { AlertService } from 'src/app/Services/alert.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { ILibraryData } from 'src/app/Models/ILibraryData';
import { ITemplateData, ITemplateInfoData } from 'src/app/Models/ITemplateData';
import {
  DOCUMENT_TEMPLATE_INFO_PATH_KEY,
  DOCUMENT_TEMPLATE_PATH_KEY,
  LIBRARY_PATH_KEY,
  TEMPLATE_INFO_URL_KEY
} from 'src/app/Constants/request-path-keys';
import {
  DOCUMENTTEMPLATE_I_CREATE,
  DOCUMENTTEMPLATE_I_UPDATE,
  DOCUMENTTEMPLATE_I_VIEW,
  DOCUMENTTEMPLATE_VIEW,
  INFO_TYPES_KEY,
  LIBRARY_VIEW,
  SUCCESS_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_CREATE_TEMPLATE_INFO_KEY,
  TR_UPDATE_MESSAGE_KEY,
  TR_UPDATE_TEMPLATE_INFO_KEY
} from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-template-info-form',
  templateUrl: './template-info-form.component.html',
  styleUrls: ['./template-info-form.component.scss']
})
export class TemplateInfoFormComponent implements OnInit {
  form: FormGroup;
  templateInfoId: number;
  selectedTemplateName: string;
  selectedLibraryName: string;
  templateInfo_view: boolean;
  templateInfo_create: boolean;
  templateInfo_update: boolean;
  template_view: boolean;
  library_view: boolean;
  isInvalidSubmit: boolean;
  openPlaceHolderField: boolean;
  isCreateForm: boolean = true;
  submitSpinnerId: string = guid();
  userRoles: Array<string> = [];
  templates: Array<ITemplateData> = [];
  libraries: Array<ILibraryData> = [];
  infoTypes: Array<string> = INFO_TYPES_KEY;

  private templatesData = new BehaviorSubject<Array<ITemplateData>>(null);
  templateObs = this.templatesData.asObservable();

  constructor(
    private dataHandlerService: DataHandlerService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    public mainService: MainService,
    private rolesService: RolesService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_CREATE_TEMPLATE_INFO_KEY);

    this.form = this.formBuilder.group({
      documentTemplateId: [null, Validators.required],
      type: ['', Validators.required],
      position: [null, Validators.required],
      entityId: [null],
      hidden: [null],
      placeholder: ['']
    });

    this.route.paramMap.subscribe(params => {
      this.templateInfoId = +params.get('item.id');

      if (this.templateInfoId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle(TR_UPDATE_TEMPLATE_INFO_KEY);
        this.getTemplateInfoById(this.templateInfoId);
      }
    });

    this.getTemplates();

    this.templateObs.subscribe(data => {
      if (!this.selectedTemplateName && this.form.value.documentTemplateId) {
        this.selectedTemplateName = data.find(template => template.id === this.form.value.documentTemplateId).name;
      }
    });
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch (role) {
        case DOCUMENTTEMPLATE_VIEW:
          this.template_view = true;
          break;
        case DOCUMENTTEMPLATE_I_CREATE:
          this.templateInfo_create = true;
          break;
        case DOCUMENTTEMPLATE_I_UPDATE:
          this.templateInfo_update = true;
          break;
        case DOCUMENTTEMPLATE_I_VIEW:
          this.templateInfo_view = true;
          break;
        case LIBRARY_VIEW:
          this.library_view = true;
          break;
      }
    });
  }

  getTemplates(): void {
    if (!this.template_view) { return; }
    this.dataHandlerService.getDataWithSpinner(DOCUMENT_TEMPLATE_PATH_KEY).then(response => {
      this.templates = response;
      this.templatesData.next(this.templates);
    });
  }

  setSelectedTemplate(id: number, name: string): void {
    this.selectedTemplateName = name;
    this.form.patchValue({ documentTemplateId: id });
  }

  setSelectedInfoType(type: string): void {
    this.form.patchValue({ type: type });
    if (this.form.value.type === 'input-string'
      || this.form.value.type === 'inputs-list'
      || this.form.value.type === 'input-short-string'
      || this.form.value.type === 'input-long-string'
      || this.form.value.type === 'sign-row-arr'
      || this.form.value.type === 'table-input-string'
      || this.form.value.type === 'table-string-and-date') {
      this.openPlaceHolderField = true;
    } else {
      this.openPlaceHolderField = false;
    }

    if (this.form.value.type === 'library' || this.form.value.type === 'table-library') {
      this.getLibraries(null);
    } else {
      this.libraries = [];
      this.form.patchValue({ entityId: null });
    }
  }

  getLibraries(entityId: number): void {
    if (!this.library_view) { return; }
    this.dataHandlerService.getData(LIBRARY_PATH_KEY).then(response => {
      this.libraries = response;
      if (entityId) {
        this.selectedLibraryName = this.libraries.find(data => data.id === entityId).name;
      }
    });
  }

  setSelectedLibrary(id: number, name: string): void {
    this.selectedLibraryName = name;
    this.form.patchValue({ entityId: id });
  }

  getTemplateInfoById(id: number): void {
    if (!this.templateInfo_view) { return; }
    this.dataHandlerService.getDataWithSpinner(`${DOCUMENT_TEMPLATE_INFO_PATH_KEY}/${id}`).then(response => {
      const templateInfo: ITemplateInfoData = response;
      this.form.patchValue({
        type: templateInfo.type,
        documentTemplateId: templateInfo.documentTemplateId,
        position: templateInfo.position,
        entityId: templateInfo.entityId,
        hidden: templateInfo.hidden,
        placeholder: templateInfo.placeholder
      });
      if (templateInfo.entityId) {
        this.getLibraries(templateInfo.entityId);
      }
      if (templateInfo.placeholder) {
        this.openPlaceHolderField = true;
      }
      if (this.templates && this.templates.length) {
        this.selectedTemplateName = this.templates.find(data => data.id === templateInfo.documentTemplateId).name;
      }
    });
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      form.position = +form.position;
      for (let i in form) {
        (form[i] === null || form[i] === '') && delete form[i];
      }
      if (this.templateInfo_update && this.templateInfoId) {
        form.id = this.templateInfoId;
        this.dataHandlerService.updateData(form, DOCUMENT_TEMPLATE_INFO_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(TEMPLATE_INFO_URL_KEY);
        });
      } else if (this.templateInfo_create) {
        this.dataHandlerService.createData(form, DOCUMENT_TEMPLATE_INFO_PATH_KEY, this.submitSpinnerId).then(() => {
          this.mainService.goBack(TEMPLATE_INFO_URL_KEY);
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }
}
