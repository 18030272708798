import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { guid } from '@progress/kendo-angular-common';
import { ActivatedRoute } from '@angular/router';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { ITemplateData } from 'src/app/Models/ITemplateData';
import { IDocumentCategoryData } from 'src/app/Models/IDocumentData';
import { AlertService } from 'src/app/Services/alert.service';
import { DOCUMENT_TEMPLATE_PATH_KEY, TEMPLATE_URL_KEY, DOCUMENT_CATEGORY_PATH_KEY } from 'src/app/Constants/request-path-keys';
import {
  DOCUMENTTEMPLATE_CREATE,
  DOCUMENTTEMPLATE_UPDATE,
  DOCUMENTTEMPLATE_VIEW,
  SUCCESS_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_CREATE_TEMPLATE_KEY,
  TR_UPDATE_MESSAGE_KEY,
  TR_UPDATE_TEMPLATE_KEY
} from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-template-form',
  templateUrl: './template-form.component.html',
  styleUrls: ['./template-form.component.scss']
})
export class TemplateFormComponent implements OnInit {
  form: FormGroup;
  templateId: number;
  userRoles: Array<string>;
  templateCategories: Array<IDocumentCategoryData> = [];
  templateCategoriesObs: BehaviorSubject<Array<IDocumentCategoryData>> = new BehaviorSubject<Array<IDocumentCategoryData>>(null);
  selectedCategoryId: number;
  selectedCategoryName: string;
  template_create: boolean;
  template_update: boolean;
  template_view: boolean;
  selectedCategory: string;
  isInvalidSubmit: boolean;
  isCreateForm: boolean = true;
  submitSpinnerId: string = guid();

  constructor(
    private dataHandlerService: DataHandlerService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    public mainService: MainService,
    private translationService: TranslationService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_CREATE_TEMPLATE_KEY);

    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      description: '',
      body: ['', Validators.required],
      documentCategoryId: [null]
    });
    this.getTemplateCategory().then(res => {
      if (res) {
        this.route.paramMap.subscribe(params => {
          this.templateId = +params.get('item.id');

          if (this.templateId) {
            this.isCreateForm = false;
            this.translationService.setPageTitle(TR_UPDATE_TEMPLATE_KEY);
            this.getTemplateById(this.templateId);
          }
        });
      }
    });
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch (role) {
        case DOCUMENTTEMPLATE_CREATE:
          this.template_create = true;
          break;
        case DOCUMENTTEMPLATE_UPDATE:
          this.template_update = true;
          break;
        case DOCUMENTTEMPLATE_VIEW:
          this.template_view = true;
          break;
      }
    });
  }

  getTemplateCategory() {
    return new Promise((res, rej) => {
      this.dataHandlerService.getDataWithSpinner(`${DOCUMENT_CATEGORY_PATH_KEY}`).then(response => {
        this.templateCategories = response;
        this.templateCategoriesObs.next(this.templateCategories)
        res(response)
      });
    })
  }



  getTemplateById(id: number): void {
    if (!this.template_view) { return; }
    this.dataHandlerService.getDataWithSpinner(`${DOCUMENT_TEMPLATE_PATH_KEY}/${id}`).then(response => {
      const templateData: ITemplateData = response;
      this.selectedCategoryId = templateData.documentCategoryId;

      if (this.templateCategories && this.templateCategories.length) {
        const selectedCategory: IDocumentCategoryData = this.templateCategories.find(data => data.id === this.selectedCategoryId);
        if (selectedCategory) {
          this.selectedCategoryName = selectedCategory.name;
        }
      }

      this.form.patchValue({
        name: templateData.name,
        description: templateData.description,
        body: templateData.body,
        documentCategoryId: templateData.documentCategoryId ? +templateData.documentCategoryId : null
        //selectedCategoryName: templateData.documentCategoryName,
        //selectedCategoryId: templateData.documentCategoryId
      });
    });
  }

  setTemplateCategory(categoryId: number, name: string): void {
    this.selectedCategoryName = name;
    this.selectedCategoryId = categoryId;
    this.form.patchValue({ documentCategoryId: categoryId });
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      if (this.templateId && this.template_update) {
        form.id = this.templateId;
        this.dataHandlerService.updateData(form, DOCUMENT_TEMPLATE_PATH_KEY, this.submitSpinnerId).then(() => {
          this.mainService.goBack(TEMPLATE_URL_KEY);
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
        });
      } else if (this.template_create) {
        this.dataHandlerService.createData(form, DOCUMENT_TEMPLATE_PATH_KEY, this.submitSpinnerId).then(() => {
          this.mainService.goBack(TEMPLATE_URL_KEY);
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }
}
