import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/Services/alert.service';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { IQuizData } from 'src/app/Models/IQuizModel';
import { COURSE_URL_KEY, COURSE_VIEW_URL_KEY, QUIZ_PATH_KEY, QUIZ_URL_KEY } from 'src/app/Constants/request-path-keys';
import {
  ALLOWED_ATTEMPTS_ARR_KEY,
  EXPIRE_TYPES_ARR_KEY,
  GRADING_METHODS_ARR_KEY,
  SUCCESS_KEY,
  TIME_TYPES_ARR_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_CREATE_QUIZ_KEY,
  TR_UPDATE_MESSAGE_KEY,
  TR_UPDATE_QUIZ_KEY
} from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-quiz-form',
  templateUrl: './quiz-form.component.html',
  styleUrls: ['./quiz-form.component.scss']
})
export class QuizFormComponent implements OnInit {
  form: FormGroup;
  isInvalidSubmit: boolean;
  isCreateForm: boolean = true;
  quizId: number;
  topicId: number;
  courseId: number;
  userRoles: Array<string> = [];
  timeTypes: Array<string> = TIME_TYPES_ARR_KEY;
  expireTypes: Array<string> = EXPIRE_TYPES_ARR_KEY;
  allowedsAttempts: Array<string> = ALLOWED_ATTEMPTS_ARR_KEY;
  gradingMethods: Array<string> = GRADING_METHODS_ARR_KEY;

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    public mainService: MainService,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.translationService.setPageTitle(TR_CREATE_QUIZ_KEY);

    this.form = this.formBuilder.group({
      entityId: [null],
      entityType: [0],
      name: ['', Validators.required],
      description: [''],
      displayDescription: [false],
      openDate: [''],
      openTime: [''],
      openDateEnabled: [false],
      closeDate: [''],
      closeTime: [''],
      closeDateEnabled: [false],
      timeLimit: [null],
      timeLimitEntity: [1],
      timeLimitEnabled: [false],
      whenTimeExpires: [0],
      gracePeriod: [null],
      gracePeriodEntity: [1],
      gracePeriodEnabled: [false],
      passGrade: [''],
      attemptsAllowed: [0],
      gradingMethod: [0],
      shuffleEnabled: [false]
    });

    this.route.paramMap.subscribe(params => {
      this.quizId = +params.get('quiz.id');
      this.courseId = +params.get('item.id');
      this.topicId = +params.get('settingsTopicId');

      this.topicId && this.form.patchValue({ entityType: 1 });
      this.form.patchValue({ entityId: this.topicId || this.courseId });

      if(this.quizId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle(TR_UPDATE_QUIZ_KEY);
        this.getQuizById();
      }
    });
  }

  getQuizById(): void {
    if(this.quizId) {
      this.dataHandlerService.getDataWithSpinner(`${QUIZ_PATH_KEY}/${this.quizId}`).then(response => {
        const quizData: IQuizData = response;
        this.form.patchValue({
          name: quizData.name,
          description: quizData.description,
          displayDescription: quizData.displayDescription,
          openDate: quizData.openDate,
          closeDate: quizData.closeDate,
          timeLimit: quizData.timeLimit,
          timeLimitEntity: quizData.timeLimitEntity,
          whenTimeExpires: quizData.whenTimeExpires,
          gracePeriod: quizData.gracePeriod,
          gracePeriodEntity: quizData.gracePeriodEntity,
          gracePeriodEnabled: quizData.gracePeriodEnabled,
          shuffleEnabled: quizData.shuffleEnabled
        });
      });
    }
  }

  submitData(): void {
    if(this.form.valid) {
      const form = { ...this.form.value };
      form.openDate = new Date(`${form.openDate} ${form.openTime}`);
      form.closeDate = new Date(`${form.closeDate} ${form.closeTime}`);
      delete form.openTime;
      delete form.closeTime;
      form.timeLimitEntity = +form.timeLimitEntity
      form.gracePeriodEntity = +form.gracePeriodEntity;
      form.whenTimeExpires = +form.whenTimeExpires;
      form.attemptsAllowed = +form.attemptsAllowed;
      form.gradingMethod = +form.gradingMethod;
      form.shuffleEnabled = Boolean(form.shuffleEnabled);

      if(this.quizId) {
        form.id = this.quizId;
        this.dataHandlerService.updateData(form, QUIZ_PATH_KEY, null).then(() => {
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(QUIZ_URL_KEY);
          this.mainService.questionSubmitted.next(false);
        });
      } else {
        this.dataHandlerService.createData(form, QUIZ_PATH_KEY, null).then(() => {
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(QUIZ_URL_KEY);
          this.mainService.questionSubmitted.next(false);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }

  goBack(): void {
    this.mainService.goBackWithParams([COURSE_URL_KEY, COURSE_VIEW_URL_KEY, this.courseId])
  }
}
