<div class="main-card mb-3 card">
  <div class="card-body">
    <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('templates')"></i>
      <h5 class="card-title">{{ isCreateForm ? ('TR_CREATE_TEMPLATE' | translate) : ('TR_UPDATE_TEMPLATE' | translate) }}</h5>
    </div>
    <form (submit)="submitData()" [formGroup]="form">
      <div ngbDropdown>
        <div class="flex flex-column">
          <label>{{ 'TR_DOCUMENT_CATEGORY' | translate }}</label>
          <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-info">
            {{ selectedCategoryName ? selectedCategoryName : 'TR_DOCUMENT_CATEGORY' | translate }}
          </button>
        </div>
        <div ngbDropdownMenu>
          <button type="button" class="dropdown-item" *ngFor="let item of templateCategories" (click)="setTemplateCategory(item.id, item.name)">
            {{ item.name }}
          </button>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-4 col-md-6">
          <div class="position-relative form-group">
            <label for="name">{{ ('TR_NAME' | translate) + mainService.asterisk }}</label>
            <input
              name="name"
              [placeholder]="('TR_NAME' | translate) + mainService.asterisk"
              type="text"
              class="form-control"
              [class.error-border]="isInvalidSubmit && !form.value.name"
              autocomplete="off"
              formControlName="name"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-4 col-md-6">
          <div class="position-relative form-group">
            <label for="description">{{ 'TR_DESCRIPTION' | translate }}</label>
            <textarea
              name="description"
              [placeholder]="'TR_DESCRIPTION' | translate"
              type="text"
              class="form-control"
              autocomplete="off"
              formControlName="description"
            ></textarea>
          </div>
        </div>
      </div>
      <ckeditor class="editor" formControlName="body" [class.error-editor]="isInvalidSubmit && !form.value.body"></ckeditor>
      <app-submit-button
        [spinnerId]="submitSpinnerId"
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit"
        [isFormValid]="form.valid"
      ></app-submit-button>
    </form>
  </div>
</div>