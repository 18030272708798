import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../Services/auth.service';
import { TranslationService } from '../Services/translation.service';
import { MainService } from '../Services/main.service';
import { Router } from '@angular/router';
import { LanguageService } from '../Services/language.service';
import { ADMIN_KEY, ERROR_KEY } from '../Constants/main-keys';
import { AlertService } from '../Services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private translationService: TranslationService,
        private mainService: MainService,
        private router: Router,
        private languageService: LanguageService,
        private alertService: AlertService,
        private modalService: NgbModal,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const requestType = request.method;
        return next.handle(request).pipe(catchError(err => {
            
            if (err instanceof HttpErrorResponse) {
                if (err.status === 400) {
                    let errorMessages: Array<string> = [];
                    const errorObj = Object.values(err.error.errors); ///// CHECK changed error field's get view
                    for (let i in errorObj) {
                        this.translationService.translateValidations(errorObj[i][0]).subscribe((data) => {
                            if (data) {
                                errorMessages.push(data);
                            } else {
                                errorMessages.push(errorObj[i][0]);
                            }
                            if (+i === errorObj.length - 1) {
                                this.mainService.handleValidationError.next(null);
                                this.mainService.handleValidationError.next(errorMessages);
                            }
                        });
                    }
                }
                if (err.status === 401 || err.status === 403) {
                    this.authService.logout();
                    // this.router.navigate([this.languageService.currentLanguage, ADMIN_KEY]);
                    this.router.navigate([this.languageService.currentLanguage]);
                }

            }
            const error = err.message || err.statusText;
            if (error) {
                if (!error.includes(401)) {
                    this.alertService.translateAndAlertMessage(error, ERROR_KEY);
                }else{
                    this.alertService.translateAndAlertMessage("Access denied", ERROR_KEY);

                }
            }
            this.mainService.clearSpinners();
            this.modalService.dismissAll();
            return throwError(error);
        }))
    }
}